import React, { useContext, useEffect, useState, useRef } from 'react';
import { Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatCategoryName } from '../../Utils/Helper';

const AppSideCategory = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [nicheCategoryList, setNicheCategoryList] = useState('');
  const [categoryActive, setCategoryActive] = useState('');

  const { categoryList, categoryFrom } = useContext(AuthContext);

  const categorySearch = searchParams.get('detail');

  const navigate = useNavigate();


  const getNicheCategory = async () => {

    if (categoryList) {
      if (categoryList.length > 0) {
        if (categorySearch) {
          const filterArr = categoryList.find((x) => x.category === categorySearch);
          setNicheCategoryList(filterArr?.subCategory);
        }
      }
    }

  };

  useEffect(() => {
    getNicheCategory();

  }, [categoryList, categorySearch]);

  const handleNavigate = (x) => {


    if (x['1688-id']) {
      navigate(`/category?detail=${categorySearch}&subCategory=${x.title}&id=${x['1688-id']}`);
    } else if (x["amazon-id"]) {
      navigate(`/category?detail=${categorySearch}&subCategory=${x.title}&id=${x["amazon-id"]}`);
    } else if (x["coupang-id"]) {
      navigate(`/category?detail=${categorySearch}&subCategory=${x.title}&id=${x['coupang-id']}`);
    }


    setCategoryActive(x);
  };


  return (
    <Stack
      bgColor={'white'}
      borderRadius='md'
      shadow={'md'}
      p={3}
      h={'450px'}
      position='fixed'
      w={'280px'}
      transition={'all 0.3s ease-in-out'}
    >
      <Stack w={'100%'} bgColor='black' p={2} borderRadius='md'>
        <Heading
          size={'sm'}
          textTransform='capitalize'
          textAlign={'center'}
          color='white'
        >
          {formatCategoryName(categorySearch)}
        </Heading>
      </Stack>
      <Stack h={'400px'} overflowY='scroll'>
        <SimpleGrid columns={3} gap={1}>
          {nicheCategoryList?.length > 0 &&
            nicheCategoryList.map((x, index) => {
              return (
                <Stack
                  bgColor={
                    categoryActive.title === x.title
                      ? 'yellow.200'
                      : 'transparent'
                  }
                  key={index}
                  alignItems='center'
                  p={1}
                  justifyContent={'center'}
                  cursor='pointer'
                  onClick={() => handleNavigate(x)}
                >
                  {x.image && (
                    <Image src={x.image} />
                  )}
                  <Text fontSize={'xs'} noOfLines={1}>
                    {x.title}
                  </Text>
                </Stack>
              );
            })}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default AppSideCategory;
