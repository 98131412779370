import { useState, useEffect, useContext, useRef } from 'react';
import { Button, HStack, Input, InputGroup, Select, useToast } from '@chakra-ui/react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { errorSlack } from '../../Api/SlackApi';
// import { clientTypessense } from '../../Api/Typesense';
import axios from 'axios';
import { sendEcommerceEvent } from '../../Utils/gtm';
// import { CiCamera } from "react-icons/ci";
import _axios from '../../Api/AxiosBarrier';
import AuthContext from '../../Routes/hooks/AuthContext';
// import TypesenseRestApi from '../../Api/TypesenseRestApi';

function AppSearchInput() {
    const [languageType, setLanguageType] = useState("id")
    const [labelSearch, setLabelSearch] = useState("Cari kata kunci pake Bahasa Indonesia / ID Product / Link")
    const [search, setSearch] = useState('');
    // const [recommendations, setRecommendations] = useState([]);
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const toast = useToast();
    const [formData, setFormData] = useState({
        image: ''
    })
    const {loadingShow, loadingClose} = useContext(AuthContext)
    const fileInputRef = useRef();

    const path = window.location.href ?? '#'


    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            setLoading(true)
            if (search === undefined || search === "") {
                return toast({
                    title: 'importir.com',
                    description: 'Please type in your search again',
                    status: 'warning',
                });
            }

            console.log(search, 'yyy')

            const ecommerceData = {
                'currency': 'IDR',
                'value': parseFloat(1),
                'items': [
                    {
                        'item_id': 123456,
                        'item_name': search,
                        'item_category': 1,
                    },
                ],
            };
            sendEcommerceEvent('search', ecommerceData);
            console.log('search', ecommerceData);

            // Jika search adalah URL, langsung navigasi
            if (search.includes('http://') || search.includes('https://')) {
                navigate(`/search?detail=${search}`, { state: search });
                setLoading(false)
            } else {
                const baseUrl = 'https://importir-next.vercel.app/api/services/translate/google';

                try {
                    const data = {
                        text: search,
                        targetLanguage: "en"
                    };

                    const response = await axios.post(baseUrl, data, {
                        headers: {
                            "token": "&Ea#Co99zQ49",
                        },
                    });
                    if (response?.data?.status === true) {
                        const dataRes = response?.data?.data
                        navigate(`/search?detail=${dataRes}`, { state: dataRes });
                    } else {
                        console.log(response.message);
                    }
                } catch (error) {
                    console.log(error, 'error translate')

                    toast({
                        title: 'importir.com',
                        description: error.message,
                        status: 'error',
                    });
                    const resSlack = await errorSlack(error, 'importir.com', path)
                    console.log(resSlack, 'CONNECTED ERROR IN SLACK');
                }
                finally {
                    setLoading(false)
                }
            }
        }
    };

    const handleSearch = async () => {
        setLoading(true)
        if (search === undefined || search === "") {
            setLoading(false)

            return toast({
                title: 'importir.com',
                description: 'Please type in your search again',
                status: 'warning',
            });
        }
        

        const ecommerceData = {
            'currency': 'IDR',
            'value': parseFloat(123456),
            'items': [
                {
                    'item_id': 123456,
                    'item_name': search,
                    'item_category': 1,
                },
            ],
        };
        sendEcommerceEvent('search', ecommerceData);

        // Jika search adalah URL, langsung navigasi
        if (search.includes('http://') || search.includes('https://')) {
            navigate(`/search?detail=${search}`, { state: search });
            setLoading(false)
        } else {
            const baseUrl = 'https://importir-next.vercel.app/api/services/translate/google';

            try {
                if (languageType !== 'en') {
                    const data = {
                        text: search,
                        targetLanguage: languageType
                    };

                    const response = await axios.post(baseUrl, data, {
                        headers: {
                            "token": "&Ea#Co99zQ49",
                        },
                    });
                    if (response?.data?.status === true) {
                        const dataRes = response?.data?.data
                        navigate(`/search?detail=${dataRes}`, { state: dataRes });
                    } else {
                        console.log(response.message);
                    }
                } else {
                    navigate(`/search?detail=${search}`, { state: search });
                }
            } catch (error) {
                console.log(error, 'error translate')

                toast({
                    title: 'importir.com',
                    description: error.message,
                    status: 'error',
                });
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK');
            }
            finally {
                setLoading(false)
            }
        }
    }

    // const fetchRecommendations = async () => {
    //     // Simulating an API call to fetch recommendations

    //     if (search.length > 0) {
    //         let searchParameters;


    //         searchParameters = {
    //             q: search,
    //             query_by: "title-en",
    //             sort_by: "_text_match:desc",
    //             per_page: 3, // Limit the number of search results to 3
    //         };

    //         const searchResult = await TypesenseRestApi(searchParameters)

    //         // const searchResult = await clientTypessense
    //         //     .collections("products")
    //         //     .documents()
    //         //     .search(searchParameters);

    //         const searchResultTitle = searchResult.hits.map((hit) => hit.document['title-en']?.en);
    //         setRecommendations(searchResultTitle)
    //     }
    // };

    // const handleIconClick = () => {
    //     fileInputRef.current.click();
    // };

    const onHandleUploadImage = async (event) => {
        const data = event.target.files[0]
        try {
            loadingShow()
            const params = new FormData();
            params.append('file', data);
            
            const response = await _axios.post('api/storage', params);
            if (response.status === true) {
                setFormData({ ...formData, image: response.data });
                toast({
                    title: 'importir.com',
                    description: 'Successfully save image',
                    status: 'success',
                });
            }
            loadingClose()
            window.location.href = `/search-by-image?url=${response.data}`
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    useEffect(() => {
        // fetchRecommendations();
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (languageType === 'id') setLabelSearch('Cari kata kunci pake Indonesia / ID Product / Link')
        if (languageType === 'en') setLabelSearch('Search By Keyword in English / ID Product / Link')
        if (languageType === 'zh') setLabelSearch('按英文关键字搜索 / ID 产品 / 链接')
    }, [languageType])

    return (
        <HStack alignItems="center" justifyContent="center" mb={2}>
            <HStack w={['100%', '100%', '100%']} spacing={4}>
                <InputGroup size="sm">
                    {/* <InputLeftElement pointerEvents="none">
                        <BiSearchAlt2 color="gray.300" />
                    </InputLeftElement> */}
                    <Input
                        type="file"
                        display="none"
                        ref={fileInputRef}
                        onChange={(e) => onHandleUploadImage(e)}
                    />
                    
                    {/* <InputLeftElement cursor={'pointer'} onClick={handleIconClick}>
                        <CiCamera size={'20'}
                        className='search-image' />
                    </InputLeftElement> */}
                    <Input
                        defaultValue={search}
                        bgColor="white"
                        size="sm"
                        borderRadius="md"
                        placeholder={labelSearch}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={handleKeyDown}
                        list="search-suggestions"
                    />
                    <Select borderRadius={'md'} bgColor={'white'} w={{base: '70%', lg: '20%'}} onChange={e => setLanguageType(e.target.value)}>
                        <option value="id">Indonesia</option>
                        <option value="en">English</option>
                        <option value="zh">Chinese</option>
                    </Select>
                    
                    {/* {recommendations.length > 0 && (
                        <datalist id="search-suggestions">
                            {recommendations.map((item) => (
                                <option key={item} value={item} />
                            ))}
                        </datalist>
                    )} */}
                </InputGroup>
                <Button isLoading={loading} onClick={handleSearch} colorScheme='blackAlpha' size={'sm'} shadow='md'>
                    <HStack px={2}>
                        <BiSearchAlt2 color="gray.300" />
                    </HStack>
                </Button>
            </HStack>
        </HStack>
    );
}

export default AppSearchInput;
