import React from 'react';
import { Box, Divider, Flex, HStack, Icon, Spacer, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import CopyToClipboard from '../../Utils/CopyToClipboard';
import moment from 'moment';
import { GiShop  } from 'react-icons/gi';
import { FaPenFancy, FaWarehouse, FaTag } from "react-icons/fa6";
import { MdShoppingCartCheckout, MdWarehouse } from "react-icons/md";
import { FaMoneyCheck } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";

const stepsDummy = [
  { title: 'SIGNATURE OF MCC', description: 'Customer has paid', icon: FaPenFancy },
  { title: 'PRODUCT CATALOG', description: 'sales by mitra', icon: MdShoppingCartCheckout },
  { title: 'PRODUCT PAID', description: 'Purchase By Mitra', icon: FaMoneyCheck },
  { title: 'SETUP MARKETPLACE', description: 'Order completed', icon: GiShop },
  { title: 'SETUP FULFILLMENT', description: 'Order completed', icon: FaWarehouse },
  { title: 'PRODUCT IN WAREHOUSE', description: 'Order completed', icon: MdWarehouse },
  { title: 'SELLING', description: 'Order completed', icon: FaTag },
  { title: 'TARGET FULFILLED', description: 'Order completed', icon: FiTarget },
];

const Step = ({ title, description, isActive, isCompleted, lastStep, icon }) => {
  return (
    <Stack>
      <Stack alignItems={["center", 'center', "center"]} justifyContent={["center", 'center', "center"]}>
        {/* <Box
        w="24px"
        h="24px"
        borderRadius="full"
        bg={isActive || isCompleted ? 'red.500' : 'gray.300'}
        mr={4}
        transition="background-color 0.3s"
      > */}
        <Icon transition="background-color 0.3s"
          boxSize='10' bgColor={isCompleted ? '#ffd600' : 'transparent'} as={icon} p='1.5' />
        {/* </Box> */}

      </Stack>
      <Stack alignItems={["center", 'center', "center"]}>
        <Text fontWeight={isActive ? 'bold' : 'normal'} fontSize='sm' textAlign={'center'} textTransform="uppercase">
          {title}
        </Text>
        <Text fontSize="xs">{description ? moment(description).format('LLL') : ''}</Text>
        <Text fontSize="xs" fontStyle="italic" fontWeight="bold">
          {lastStep === title ? moment(description).subtract('YYYYMMDD').fromNow() : ''}
        </Text>
      </Stack>
    </Stack>
  );
};

const CustomStepper = ({ currentStep, message, steps }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getStepIndex = () => {
    return stepsDummy.findIndex((step) => step.title === currentStep);
  };

  const updatedSteps = stepsDummy.map((step, index) => {
      const matchedStep = steps.find((s) => s.title === step.title);
      if (step.title == 'PRODUCT PAID' && matchedStep !== undefined){
        const signature = stepsDummy.find((item) => item.title == 'SIGNATURE OF MCC');
        stepsDummy[0] = {
          ...signature,
          description: matchedStep ? matchedStep.created_at : '',
        }

        const productCatalog = stepsDummy.find((item) => item.title == 'PRODUCT CATALOG');
        stepsDummy[1] = {
          ...productCatalog,
          description: matchedStep ? matchedStep.created_at : '',
        }
      }
      return stepsDummy[index] = {
      ...step,
      description: matchedStep ? matchedStep.created_at : '',
    };
  });

  return (
    <Stack>
      <Flex direction={isMobile ? 'column' : 'row'}>
        {stepsDummy.length > 0 &&
          stepsDummy.map((step, index) => (
            <React.Fragment key={index}>
              {index !== 0 && !isMobile && (
                <Box
                  flex="1"
                  mx={3}
                  h="2px"
                  bg={step.description !== '' ? '#ffd600' : 'gray.300'}
                  alignSelf="center"
                  transition="background-color 0.3s"
                />
              )}
              <Stack>
                <Step
                  title={step.title}
                  icon={step.icon}
                  description={step.description}
                  isActive={step.title === currentStep}
                  isCompleted={step.description !== ''}
                  lastStep={currentStep}
                />
              </Stack>
            </React.Fragment>
          ))}
      </Flex>
      <Divider py={1} />
      <HStack>
        <Text fontSize={'sm'} color="gray.600">
          Message :
        </Text>
        <Text fontSize={'sm'}>{message}</Text>
        <Spacer />
        <CopyToClipboard text={message} />
      </HStack>
    </Stack>
  );
};

export default CustomStepper;