import { Button, Heading, Input, Stack, Text, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import store from 'store'
import { db } from '../../Config/firebase'
import moment from 'moment'
function LoginAsMember() {

    const [email, setEmail] = useState('')
    const [emailMember, setEmailMember] = useState('')
    const [password, setPassword] = useState('')

    let [searchParams, setSearchParams] = useSearchParams()

    const emailMemberParams = searchParams.get('email-member')
    const emailAdminParams = searchParams.get('email-admin')

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const getToken = async () => {

        setLoading(true)

        const user = {
            email: emailAdminParams,
            email_member: emailMemberParams,
            password: password
        }
        try {
            const res = await axios.post(process.env.REACT_APP_API_COM + 'api/general/login-as-admin', user)

            if (res.status === 200) {
                let dataUserDb = {}
                if (res.data.status === true) {
                    toast({
                        title: 'importir.com',
                        description: 'succes get data member',
                        status: 'success',
                    });

                    try {
                        const querySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', emailMemberParams)));
                        if (querySnapshot.empty) {
                            toast({
                                title: 'importir.com',
                                description: 'Please chat admin for register member ',
                                status: 'warning',
                            });
                        } else {
                            const userDataArray = querySnapshot.docs.map((doc) => doc.data());
                            dataUserDb = userDataArray[0];
                        }
                    } catch (error) {
                        console.log(error, 'ini error')
                    }
                    return activeUser(res.data.data.token, true, emailMemberParams, res.data.data.token_user, dataUserDb.data_importir)
                } else {
                    toast({
                        title: 'importir.com',
                        description: res.data.message,
                        status: 'error',
                    });
                }
            }
            setLoading(false)
        } catch (error) {
            console.log(error, 'ini error')
            setLoading(false)
        }
        setLoading(false)
    }

    const activeUser = (token, status, emailMember, url, data) => {
        const dateNow = moment();
        const expiredDate = dateNow.add(3, 'days');
        store.set('user_api', data.user)
        store.set('package_user', data.package_user.package_child)
        store.set('user_package', data.package_user)
        store.set('lang', data.user.country_code !== 'id' ? 'en' : 'id')
        store.set('user_token', token);
        store.set('expiredAt', expiredDate);
        store.set('as_admin', status);
        store.set('as_member_email', emailMember);
        window.location.href = '/'
        window.open(url)
    }


    return (
        <Stack alignItems={'center'} justifyContent='center'>
            <Stack bgColor={'white'} w={['80%', '60%', '40%']} p={5} borderRadius='md' shadow={'md'} >
                <Heading size={'md'}>Login As Member</Heading>
                <Stack p={5} spacing={3}>
                    <Stack>
                        <Text>Email Admin</Text>
                        <Input size={'sm'} isDisabled={true} placeholder={emailAdminParams} />
                    </Stack>

                    <Stack>
                        <Text>Password</Text>
                        <Input size={'sm'} type='password' onChange={(e) => setPassword(e.target.value)} />
                    </Stack>

                    <Stack>
                        <Text>Email User</Text>
                        <Input size={'sm'} isDisabled={true} placeholder={emailMemberParams} />
                    </Stack>


                    <Button size={'sm'} onClick={() => getToken()}>
                        <Text>Submit</Text>
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default LoginAsMember