// import { useEffect,useState } from "react"
import React from 'react'
// import { Ionicons } from '@expo/vector-icons'
import { BsPlus } from 'react-icons/bs'
import { BiMinus } from 'react-icons/bi'
import { Button, HStack, Icon, Input, Text } from '@chakra-ui/react'

export default function ButtonQuantity ({quantity, pressButton, isCustom = false}) {
  return(
    <HStack space={0.3} justifyContent="center" alignItems={'center'} >
      <Button size="xs" variant="outline" onClick={() => pressButton(false)}>
        <Icon as={BiMinus} name="remove-outline" size="xs" color="#000" />
      </Button>
      {
        !isCustom ? 
        <Input 
        alignItems={'center'}
        justifyContent='center'
        fontSize={'xs'}
        value={quantity} 
        onChange={pressButton}
        type='number'
        size={'sm'}
      borderRadius='md'
        />
        : 
        <Text>{quantity}</Text>
      }
      <Button size="xs" variant="outline" onClick={() => pressButton(true)}>
        <Icon as={BsPlus} name="add-outline" size="xs" color="#000" />
      </Button>
    </HStack>
  )
}