/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import _axios from "./Api/AxiosBarrier";
import { errorSlack } from "./Api/SlackApi";
import PopupImage from "./Components/Basic/PopupImage";
import Preloader from "./Components/Basic/Preloader";
import AuthContext from "./Routes/hooks/AuthContext";
import MainRoute from "./Routes/MainRoute";
import store from "store";
import {JitsuProvider} from "@jitsu/jitsu-react";
import { addFacebookPixel } from "./Utils/facebook";
function App() {
  const { currentUser, setUsedPopupFunc, setCategoryFunc } =
    useContext(AuthContext);
  const location = useLocation();
  const [popupData, setPopupData] = useState([]);

  let [searchParams] = useSearchParams();

  const categoryActive = searchParams.get("country");

  const getDataPopup = async () => {
    if (currentUser) {
      try {
        const res = await _axios.get(
          "api/old-popup-poster/get-popup-poster-by-user-country"
        );
        if (res.status === true) {
          if (res?.data?.length > 0) {
            const dataFind = res?.data.filter((x) => parseInt(x?.is_active) === 1);
            setPopupData(dataFind);
          }
        } else {
          const resSlack = await errorSlack(
            res.message,
            "importir.co",
            "Popup Display"
          );
          console.log(resSlack, "CONNECTED ERROR IN SLACK");
        }
      } catch (error) {
        console.log(error, " ini error");
        const resSlack = await errorSlack(
          error.message,
          "importir.co",
          "Popup Display"
        );
        console.log(resSlack, "CONNECTED ERROR IN SLACK");
      }
    }
  };

  const handlePopupView = () => {
    setUsedPopupFunc(true);
  };

  const handlePopupClose = (_id) => {
    console.log("handlec", _id)
    var now = new Date();
    var time = now.getTime();
    time += 6 * 3600 * 1000;
    // time += 3600 * 1000; // per 1 hour
    now.setTime(time);
    document.cookie = 
    'popup_' + _id + '=1'+ 
    '; expires=' + now.toUTCString() + 
    '; path=/';
    setUsedPopupFunc(false);
  };

  useEffect(() => {
    handlePopupView();
    getDataPopup();

    const categoryFromStore = store.get("categoryFrom");
    if (categoryActive) {
      if (categoryActive === "usa") {
        setCategoryFunc("usa");
      }

      if (categoryActive === "korea") {
        setCategoryFunc("korea");
      }
    } else {
      if (categoryFromStore) {
        setCategoryFunc(categoryFromStore);
      }
    }
    const checkWebConfig = store.get('webConfig');
    if (!checkWebConfig){
      store.set('webConfig', {
        facebook: 3918751638161726,
        tag_manager: "GTM-58TGV2Z"
      })
    }
    addFacebookPixel();
  }, [currentUser]);

  const showPopup = (x) => {
    if (document.cookie.includes("popup_" + x._id)) {
      return false
    }
    return true
  }
  return (
    <>
      <JitsuProvider options={{host: "https://data.autopilot.co.id"}}>
        {/* dinyalain pas natal ya */}
          {/* <Snowfall
            snowflakeCount={100} // Adjust as needed
            snowflakeDivs={[]}
            zIndex={100000} // Set the z-index to be the highest
            snowflakeColor="white" // Customize snowflake color
            snowflakeStyle={{ opacity: 5 }} // Customize snowflake style
          /> */}
          <Preloader />
          {popupData?.length > 0 &&
            location.pathname === "/" &&
            popupData.map((x) => (
              showPopup(x) ?
                <PopupImage
                  data_x={x}
                  link_ref={x.link_ref}
                  link_mobile={x.link_mobile}
                  link_web={x.link_web}
                  handlePopupClose={handlePopupClose}
                />
              : ""
            ))}
          <MainRoute />
      </JitsuProvider>
    </>
  );
}

export default App;
