import { Stack, Card, CardBody, Image, Divider, Input, Button, Text, Heading, Link, useToast, Box, InputGroup, InputLeftAddon, InputRightElement } from '@chakra-ui/react'
import React, { useState, useContext } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { MdEmail, MdVpnKey, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import _axios from '../../Api/AxiosBarrier';
import AuthContext from '../../Routes/hooks/AuthContext';
import store from 'store'
import colors from '../../Utils/colors';

function FormNewPassword() {
    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const { loadingShow, loadingClose } = useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        'password' : '',
        'confirm_password': '',
        'email': params.get('email'),
        'hashToken': params.get('token')
    });

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const handleChangeForm = (nameKey, valueKey) => {
        setFormData({ ...formData, [nameKey]: valueKey });
    }

    const changePassword = async () => {
        try {
            loadingShow()
            const data = await _axios.post('api/reset-password', formData)
            loadingClose()
            if (data.status) {
                toast({
                    title: 'Success',
                    description: data.message,
                    status: 'success',
                });
                navigate('/login')
            } else {
                loadingClose()
                toast({
                    title: 'Upps!!!',
                    description: data.message,
                    status: 'error',
                }); 
            }
        } catch (error) {
            loadingClose()
            toast({
                title: 'Upps!!!',
                description: error,
                status: 'error',
            }); 
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
  return (
    <Stack alignItems={'center'} px={10} py={10}>
        <Card maxW='sm' bgColor={'white'}>
            <CardBody>
                <Heading size='md'>Create New Password</Heading>
                <Text>
                    Your new password must be different from previous used passwords
                </Text>
                <Image
                src='https://d2jnbxtr5v4vqu.cloudfront.net/images/amico-2020_12_28_13_59_26.png'
                alt='image not found'
                borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Box>
                        <Text mb='8px'>Password</Text>
                        <InputGroup w={{
                            base: '92%',
                            md: '92%'
                        }}>
                            <InputLeftAddon shadow={'md'} children={<MdVpnKey size={24} color="black" />} />
                            <Input
                            w={{
                                base: '100%',
                                md: '100%'
                            }}
                            placeholder="Password"
                            name='password'
                            fontSize={'md'}
                            shadow={'md'}
                            type={showPassword ? "text" : "password"}
                            bgColor={'white'}
                            color={colors.black}
                            onChange={(e) => handleChangeForm("password", e.target.value)}
                            />
                            <InputRightElement>
                            {showPassword ? (
                                <MdVisibilityOff
                                size={20}
                                color="black"
                                onClick={togglePasswordVisibility}
                                style={{ cursor: 'pointer' }}
                                />
                            ) : (
                                <MdVisibility
                                size={20}
                                color="black"
                                onClick={togglePasswordVisibility}
                                style={{ cursor: 'pointer' }}
                                />
                            )}
                            </InputRightElement>
                        </InputGroup>
                    </Box>
                    <Box>
                        <Text mb='8px'>Confirmation Password</Text>
                        <InputGroup w={{
                            base: '92%',
                            md: '92%'
                        }}>
                            <InputLeftAddon shadow={'md'} children={<MdVpnKey size={24} color="black" />} />
                            <Input
                            w={{
                                base: '100%',
                                md: '100%'
                            }}
                            placeholder="Confirm Password"
                            name='confirm_password'
                            fontSize={'md'}
                            shadow={'md'}
                            type={showConfirmPassword ? "text" : "password"}
                            bgColor={'white'}
                            color={colors.black}
                            onChange={(e) => handleChangeForm("confirm_password", e.target.value)}
                            />
                            <InputRightElement>
                            {showConfirmPassword ? (
                                <MdVisibilityOff
                                size={20}
                                color="black"
                                onClick={toggleConfirmPasswordVisibility}
                                style={{ cursor: 'pointer' }}
                                />
                            ) : (
                                <MdVisibility
                                size={20}
                                color="black"
                                onClick={toggleConfirmPasswordVisibility}
                                style={{ cursor: 'pointer' }}
                                />
                            )}
                            </InputRightElement>
                        </InputGroup>
                    </Box>
                    <Button bg={'#ffd600'} textColor={'black'} onClick={() => changePassword()}>
                        Send
                    </Button>
                </Stack>
                <Text mt={5}>
                    Remember password ? <Link onClick={() => navigate('/login')} color={'blue'}>Login</Link> 
                </Text>
            </CardBody>
        </Card>
    </Stack>
  )
}

export default FormNewPassword