import { Button, Grid, Heading, HStack, Image, Spacer, Stack, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer, } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import { formatFrice, readMore } from '../../Utils/Helper'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import { IoCaretBackOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'

function OldOrderDetailPage() {
    const {t} = useTranslation();
    const [orderList, setOrderList] = useState([]) 
    const [page, setPage] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const {loadingShow, loadingClose} = useContext(AuthContext)
    const navigate = useNavigate()

    const fetchData = async (page) => {
        try {
            const res = await _axios.get(`api/orders/old-order?page=${page}`)
            setLastPage(res.last_page)
            if (page === 1) {
                setOrderList(res.data)
            } else {
                setOrderList(previousData => [...previousData, ...res.data])
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);
    
    const handleLoadmore = async () => {
        if (currentPage < lastPage) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    }
  return (
    <Stack bgColor={'gray.100'} p={[1, 1, 5]}>
            <HStack>
                <HStack cursor='pointer' zIndex='50' w='100px' mx={5} mb={2} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                    <IoCaretBackOutline size={15} />
                    <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
                </HStack>
                <Spacer />
            </HStack>


            <Grid templateColumns={{ base: "1fr", md: "1fr 4fr" }}>
                <Stack p={[1, 1, 5]}>
                    <Heading size={'md'}>
                        {t('old_order_history_list')}
                    </Heading>
                </Stack>
            </Grid>

            {orderList.length > 0 ? (
                <Stack>
                    <Stack p={[1, 1, 5]}>
                        <TableContainer>
                            <Table variant='striped' colorScheme='teal'>
                                <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th>{t('product_image')}</Th>
                                    <Th>{t('invoice_number')}</Th>
                                    <Th>{t('product_name')}</Th>
                                    <Th>{t('product_price')}</Th>
                                    <Th>Status</Th>
                                    <Th>{t('payment_status')}</Th>
                                    <Th>{t('actions')}</Th>
                                </Tr>
                                </Thead>
                                <Tbody>
                                {orderList?.map((x, index) => (
                                    <Tr>
                                        <Td>{index + 1}</Td>
                                        <Td>
                                            <Image
                                                w={'36'}
                                                src={x?.yg_order_detail?.product_image}
                                            />
                                        </Td>
                                        <Td>{x?.invoice_no}</Td>
                                        <Td>{readMore(x?.yg_order_detail?.product_title) }</Td>
                                        <Td>Rp. {formatFrice(parseInt(x?.yg_order_detail?.price_total))}</Td>
                                        <Td>{x?.current_status !== "" ? x?.current_status : "-"}</Td>
                                        <Td>{x?.payment_status}</Td>
                                        <Td>
                                            <Button colorScheme='orange' onClick={() => navigate(`/old-order/${x.id}`)}>Detail</Button>
                                        </Td>
                                    </Tr>
                                    
                                ))} 
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Stack>
                    {
                        currentPage < lastPage && ( 
                        <Stack justifyItems={'center'} alignItems={'center'}>
                            <Button colorScheme='blue'  w={'40'} onClick={() => handleLoadmore()}>{t('load_more')}</Button>
                        </Stack>
                    )}
                </Stack>
            )
            :
            <Text></Text>
            }
        </Stack >
  )
}

export default OldOrderDetailPage