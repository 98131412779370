/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Heading,
    Stack,
    HStack,
    Divider,
    Button,
    Modal,
    Input,
    VStack,

    Select,

    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
    Spinner,
    Textarea
} from '@chakra-ui/react'
import _axios from '../../Api/AxiosBarrier';
import colors from '../../Utils/colors';
import { BsCheckCircle } from 'react-icons/bs';
import { errorSlack } from '../../Api/SlackApi';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export default function AddressList({ data, setActive, updateData, openModal }) {
    const {t} = useTranslation();
    const [addressModal, setAddressModal] = useState(false);
    const [numberPhone, setNumberPhone] = useState('')
    const [label, setLabel] = useState('')
    const [recipients, setRecipients] = useState('')
    const [city, setCity] = useState('')
    const [cityFix, setCityFix] = useState('')
    const [cityArr, setCityArr] = useState([])
    const [address, setAddress] = useState('')
    const [postal_code, setPostal_code] = useState('')
    const [loading, setLoading] = useState(false)

    const path = window.location.href ?? '#'

    const toast = useToast({
		position: "top",
		align: "center",
	});


    const changeAddressList = () => {
        setAddressModal(true)
    }

    const handleSubmit = async () => {
        setLoading(true)
        const addressSplit = cityFix.split(",")

        const params = {
            province: addressSplit[2],
            city: addressSplit[1],
            district: addressSplit[0],
            label: label,
            phone: numberPhone,
            address: address,
            receiver: recipients,
            postal_code: postal_code,
        };

        if (params) {
            try {
                // loadingShow()
                const res = await _axios.post(`api/address`, params)
                if (res.status === true) {
                    updateData()
                    setAddressModal(false)
                    toast({
                        title: 'importir.com',
                        description: res.message,
                        status: 'success'
                    })
                }
                setLoading(false)
            } catch (error) {
                console.log(error.message)
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')

                setLoading(false)
            }
            // loadingClose()
            setLoading(false)
        }
    }


    const getDataSearch = () => {
        return new Promise((resolve, reject) => {
            _axios.get(`api/address/search?keyword=${city}`).then((res) => {
                setCityArr(res.data)

            })
                .catch(err => reject(err))
        })
    }

    const deleteAddress = async (id) => {
        try {
            openModal()
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
              }).then(async(result) => {
                if (result.isConfirmed) {
                    setLoading(true)
                    const res = await  _axios.delete(`api/address/${id}`)
                    if (res.status === true) {
                        toast({
                            title: 'importir.com',
                            description: res.message,
                            status: 'success'
                        })
                        updateData()
                    }
                    setLoading(false)
                }
              });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDataSearch()

        return () => {
            setCityArr([])
        }
    }, [city])



    const renderAddressItem = () => {
        return data.map((x) => {
            return (
                <Box
                    alignItems="center"
                    borderColor="coolGray.200"
                    borderWidth="1"
                    borderRadius={'md'}
                    bg={x.is_active ? 'green.100' : 'gray.100'}
                    key={x.id}
                    shadow={1}
                    cursor={!x.is_active ? "pointer" : ''}
                >
                    <Stack onClick={() => setActive(x)}>
                        <Box rounded="lg" overflow="hidden">
                            <Stack p="4" space={3} w='100%' cursor={'pointer'}>
                                <Stack space={2}>
                                    <HStack>
                                        <Heading size="sm" textTransform={'capitalize'} >
                                            {x.label}
                                        </Heading>
                                        {x.is_active ? (<BsCheckCircle color='green' />) : ''}
                                    </HStack>

                                    <Text fontSize="sm" textTransform={'capitalize'} _light={{
                                        color: x.is_active ? "green.700" : "gray.700"
                                    }} _dark={{
                                        color: "green.700"
                                    }} fontWeight='bold'>
                                        {x.receiver}
                                    </Text>
                                </Stack>
                                <Text fontSize={'sm'}>
                                    {x.address}, {x.district}, {x.city}, {x.province}
                                </Text>
                                <Text fontSize={'sm'}>
                                    {x.phone}
                                </Text>
                            </Stack>
                        </Box>
                    </Stack>
                    {
                        x.is_active === 0 ? 
                            <Button m="5" colorScheme='red' onClick={() => deleteAddress(x.id)}>{t('delete')}</Button>
                        : <Text></Text>
                    }
                </Box>
            )
        })
    }


    return (
        <>
            <HStack>
                <Stack horizontal={true} maxH={96} overflowY={"auto"} p={5}>
                    {renderAddressItem()}
                </Stack>
            </HStack>
            <Divider bg="gray.200" thickness="1" my="3" />
            <Stack mt="5" alignItems={'center'} justifyContent='center' spacing={2}>
                <Heading size="sm" >{t('want_to_add_address')}</Heading>
                <Button onClick={() => changeAddressList()} bgColor={colors.theme}>
                    <Text color={colors.black}>{t('add_new_address')}</Text>
                </Button>
            </Stack>


            <Modal isOpen={addressModal} onClose={() => setAddressModal(false)} >
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <HStack spacing={2} alignItems='center' >
                            <Text fontSize={'lg'} fontWeight='bold'>{t('new_address')}</Text>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <VStack spacing={5} m={10} fontSize='sm' >

                            <Input
                                id="label"
                                color={colors.black}

                                onChange={(e) => setLabel(e.target.value)}
                                placeholder="Label"
                            />

                            <Input
                                id="numberPhone"
                                type="number"
                                color={colors.black}
                                onChange={(e) => setNumberPhone(e.target.value)}
                                placeholder={t('receiver_phone')}
                            />

                            <Input
                                id="recipients"
                                color={colors.black}
                                onChange={(e) => setRecipients(e.target.value)}
                                placeholder={t('receiver_name')}
                            />

                            <Input
                                id="city"
                                color={colors.black}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder={`${t('city')}/${t('district')}. Ex: Jakarta`}
                            />
                            <Select onKeyUp={(e) => setCityFix(e.target.value)} fontSize='md' bgColor={'white'} onChange={(e) => setCityFix(e.target.value)}>   
                                {
                                    city.length > 0 && cityArr.length === 0 ? 
                                    <>
                                        <option><Spinner /></option>
                                    </> : 
                                    <>
                                        <option>{t('choose_address')}</option>
                                        {cityArr.map((x, index) => (
                                            <option value={x} key={index}>{x}</option>
                                        ))}
                                    </>
                                }
                            </Select>

                            <Input
                                id="address"
                                color={colors.black}
                                onChange={(e) => setPostal_code(e.target.value)}
                                placeholder={t('postal_code')}
                            />
                            <Textarea 
                                id="address"
                                color={colors.black}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder={t('full_address')} />

                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={() => setAddressModal(false)}>
                            {t('close')}
                        </Button>
                        {loading ? (
                            <Stack>
                                <Spinner />
                            </Stack>
                        ) : (
                            <Button colorScheme='green' onClick={() => handleSubmit()}>{t('submit')}</Button>

                        )}
                    </ModalFooter>

                </ModalContent>
            </Modal>

        </>
    )
}