import axios from "axios";

export const get = async (data, param) => {
  let response = {};
  const baseUrl = `https://importir.com/api/belanja-co-id/${data}?token=syigdfjhagsjdf766et4wff6&${param}`;
  // console.log(baseUrl, 'this is the url')
  const configurationObject = {
    url: baseUrl,
    method: "GET",
  };

  try {
    // console.log('ini response')
    const resp = await axios(configurationObject);
    response = resp.data;
    // console.log(response.data,'ini response')
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const getNew = async (data, type, param) => {
  let response = {};
  const baseUrl = `https://new-apiv2.importir.com/api/${data}/${type}?token=syigdfjhagsjdf766et4wff6&${param}`;

  const configurationObject = {
    url: baseUrl,
    method: "GET",
  };

  try {
    // console.log('ini response')
    const resp = await axios(configurationObject);
    response = resp.data;
    // console.log(response.data,'ini response')
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const viewSingleProduct = async (id, flag) => {
  let response = {};
  const baseUrl = `${process.env.REACT_APP_API_NODE}api/products/${flag}/${id}`;
  const configurationObject = {
    url: baseUrl,
    method: "GET",
  };

  try {
    const resp = await axios(configurationObject);
    response = resp.data;
    if (response.status === false) {
      throw new Error(response.message); // Throw an error with the response message
    }
  } catch (error) {
    response.message = error.message; // Assign the error message to response.message
  }
  return response;
};

export const apiPaymentXenditStaging = async (data, type) => {
  let response = {};
  // const baseUrl=`https://dev-api.importir.com/api/belanja-co-id/${type}`
  const baseUrl = `https://dev-api.importir.com/api/payment/custom-create-va`;
  const configurationObject = {
    url: baseUrl,
    method: "POST",
    data: data,
  };
  

  try {
    const resp = await axios(configurationObject);
    response = resp.data;
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const apiCheckoutBill = async (data, type) => {
  let response = {};
  // const baseUrl=`https://dev-api.importir.com/api/belanja-co-id/${type}`
  const baseUrl = `https://dev-api.importir.com/api/carts/titip-beliin/store`;
  const configurationObject = {
    url: baseUrl,
    method: "POST",
    data: data,
  };
  

  try {
    const resp = await axios(configurationObject);
    response = resp.data;
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const postImportirAuth = async (data, type) => {
  let response = {};
  // const baseUrl=`https://dev-api.importir.com/api/belanja-co-id/${type}`
  const baseUrl = `https://new-apiv2.importir.com/api/belanja-co-id/${type}`;
  const configurationObject = {
    url: baseUrl,
    method: "POST",
    data: data,
  };

  try {
    const resp = await axios(configurationObject);
    response = resp.data;
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const transactionImportir = async (data, auth) => {
  let response = {};
  // console.log(auth, 'this is the data')

  // const baseUrl=`https://dev-api.importir.com/api/blj-cart/store`
  const baseUrl = "https://new-apiv2.importir.com/api/blj-cart/store";
  const configurationObject = {
    url: baseUrl,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  };

  try {
    //   console.log(data,auth,'in axios')
    const resp = await axios(configurationObject);
    response = resp;
    // console.log(resp,'ini response')
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const videoImportirProduct = async () => {
  let response = {};
  const baseUrl =
    "https://importir.com/api/belanja-co-id/product-video-list?token=syigdfjhagsjdf766et4wff6";
  const configurationObject = {
    url: baseUrl,
    method: "GET",
  };

  try {
    const resp = await axios(configurationObject);
    response = resp.data;
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const getSeminarMember = async () => {
  let response = {};
  const baseUrl = `${process.env.REACT_APP_API_COM}api/home/get-list-seminar-member`
  const configurationObject = {
    url: baseUrl,
    method: "GET",
  }

  try {
    const resp = await axios(configurationObject)
    response = resp.data;
  } catch (error) {
    console.log(error.message)
  }
  return response;
}

export const getSeminar = async () => {
  let response = {};
  const baseUrl = "https://new-admin.importir.com/api/home/get-list-org-schedule"
  const configurationObject = {
    url: baseUrl,
    method: "GET",
  }

  try {
    const resp = await axios(configurationObject)
    response = resp.data;
  } catch (error) {
    console.log(error.message)
  }
  return response;
}

export const getSourceForYou = async () => {
  let response = {};
  const baseUrl = "api/sfy/list-sfy/5"
  const configurationObject = {
    url: baseUrl,
    method: "GET",
  }

  try {
    const resp = await axios(configurationObject)
    response = resp.data;
  } catch (error) {
    console.log(error.message)
  }
  return response;
}

export const translateApi = async (token, body) => {
  let response = {};
  console.log(token, body, 'ini isi')
  const baseUrl = `https://importir-next.vercel.app/api/services/translate/google`
  const configurationObject = {
    url: baseUrl,
    method: "POST",
    data: body,
    headers: {
      token,
    },
  }

  try {
    const resp = await axios(configurationObject)
    response = resp.data;
  } catch (error) {
    console.log(error.message)
    throw error;
  }
  return response;
}


export const getListAcademy = async (token) => {
  let response = {};
  // const baseUrl = `https://stage.edutama.id/api/frontend/academy`
  const baseUrl = `https://edutama.id/api/frontend/academy`
  const configurationObject = {
    url: baseUrl,
    method: "GET",
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  try {
    const resp = await axios(configurationObject)
    response = resp.data;
  } catch (error) {
    console.log(error.message)
  }
  return response;
}

export const postEdutamaAuth = async (data, type) => {
  let response = {};
  const baseUrl = `https://stage.edutama.id/api/login`;
  const configurationObject = {
    url: baseUrl,
    method: "POST",
    data: data,
  };

  try {
    const resp = await axios(configurationObject);
    response = resp.data;
  } catch (error) {
    console.log(error.message);
  }
  return response;
};

export const getDetailAcademy = async (token, id) => {
  let response = {};
  const baseUrl = `https://stage.edutama.id/api/frontend/academy/${id}`
  const configurationObject = {
    url: baseUrl,
    method: "GET",
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  try {
    const resp = await axios(configurationObject)
    response = resp.data;
  } catch (error) {
    console.log(error.message)
  }
  return response;
}