import React, { useEffect, useState, useContext } from 'react'
import { Container, Stack, Text, Table, Flex, Card, CardHeader, CardBody, Heading, Divider, Box, CardFooter,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer, Button, useToast, SimpleGrid, ButtonGroup, Link, Icon } from '@chakra-ui/react';
import _axios from '../../../Api/AxiosBarrier';
import { useParams } from 'react-router-dom';
import { formatFrice } from '../../../Utils/Helper';
import AuthContext from '../../../Routes/hooks/AuthContext';
import { BsBank, BsBank2 } from 'react-icons/bs';
import { FaMoneyBill } from 'react-icons/fa';
import CustomStepperMitra from '../../../Components/Stepper/CustomStepperMitra';
import { DownloadIcon } from '@chakra-ui/icons'

function DetailPage() {
    const [detail, setDetail] = useState({})
    const [payload, setPayload] = useState(null);
    const params = useParams();
    const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])
    const toast = useToast({
        position: 'top',
        align: 'center',
    });
    const { loadingShow, loadingClose } = useContext(AuthContext);

    useEffect(() => {
        fetchOrderDetail()
    }, []);

    const head = () => {
        return {
          script: [
            {
              src: process.env.MIDTRANS_URL,
            },
          ],
        };
      };

    const fetchOrderDetail = async () => {
        try {
            loadingShow();
            const res = await _axios.get(`api/mitra/order/${params.id}/detail`)
            loadingClose();
            if (res.status === true) {
                setDetail(res.data)
                if (res.data.last_payment_log !== null) setPayload(res.data.last_payment_log);
                if (res?.data?.order_statuses?.length > 0) {
                    const dataArr = res.data.order_statuses
                    setCurrentStep(res.data.order_statuses[0].title)
                    setMessageStep(res.data.order_statuses[0].message)
                    setSteps(dataArr.reverse())
                } else {
                    setCurrentStep('')
                }
            } else {
                toast({
                    title: 'importir.com',
                    description: 'Data not found',
                    status: 'error',
                });
            }
        } catch (error) {
            loadingClose();
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const totalPrice = () => {
        let total = 0
        detail?.mitra_order_details?.forEach((x, i) => {
            total += x?.product_price_total
        })
        return total
    }

    const makePayment = async (orderNumber, paymentType) => {
        try {
            loadingShow();
            const bodyParams = {
                order_number: orderNumber,
                type: paymentType
            };
            const url = '/api/make-payment-mitra';
            const {status, message, data} = await _axios.post(url, bodyParams);
            loadingClose();
            if (!status){
                return toast({
                    title: 'importir.com',
                    description: message,
                    status: 'error',
                });
            }
            setPayload(data);
            if (paymentType == 'xendit'){
                return window.open(data.payment_code);
            }
            console.log(data)
        } catch (error) {
            loadingClose();
            return toast({
                title: 'importir.com',
                description: error,
                status: 'error',
            });
        }
    }

    const cancelPayment = async (id) => {
        try {
            loadingShow();
            const url = `/api/${id}/cancel-payment-mitra`;
            const {status, message, data} = await _axios.delete(url);
            loadingClose();
            if (!status){
                return toast({
                    title: 'importir.com',
                    description: message,
                    status: 'error',
                });
            }
            setPayload(null);
        } catch (error) {
            loadingClose();
            return toast({
                title: 'importir.com',
                description: error,
                status: 'error',
            });
        }
    }

    const downloadInvoice = () => {
        const url = `${process.env.REACT_APP_API_COM}mitra/${params.id}/download-invoice`
        return window.open(url);
    }

    return (
        <>
            <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxWidth='container.xl' position={'relative'} zIndex={'1'} top={'-3rem'}>
                <Stack p={[1, 1, 5]}>
                    <Stack bgColor={'white'} p={5} borderRadius='md' shadow={'md'}>
                        <CustomStepperMitra steps={steps} currentStep={currentStep} message={messageStep} />
                    </Stack>
                    <Flex>
                        <Stack>
                            <Card overflowX={'auto'} border={'1px'} borderColor={'gray.300'}>
                                <CardHeader>
                                    <Flex gap={5}>
                                        <Heading size='md' textAlign={'center'}>Invoice #{detail?.order_number}</Heading>
                                        {detail?.paid_at !== null ? 
                                            <Button onClick={() => downloadInvoice()} colorScheme='green'>
                                                Invoice &nbsp;
                                                <DownloadIcon></DownloadIcon>
                                            </Button>
                                         : ''}
                                    </Flex>
                                </CardHeader>
                                <Divider />
                                <CardBody>
                                    <Box>
                                        <Heading size='md' textTransform='uppercase'>
                                            PAYMENT GATEWAY
                                        </Heading>
                                        <Stack p={3}>
                                            <SimpleGrid columns={2} spacing={5}>
                                                <Stack>
                                                    <Text fontSize='sm'>
                                                        Account Name
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm' color={'blue.300'}>
                                                        {detail?.user_inquiry?.name}
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm'>
                                                        Account Number
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm' color={'blue.300'}>
                                                        {detail?.user_inquiry?.account_no}
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm'>
                                                        Bank Name
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm' color={'blue.300'}>
                                                        {detail?.user_inquiry?.bank_code}
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm'>
                                                        Status Inquiry
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm' color={'green.300'}>
                                                        {detail?.user_inquiry?.status_inquiry}
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm'>
                                                        Status Order
                                                    </Text>
                                                </Stack>
                                                <Stack>
                                                    <Text fontSize='sm' >
                                                        {detail?.paid_at !== null ? 'Paid' : 'Not Paid'}
                                                    </Text>
                                                </Stack>
                                            </SimpleGrid>
                                        </Stack>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Stack>
                        <Stack ml={'3'} overflowX={'auto'}>
                            <Card border={'1px'} borderColor={'gray.300'}>
                                <CardBody>
                                    <TableContainer>
                                        <Table variant='striped' colorScheme='teal'>
                                            <Thead>
                                            <Tr >
                                                <Th>Product</Th>
                                                <Th>Order LOT</Th>
                                                <Th>PCS/Lot</Th>
                                                <Th>Total Pcs</Th>
                                                <Th>Product Price</Th>
                                                <Th>Price</Th>
                                            </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    detail?.mitra_order_details?.length > 0 ?
                                                        detail?.mitra_order_details?.map((x, i) => 
                                                            <Tr>
                                                                <Td isTruncated maxWidth={'300px'}>{x?.title}</Td>
                                                                <Td>{x?.quantity_lot_order}</Td>
                                                                <Td>{x?.per_lot} PCS/LOT</Td>
                                                                <Td>{x?.per_lot * x?.quantity_lot_order}</Td>
                                                                <Td>Rp. {formatFrice(x?.price_per_pcs)}</Td>
                                                                <Td fontWeight={'bold'}>Rp. {formatFrice(x?.product_price_total)}</Td>
                                                            </Tr>
                                                        )
                                                    :
                                                    <Text>Data not found</Text>
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                    <Divider />
                                    <SimpleGrid columns={2} p={4}>
                                        {detail?.paid_at == null && payload == null ? 
                                            <ButtonGroup spacing='2'>
                                                <Button onClick={() => makePayment(detail?.order_number, 'midtrans')} variant='solid' colorScheme='green'>
                                                    <Icon as={BsBank2} /> via BCA Bank
                                                </Button>
                                                <Button onClick={() => makePayment(detail?.order_number, 'xendit')} variant='solid' colorScheme='blue'>
                                                <Icon as={FaMoneyBill} />  Via Other Bank
                                                </Button>
                                            </ButtonGroup>
                                        : ''}
                                        {payload !== null && detail?.paid_at == null ? 
                                            (payload?.type === 'xendit' ? 
                                                <ButtonGroup my="2" spacing='2'>
                                                    <Link target="_blank" href={payload?.payment_code}>
                                                        <Button variant="solid" colorScheme='yellow'>
                                                            Contiune Payment
                                                        </Button>
                                                    </Link>
                                                    <Button onClick={() => cancelPayment(payload?._id)} variant='solid' colorScheme='red'>
                                                        Cancel
                                                    </Button>
                                                </ButtonGroup>
                                            :
                                                <Stack>
                                                    <Button onClick={() => cancelPayment(payload?._id)} variant='solid' colorScheme='red'>
                                                        Cancel
                                                    </Button>
                                                    <Text>
                                                        Bank Code: {payload?.payload?.data?.va_numbers[0]?.bank.toUpperCase()} <br></br>
                                                        Virtual Account: {payload?.payload?.data?.va_numbers[0]?.va_number} <br></br>
                                                        Transfer Amount: Rp. {formatFrice(parseInt(payload?.payload?.data?.gross_amount))}
                                                    </Text>
                                                </Stack>
                                            )
                                        : ""}
                                        {detail?.paid_at == null ? 
                                            <Stack textAlign={'end'}>
                                                <Text fontWeight={'bold'}>Total</Text>
                                                <Text fontWeight={'bold'}>Rp. {formatFrice(totalPrice())}</Text>
                                            </Stack>
                                        : ""}
                                    </SimpleGrid>
                                </CardBody>
                            </Card>
                        </Stack>
                    </Flex>
                </Stack>
            </Container>
        </>
    )
}

export default DetailPage