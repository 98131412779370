import { Stack, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const Countdown = ({ startDate, endDate }) => {
  const {t} = useTranslation();
  const [countdownText, setCountdownText] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('first', startDate)
      if (startDate === undefined) {
        return
      }
      const end = new Date(endDate).getTime();
      const totalSeconds = (end - Date.now()) / 1000;

      if (totalSeconds <= 0) {
        clearInterval(interval);
        setCountdownText('Crowdfund has ended');
      } else {
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        setCountdownText(
          `${days} ${t('days')} ${hours} ${t('hours')} ${minutes} ${t('minutes')} ${seconds} ${t('seconds')}`
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Stack border={"1px solid black"} borderRadius={'lg'} mt={2} shadow={'xl'} textAlign={'center'} p={2}>
      <Text fontWeight={'bold'} color='red.500' fontSize={'xs'} noOfLines={1}>
        {countdownText}
      </Text>
    </Stack>
  );
};

export default Countdown;