import { Box, Button, Checkbox, CheckboxGroup, Menu, MenuButton, MenuItem, MenuList, Select, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

function TestPage() {
  const [list, setList] = useState([
    { id: 1, name: 'Item 1', price: 10, quantity: 0 },
    { id: 2, name: 'Item 2', price: 15, quantity: 0 },
    { id: 3, name: 'Item 3', price: 20, quantity: 0 },
    // Tambahkan item lainnya sesuai kebutuhan
  ]);
  const [selectedItems, setSelectedItems] = useState([]);


  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    { value: 'option4', label: 'Option 4' },
    { value: 'option5', label: 'Option 5' },
  ];
  const handleIncrease = (itemId) => {
    const updatedList = list.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });
    setList(updatedList);
  };

  const handleDecrease = (itemId) => {
    const updatedList = list.map((item) => {
      if (item.id === itemId && item.quantity > 0) {
        return {
          ...item,
          quantity: item.quantity - 1,
        };
      }
      return item;
    });
    setList(updatedList);
  };

  const handleSelectItem = (itemId, itemName, itemQuantity) => {
    const selectedItem = { id: itemId, name: itemName, quantity: itemQuantity };
    const isItemAlreadySelected = selectedItems.some(
      (item) => item.id === itemId
    );

    if (isItemAlreadySelected) {
      const updatedSelectedItems = selectedItems.map((item) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: itemQuantity,
          };
        }
        return item;
      });
      setSelectedItems(updatedSelectedItems);
    } else {
      setSelectedItems([...selectedItems, selectedItem]);
    }
  };

  return (
    <div>
      <h2>Data List</h2>
      <ul>
        {list.map((item) => (
          <li key={item.id}>
            {item.name} - ${item.price}
            <button onClick={() => handleDecrease(item.id)}>-</button>
            <span>{item.quantity}</span>
            <button onClick={() => handleIncrease(item.id)}>+</button>
            <button
              onClick={() =>
                handleSelectItem(item.id, item.name, item.quantity)
              }
            >
              Select
            </button>
          </li>
        ))}
      </ul>
      <h2>Selected Items:</h2>
      <ul>
        {selectedItems.map((item) => (
          <li key={item.id}>
            {item.name} - Quantity: {item.quantity}
          </li>
        ))}
      </ul>

      <Box>
        <Button onClick={handleMenuToggle}>Open Select</Button>
        {isMenuOpen && (
          <Box
            borderWidth="1px"
            borderRadius="md"
            mt={2}
            backgroundColor="white"
            boxShadow="md"
            position="absolute"
            zIndex={1}
          >
            {options.map((option) => (
              <Button
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                width="100%"
                justifyContent="flex-start"
                textAlign="left"
              >
                {option.label}
              </Button>
            ))}
            <Button onClick={handleMenuToggle}>Close Select</Button>
          </Box>
        )}
        <p>Selected options: {selectedOptions.join(', ')}</p>
      </Box>
    </div>

  );
}

export default TestPage;