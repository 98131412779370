/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Heading, Button, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer, Text, HStack, Flex } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../Routes/hooks/AuthContext';
import _axios from '../../Api/AxiosBarrier';
import { useNavigate, useParams } from 'react-router-dom';
import colors from '../../Utils/colors';
import { IoCaretBackOutline, IoPrint } from 'react-icons/io5'
import { formatFrice } from '../../Utils/Helper';
import { useTranslation } from 'react-i18next'
import store from 'store'

function InvoiceDetailOldPage() {
    const {t} = useTranslation();
    const {loadingShow, loadingClose} = useContext(AuthContext)
    const userDb = store.get("user_api")
    const params = useParams()
    const navigate = useNavigate()

    const [billDetail, setBillDetail] = useState([])
    const [detailOrder, setDetailOrder] = useState({})
    const [detailInvoice, setDetailInvoice] = useState({});
    const [isRefund, setIsRefund] = useState(false);

    const fetchData = async () => {
        try {
            loadingShow()
            const res = await _axios.get(`/api/orders/old-order/${params.id}/invoice`)
            loadingClose()
            setBillDetail(res.data.invoices)
            setDetailInvoice(res.data.detail_invoice)
            setIsRefund(res.data?.detail_invoice?.is_refund === 1 ? true : false);
            setDetailOrder(res.data.order)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePrintContent = () => {
      const contentToPrint = document.getElementById('content-to-print');
      const windowToPrint = window.open('', '_blank');
      windowToPrint.document.write(contentToPrint.outerHTML);
      windowToPrint.document.close();
      // windowToPrint.print();
      // windowToPrint.close();
  };

    useEffect(() => {
        fetchData();

        return () => {};
    }, []);
  return (
    <Stack p={[1, 1, 5]} spacing={5} bgColor={'white'}>
      <Flex justifyContent={"space-between"}>
        <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
              <IoCaretBackOutline size={15} />
              <Text fontSize={'xs'} letterSpacing={0.5}>Back</Text>
          </HStack>

          <Heading fontWeight={'bold'} size={'lg'}>
              <Button colorScheme='green' onClick={() => handlePrintContent()}>
                <IoPrint />&nbsp;Print
              </Button>
          </Heading>
      </Flex>

        <Stack mt={'5'}>
            <TableContainer>
                <Heading size={'md'} fontWeight={'bold'}>
                    Detail Bill
                </Heading>
                <Table variant='striped' colorScheme='teal' mt={'5'}>
                    <Thead>
                        <Tr>
                            <Th>Title</Th>
                            <Th>Amount</Th>
                            <Th>Tag</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                      {
                        billDetail?.length > 0 && (() => {
                          let grandTotal = 0; // Initialize the grand total

                          return billDetail?.flatMap((x, i) => {
                            const dataRows = x?.list?.map((data, index) => (
                              <Tr key={`data-${i}-${index}`}>
                                  <Td>{data?.title}</Td>
                                  <Td>
                                  {data?.type === '+' ? (
                                      <>Rp. {formatFrice(parseInt(data?.amount))}</>
                                  ) : (
                                      <>Rp. -{formatFrice(parseInt(data?.amount))}</>
                                  )}
                                  </Td>
                                  <Td>{data?.tag}</Td>
                              </Tr>
                            ));

                            let subtotal = 0;
                            if (!isNaN(x?.total_amount)) {
                              subtotal = parseInt(x?.total_amount);
                            }

                            if (subtotal >= 0) {
                              grandTotal += subtotal; // Update grand total

                              const totalRow = (
                                <Tr key={`total-${i}`} bgColor={'#ffd600'}>
                                  <Td textAlign={'center'} fontWeight={'bold'} fontSize={'xl'}>
                                    Sub Total
                                  </Td>
                                  <Td fontWeight={'bold'} fontSize={'xl'}>
                                    Rp. {formatFrice(subtotal)}
                                  </Td>
                                  <Td></Td>
                                </Tr>
                              );

                              return [dataRows, totalRow];
                            } else {
                              return dataRows; // Skip rendering total row for negative subtotal
                            }
                          }).concat(
                            grandTotal >= 0
                              ? [
                                  <Tr key="grand-total" bgColor={'#ffd600'}>
                                    <Td textAlign={'center'} fontWeight={'bold'} fontSize={'xl'}>
                                      Grand Total
                                    </Td>
                                    <Td fontWeight={'bold'} fontSize={'xl'}>
                                      Rp. {formatFrice(grandTotal)}
                                    </Td>
                                    <Td></Td>
                                  </Tr>
                                ]
                              : []
                          );
                        })()
                      }
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>
        <div id="content-to-print">
            <Stack display={'none'}>
                <div id="print" style={{ overflow: 'hidden', background: 'white' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1.25rem' }}>
                        <div style={{ display: 'flex', height: '150px', width: '100%', flexDirection: 'row', justifyContent: 'space-between', background: '#F6E05E' }}>
                            <span style={{ margin: 'auto 0', padding: '0 1rem', fontWeight: 'bold' }}>Importir.org {t('remittence').toUpperCase()}</span>
                            <span style={{ margin: 'auto 0', padding: '0 1rem', textAlign: 'right', fontSize: '12px' }}>
                                <div>Importir.org</div>
                                <div>
                                    Metland Tangerang<br />
                                    Jl. Puri Utama Blok B1 | No. 23, RT.004/RW.008<br />
                                    Petir, Cipondoh, Tangerang City,<br />
                                    Banter 15147
                                </div>
                                <div>(021) 55715045</div>
                                <div>
                                    <a href="mailto:sales@importir.org">sales@importir.org</a>
                                </div>
                            </span>
                        </div>

                        <div style={{ background: `url('https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ami/dimension-2022_09_13_17_24_14.png')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', display: 'flex', justifyContent: 'center', borderTop: '2px solid black', borderBottom: '2px solid black', padding: '0.75rem', fontWeight: 'bold' }}>
                            {detailInvoice?.title}
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '0.5rem', fontSize: '12px', color: '#718096' }}>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                                    <span>{t('service')}</span>
                                    <span style={{ color: 'black' }}>Shipping Importir</span>
                                    <div>
                                        <span>{t('buyer')}</span>
                                        <span style={{ color: 'black' }}>&nbsp;{ userDb?.name }</span>
                                    </div>
                                    <div>
                                        <span>{t('phone_number')}</span>
                                        <span style={{ color: 'black' }}>&nbsp;{ userDb?.phone }</span>
                                    </div>
                                    <div>
                                        <span>Email</span>
                                        <a href={`mailto:${userDb?.email}`}>
                                            <span style={{ color: 'black' }}>&nbsp;{ userDb?.email }</span>
                                        </a>
                                    </div>
                                    <div>
                                        <span>Status</span>
                                        <span style={{ color: detailInvoice?.paid_at !== null ? '#48BB78' : '#F56565' }}>&nbsp;{detailInvoice?.paid_at !== null ? 'PAID' : 'UNPAID'}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                <span>{detailOrder?.supplier_name}</span>
                                <span>1688</span>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', padding: '1.25rem' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('bill_title')} :</div>
                        <div style={{ fontSize: '14px' }}>BP{params.id}</div>
                    </div>

                    <div>
                        <table style={{ width: '100%', fontSize: '14px', color: '#718096' }}>
                            <thead style={{ borderBottom: '2px solid #718096' }}>
                                <tr>
                                    <td>{t('bill_detail')}</td>
                                    <td>{t('total')}</td>
                                </tr>
                            </thead>
                            <tbody>
                            {
                              billDetail?.length > 0 && (() => {
                                let grandTotal = 0; // Initialize the grand total

                                return billDetail?.flatMap((x, i) => {
                                  const dataRows = x?.list?.map((data, index) => (
                                    <tr key={`data-${i}-${index}`}>
                                        <td>{data?.title}</td>
                                        <td>
                                        {data?.type === '+' ? (
                                            <>Rp. {formatFrice(parseInt(data?.amount))}</>
                                        ) : (
                                            <>Rp. -{formatFrice(parseInt(data?.amount))}</>
                                        )}
                                        </td>
                                        <td>{data?.tag}</td>
                                    </tr>
                                  ));

                                  let subtotal = 0;
                                  if (!isNaN(x?.total_amount)) {
                                    subtotal = parseInt(x?.total_amount);
                                  }

                                  if (subtotal >= 0) {
                                    grandTotal += subtotal; // Update grand total

                                    if (billDetail.length > 1){
                                      const totalRow = (
                                        <tr key={`total-${i}`} style={{ bgColor: '#ffd600' }}>
                                          <td style={{ textAlign: "center", fontWeight: 'bold', fontSize: 'xl' }}>
                                            Sub Total
                                          </td>
                                          <td style={{ fontWeight: 'bold', fontSize: 'xl' }}>
                                            Rp. {formatFrice(subtotal)}
                                          </td>
                                          <td></td>
                                        </tr>
                                      );
                                      return [dataRows, totalRow];
                                    }
                                    return dataRows;
                                  } else {
                                    return dataRows; // Skip rendering total row for negative subtotal
                                  }
                                }).concat(
                                  grandTotal >= 0
                                    ? [
                                        <tr key="grand-total" style={{ bgColor: '#ffd600' }}>
                                          <td style={{ textAlign: "center", fontWeight: 'bold', fontSize: 'xl' }}>
                                            Grand Total
                                          </td>
                                          <td style={{ fontWeight: 'bold', fontSize: 'xl' }}>
                                            Rp. {formatFrice(grandTotal)}
                                          </td>
                                          <td></td>
                                        </tr>
                                      ]
                                    : []
                                );
                              })()
                            }
                            </tbody>
                        </table>
                    </div>

                    <div style={{ paddingTop: '0.5rem' }}>
                        {
                            !detailInvoice?.paid_at && !isRefund ? 
                                <>
                                    <div style={{ paddingTop: '0.5rem' }}>
                                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('notice').toUpperCase()}:</div>
                                        {
                                            detailInvoice?.currency?.toUpperCase() === 'IDR' ? 
                                            <div style={{ fontSize: '14px', color: '#718096' }}>
                                                <p>
                                                    Silahkan transfer menggunakan online payment gateway di dalam dashboard shipping Anda sebelum <b>{detailInvoice?.due_date_time}</b> ke:
                                                </p>
                                                <br />
                                                <p>
                                                    Klik <a style={{ color: '#be1d13' }} href="" target="_blank">PEMBAYARAN</a>, untuk membayar transaksi ini
                                                </p>
                                            </div> 
                                            :
                                            <div style={{ fontSize: '14px', color: '#718096' }}>
                                                <p>Please transfer manually before <b>{detailInvoice?.due_date_time}</b></p>
                                                <p>With transfer amount {detailInvoice?.grand_total > 0 ? detailInvoice?.grand_total : 0 }</p>
                                                <p style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div>Bank Name : <span>BDO</span></div>
                                                    <div>Account Number : <span>0024 5029 5085</span></div>
                                                    <div>Branch : <span>SM City Cebu B</span></div>
                                                    <div>Account Name: <span>Rogelio P. Sungahid,Jr.</span></div>
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </>
                            : 
                            !isRefund ? 
                                <div style={{ paddingTop: '0.5rem' }}>
                                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('notice').toUpperCase()}:</div>
                                    <div style={{ textAlign: 'center', fontSize: '14px', color: '#718096' }}>
                                        <p>
                                        {detailInvoice?.currency?.toUpperCase() === 'IDR' ? 
                                        "Silahkan memberikan nomor rekening Anda ke Admin melalui pesan chat di informasi Shipping Anda" 
                                        : "Please provide your account number to admin via chat message in your shipping information"}
                                        </p>
                                    </div>
                                </div>
                            :
                            <div style={{ paddingTop: '0.5rem' }}>
                                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('notice').toUpperCase()}:</div>
                                <div style={{ textAlign: 'center', fontSize: '14px', color: '#718096' }}>
                                    <p>{detailInvoice?.currency?.toUpperCase() === 'IDR' ? "Pembayaran Telah Lunas" : "PAID PAYMENT"}</p>
                                </div>
                            </div>
                        }
                    </div>

                    <footer style={{ borderTop: '2px solid #718096', textAlign: 'center', fontSize: '14px' }}>
                        {t('digital_invoice_policy')}
                    </footer>
                </div>
            </Stack>
        </div>
    </Stack>
  )
}

export default InvoiceDetailOldPage