import React, { useEffect, useState, useContext } from 'react'
import { Container, Stack, Text, Table, Flex, Card, CardHeader, CardBody, Heading, Divider, Box, CardFooter,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer, Button, useToast, SimpleGrid, ButtonGroup, Link } from '@chakra-ui/react';
import _axios from '../../../Api/AxiosBarrier';
import { useParams } from 'react-router-dom';
import { formatFrice } from '../../../Utils/Helper';
import AuthContext from '../../../Routes/hooks/AuthContext';

function DetailPage() {
    const [detail, setDetail] = useState({})
    const [payload, setPayload] = useState(null);
    const params = useParams();
    const toast = useToast({
        position: 'top',
        align: 'center',
    });
    const { loadingShow, loadingClose } = useContext(AuthContext);

    useEffect(() => {
        fetchOrderDetail()
    }, []);

    const fetchOrderDetail = async () => {
        try {
            loadingShow();
            const res = await _axios.get(`api/mitra/order/${params.id}/detail-old`)
            loadingClose();
            setDetail(res[0])
        } catch (error) {
            loadingClose();
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const totalPrice = () => {
        let total = 0
        detail?.mitra_order_details?.forEach((x, i) => {
            total += x?.product_price
        })
        return total
    }
  return (
    <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxWidth='container.xl' position={'relative'} zIndex={'1'} top={'-3rem'}>
        <Stack p={[1, 1, 5]}>
            <Flex>
                <Stack>
                    <Card overflowX={'auto'} border={'1px'} borderColor={'gray.300'}>
                        <CardHeader>
                            <Heading size='md' textAlign={'center'}>Invoice #{detail?.order_number}</Heading>
                        </CardHeader>
                        <Divider />
                        <CardBody>
                            <Box>
                                <Heading size='md' textTransform='uppercase'>
                                    PAYMENT GATEWAY
                                </Heading>
                                <Stack p={3}>
                                    <SimpleGrid columns={2} spacing={5}>
                                        <Stack>
                                            <Text fontSize='sm'>
                                                Account Name
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm' color={'blue.300'}>
                                                {detail?.user_inquiry?.name}
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm'>
                                                Account Number
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm' color={'blue.300'}>
                                                {detail?.user_inquiry?.account_no}
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm'>
                                                Bank Name
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm' color={'blue.300'}>
                                                {detail?.user_inquiry?.bank_code}
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm'>
                                                Status Inquiry
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm' color={'green.300'}>
                                                {detail?.user_inquiry?.status_inquiry}
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm'>
                                                Status Order
                                            </Text>
                                        </Stack>
                                        <Stack>
                                            <Text fontSize='sm' >
                                                {detail?.paid_at !== null ? 'Paid' : 'Not Paid'}
                                            </Text>
                                        </Stack>
                                    </SimpleGrid>
                                </Stack>
                            </Box>
                        </CardBody>
                    </Card>
                </Stack>
                <Stack ml={'3'} overflowX={'auto'}>
                    <Card border={'1px'} borderColor={'gray.300'}>
                        <CardBody>
                            <TableContainer>
                                <Table variant='striped' colorScheme='teal'>
                                    <Thead>
                                    <Tr >
                                        <Th>Product</Th>
                                        <Th>Order LOT</Th>
                                        <Th>PCS/Lot</Th>
                                        <Th>Total Pcs</Th>
                                        <Th>Product Price</Th>
                                        <Th>Price</Th>
                                    </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            detail?.mitra_order_details?.length > 0 ?
                                                detail?.mitra_order_details?.map((x, i) => 
                                                    <Tr>
                                                        <Td isTruncated maxWidth={'300px'}>{x?.title}</Td>
                                                        <Td>{x?.quantity_lot}</Td>
                                                        <Td>{x?.per_lot} PCS/LOT</Td>
                                                        <Td>{x?.per_lot * x?.quantity_lot}</Td>
                                                        <Td>Rp. {formatFrice(x?.price)}</Td>
                                                        <Td fontWeight={'bold'}>Rp. {formatFrice(x?.product_price)}</Td>
                                                    </Tr>
                                                )
                                            :
                                            <Text>Data not found</Text>
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Divider />
                            <SimpleGrid columns={1} p={4}>
                                {/* {detail?.paid_at == null ?  */}
                                    <Stack textAlign={'end'}>
                                        <Text fontWeight={'bold'}>Total</Text>
                                        <Text fontWeight={'bold'}>Rp. {formatFrice(totalPrice())}</Text>
                                    </Stack>
                                {/* : ""} */}
                            </SimpleGrid>
                        </CardBody>
                    </Card>
                </Stack>
            </Flex>
        </Stack>
    </Container>
  )
}

export default DetailPage