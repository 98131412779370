import axios from "axios";
import store from "store";
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const config = {
	baseURL: process.env.REACT_APP_API_COM,
	timeout: 60 * 1000, // Timeout
};

const _axios = axios.create(config);

_axios.interceptors.request.use(async (config) => {
	let user = await store.get("user_token");
	const userStorage = user && user;
	const token = userStorage;

	if (userStorage) {
		// config.headers['Authorization'] = `Bearer 3126|yb7B1pgGNiQ33FLAb809ekt7s1F7HpbKhs0dCla5`
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	return config;
});

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		response =
			typeof response.data !== "undefined" ? response.data : response;
		return response;
	},

	function (error) {
		if (error.response.status === 404) {
			// return window.location.href = '/error/error-404'
		} else if (error.response.status === 401) {
			console.log(window.location.pathname);
			if (window.location.pathname !== "/login") {
        store.set("expired_session", true)
				return (window.location.href = "/login");
			}
		}
		console.log(error);

		return Promise.reject(error);
	}
);

export default _axios;
