import { ChevronRightIcon } from '@chakra-ui/icons';
import { HStack, Image, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatCategoryName } from '../../Utils/Helper';

function AppNavigationCategory({ category }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { categoryList } = useContext(AuthContext);

  const width = window.innerWidth;
  const height = window.innerHeight;


  const filterArr = useMemo(() => {
    return categoryList.find((x) => x.category === category.category);
  }, [categoryList, category.category]);

  const nicheCategoryList = useMemo(() => {
    return filterArr?.subCategory || [];
  }, [filterArr]);

  const handleMenuOpen = () => {
    if (!isOpen) {
      onOpen();
    }
  };

  

  const handleMenuClose = () => {
    if (isOpen) {
      onClose();
    }
  };

  const handleMenuItemClick = (subcategory) => {
    if (subcategory['1688-id']) {
      navigate(`/category?detail=${category?.category}&subCategory=${subcategory?.title}&id=${subcategory['1688-id']}`);
    } else if (subcategory["amazon-id"]) {
      navigate(`/category?detail=${category?.category}&subCategory=${subcategory?.title}&id=${subcategory['amazon-id']}`);
    } else if (subcategory["coupang-id"]) {
      navigate(`/category?detail=${category?.category}&subCategory=${subcategory?.title}&id=${subcategory['coupang-id']}`);
    }
  };
  
  return (
    <Menu isOpen={isOpen} placement='end' onClose={handleMenuClose} >
      <MenuButton
        as={Text}
        variant='solid'
        onClick={handleMenuOpen}
        onMouseLeave={handleMenuClose} 
        cursor='pointer'
      >
        <HStack spacing={2} alignItems='center' px={3}>
          {category?.subCategory[0]?.image && (
            <Image src={category?.subCategory[0]?.image} alt='image' width='15px' />
          )}
          <Text  fontSize='sm'  textTransform='capitalize'>
            {formatCategoryName(category?.category)}
          </Text>
          <Spacer />
          <ChevronRightIcon />
        </HStack>
      </MenuButton>
      {isOpen && (
        <MenuList onMouseEnter={onOpen}   zIndex={100} ml={'-10px'}>
          <SimpleGrid columns={[1, null, 4]} maxW={width / 1.5}  maxH={height} overflowY='scroll'>
            {nicheCategoryList.map((x, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(x)}>
                <HStack>
                  {x.image && (
                    <Image src={x.image} alt={x.title} width='20px' />
                  )}
                  <Text fontSize='xs' textTransform='capitalize' color={'black'}>
                    {formatCategoryName(x?.title)}
                  </Text>
                </HStack>
              </MenuItem>
            ))}
          </SimpleGrid>
        </MenuList>
      )}
    </Menu>
  );
}

export default AppNavigationCategory;
