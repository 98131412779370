import { Box, Heading, HStack, Text, Center, Input, Image, SimpleGrid, Button, Flex, AccordionItem, Accordion, AccordionButton, AccordionIcon, AccordionPanel, useToast } from '@chakra-ui/react'
import axios from 'axios'
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore'
import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { errorSlack } from '../../Api/SlackApi'
import { db } from '../../Config/firebase'


function CatalogPage() {
	const [categoryList, setCategoryList] = useState()
	const [categoryArr, setCategoryArr] = useState([])
	const [pdfArr, setPdfArr] = useState([])
	const [title, setTitle] = useState('')
	const [totalProduct, setTotalProduct] = useState(0)
	const [kurs, setKurs] = useState()

	const path = window.location.href ?? '#'

	const toast = useToast({
		position: "top",
		align: "center",
	});

	const startPDF = async () => {

		console.log(pdfArr)
		const ratio = 1

		const width = 1748 * ratio
		const height = 2480 * ratio

		const imgWidth = 400 * ratio
		const imgHeight = 400 * ratio

		const footer = 'https://assets.cdn.filesafe.space/GpaUArq7XKjttUDuv9d2/media/6457737960ba794348010989.png'
		const bg = 'https://static.vecteezy.com/system/resources/previews/018/884/010/non_2x/gold-gradient-footer-for-design-feed-free-png.png'
		const doc = new jsPDF({
			orientation: "portrait",
			unit: "mm",
			format: [width, height],
			compress: false,
			userUnit: 300

		});

		const config = {
			imageX: [
				width * 0.33 - imgWidth,
				width * 0.5 - (imgWidth / 2),
				width * 0.67,
				width * 0.33 - imgWidth,
				width * 0.5 - (imgWidth / 2),
				width * 0.67,
				width * 0.33 - imgWidth,
				width * 0.5 - (imgWidth / 2),
				width * 0.67,
				width * 0.33 - imgWidth,
				width * 0.5 - (imgWidth / 2),
				width * 0.67],
			imageY: [200, 200, 200, 800, 800, 800, 1400, 1400, 1400, 2000, 2000, 2000],
			titleX: [
				(width / 3) - (imgWidth / 2),
				(width / 2),
				(width * 0.66) + (imgWidth / 2),
				(width / 3) - (imgWidth / 2),
				(width / 2),
				(width * 0.66) + (imgWidth / 2),
				(width / 3) - (imgWidth / 2),
				(width / 2),
				(width * 0.66) + (imgWidth / 2),
				(width / 3) - (imgWidth / 2),
				(width / 2),
				(width * 0.66) + (imgWidth / 2),],
			titleY: [650, 650, 650, 1250, 1250, 1250, 1850, 1850, 1850, 2450, 2450, 2450],
			priceY: [700, 700, 700, 1300, 1300, 1300, 1900, 1900, 1900, 2500, 2500, 2500],
			bgY: [670, 670, 670, 1270, 1270, 1270, 1870, 1870, 1870, 2470, 2470, 2470]
		}
		const newPDF = pdfArr.filter(n => n.length)
		newPDF.map((x, index) => {
			console.log('halaman', index)

			if (x.length < 1) return
			x.map((data, i) => {
				console.log(data.id)
				// console.log(config.imageX[i])
				doc.addImage(data.image, "JPEG", config.imageX[i], config.imageY[i] - 150, imgWidth, imgHeight);
				doc.addImage(footer, "PNG", (config.imageX[i]), config.bgY[i] - 162, 400, 38);

				//title
				if (data['title-en']) {
					doc.setFontSize(75)
					doc.setTextColor('black')
					const text = `${(data['title-en'].en).slice(0, 27)}...`
					var textWidth = parseInt(doc.getTextDimensions(text).w)
					var secondCount = (textWidth / 2)
					doc.text(String(text), (config.titleX[i] - secondCount), config.titleY[i] - 175, { baseline: 'middle' });
				}
				//price
				doc.setFontSize(100)
				doc.setTextColor('black')
				const price1 = parseInt(data.price * kurs)
				const integerPrice = new Intl.NumberFormat('en-ID', {maximumFractionDigits: 0}).format(price1)
				const rupiahPrice = String(`Rp ${integerPrice}`)
				var pricetWidth = doc.getTextDimensions(rupiahPrice).w
				var secondCountPrice1 = (pricetWidth / 2)
				doc.text(String(rupiahPrice), ((config.titleX[i]) - secondCountPrice1), config.priceY[i] - 175, { baseline: 'middle' });


				//sold
				doc.setFontSize(60)
				doc.setTextColor('black')
				const sold = `${data.sold}`
				var numb = sold.match(/\d/g);
				numb = String(`Sold ${(numb.join("").substr(2) * 10000).toLocaleString('en-US')} pcs ++`);
				var soldWidth = parseInt(doc.getTextDimensions(numb).w)
				var soldCount = (soldWidth / 2)
				doc.text(numb, ((config.titleX[i]) - soldCount), config.priceY[i] - 140, { baseline: 'middle' });

				//ID
				doc.setFontSize(60)
				doc.setTextColor('black')
				const productId = `ID: ${data.id}`
				var productIdWidth = parseInt(doc.getTextDimensions(productId).w)
				var secondProductIdWidth = (productIdWidth / 2)
				doc.text(String(productId), ((config.titleX[i]) - secondProductIdWidth), config.priceY[i] - 115, { baseline: 'middle' });

				return
			}
			)

			doc.setFontSize(100)
			doc.setTextColor('black')
			doc.text(String(index + 1), width / 2, height - 40, { baseline: 'middle' });

			// doc.addImage(bg, width - 980, height - 700, 980, 980)
			doc.addPage({
				orientation: "portrait",
				unit: "px",
				format: [width, height],
				compress: false,

			})
		})
		console.log(newPDF)

		return doc.save(title)
	}

	const getDataCategory = async () => {
		try {
			onSnapshot(doc(db, "display", 'category'), (doc) => {
				// console.log(doc.data().data, 'data dari firebase')
				const data = doc.data().data

				let newData = []
				data.map((x => {
					x.subCategory = x?.subCategory.filter((value, index, self) =>
						index === self.findIndex((t) => (
							t['1688-id'] === value['1688-id']
						))
					)
				}

				))




				setCategoryList(data);
			});
		} catch (error) {
			console.log(error, 'ini error')
			const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
		}

	}

	const addDataCategory = async (id) => {
		const querySnapshot = await getDocs(query(collection(db, "products"), where("category", "==", String(id)), limit(200)))
		const data = []


		const catID = []
		pdfArr.map((x) =>
			x.map((y) => {
				catID.push(y.category)
				return y.price = parseFloat(y.price)
			}))

		querySnapshot?.forEach((docs) => {
			data.push(docs.data())
		});

		if (data.length > 2) {
			const checker = catID.find((x) => x === data[0].category)

			if (!checker) {
				console.log(data)
				const sortData = data.filter((y) => (parseFloat(y.price)) > 4)
				sortData.sort((a, b) => a.price - b.price)
				sortData.splice(12, sortData.length - 12)

				console.log(sortData, 'this is sorted array')

				setTotalProduct(totalProduct + sortData.length)
				setPdfArr([...pdfArr, sortData])
			}
			else {
				toast({
					title: 'Duplicate category',
					description: "Please add other category.",
					status: 'error',
					duration: 9000,
					isClosable: true,
				})
			}
		}

	}

	const cutArray = () => {
		const newData = pdfArr.map((x) => {
			const sortData = x.filter((y) => (parseFloat(y.price)) > 4)
			sortData.sort((a, b) => a.price - b.price)

			return sortData.splice(0, 12)
			// return sortData
		}
		)

		console.log(newData, 'this is sorted array')

	}

	const getDataKurs = async () => {
		try {
			const res = await axios.get('https://new-apiv2.importir.com/api/rates')
			console.log(res.data.data.rmb, 'ini dia kursnya')
			setKurs(res.data.data.rmb)

		} catch (error) {
			console.log(error.message)
			const resSlack = await errorSlack(error, "importir.com", path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')

		}
	}


	useEffect(() => {
		getDataCategory()
		getDataKurs()

		return () => {
			setTitle()
		}
	}, [])

	return (
		<><Flex>
			<Box width='30%' borderRight='1px'>
				<Heading>Create catalog</Heading>
				<Accordion >
					{categoryList ?
						categoryList.map((x) =>
							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex='1' textAlign='left'>
											{x.category}
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4}>
									<SimpleGrid columns={2}>
										{x?.subCategory.map((y) =>
											<Box onClick={() => addDataCategory(
												String(y['1688-id'])
											)}>
												<Image src={y.image} />
												<Text fontSize='2xs' textAlign='center'>{y.title}</Text>
												<Text fontSize='2xs' textAlign='center'>{y['1688-id']}</Text>
											</Box>)}
									</SimpleGrid>
								</AccordionPanel>
							</AccordionItem>
						)
						:
						<></>}
				</Accordion>


			</Box>
			<Box width='70%'>
				{categoryArr ?
					<Box>
						<Text>Total Prodct: {totalProduct}</Text>
						<HStack>
							<Input type='text' onChange={(e) => setTitle(e.target.value)} />
							<Button onClick={() => startPDF()}>create</Button>
							<Button onClick={() => console.log(pdfArr)}>Check Console</Button>
							<Button onClick={() => cutArray()}>Check Console</Button>
						</HStack>
					</Box>
					: <></>}

				<SimpleGrid columns={6}>
					{pdfArr ? pdfArr.map((x, halaman) =>
						x.map((y) =>
							<Box>
								<Image src={y.image} />
								<Text fontSize='2xs'>{y.id}</Text>
								<Text fontSize='2xs'>Halaman{halaman}</Text>
							</Box>))
						:
						<></>
					}
				</SimpleGrid>
			</Box>
		</Flex>
			{/* <Box width={'1748px'} height={'2480px'} border='1px'> */}
			{/* <Main6 padding={mainPadding} /> */}
			{/* <Div10 /> */}
			{/* <Basic9 padding={mainPadding} />
			<Basic9 padding={mainPadding} />
			<Basic9 padding={mainPadding} />
			<Basic9 padding={mainPadding} /> */}

			{/* </Box > */}
		</>
	)
}

export default CatalogPage

