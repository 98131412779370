import { Avatar, AvatarBadge, Box, Button, ButtonGroup, Card, CardBody, CardFooter, Divider, Flex, Heading, HStack, Input, Spinner, Stack, Text, useToast } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { IoSend } from 'react-icons/io5'
import _axios from '../../Api/AxiosBarrier';
import { errorSlack } from '../../Api/SlackApi';
import { useTranslation } from 'react-i18next';
function SourceForYouChat({ id }) {
    const {t} = useTranslation();
    const [chat, setChat] = useState([]);
    const [loading, setLoading] = useState(false)
    const [inputMessage, setInputMessage] = useState('');
    const toast = useToast({
        position: "top",
        align: "center",
    });
    const chatContainerRef = useRef(null);


    const path = window.location.href ?? '#'

    const getChat = async () => {
        setLoading(true)
        try {
            const res = await _axios.get(`api/sfy/chat-admin-to-users/${id}`);
            if (res.status === true) {
                setChat(res?.data?.data_chat);
                setLoading(false)
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error',
                });
                setLoading(false)


                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
            setLoading(false)

            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
        setLoading(false)
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() === '') {
            return;
        }

        try {
            const res = await _axios.post(`api/sfy/chat-admin-to-users/${id}`, {
                message: inputMessage,
            });

            if (res.status === true) {
                setInputMessage('');
                setChat((prevChat) => [
                    ...prevChat,
                    {
                        message: inputMessage,
                    },
                ]);
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error',
                });

                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });

            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    };

    useEffect(() => {
        getChat();
    }, []);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chat]);

    return (
        <Stack>
            <Stack bg={'white'} shadow="md" borderRadius="md" p={5} >
                <Heading size="md">{t('chat_to_admin')}</Heading>

                <Stack h="370px" overflowY="scroll" ref={chatContainerRef} >
                    <Divider pt={3} />
                    {!loading ? (
                        chat.length > 0 ? (
                            chat.map((item, index) => (
                                <Stack mt="6" spacing="3" key={index}>
                                    {item.from === 'admin' ? (
                                        <HStack alignItems="flex-start" justify="flex-start">
                                            <Avatar
                                                size="sm"
                                                src="https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Eko-IT/love-2022_10_10_16_44_07.png"
                                            >
                                                <AvatarBadge boxSize="1.25em" bg="green.500" />
                                            </Avatar>
                                            <Box bg="gray.200" p={2} px={5} maxW="80%" borderRadius="xl" shadow={'ms'}>
                                                <Text fontWeight="bold">{item.admin_name}</Text>
                                                <Text fontSize="sm" py={1}>
                                                    {item.message}
                                                </Text>
                                                <Text fontSize="xs">{moment(item.date_create).fromNow()}</Text>
                                            </Box>
                                        </HStack>
                                    ) : (
                                        <HStack alignItems="flex-start" justify="flex-end">
                                            <Box shadow='md' bg="gray.200" p={2} px={5} maxW="80%" borderRadius="xl">
                                                <Text fontWeight="bold">{t('me')}</Text>
                                                <Text fontSize="sm" py={1}>
                                                    {item.message}
                                                </Text>
                                                <Text fontSize="xs">{moment(item.date_create).fromNow()}</Text>
                                            </Box>
                                            <Avatar size="sm">
                                                <AvatarBadge boxSize="1.25em" bg="green.500" />
                                            </Avatar>
                                        </HStack>
                                    )}
                                </Stack>
                            ))
                        ) : (
                            <Flex flex={1} h={'100%'} alignItems='center' justifyContent={'center'}>
                                <Text color={'gray.600'}>{t('data_not_found')}</Text>
                            </Flex>
                        )
                    ) : (
                        <Stack alignItems={'center'} justifyContent='center'>
                            <Spinner size="md" />
                        </Stack>
                    )}

                </Stack>
                <Divider />
                <HStack gap={3} alignItems='center' justifyContent={'center'} w='100%' >

                    <Input
                        placeholder={t('chat_to_admin') + ' ...'}
                        size="sm"
                        bgColor='gray.100'
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                    />
                    <ButtonGroup spacing="5">
                        <Button variant="solid" size="sm" bgColor="#ffd600" onClick={() => handleSendMessage()}>
                            <IoSend />
                        </Button>
                    </ButtonGroup>
                </HStack>
            </Stack>
        </Stack>
    );
}

export default SourceForYouChat;
