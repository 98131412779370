import { updatePassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../Config/firebase";
import store from 'store';

export const changePasswordFirebaseWithMiddleware = async (uid, newPassword) => {
  try {
    const user = auth.currentUser;

    if (!user) {
      console.log("User is not logged in");
      return;
    }

    const userRef = doc(db, "users", uid ? uid : user.uid);
    await setDoc(userRef, { password: newPassword }, { merge: true });

    updatePassword(user, newPassword)
      .then(() => {
        console.log("Password changed successfully");
        window.open('https://old.importir.com/logout', '_blank');
        store.clearAll();
      })
      .catch((error) => {
        console.log("Failed to change password:", error);
      });
  } catch (error) {
    console.log("Failed to update document:", error);
  }
};