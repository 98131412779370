import { HStack, Spacer, Stack, Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer, 
    Tag,
    TagLabel,
    InputGroup,
    InputLeftAddon,
    Input,
    Select,
    Button,
    Box} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import colors from '../../Utils/colors'
import { IoCaretBackOutline } from 'react-icons/io5'
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice'

function OldSHPIndex() {
    const navigate = useNavigate()
    const {loadingShow, loadingClose, userSymbolCurrency} = useContext(AuthContext)
    const convertPriceFix = ConvertPrice()
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [shippingList, setShippingList] = useState()

    const fetchShpData = async (page) => {
        try {
            loadingShow()
            const res = await _axios.get(`/api/shipping/old-shp?page=${page}`)
            if (page === 1) {
                setShippingList(res.data)
            } else {
                setShippingList(previousData => [...previousData, ...res.data])
            }
            loadingClose()
            console.log(res)
        } catch (error) {
            console.log(error)   
        }
    }

    useEffect(() => {
        fetchShpData(currentPage);
    
    }, [currentPage]);

    const handleLoadmore = async () => {
        if (currentPage < lastPage) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    }
  return (
    <Stack border={"1px gray"} borderRadius={"xl"} bgColor={"white"} shadow={"2xl"} mt={5} p={[1, 1, 5]}>
        <HStack>
            <HStack cursor='pointer' zIndex='50' w='100px' mx={5} mb={2} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                <IoCaretBackOutline size={15} />
                <Text fontSize={'xs'} letterSpacing={0.5}>Back</Text>
            </HStack>
            <Spacer />
        </HStack>
        <HStack>
            <TableContainer width={'100%'}>
                <Table variant='striped' colorScheme='teal'>
                    <Thead>
                    <Tr>
                        <Th textAlign={'center'}>Shipping Number</Th>
                        <Th textAlign={'center'}>Type</Th>
                        <Th textAlign={'center'}>Total</Th>
                        <Th textAlign={'center'}>Created At</Th>
                        <Th textAlign={'center'}></Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                        {shippingList?.length > 0 && shippingList?.map((x, i) => 
                            <Tr key={i}>
                                <Td>
                                    <a href='javascript:;' onClick={() => navigate('/shipping/detail/' + x.invoice_no)}>
                                        <Text color={'blue.500'}>{x.invoice_no}</Text>
                                    </a>
                                </Td>
                                <Td>
                                    {
                                        x.is_demo ? 
                                        <Tag
                                            size={'md'}
                                            key={'md'}
                                            borderRadius='full'
                                            variant='solid'
                                            colorScheme='green'
                                            >
                                            <TagLabel>DEMO</TagLabel>
                                        </Tag>
                                        : 
                                            <Tag
                                                size={'md'}
                                                key={'md'}
                                                borderRadius='full'
                                                variant='solid'
                                                colorScheme='red'
                                                >
                                                <TagLabel>REAL</TagLabel>
                                            </Tag>
                                    }
                                </Td>
                                <Td textAlign={'right'}>{userSymbolCurrency} {formatFrice(parseInt(x.grand_total))}</Td>
                                <Td>{x.created_at}</Td>
                                <Td>
                                    <Button bgColor={'blue.200'} textColor={'white'} mr={2} onClick={() => navigate('/old-shp/' + x.invoice_no)}>Detail</Button>
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        </HStack>
        {
            currentPage < lastPage && ( 
            <Stack justifyItems={'center'} alignItems={'center'}>
                <Button colorScheme='blue'  w={'40'} onClick={() => handleLoadmore()}>Loadmore</Button>
            </Stack>
        )}
    </Stack >
  )
}

export default OldSHPIndex