/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, HStack, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Skeleton, Spacer, Stack, Tag, Text, useToast, AspectRatio, ListItem,  UnorderedList } from '@chakra-ui/react'
import { arrayUnion, collection, doc, documentId, getDocs, increment, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { SlArrowDown } from 'react-icons/sl'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { errorSlack, productSearchSlack } from '../Api/SlackApi'
// import { clientTypessense } from '../Api/Typesense'
import ProductCard from '../Components/Card/ProductCard'
import { db } from '../Config/firebase'
import AuthContext from '../Routes/hooks/AuthContext'
import ConvertPrice from '../Utils/ConvertPrice'
import { formatFrice } from '../Utils/Helper'
import nonlartas from "../Config/Json/NonLartas.json"
import { sendEcommerceEvent } from '../Utils/gtm'
import axios from 'axios'
import moment from 'moment'
import TypesenseRestApi from '../Api/TypesenseRestApi'
import { useJitsu } from '@jitsu/jitsu-react';
import store from 'store'
import { triggerEventFacebookPixel } from '../Utils/facebook'

function SearchPage() {
    const {analytics} = useJitsu();
    const userData = store.get('fb_account');

    let [searchParams] = useSearchParams()
    const { currentUser, kursRmb, loadingShow, loadingClose, productListWishlistId, userSymbolCurrency, categoryFrom } = useContext(AuthContext)

    const path = window.location.href ?? '#'
    const toast = useToast({
        position: "top",
        align: "center",
    });


    // const [search, setSearch] = useState('')
    const [productList, setProductList] = useState([])
    const [page, setPage] = useState(1)

    const [imageView, setImageView] = useState('')
    const [detailModal, setDetailModal] = useState(false)
    const [lastPage, setLastPage] = useState(0)

    const navigate = useNavigate()

    const { convertPriceFix } = ConvertPrice()


    const location = useLocation()
    const searchLocation = location.state
    const detailSearchParams = searchParams.get("detail")


    const height = window.innerHeight

    const dataNonLartas = nonlartas.map((x) => x.product_id)


    const handleSearchParams = async () => {
        console.log('handlesearch')
        loadingShow();

        try {
            let detailSearch = searchLocation || detailSearchParams;

            // setSearch(detailSearch);

            if (/^\d+$/.test(detailSearch)) {
                const q = query(collection(db, "products"), where("id", "==", Number(detailSearch)));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    loadingClose();
                    setProductList([]);
                    return;
                }

                const arrProducts = querySnapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));

                if (arrProducts.length > 0) {
                    arrProducts.sort((a, b) => a.price - b.price);
                }

                if (page > 1) {
                    setProductList((prevProductList) => [...prevProductList, ...arrProducts]);
                } else {
                    setProductList(arrProducts);
                }

                loadingClose();
            } else {
                const urlPattern = /https?:\/\/(?:detail|m)\.1688\.com\/[^/]+\/([^/?]+)/;
                const match = detailSearch.match(urlPattern);

                if (match) {
                    const objectId = match[1];
                    navigate(`/product/1688/${objectId}`);
                    return;
                } else if (detailSearch.includes('1688.com')) {
                    const objectId = detailSearch.split("Id=")[1];
                    navigate(`/product/1688/${objectId}`);
                    return;
                } else if (detailSearch.includes('taobao.com')) {
                    let params = (new URL(detailSearch)).searchParams;
                    const objectId = params.get('id')
                    navigate(`/product/taobao/${objectId}`);
                    return;
                } else if (detailSearch.includes('tmall.com')) {
                    let detailId = searchParams.get("id");
                    if (!detailId) {
                        detailId = detailSearch.split("?id=")[1].split('&ut')[0]
                    }
                    navigate(`/product/tmall/${detailId}`);
                    return;
                } else if (detailSearch.includes('amazon.com')) {
                    let detailId = null;

                    if (detailSearch.split('dp/').length > 1) {
                        detailId = detailSearch.split("dp/")[1].split("/ref")[0];
                    } else if (detailSearch.split('gp/').length > 1) {
                        detailId = detailSearch.split("gp/")[1].split("/ref")[0].split("product/")[1];
                    }
                    navigate(`/product/amazonus/${detailId}`);
                    return;
                }

                const searchParameters = {
                    q: detailSearch,
                    query_by: "title-en.en",
                    filter_by: `domain:${categoryFrom === "usa" ? "amazon.com" : categoryFrom === "korea" ? "coupang.com" : "detail.1688.com"} `,
                    page: page,
                    per_page: 10,
                    sort_by: "_text_match:desc",
                };

                const searchResult = await TypesenseRestApi(searchParameters)
                console.log('first', searchResult)

                // const searchResult = await clientTypessense
                //     .collections("products")
                //     .documents()
                //     .search(searchParameters);

                const totalPages = Math.ceil(searchResult.found / searchParameters.per_page);
                setLastPage(totalPages);

                const searchResultIds = searchResult.hits.map((hit) => hit.document.id);
                console.log(searchResultIds, 'search')

                let otCommerceData = [];

                // if (searchParameters.filter_by.includes("1688")) {
                //     const resOtCommerce = (await axios.post(`https://service-third-party.importir.com/general/search-ot-commerce`, {
                //         "provider": "Taobao",
                //         "keyword": searchParameters.q
                //     })).data;
                //     console.log('resOtCommerce', resOtCommerce)
                //     if (resOtCommerce.status) {
                //         for (let i = 0; i < resOtCommerce?.data?.Result?.Items?.Content.length; i++) {
                //             const dataItem = resOtCommerce?.data?.Result?.Items?.Content[i]
                //             console.log("dataItem", dataItem)
                //             otCommerceData.push({
                //                 "id": dataItem.Id,
                //                 "image": dataItem?.Pictures[0].Url,
                //                 "title": dataItem?.OriginalTitle,
                //                 "title-en": {
                //                     "en" : dataItem?.Title
                //                 },
                //                 "price": dataItem?.PromotionPrice !== undefined ?  dataItem?.PromotionPrice?.OriginalPrice : dataItem?.Price?.OriginalPrice,
                //                 "link": dataItem?.ExternalItemUrl
                //             })
                //         }
                //     }
                // }

                if (searchResultIds.length < 10) {

                    try {
                        const res = await axios.get(`https://new-apiv2.importir.com/api/products?page=2&platform_type=${categoryFrom === "usa" ? "amazonus" : categoryFrom === "korea" ? "coupang" : "1688"}&title=${detailSearch}&page=${page}`);

                        if (res?.data?.status === true) {
                            const dataArr = res.data.data.data;
                            setLastPage(res?.data?.data?.last_page)
                            const arrProductsV2 = dataArr.map((element) => ({
                                category: element.category_id_platform,
                                date: moment(new Date()).valueOf(),
                                domain: categoryFrom === "usa" ? "category-amazon-com-v2" : categoryFrom === "korea" ? "coupang.com" : "detail.1688.com",
                                id: `${categoryFrom === "korea" ? "coupang.com-" : ""}${element.product_id}`,
                                image: element.image,
                                link: categoryFrom === "usa" ? "https://www.amazon.com" : categoryFrom === "korea" ? "https://www.coupang.com" : "detail.1688.com",
                                price: Number(element.prices),
                                sold: JSON.stringify(element.moq),
                                title: element.name,
                                "title-en": {
                                    en: element.name_en,
                                },
                            }));
                            

                            if (arrProductsV2.length > 0) {
                                arrProductsV2.sort((a, b) => a.price - b.price);
                            }

                            if (arrProductsV2.length === 0) {
                                productSearchSlack(detailSearch, path); // login slack
                                setProductList([]);

                                try {
                                    const currentDate = new Date();
                                    const currentDateString = currentDate.toISOString().split("T")[0];
                                    const validCollectionName = `SNF_${currentDateString.replace(/-/g, "_")}`;
                                    const ref = doc(db, "product_search", validCollectionName);
                                    await setDoc(ref, {
                                        product_not_found: arrayUnion(detailSearch),
                                        updatedAt: new Date(),
                                    }, { merge: true });
                                } catch (error) {
                                    console.log(error, 'ini eror');
                                } finally {
                                    loadingClose();
                                }
                            }
                            if (page > 1) {
                                setProductList((prevProductList) => [...prevProductList, ...arrProductsV2]);
                            } else {
                                setProductList(arrProductsV2);
                            }

                        } else {
                            console.log(res.data.message);
                        }
                    } catch (error) {
                        console.log(error, 'save to firebase ');
                    } finally {
                        loadingClose();
                    }

                    return;
                }

                const q = query(
                    collection(db, "products"),
                    where(documentId(), "in", searchResultIds)
                  );
      
                  onSnapshot(q, (querySnapshot) => {
                    const arrProducts = [];
      
                    // Loop through querySnapshot in the order of searchResultIds
                    searchResultIds.forEach((id) => {
                      const doc = querySnapshot.docs.find((doc) => doc.id === id);
                      if (doc) {
                        const objData = doc.data();
                        objData.id = doc.id;
                        arrProducts.push(objData);
                      }
                    });
      
                    if (arrProducts.length > 0) {
                      arrProducts.sort((a, b) => a.price - b.price);
                    }
      
                    if (page > 1) {
                      setProductList((prevProductList) => [...prevProductList, ...arrProducts]);
                    } else {
                      setProductList(arrProducts);
                    }

                    if (otCommerceData.length > 0) {
                        setProductList((prevProductList) => [...prevProductList, ...otCommerceData]);
                    }
      
                    loadingClose();
                  });
            }
            setTimeout(() => {
                const paramItems = productList.map((e) => {
                    return {
                        item_id: e?.id,
                        item_name: e["title-en"]?.en,
                        item_category: e?.category,
                        item_value: e
                    }
                })
                const ecommerceData = {
                    'user_id': userData?.user?.uid ?? "",
                    'email': userData?.user?.email ?? "",
                    'location': `${window.location.pathname}?detail=${detailSearch}`,
                    'company_id': "dNNHm2zy4iTSfp1ejV9K",
                    'project_id': "wjS0AhuP4FAF1j0A6tb9",
                    'currency': 'IDR',
                    'value': detailSearch,
                    'items': JSON.stringify(paramItems),
                };
                analytics.track('search', ecommerceData)
                triggerEventFacebookPixel('Search', ecommerceData)
            }, 3000)
        } catch (error) {
            if (error.code === "aborted") {
                console.log("Network error occurred");
            } else {
                const resSlack = await errorSlack(error, 'importir.com', path);
                console.log(resSlack, 'CONNECTED ERROR IN SLACK');
            }
            loadingClose();
        }
    };

    useEffect(() => {
        setProductList([]);
        setPage(1);
    }, [searchLocation]);

    useEffect(() => {
        handleSearchParams()

    }, [searchLocation, page])

    const handleLoadMore = () => {
        const nextPage = page + 1;
        // setSearchParams(`detail=${search}&page=${nextPage}`);
        setPage(nextPage);
    };

    const handleWishlist = async (data) => {
        loadingShow()

        let newData = data
        newData.createdAt_wishlist = new Date()

        try {

            const ref = doc(db, "wishlist", currentUser.uid);
            await setDoc(ref, {
                uid: currentUser.uid,
                data: arrayUnion(newData),
                createdAt: new Date()
            }, { merge: true });

            const docRef = doc(db, "products", data.id);
            try {
                await updateDoc(docRef, {
                    wishlist_count: increment(1),
                });


                const ecommerceData = {
                    'currency': 'IDR',
                    'value': parseFloat(data.price),
                    'items': [
                        {
                            'item_id': data.id,
                            'item_name': data.title,
                            'item_category': data.category,
                            // 'price': parseFloat(data.price),
                            // 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
                        },
                    ],
                };

                sendEcommerceEvent('add_to_wishlist', ecommerceData);


            } catch (error) {
                console.log(error);
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
            loadingClose()
            toast({
                title: 'importir.com',
                description: 'Successfully added the product to the wishlist.',
                status: 'success'
            })

        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }

        loadingClose()
    }

    const handleImage = (data) => {
        setImageView(data)
        setDetailModal(true)
    }

    const handleNavigateProduct = (idProduct, platformType) => {
        const navigateRouteProduct = `/product/${platformType}/${idProduct}`
        navigate(navigateRouteProduct)
    }

    return (
        <Stack px={[1, 1, 5]} pb={10} bgColor='gray.100' minH={height}>
            <Grid templateColumns={{ base: "1fr", md: "1fr " }} gap={3} >

                <Stack>
                    <HStack alignItems={'center'} justifyContent='center' p={[1, 1, 3]}>

                    </HStack>

                    <Stack spacing={2} px={[1, 1, 3]}>
                        <Stack >
                            {productList?.length >= 1 ? (
                                <>
                                    <SimpleGrid columns={[2, 3, 5]} gap={5} >
                                        {
                                            productList?.map((product, i) => {

                                                const wishlistFilter = productListWishlistId.includes(product.id)

                                                const nonLartasFilter = dataNonLartas.includes(product.id)



                                                return (
                                                    <ProductCard
                                                        key={i}
                                                        product={product}
                                                        kursRmb={kursRmb}
                                                        handleNavigateProduct={handleNavigateProduct}
                                                        handleWishlist={handleWishlist}
                                                        handleImage={handleImage}
                                                        nonLartasFilter={nonLartasFilter}
                                                        wishlistFilter={wishlistFilter}
                                                        userSymbolCurrency={userSymbolCurrency}
                                                    />
                                                )
                                            }
                                            )
                                        }

                                    </SimpleGrid>
                                    {
                                        lastPage === page ? ""
                                            :
                                            <Stack pt={5} alignItems='center' justifyContent={'center'}>
                                                <Button onClick={() => handleLoadMore()} bgColor='#ffd600' shadow={'md'}>
                                                    <HStack >
                                                        <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                                                            Load more
                                                        </Text>
                                                        <Stack>
                                                            <SlArrowDown size={10} />
                                                        </Stack>
                                                    </HStack>
                                                </Button>
                                            </Stack>
                                    }
                                </>
                            ) :
                                <Stack bg={'white'} px={6} py={6} w={'full'}>
                                    <SimpleGrid columns={2}>
                                        <Box marginTop={'30%'}>
                                            <Text fontSize={'xl'} textColor={'red.300'} textAlign={'center'} fontWeight={'bold'} fontFamily={'monospace'}>Sorry, Search engine under maintenence.</Text>
                                            <Text fontSize={'3xl'} textColor={'#407BFF'} textAlign={'center'} fontWeight={'bold'} fontFamily={'monospace'}>Opps!!! the product you are looking for is not found.</Text>
                                            <Stack px={6} py={6} marginLeft={'10%'} textColor={'GrayText'}>
                                                <Text>Try:</Text>
                                                <UnorderedList>
                                                    <ListItem>if the keywords you enter are too long, please search using more specific words</ListItem>
                                                    <ListItem>Use English to search for the keywords you want</ListItem>
                                                    <ListItem>Or please click the video below to search for products from 1688.com</ListItem>
                                                </UnorderedList>
                                            </Stack>
                                        </Box>
                                        <Box>
                                            <Image src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/no-data-rafiki-2-2023_06_22_11_14_32.png'></Image>
                                        </Box>
                                    </SimpleGrid>
                                    <Box style={{ marginLeft: '12%', marginRight: '10%' }}>
                                        <AspectRatio maxW='960px' ratio={16 / 9}>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ArDbLsB_inU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </AspectRatio>
                                    </Box>
                                </Stack>

                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>

            {imageView !== "" && (
                <Modal isOpen={detailModal} onClose={() => setDetailModal(false)} >
                    <ModalOverlay />
                    <ModalContent bgColor={'white'} >
                        <ModalHeader>
                            <HStack spacing={2} alignItems='center' >
                                {/* <FontAwesome5 name="file-invoice" size={22} color="black" /> */}
                                <Text fontSize={'lg'} fontWeight='bold'>Detail</Text>
                                <Spacer />
                                <HStack alignItems={'center'} justifyContent='flex-end'>
                                    <Stack>
                                        <Tag>CN 🇨🇳</Tag>
                                    </Stack>

                                </HStack>
                            </HStack>
                        </ModalHeader>

                        <ModalBody >
                            <Stack borderRadius={'xl'} spacing={2} bgColor='white' >
                                <Stack alignItems={'center'} cursor='pointer'>
                                    {imageView.image ? (
                                        <Image w={'full'} src={imageView.image} alt='img' borderRadius={'md'} />
                                    ) : (
                                        <Skeleton w={'200px'} h='300px' borderRadius={'md'} />
                                    )}

                                </Stack>
                                <Stack px={3}>

                                    <Text textTransform={'capitalize'} fontWeight='bold' fontSize={'sm'} noOfLines={2}> {imageView["title-en"]?.en}</Text>
                                </Stack>

                                <Spacer />

                                <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                                    <Stack spacing={0} alignItems='flex-start' justifyContent={'flex-start'} >

                                        <Text textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>Price</Text>
                                        {/* <Text textTransform={'capitalize'} fontWeight='extrabold' color={'black'} fontSize={'md'}>{userSymbolCurrency} {formatFrice(x.price)}</Text> */}
                                        <Text textTransform={'capitalize'} fontWeight='bold' color={'black'} fontSize={'sm'}>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(imageView.price)))}</Text>
                                    </Stack>

                                    <Stack >

                                        <Stack spacing={0} alignItems='flex-end' justifyContent={'flex-end'}>
                                            <Text fontsize textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>Sold</Text>
                                            <Text textTransform={'capitalize'} color={'black'} fontWeight='bold' fontSize={'sm'}>{imageView.sold}</Text>
                                        </Stack>
                                    </Stack>


                                </SimpleGrid>

                            </Stack>
                        </ModalBody>

                        <ModalFooter>
                            <HStack spacing={5}>
                                <Button bgColor='green.400' onClick={() => handleNavigateProduct(imageView.id, '1688')}>
                                    <Text color={'white'}>🛒 Order now</Text>
                                </Button>
                                <Button colorScheme='red' mr={3} onClick={() => setDetailModal(false)}>
                                    Close
                                </Button>
                            </HStack>
                        </ModalFooter>


                    </ModalContent>
                </Modal>
            )}
        </Stack>
    )
}

export default SearchPage