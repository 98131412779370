import { Badge, Box, Flex, Heading, HStack, Image, Spacer, Stack, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { PriceTag } from "../../Utils/Helper"
import { AiOutlineEye, AiOutlineTag } from "react-icons/ai"



function ListingCard({ listing, index }) {
    return (
        // <Link to={`/business/${listing?.id}`} key={index}>
        <Stack p={3} borderRadius='md' shadow='md' bgColor={'white'} key={index}>
            <Stack>
                <Image src={listing?.image} />

            </Stack>

            <Stack>
                <Heading my='2' fontSize='md'>{listing?.title}</Heading>
                <Text mb='2' fontSize={'sm'} color='gray.500' noOfLines={2}>{listing?.description}</Text>
            </Stack>


            <Spacer />
            <HStack>


                <Stack>
                    <HStack spacing={5}>
                        <HStack >
                            <AiOutlineTag color="gray" />
                            <Text fontSize='xs' fontWeight={500}>Tags</Text>
                        </HStack>
                        {/* {listing?.seen?.length > 0 && (
                            <HStack>
                                <AiOutlineEye color="gray" />
                                <Text fontSize={'xs'}>{listing?.seen?.length}</Text>
                            </HStack>
                        )} */}
                    </HStack>
                    <Flex overflowY={'auto'} css={{
                        '&::-webkit-scrollbar': {
                            height: '0rem',
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            width: '6px',
                            // backgroundColor: 'whitesmoke'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            // background: 'DarkGray',
                            height: '2px',
                            // borderRadius: '24px',
                        },
                    }} >
                        <Box width={'150px'} >
                            {listing?.category?.map((x, i) => (
                                <Box bgColor={'green.200'} key={i} borderRadius="md" p={1}>
                                    <Text fontWeight={500} fontSize='2xs' mx='1' textTransform={'uppercase'} colorScheme='green' noOfLines={1}>{x}</Text>
                                </Box>
                            ))}
                        </Box>
                    </Flex>
                </Stack>

                <Spacer />


                <Stack spacing={0} fontSize='sm'>
                    <Text textAlign='end'>Start From</Text>
                    <Flex justify={'right'} flexWrap={'wrap'}>
                        <PriceTag price={listing?.price} />
                    </Flex>
                </Stack>
            </HStack>
        </Stack>
        // </Link>
    )
}

export default ListingCard