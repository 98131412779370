import React, { useEffect, useState } from 'react'
import { Container, Stack, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Icon } from '@chakra-ui/react';
import _axios from '../../../Api/AxiosBarrier';
import { useNavigate } from 'react-router-dom';
import { formatFrice } from '../../../Utils/Helper';
import { FcSearch } from 'react-icons/fc';

function IndexPage() {
    const [orders, setOrders] = useState()
    const navigate = useNavigate()

    const fetchOrderList = async () => {
        try {
            const res = await _axios.get('api/mitra/order/index-old')
            setOrders(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchOrderList()
    }, []);
  return (
    <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxW="container.xl" position={'relative'} zIndex={'1'} top={'-3rem'}>
        <Stack p={[1, 1, 5]}>
            <TableContainer>
                <Table variant='striped' colorScheme='teal'>
                    <Thead>
                        <Tr>
                            <Th>Invoice</Th>
                            <Th>Items</Th>
                            <Th>Price</Th>
                            <Th>Paid At</Th>
                            <Th>Checkout At</Th>
                            <Th>Detail</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            orders?.length > 0 ? 
                            orders?.map((x, i) => {
                                return (
                                    <Tr>
                                        <Td>{x?.order_number}</Td>
                                        <Td>{x?.mitra_order_details.length}</Td>
                                        <Td>Rp. {formatFrice(parseInt(x.product_price_total))}</Td>
                                        <Td>{x?.paid_at == null ? 'Not Paid' : 'Paid'}</Td>
                                        <Td>{x?.checkout_at}</Td>
                                        <Td>
                                            <Button colorScheme={'blue'} onClick={() => navigate(`/mitra/old-order/${x.id}/detail`)}>
                                                <Icon as={FcSearch} />
                                            </Button>
                                        </Td>
                                    </Tr>
                                )
                            })
                            :
                            <Stack
                                position={'absolute'}
                                top={'85%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'}
                                textAlign={'center'}
                                >
                                <Text>Data not found</Text>
                            </Stack>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>
    </Container>
  )
}

export default IndexPage