import { Stack, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import nonlartas from "../../Config/Json/NonLartas.json"


function ProductListNonLartasPage() {

  const getData = () => {

    const dataNon = nonlartas.map((x) => x.product_id)

    console.log(dataNon, 'xxx')

    



  }

  useEffect(() => {
    getData()
  
    return () => {
    }
  }, [])
  

  return (
    <Stack>
      <Text>Hello world</Text>
    </Stack>
  )
}

export default ProductListNonLartasPage