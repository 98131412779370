import { Box, Button, Divider, Grid, Heading, HStack, Image, SimpleGrid, Spacer, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { IoCaretBackOutline, IoHelpCircleOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import { errorSlack } from '../../Api/SlackApi'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import ConvertPrice from '../../Utils/ConvertPrice'
import CopyToClipboard from '../../Utils/CopyToClipboard'
import { sendEcommerceEvent } from '../../Utils/gtm'
import { formatFrice } from '../../Utils/Helper'

function InvoiceDetailNoMemberPage() {

    const params = useParams()

    const { loadingShow, loadingClose, userSymbolCurrency } = useContext(AuthContext)
    const [bankAccount, setBankAccount] = useState('')
    const [bankAccountImage, setBankAccountImage] = useState('')
    const [invoiceProduct, setInvoiceProduct] = useState('')
    const path = window.location.href ?? '#'


    const toast = useToast({
        position: "top",
        align: "center",
    });

    const { convertPriceFix } = ConvertPrice()

    const navigate = useNavigate()

    const banks = [
        { name: "BNI", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png" },
        { name: "BCA", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png" },
        { name: "MANDIRI", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png" },
        { name: "PERMATA", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png" },
        { name: "BRI", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png" }
    ]




    const [detailInvoice, setDetailInvoice] = useState('')

    const getDataDetail = async () => {
        setDetailInvoice('')
        loadingShow()
        if (params.id !== undefined) {
            try {
                const res = await _axios.get(`api/invoices/${params.id}`,   {
                    headers: {
                      Authorization: "Bearer 28618|bDNufNm82NohKj6c21p94kiiAUyaVORCGuhUWqFl",
                    },
                  })
                if (res.status === true) {
                    setDetailInvoice(res.data)
                }

            } catch (error) {
                console.log(error)
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
                loadingClose()
            }
            loadingClose()
        }
    }

    useEffect(() => {
        getDataDetail()

        return () => {
        }
    }, [])

    const handleCopy = (id) => {
        navigator.clipboard.writeText(id)
        toast({
            title: 'importir.com',
            description: 'Copy to clipboard.',
            status: 'success'
        })
    }



    const handleBankActive = (name, image) => {
        setBankAccount(name)
        setBankAccountImage(image)
    }

    const handlePayment = async (method) => {
        loadingShow()
        const data = {
            invoiceId: detailInvoice?.id,
            payment_method: method,
            redirect_url: path
        }
        try {
            const res = await _axios.post('api/payments/xendit', data,   {
                headers: {
                  Authorization: "Bearer 28618|bDNufNm82NohKj6c21p94kiiAUyaVORCGuhUWqFl",
                },
              })
            if (res.status === true) {
                const ecommerceData = {
                    'currency': 'IDR',
                    'value': parseFloat(detailInvoice?.amount_total),
                    'items': [
                        {
                            'item_name': data.title,
                            'paid_with': 'xendit',
                            // 'price': parseFloat(data.price),
                            // 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
                        },
                    ],
                };
    
                sendEcommerceEvent('add_payment_info', ecommerceData);
                console.log('add_payment_info', ecommerceData);
                window.open(res.data, 'blank')
                loadingClose()
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                loadingClose()
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
        loadingClose()
    }

    const handlePaymentBca = async () => {
        loadingShow()
        setBankAccount("BCA")
        setBankAccountImage("https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png")
        const data = {
            invoiceId: detailInvoice?.id,
            payment_method: "bank_transfer",
        }

        try {
            const res = await _axios.post('api/payments/midtrans', data,   {
                headers: {
                  Authorization: "Bearer 28618|bDNufNm82NohKj6c21p94kiiAUyaVORCGuhUWqFl",
                },
              })
            if (res.status === true) {
                const ecommerceData = {
                    'currency': 'IDR',
                    'value': parseFloat(detailInvoice?.amount_total),
                    'items': [
                        {
                            'item_name': data.title,
                            'paid_with': 'midtrans',
                            // 'price': parseFloat(data.price),
                            // 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
                        },
                    ],
                };
    
                sendEcommerceEvent('add_payment_info', ecommerceData);
                console.log('add_payment_info', ecommerceData);
                setInvoiceProduct(res.data)
                loadingClose()
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                loadingClose()
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
        loadingClose()
    }



    return (
        <Stack spacing={2} p={[1, 1, 5]}>
            <HStack cursor='pointer' w='100px'  mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate('/order-tracking-non-member')}>
                <IoCaretBackOutline size={15} />
                <Text fontSize={'xs'} letterSpacing={0.5}>Back</Text>
            </HStack>


            <Stack spacing={5} p={[1, 1, 5]}>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>Product Order</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm' textAlign='center'>Invoice</Th>
                                    <Th fontSize='sm' textAlign='center'>Total Product</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr >
                                    <Td fontSize='sm' textAlign='center'>{detailInvoice?.invoice}</Td>
                                    <Td fontSize='sm' textAlign='center'>{userSymbolCurrency} {formatFrice(convertPriceFix(Number(detailInvoice?.amount_total)))}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>Detail</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm'>Title</Th>
                                    <Th fontSize='sm'>Amount</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {detailInvoice?.details?.length > 0 && detailInvoice?.details?.map((x, index) => (
                                    <Tr key={index}>
                                        <Td fontSize='sm'>{x.title}</Td>
                                        <Td fontSize='sm'>{userSymbolCurrency} {formatFrice(convertPriceFix(x.amount))}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>


                <Stack >
                    <HStack alignItems={'center'} spacing={2} >
                        <Text fontSize={'lg'} fontWeight='bold'>Payment Method</Text>
                        <Stack onClick={() => navigate('/information')}>
                            <IoHelpCircleOutline size={20} color="black" />
                        </Stack>
                    </HStack>
                    {detailInvoice.paid_at ? (
                        <Stack bgColor={'white'} spacing={5} borderRadius='xl' m={2} p={3} shadow={'md'} alignItems='center' justifyContent={'center'}>
                            <Heading size='sm'>Payment has paid</Heading>
                            <Text size='sm'>{moment(detailInvoice.paid_at).format('LLL')}</Text>
                        </Stack>
                    ) : (
                        <Stack bgColor={'white'} spacing={5} borderRadius='xl' m={2} p={3} shadow={'md'}>
                            {
                                invoiceProduct ? (
                                    <Stack   >
                                        <HStack w='full' px={5}>
                                            <Image
                                                src={
                                                    bankAccountImage
                                                }
                                                alt={bankAccount}
                                                w="80px"
                                                borderRadius={'xl'}
                                            />

                                            <Spacer />

                                            <Text fontSize={'sm'} textTransform='uppercase'>{invoiceProduct?.transaction_status}</Text>


                                        </HStack>

                                        <Box bg='white' px={5}>
                                            <Text>No. Virtual Account : </Text>
                                            <Divider my={2} />
                                            <Box display='flex' flexDirection='row' justifyContent="space-between">
                                                <Text fontSize={20} color="gray.500">{invoiceProduct ? (invoiceProduct?.va_numbers[0]?.va_number) : "none"}</Text>
                                                <Text color="blue.600" cursor={'pointer'} onClick={() => handleCopy(invoiceProduct?.va_numbers[0]?.va_number)}>COPY</Text>
                                            </Box>
                                            <Divider my={2} />
                                            <HStack py={1}>
                                                <Text fontSize={'sm'}>IMPORTIR.COM</Text>
                                                <Spacer />
                                                <Text  >{userSymbolCurrency} {formatFrice(convertPriceFix(Number(invoiceProduct?.gross_amount)))}</Text>
                                                <CopyToClipboard text={invoiceProduct?.gross_amount} />

                                            </HStack>
                                            <Divider my={2} />

                                            <Text fontSize={10} color="gray.600">Proses verifikasi otomatis kurang dari 10 menit setelah pembayaran berhasil</Text>
                                            <Spacer />
                                            <Text fontSize={10} color="gray.600">Bayar ke Virtual Account di atas sebelum membuat donasi baru dengan Virtual account agar nomor tetap sama.</Text>
                                        </Box>
                                        <Box bg='white' p={5}>
                                            <Text fontSize={10} color="gray.600">
                                                Petunjuk Transfer mBanking :
                                            </Text>
                                            <Divider />
                                            <Text fontSize={10} color="gray.600">
                                                1. Login ke mBanking-mu, pilih Transaksi, kemudian cari BCA Virtual Account
                                            </Text>
                                            <Text fontSize={10} color="gray.600">
                                                2. Masukkan nomor Virtual Account
                                            </Text>
                                            <Text fontSize={10} color="gray.600">3. Pastikan nama dan nominal bayar benar</Text>

                                        </Box>

                                        <Stack alignItems={'center'} justifyContent='center'>
                                            <Box>
                                                <Button colorScheme={'red'} size='sm' onClick={() => setInvoiceProduct('')}>Cancel payment</Button>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                )
                                    : (
                                        <HStack spacing={3} p={[1, 1, 5]} alignItems='center' justifyContent={'center'}>

                                            <Button onClick={() => handlePayment("INVOICE_TRANSFER_VA")} bg="green.600">

                                                <HStack alignItems={'center'} justifyContent='center' space={2}>
                                                    {/* <Ionicons name="ios-cart-outline" size={25} color="white" /> */}
                                                    <Text color="gray.100" fontWeight='bold'>Pay with transfer</Text>
                                                </HStack>
                                            </Button>

                                            <Button onClick={() => handlePayment("CREDIT_CARD")} bg="green.600">

                                                <HStack alignItems={'center'} justifyContent='center' space={2}>
                                                    {/* <Ionicons name="ios-cart-outline" size={25} color="white" /> */}
                                                    <Text color="gray.100" fontWeight='bold'>Pay with credit card</Text>
                                                </HStack>
                                            </Button>

                                            <Button onClick={() => handlePaymentBca()} colorScheme="facebook">

                                                <HStack alignItems={'center'} justifyContent='center' space={2}>
                                                    {/* <Ionicons name="ios-cart-outline" size={25} color="white" /> */}
                                                    <Text color="gray.100" fontWeight='bold'>Pay with BCA</Text>
                                                </HStack>
                                            </Button>
                                        </HStack>
                                    )
                            }


                        </Stack>
                    )}



                </Stack>
            </Stack >
        </Stack >

    )
}

export default InvoiceDetailNoMemberPage