import {
	Grid,
	GridItem,
	Stack,
	Card,
	CardBody,
	Heading,
	Text,
	useToast,
	Image,
	CardFooter,
	Button,
	Link,
	WrapItem,
	Badge,
	Wrap,
	HStack,
	InputGroup,
	InputLeftAddon,
	Input,
	Center,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import _axios from "../../Api/AxiosBarrier";
import AuthContext from "../../Routes/hooks/AuthContext";
import { convertDateToDateString } from "../../Utils/Helper";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { FaInfoCircle, FaSearch, FaSync } from "react-icons/fa";

function IndexResearch() {
	const toast = useToast({
		position: "top",
		align: "center",
	});
	const { loadingShow, loadingClose } = useContext(AuthContext);
	const [filters, setFilters] = useState({
		title: "",
		startDate: "",
		endDate: "",
	});

	const [researchList, setResearchList] = useState([]);
	const [lastPage, setLastPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const fetchResearchList = async (page = 1) => {
		try {
			if (filters.startDate !== "") {
				if (filters.endDate == "") {
					return toast({
						title: "importir.com",
						description: "End Date Is Required",
						status: "error",
					});
				}
			}
			if (filters.endDate !== "") {
				if (filters.startDate == "") {
					return toast({
						title: "importir.com",
						description: "Start Date Is Required",
						status: "error",
					});
				}
				if (moment(filters.startDate).isAfter(filters.endDate)) {
					return toast({
						title: "importir.com",
						description:
							"Start Date Cannot Be Greater Than End Date",
						status: "error",
					});
				}
			}
			loadingShow();
			const res = await _axios.get(
				`api/research?page=${page}&title=${filters.title}&startDate=${filters.startDate}&endDate=${filters.endDate}`
			);
			setLastPage(res.last_page);
			setCurrentPage(res.current_page);
			if (page == 1) {
				setResearchList(res.data);
			} else {
				setResearchList((prev) => [...prev, ...(res.data || [])]);
			}
			loadingClose();
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	useEffect(() => {
		fetchResearchList();
	}, []);

	const resetFilters = () => {
		setFilters({
			title: "",
			startDate: "",
			endDate: "",
		});
		fetchResearchList(1);
	};

	const loadMore = () => {
		let page = currentPage + 1;
		fetchResearchList(page);
	};

	return (
		<Stack
			bgColor={"white"}
			p={5}
			rounded={10}
			direction={"column"}
			my={5}
			gap="3"
		>
			<Heading>Research List</Heading>
			<Wrap spacing={3} overflowX={"auto"} p={2}>
				<WrapItem>
					<InputGroup>
						<InputLeftAddon>Title</InputLeftAddon>
						<Input
							type="text"
							value={filters?.title}
							onChange={(e) =>
								setFilters({
									...filters,
									title: e.target.value,
								})
							}
						/>
					</InputGroup>
				</WrapItem>
				<WrapItem>
					<InputGroup>
						<InputLeftAddon>Start Date</InputLeftAddon>
						<Input
							type="date"
							value={filters?.startDate}
							onChange={(e) =>
								setFilters({
									...filters,
									startDate: e.target.value,
								})
							}
						/>
					</InputGroup>
				</WrapItem>
				<WrapItem>
					<InputGroup>
						<InputLeftAddon>End Date</InputLeftAddon>
						<Input
							type="date"
							value={filters?.endDate}
							onChange={(e) =>
								setFilters({
									...filters,
									endDate: e.target.value,
								})
							}
						/>
					</InputGroup>
				</WrapItem>
				<WrapItem gap={3}>
					<Button
						colorScheme="blue"
						onClick={() => fetchResearchList(1)}
					>
						<HStack gap={2}>
							<FaSearch /> <Text>Search</Text>
						</HStack>
					</Button>
					<Button colorScheme="yellow" onClick={resetFilters}>
						<HStack gap={2}>
							<FaSync /> <Text>Reset</Text>
						</HStack>
					</Button>
				</WrapItem>
			</Wrap>
			<HStack spacing={3} my="3"></HStack>
			<Grid
				templateColumns={{
					base: "repeat(1, 1fr)",
					lg: "repeat(2, 2fr)",
				}}
				gap={3}
			>
				{researchList?.length > 0 ? (
					researchList?.map((item, index) => (
						<GridItem key={index}>
							<Card
								direction={{ base: "column", sm: "row" }}
								overflow="hidden"
								variant="outline"
							>
								<Image
									maxW={{ base: "100%", sm: "200px" }}
									objectFit="cover"
									src={item?.image_cat}
									alt="Image"
								/>

								<Stack>
									<CardBody>
										<Wrap spacing={3} align={"center"}>
											<WrapItem>
												<Heading size="md">
													{item?.title}
												</Heading>
											</WrapItem>
											<WrapItem>
												<Badge colorScheme="green">
													{item?.type?.toUpperCase()}
												</Badge>
											</WrapItem>
										</Wrap>

										<Text py="2">
											<strong>{item?.title_cat}</strong>,
											Published:{" "}
											{convertDateToDateString(
												moment(item?.created_at).format(
													"YYYY-MM-DD"
												)
											)}
										</Text>
									</CardBody>

									<CardFooter>
										<Link
											target="_blank"
											href={`/research/${item?.slug}`}
										>
											<Button
												variant="solid"
												colorScheme="blue"
											>
												<HStack gap={2}>
													<FaInfoCircle />
													<Text>More Detail</Text>
												</HStack>
											</Button>
										</Link>
									</CardFooter>
								</Stack>
							</Card>
						</GridItem>
					))
				) : (
					<GridItem colSpan={2}>
						<Center>
							<Text color="red" fontWeight={"bold"}>
								Data Empty
							</Text>
						</Center>
					</GridItem>
				)}
			</Grid>
			{lastPage > 1 && currentPage < lastPage ? (
				<Stack>
					<Center>
						<Button onClick={loadMore}>Load More</Button>
					</Center>
				</Stack>
			) : (
				""
			)}
		</Stack>
	);
}

export default IndexResearch;
