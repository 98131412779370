import { Alert, AlertIcon, Box, Button } from "@chakra-ui/react";
import _axios from "../Api/AxiosBarrier";

export function validatePersonalData(dataUser, toast) {


    if (dataUser.user.name === "" ||
        dataUser.user.phone === "" ||
        dataUser.user.detail === null ||
        dataUser.user.detail.address === "" ||
        dataUser.user.detail.date_of_birth === "" ||
        dataUser.user.detail.occupation === "" ||
        dataUser.user.detail.city === "" ||
        dataUser.user.detail.ktp_image === "" ||
        dataUser.user.detail.ktp_number === "" ||
        dataUser.user.detail.number === "") {
        window.open('https://www.importir.com/profile');
        // window.open('http://localhost:3000/profile');
        toast({
            title: 'importir.com',
            description: 'You must complete your personal data first',
            status: 'warning',
        });
        return false;
    }
    return true;
}


export function checkProductValidity(userDb, param, shipBy, volume, weight, currentDate, expirationDate, totalOrder, product) {
    if (param.type !== "crowdfund_1688") {
        if (shipBy === '')
            return {
                error: {
                    title: 'importir.com',
                    description: 'Please set up shipping.',
                    status: 'error',
                },
                success: false
            };

        if (volume === "0")
            return {
                error: {
                    title: 'importir.com',
                    description: 'Volume estimation is required.',
                    status: 'error',
                },
                success: false
            };

        if (weight === "0")
            return {
                error: {
                    title: 'importir.com',
                    description: 'Weight estimation is required.',
                    status: 'error',
                },
                success: false
            };
    }

    if (totalOrder < product?.moq)
    return {
        error: {
            title: 'importir.com',
            description: 'Total order does not meet the minimum order requirement.',
            status: 'error',
        },
        success: false
    };

    if (param.type === "crowdfund_1688" || param.type === "crowdfund_taobao") {
        if (product.shipping_from === "china") {
            if (totalOrder < product?.moq || totalOrder % product?.moq !== 0 ) {
                return {
                    error: {
                    title: 'importir.com',
                    description: 'Make sure the order total matches the minimum order and multiples of the minimum order',
                    status: 'error',
                    },
                    success: false   
                }
            }
        }
    }


    if (!userDb)
        return {
            error: {
                title: 'importir.com',
                description: 'You must log in to your account first.',
                status: 'error',
            },
            success: false
        };
    if (userDb?.data_importir?.user?.country_code === 'ph'){
        if (currentDate > expirationDate) {
            return {
                error: {
                    render: () => (
                        <Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="150px">
                            <AlertIcon boxSize="20px" mr={0} />
                            <Box mt={4} fontWeight="semibold">
                                Your package has expired. Please click
                                <Button variant="link" colorScheme="blue" ml={1} onClick={() => window.open('https://old.importir.com/view-profile', '_blank')}>
                                    here
                                </Button>
                                or contact the administrator to renew your package by clicking the WhatsApp icon at the bottom right.
                            </Box>
                        </Alert>
                    ),
                },
                success: false
            };
        }
    }


    if (shipBy === 'Sea' && userDb?.package_importir?.toLowerCase() === "basic")
        return {
            error: {
                title: 'importir.com',
                description: 'Your package is Basic, you cannot choose "Ship by Sea".',
                status: 'error',
            },
            success: false
        };

    return {
        success: true,
        error: null
    };
}


export function checkProductValidityNoMember( param, shipBy, volume, weight, totalOrder, product) {




    if (param.type !== "crowdfund_1688") {
        if (shipBy === '')
            return {
                error: {
                    title: 'importir.com',
                    description: 'Please set up shipping.',
                    status: 'error',
                },
                success: false
            };

        if (volume === "0")
            return {
                error: {
                    title: 'importir.com',
                    description: 'Volume estimation is required.',
                    status: 'error',
                },
                success: false
            };

        if (weight === "0")
            return {
                error: {
                    title: 'importir.com',
                    description: 'Weight estimation is required.',
                    status: 'error',
                },
                success: false
            };
    }

    if (totalOrder < product?.moq)
    return {
        error: {
            title: 'importir.com',
            description: 'Total order does not meet the minimum order requirement.',
            status: 'error',
        },
        success: false
    };



    if (shipBy === 'Sea' )
        return {
            error: {
                title: 'importir.com',
                description: 'You cannot choose "Ship by Sea".',
                status: 'error',
            },
            success: false
        };

    return {
        success: true,
        error: null
    };
}

export async function checkQuizOnboarding(mustAnswer){
    try {
        if (parseInt(mustAnswer) === 1) {
            const url = 'api/question';
            const {data} = await _axios.get(url);
            if (data !== null){
                return window.location.href = `${process.env.REACT_APP_BASE_URL}quiz-onboarding`;
            }
            
        }
        return {
            success: true,
            error: null
        };
    } catch(error){
        return {
            error: {
                title: 'importir.com',
                description: error,
                status: 'error',
            },
            success: false
        };
    }
}

