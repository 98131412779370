import { Button, Checkbox, Divider, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getCollectionFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';

function ProductNotFoundPage() {

    const [dataList, setDataList] = useState([])

    const toast = useToast({
        position: 'top',
        align: 'center',
      });

    const [dataActive, setDataActive] = useState({})
    const [detailModal, setDetailModal] = useState(false)

    const getData = async () => {
        const conditions = [
        ];
        const sortBy = { field: "updatedAt", direction: "desc" };
        const limitValue = 10;

        try {
            const res = await getCollectionFirebase(
                "product_search",
                conditions,
                sortBy,
                limitValue
            );
            setDataList(res);
        } catch (error) {
            console.log(error, "ini error");
        }
    }

    const handleDetail = (x) => {
        setDetailModal(true);
        setDataActive({
            ...x,
            // Inisialisasi product_approved sebagai array kosong jika belum ada
            product_approved: x.product_approved || [],
        });
    }


    const handleCheckboxChange = (item) => {
        setDataActive((prevDataActive) => {
            const updatedProductApproved = prevDataActive.product_approved || [];
            if (updatedProductApproved.includes(item)) {
                // Jika item sudah ada dalam product_approved, maka hapus dari daftar
                const index = updatedProductApproved.indexOf(item);
                updatedProductApproved.splice(index, 1);
            } else {
                // Jika item belum ada dalam product_approved, tambahkan ke daftar
                updatedProductApproved.push(item);
            }

            return {
                ...prevDataActive,
                product_approved: updatedProductApproved,
            };
        });
    };

    const handleSubmit = async () => {
        try {
            await updateDocumentFirebase("product_search", dataActive.id, {
                product_approved: dataActive.product_approved || [],
            });
            setDetailModal(false);
            getData();
        } catch (error) {
            console.log(error);
        }
    };

    const handleCopy = (id) => {
        navigator.clipboard.writeText(id)
        toast({
            title: 'importir.com',
            description: 'Copy to clipboard.',
            status: 'success'
        })
    }



    useEffect(() => {
        getData()

        return () => {
        }
    }, [])

    return (
        <Stack p={[1, 1, 5]}>
            <Stack>
                <Text fontSize='md' fontWeight='bold'>Detail</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray'>
                        <Thead>
                            <Tr>
                                <Th fontSize='sm'>Title</Th>
                                <Th fontSize='sm'>Amount</Th>
                                <Th fontSize='sm'>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {dataList?.length > 0 && dataList?.map((x, index) => (
                                <Tr key={index}>
                                    <Td fontSize='sm'>{x.id}</Td>
                                    <Td fontSize='sm'>{x?.product_not_found?.length}</Td>
                                    <Td fontSize='sm'>
                                        <Button colorScheme={'yellow'} fontSize='sm' onClick={() => handleDetail(x)}>Detail</Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>


            <Modal isOpen={detailModal} size='2xl' onClose={() => setDetailModal(false)}>
                <ModalOverlay />
                <ModalContent w={'100%'}>
                    <ModalHeader>{dataActive.id}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Stack>
                            {dataActive?.product_not_found?.length > 0 && (
                                dataActive?.product_not_found?.map((x, index) => {
                                    const isChecked = dataActive?.product_approved?.includes(x) || false;
                                    return (
                                        <Stack key={index}>
                                            <HStack>
                                                <Checkbox
                                                    isChecked={isChecked}
                                                    onChange={() => handleCheckboxChange(x)}
                                                >
                                                </Checkbox>
                                                <Text cursor={'pointer'} fontWeight="bold" fontSize={'sm'}  onClick={() => handleCopy(x)}>{x}</Text>
                                            </HStack>

                                            <Divider />
                                        </Stack>
                                    );
                                })
                            )}
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button size={'sm'} colorScheme='red' onClick={() => setDetailModal(false)}>
                                Close
                            </Button>
                            <Button size={'sm'} colorScheme='blue' onClick={handleSubmit}>
                                Submit
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Stack>
    )
}

export default ProductNotFoundPage