/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Center, Grid, Heading, HStack, Image, Input, Select, SimpleGrid, Spacer, Stack, Text, useToast  } from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { IoCaretBackOutline } from 'react-icons/io5'
import { SlArrowDown } from 'react-icons/sl'
import { useNavigate } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import _axiosChina from '../../Api/AxiosChina'
import { errorSlack } from '../../Api/SlackApi'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import ConvertPrice from '../../Utils/ConvertPrice'
import { formatFrice } from '../../Utils/Helper'
import store from 'store'
import { useTranslation } from 'react-i18next';

function InvoiceListPageV2() {
    const {t} = useTranslation();
    const [invoiceList, setInvoiceList] = useState([])
    const [count, setCount] = useState(1)
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('')
    const [startPaid, setStartPaid] = useState('')
    const [endPaid, setEndPaid] = useState('')

    const { convertPriceFix } = ConvertPrice()
    const height = window.innerHeight
    const path = window.location.href ?? '#'

    const { userSymbolCurrency, loadingShow, loadingClose } = useContext(AuthContext)
    const navigate = useNavigate()
    let userDB = store.get('user_api')

    const toast = useToast({
        position: "top",
        align: "center",
    });


    const handleDateChange = (e) => {
        const { id, value } = e.target;

        if (id === 'startPaid') {
            setStartPaid(value);
        } else if (id === 'endPaid') {
            setEndPaid(value);
        }
    };

    const getDataInvoice = async () => {
        loadingShow()
        try {
            const res = await _axios.get(`api/invoices?page=${count}&invoice=${search}&status=${status}&start_paid_at=${startPaid}&end_paid_at=${endPaid}`)
            if (res && res.data && res.data.invoice) {
                const dataArr = res.data.invoice
                if (count > 1) {
                    setInvoiceList(prevDataList => [...prevDataList, ...dataArr])
                } else {
                    setInvoiceList(dataArr)
                }
            }
            loadingClose()
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
    }

    const handlePagination = () => {
        setCount(prevCount => prevCount + 1)
    }

    const handleNavigateDetail = async (item) => {
        if (userDB?.country_code === "id") {
            if (item?.details[0]?.order?.is_lartas > 0) {
                console.log('ini lartas')
                try {
                    const res = await _axiosChina.get(`payment/order/v2/${item?.invoice}/init-payment`)
                    if (res.status === true) {
                        window.open(res.data.redirect_url, 'blank')
                        toast({
                            title: 'importir.com',
                            description: 'success create payment',
                            status: 'success'
                        })
                    } else {
                        toast({
                            title: 'importir.com',
                            description: res.message,
                            status: 'error'
                        })
                        const resSlack = await errorSlack(res.message, 'importir.com', path)
                        console.log(resSlack, 'CONNECTED ERROR IN SLACK')
                    }
                } catch (error) {
                    console.log(error, 'ini error')
                    toast({
                        title: 'importir.com',
                        description: error.message,
                        status: 'error'
                    })
                }
            }
        }
        navigate(`/invoice/${item.invoice}`)
    }

    const handleReset = () => {
        setCount(1)
        setSearch('')
        setStatus('')
        setStartPaid('')
        setEndPaid('')
        getDataInvoice()
    }

    useEffect(() => {
        getDataInvoice()

        return () => { }
    }, [count])

    return (
        <Stack p={[1, 1, 5]} bgColor='gray.100' mt={[5, 5, 0]}>
            <HStack>
                <HStack
                    cursor='pointer'
                    zIndex='50'
                    w='100px'
                    mx={5}
                    mb={2}
                    p={2}
                    alignItems='center'
                    shadow={'base'}
                    justifyContent={'center'}
                    borderRadius='full'
                    bgColor={colors.theme}
                    onClick={() => navigate(-1)}
                >
                    <IoCaretBackOutline size={15} />
                    <Text fontSize={'xs'} letterSpacing={0.5}>
                        {t('back')}
                    </Text>
                </HStack>
                <Spacer />
            </HStack>

            <Grid templateColumns={{ base: "1fr", md: "1fr 4fr" }} >
                <Stack p={[1, 1, 5]}>
                    <Heading size={'md'}>{t('invoice_history_list')}</Heading>
                </Stack>

                <SimpleGrid columns={[1, 3, 6]} gap={1} p={[1, 1, 5]} alignItems='center' justifyContent={'flex-end'}>
                    <Input
                        id="searchList"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        size='sm'
                        placeholder={t('invoice_number')}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                getDataInvoice()
                            }
                        }}
                    />
                    <Select fontSize='sm' size={'sm'} bgColor={'white'} placeholder={t('all')} onChange={(e) => setStatus(e.target.value)}>
                        <option value="Paid">{t('paid')}</option>
                        <option value="Not Paid">{t('unpaid')}</option>
                    </Select>
                    <Input
                        id="startPaid"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        type={'date'}
                        size='sm'
                        placeholder="Start Paid Date (YYYY-MM-DD)"
                        value={startPaid}
                        onChange={handleDateChange}
                    />

                    <Input
                        id="endPaid"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        size='sm'
                        type={'date'}
                        placeholder="End Paid Date (YYYY-MM-DD)"
                        value={endPaid}
                        onChange={handleDateChange}
                    />
                    <HStack alignItems="center" justifyContent="center" space={3} >
                        <Button bgColor={colors.theme} size='sm' onClick={() => getDataInvoice()}>
                            <HStack alignItems="center" justifyContent="center">
                                <Text color={colors.black}>{t('search')}</Text>
                            </HStack>
                        </Button>

                        <Button bgColor={colors.theme} size='sm' onClick={() => handleReset()}>
                            <HStack alignItems="center" justifyContent="center">
                                <Text color={colors.black}>{t('reset')}</Text>
                            </HStack>
                        </Button>
                    </HStack>
                </SimpleGrid>
            </Grid>

            {invoiceList?.length > 0 ? (
                <Stack>
                    <Stack p={[1, 1, 5]}>
                        {invoiceList?.map((x, index) => {
                            const imageCarousel = x?.details[0]?.order?.product_image
                            return (
                                <Stack bgColor={'white'} spacing={1} key={index} borderRadius='xl' p={5} shadow={3}>
                                    <Stack>
                                        <Grid templateColumns={{ base: "1fr", md: "1fr 3fr 1fr 1fr" }} gap={5} justifyContent={'space-between'} m={3}>
                                            <Center>
                                                <Box width={'200px'}>
                                                    <Image src={imageCarousel} alt='image' shadow={'md'} borderRadius='md' />
                                                </Box>
                                            </Center>
                                            <Stack spacing={1} justifyContent='center'>
                                                <Text fontSize='sm' color={'gray.600'}>{moment(x?.created_at).format('LLL')}</Text>
                                                <Text fontSize={'md'} fontWeight='bold'>{x?.invoice} - {x.name}</Text>
                                                {
                                                    userDB?.country_code === "id" ? 
                                                        <Text color={'gray.600'} fontSize='md'>{x?.details[0]?.order?.is_lartas > 0 ? 'Lartas' : 'Non Lartas'}</Text>
                                                    : ""
                                                }
                                                {x.paid_at !== null ? (
                                                    <HStack spacing={1}>
                                                        <Text color={'gray.600'} fontSize='md'>Status :</Text>
                                                        <Text color={'gray.600'} fontSize='md'>{t('paid')}</Text>
                                                        <BsCircleFill size={8} color="green" />
                                                    </HStack>
                                                ) : (
                                                    <HStack spacing={1}>
                                                        <Text color={'gray.600'} fontSize='md'>Status :</Text>
                                                        <Text color={'gray.600'} fontSize='md'>{t('unpaid')}</Text>
                                                        <BsCircleFill size={8} color="red" />
                                                    </HStack>
                                                )}
                                            </Stack>
                                            <Spacer />
                                            <Stack justifyContent={'center'}>
                                                <Text fontWeight={'bold'} fontSize='lg' textAlign={'end'}>{userSymbolCurrency} {formatFrice(convertPriceFix(Number(x.amount_total)))}</Text>
                                                <Button shadow={'md'} borderRadius={'lg'} bgColor={'green.400'} onClick={() => handleNavigateDetail(x)}>
                                                    <HStack>
                                                        <Text color={'white'} fontWeight='bold' fontSize={'md'} shadow={3}>Detail</Text>
                                                    </HStack>
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Stack>
                                </Stack>
                            )
                        })}
                        <Stack pt={5} alignItems='center' justifyContent={'center'}>
                            <Button onClick={() => handlePagination()} bgColor='#ffd600' shadow={'md'}>
                                <HStack>
                                    <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                                        {t('load_more')}
                                    </Text>
                                    <Stack>
                                        <SlArrowDown size={10} />
                                    </Stack>
                                </HStack>
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            ) : (
                <Stack h={height} alignItems={'center'} justifyContent='center'>
                    <Text color={'gray.500'} fontWeight='bold'>{t('invoice_unavailable')}</Text>
                </Stack>
            )}
        </Stack>
    )
}

export default InvoiceListPageV2
