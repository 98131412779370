import { Button, Grid, Heading, HStack, Input, SimpleGrid, Stack, Text,  TableContainer, Table, Tr, Th, Td, Icon, Tbody, useToast, Thead, Flex } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import _axios from '../Api/AxiosBarrier'
import { errorSlack } from '../Api/SlackApi'
import AuthContext from '../Routes/hooks/AuthContext'
import colors from '../Utils/colors'
import ConvertPrice from '../Utils/ConvertPrice'
import _axiosChina from '../Api/AxiosChina'
import axios from 'axios'
import store from 'store'
import { useNavigate, useParams } from 'react-router-dom'

function OrderTrackingNonMember() {

    const path = window.location.href ?? '#'
    const navigate = useNavigate()

    const [orderList, setOrderList] = useState([])
    const [search, setSearch] = useState('');
    const [data, setData] = useState('');
    const userToken = store.get('user_token')
    if (userToken !== undefined) {
        window.location.href = '/'
    }

    const toast = useToast({
        position: "top",
        align: "center",
    });
    const { loadingShow, loadingClose } = useContext(AuthContext)
    const { convertPriceFix } = ConvertPrice()

    const getDataOrder = async () => {
        loadingShow();
        const res = await _axios.get(`api/general/search-order-non-member?order_number=${search}`);
        if (res.order_id !== undefined) {
            if (res.order !== null) {
                setOrderList(res.order.invoice_details_group_by_invoice_id)
                setData(res)
            } else {
                toast({
                    title: 'importir.com',
                    description: "Your order not found",
                    status: 'error'
                })
            }
        }
        console.log("res", res)
        loadingClose();
        try {
        } catch (error) {
            console.log(error);
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose();
        }
    };

    const redirectPayment = async (invoice) => {
        console.log("oke", invoice)
        const res = (await axios.get(`https://china2.id/payment/order/v2/${invoice}/init-payment`, { headers: {"Authorization" : `Bearer 47072|QaC8ROpwRTBCllOaMAoNvqikN1tCKY8t3765pr4g`} })).data
        if (res.status === true) {
            window.open(res.data.redirect_url, 'blank')
            toast({
                title: 'importir.com',
                description: 'success create payment',
                status: 'success'
            })
        } else {
            toast({
                title: 'importir.com',
                description: res.message,
                status: 'error'
            })
            const resSlack = await errorSlack(res.message, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    return (
        <Stack bgColor={'gray.100'} p={[1, 1, 5]}
        >
            <Stack bg={'white'} p={4}>
                <Stack p={[1, 1, 5]}>
                    <Heading size={'md'}>
                        Order Tracking Non Member
                    </Heading>
                </Stack>
                <Flex justifyContent={'center'} alignItems={'center'}>
                    <HStack>
                        <Input
                            id="searchList"
                            bgColor={'white'}
                            color={colors.black}
                            fontWeight="bold"
                            fontSize={'sm'}
                            size='md'
                            w={'lg'}
                            rounded={'md'}
                            placeholder="Order Number"
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    getDataOrder();
                                }
                            }}
                        />


                        <HStack alignItems="center" justifyContent="center" space={3} >
                            <Button bgColor={colors.theme} size='sm' onClick={() => getDataOrder()}>
                                <HStack alignItems="center" justifyContent="center">
                                    <Text color={colors.black}>Search</Text>
                                </HStack>
                            </Button>
                        </HStack>

                    </HStack>
                </Flex>

                <TableContainer width={'100%'} mt={'4'} border={'1px'} borderColor={'gray.200'}>
                    <Table variant='striped' colorScheme='teal'>
                        <Thead>
                        <Tr>
                            <Th textAlign={'center'}>Title</Th>
                            <Th textAlign={'center'}>Status Paid</Th>
                            <Th textAlign={'center'}>Amount Total</Th>
                            <Th textAlign={'center'}>Description</Th>
                            <Th textAlign={'center'}>Address</Th>
                            <Th textAlign={'center'}>#</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                            {orderList.map((x, i) => 
                                <Tr key={i}>
                                    <Td>{x.invoice.title}</Td>
                                    <Td>{x.invoice.paid_at ? "PAID" : "NOT PAID"}</Td>
                                    <Td>{convertPriceFix(x.invoice.amount_total)}</Td>
                                    <Td>{data.name} | {data.email}</Td>
                                    <Td>{data.address.district}, {data.address.city}, {data.address.province}</Td>
                                    <Td>
                                        <Button bgColor={'blue.200'} textColor={'white'} mr={2} onClick={() => navigate(`/invoice-nomember/${x.invoice.invoice}`)} className='detail'>
                                            <Icon as={FaEye} w={4} h={4} color='black.500' />
                                        </Button>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Stack>
        </Stack >
    )
}

export default OrderTrackingNonMember