import { Box, Heading, HStack, Spinner, Stack, Text, Image } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../Routes/hooks/AuthContext';
import AppNavigationCategory from './AppNavigationCategory';
import { useTranslation } from 'react-i18next';
function AppCategory() {
    const {t} = useTranslation();
    const { categoryList, setCategoryFunc, categoryFrom } = useContext(AuthContext)

    const height = window.innerHeight

    const [categoryActive, setCategoryActive] = useState("")


    const [isVisible, setIsVisible] = useState(true);


    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prevIsVisible) => !prevIsVisible);
        }, 500); // Waktu kedap-kedip dalam milidetik (misalnya setiap 500ms)

        return () => {
            clearInterval(interval);
        };
    }, []);



    return (
        <Stack m='2' borderRadius='md' shadow='lg' bgColor={'white'} h='670px' overflowY={'scroll'}  >
            <Stack  m={2} >
                <HStack >
                    <Stack spacing={-2} alignItems={'center'} justifyContent='center' bgColor={categoryFrom === "china" ? "red.400" : "white"} _hover={{ transform: "scale(1.05)" }} transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => setCategoryFunc('china')} px={3} py={2} borderRadius='lg' borderWidth={1} borderColor='gray.200' shadow={'sm'}>
                        {/* <Text fontSize={30}>
                        </Text> */}
                        <Image src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/china-2023_12_08_14_22_39.png' />
                        <Text fontSize={'sm'} color={categoryFrom === "china" ? "white" : "gray.700"}>China</Text>
                    </Stack>
{/* 
                    <Box bgColor={categoryFrom === "japan" ? "red.400" : "white"} _hover={{ transform: "scale(1.05)" }} position='relative' transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => setCategoryFunc('japan')} px={4} py={2} borderRadius='lg' borderWidth={1} borderColor='gray.200' shadow={'sm'}>
                        <Text fontSize={'sm'} color={categoryFrom === "japan" ? "white" : "gray.700"}>Japan 🇯🇵</Text>
                        <Box
                            bgColor={'red'}
                            position='absolute'
                            zIndex={50}
                            p={1}
                            borderRadius='md'
                            visibility={isVisible ? 'visible' : 'hidden'}
                        >
                            <Text fontSize={'2xs'} color='white'>
                                Coming soon
                            </Text>
                        </Box>
                    </Box> */}

                    <Stack alignItems={'center'} justifyContent='center' spacing={-2} bgColor={categoryFrom === "usa" ? "red.400" : "white"} _hover={{ transform: "scale(1.05)" }} position='relative' transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => setCategoryFunc("usa")} px={4} py={2} borderRadius='lg' borderWidth={1} borderColor='gray.200' shadow={'sm'}>
                        {/* <Text fontSize={30}>🇺🇸</Text> */}
                        <Image src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/united-states-of-america-2023_12_08_14_22_39.png' />
                        <Text fontSize={'sm'} color={categoryFrom === "usa" ? "white" : "gray.700"}>USA</Text>
                        {/* <Box
                            bgColor={'red'}
                            position='absolute'
                            p={1}
                            right={0}
                            borderRadius='md'
                            visibility={isVisible ? 'visible' : 'hidden'}
                            maxW='80px'
                        >
                            <Text textAlign={'center'} fontSize={'2xs'} color='white'>
                                Coming soon
                            </Text>
                        </Box> */}
                    </Stack>

                    <Stack alignItems={'center'} justifyContent='center' spacing={-2} bgColor={categoryFrom === "korea" ? "red.400" : "white"} _hover={{ transform: "scale(1.05)" }} position='relative' transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => setCategoryFunc('korea')} px={4} py={2} borderRadius='lg' borderWidth={1} borderColor='gray.200' shadow={'sm'}>
                        {/* <Text fontSize={30}>🇰🇷</Text> */}
                        <Image src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/south-korea-2023_12_08_14_22_39.png' />
                        <Text fontSize={'sm'} color={categoryFrom === "korea" ? "white" : "gray.700"}>Korea</Text>
                        <Box
                            bgColor={'red'}
                            position='absolute'
                            p={1}
                            right={0}
                            borderRadius='md'
                            visibility={isVisible ? 'visible' : 'hidden'}
                            maxW='80px'
                            bottom={-10}
                        >
                            <Text textAlign={'center'} fontSize={'2xs'} color='white'>
                                {t('coming_soon')}
                            </Text>
                        </Box>
                    </Stack>
                </HStack>
            </Stack>
            <Stack px={3} py={1}>
                <Heading size={'sm'}>{t('category')}</Heading>
            </Stack>
            <Stack spacing={2}>
                {
                    categoryList?.length > 0 ? categoryList.map((x, index) =>
                        <Stack key={index} color={categoryActive === x ? "white" : "black"} p={2} bgColor={categoryActive === x ? 'green' : 'white'} onClick={() => setCategoryActive(x)}>
                            <AppNavigationCategory  category={x} />
                        </Stack>
                    ) :
                        <Stack alignItems={'center'} justifyContent='center' w={'100%'} h={height}>
                            <Spinner />
                        </Stack>
                }
            </Stack>
        </Stack>
    )
}

export default AppCategory