import { 
    Stack,
    Card,
    Heading,  
    useToast, 
    TableContainer,
    TableCaption,
    Table,
    Thead, Tbody,
    Tr, Th, Td,
    Text,
    Link,
    Center,
    SimpleGrid,
    Grid,
    Input,
    HStack,
    Button
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'

function IndexPage() {
    const [catalog, setCatalog] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const { loadingShow, loadingClose } = useContext(AuthContext);
    const toast = useToast({
        position: "top",
        align: "center",
    });

    const fetcDataCatalog = async () => {
        try {
            loadingShow();
            const { status, message, data } = await _axios.get(`/api/catalog-hpp?page=${currentPage}c&title=${search}`);
            loadingClose();
            if (!status) {
                return toast({
                    title: "importir.com",
                    description: message,
                    status: "error",
                });
            }
            setCatalog(data.data);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
        } catch (error) {
            return toast({
                title: "importir.com",
                description: error,
                status: "error",
            });
        }
    };
    useEffect(() => {
        fetcDataCatalog();
    }, []);

    const handleReset = () => {
        setSearch('');
    };

    const handleLoadMore = async () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1); // Increment currentPage correctly
        } else {
            return toast({
                title: "importir.com",
                description: "You have loaded the last data",
                status: "error",
            });
        }
    
        try {
            loadingShow();
            const { status, message, data } = await _axios.get(
                `/api/catalog-hpp?page=${currentPage + 1}&title=${search}`
            );
    
            if (!status) {
                return toast({
                    title: "importir.com",
                    description: message,
                    status: "error",
                });
            }
    
            let mergeData = [...catalog, ...data.data];
            setCatalog(mergeData);
            setCurrentPage(data.current_page);
            setLastPage(data.last_page);
            loadingClose();

        } catch (error) {
            return toast({
                title: "importir.com",
                description: error.message, // Display the error message
                status: "error",
            });
        }
    };

    return (
        <Center>
            <Stack border={"1px gray"} borderRadius={"sm"} bgColor={"white"} shadow={"xl"} width={'60%'} px={3} pb={3}>
                <Card m={5}>
                    <Grid bgColor={"gray.100"} templateColumns={{ base: "1fr", md: "1fr 4fr" }}>
                        <Stack p={[1, 1, 5]}>
                            <Heading size={'md'}>
                                List Catalog E-Book
                            </Heading>
                        </Stack>
                        <SimpleGrid columns={[1, 3, 6]} gap={1} p={[1, 1, 5]}>
                            <Input
                                mt={2}
                                id="searchList"
                                bgColor={'white'}
                                color={"black"}
                                fontWeight="bold"
                                fontSize={'sm'}
                                size='sm'
                                placeholder="Title"
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        fetcDataCatalog();
                                    }
                                }}
                            />
                            <HStack alignItems="center" justifyContent="center" space={3} >
                                <Button bgColor={'#FFD600'} size='sm' onClick={() => fetcDataCatalog()}>
                                    <HStack alignItems="center" justifyContent="center">
                                        <Text color={"black"}>Search</Text>
                                    </HStack>
                                </Button>

                                <Button bgColor={'#FFD600'} size='sm' onClick={() => handleReset()}>
                                    <HStack alignItems="center" justifyContent="center">
                                        <Text color={"black"}>Reset</Text>
                                    </HStack>
                                </Button>
                            </HStack>
                        </SimpleGrid>
                    </Grid>
                    <TableContainer>
                        {/* <Heading bgColor={'gray.100'} p={5} align="center" size={'md'}>List Catalog E-Book</Heading> */}
                        <Table variant='striped' bgColor={'#FFD600'}>
                            <Thead>
                                <Tr>
                                    <Th>No</Th>
                                    <Th>Title</Th>
                                    <Th>Published At</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {catalog?.length > 0 && catalog?.map((item, index) => (
                                        <Tr key={index}>
                                            <Td>{index+1}</Td>
                                            <Td>
                                                <Link color="blue.300" href={`/catalog-hpp/${item.slug}`} target="_blank">
                                                    {item?.title}
                                                </Link >
                                            </Td>
                                            <Td>{item?.published_at}</Td>
                                        </Tr>
                                ))}
                            </Tbody>
                            <TableCaption>
                                <Button onClick={() => handleLoadMore()} colorScheme={"blue"}>Load More</Button>
                            </TableCaption>
                        </Table>
                    </TableContainer>
                </Card>
            </Stack>
        </Center>
    )
}

export default IndexPage