/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, HStack, Image, SimpleGrid, Spacer, Stack, Text, Heading, Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure, Badge,Input, Container, Card, Box, CardBody, Icon, Center, useToast, Flex } from '@chakra-ui/react';
import AuthContext from '../../Routes/hooks/AuthContext';
import _axios from '../../Api/AxiosBarrier';
import { formatFrice } from '../../Utils/Helper';
import moment from 'moment';
import { AiFillMinusCircle } from "react-icons/ai";
import { GiShop } from "react-icons/gi";
import { BiMoney } from "react-icons/bi";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Countdown from '../../Components/CountDown/CountDown';
import store from "store";

function IndexPage() {
    const navigate = useNavigate()
    const {loadingShow, loadingClose} = useContext(AuthContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [mitraList, setMitraList] = useState([])
    const [detail, setDetail] = useState([])
    const currentDate = moment();
    const threeMonthsFromNow = currentDate.add(3, 'months');
    const [quantity, setQuantity] = useState(0);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    

    const fetchData = async () => {
        try {
            loadingShow()
            const res = await _axios.get('api/mitra/product/list')
            loadingClose()
            setMitraList(res)
        } catch (error) {
            console.log(error)
        }
    }

    const toggleVideo = () => {
        if (videoRef.current) {
          if (isPlaying) {
            videoRef.current.pause();
          } else {
            videoRef.current.play();
          }
          setIsPlaying(!isPlaying);
        }
    };

    const handleModal = (x) => {
        console.log(x)
        setDetail(x)
        onOpen()
    }

    const addToCart = async (detail) => {
        console.log("date", detail)
        if (quantity === 0 || isNaN(quantity)) {
            onClose()
            Swal.fire({
                title: 'Error',
                text: "Please input quantity",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes'
            })
            return
        }
        const dataParam = {
            "id": detail.id,
            "quantity_lot_order": quantity
        }
        const response = await _axios.post('api/mitra/cart/add-to-cart', dataParam)
        onClose()
        if (!response.status) {
            Swal.fire({
                title: 'Error',
                text: response.message,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes'
            })
        } else {
            Swal.fire({
				title: 'Success',
                text: response.message,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes'
            }).then(async(result) => {
                if (result.isConfirmed) {
                    navigate('/mitra/cart')
                }
            })
        }
    }

    const imageStyles = {
        width: '200px', 
    };
    const packageUser = store.get('user_package');
    const toast = useToast({
        position: "top",
        align: "center",
    });

    useEffect(() => {
        if (packageUser?.package_child?.name === 'FREE MEMBER'){
            toast({
                title: 'importir.com',
                description: 'Your package is FREE MEMBER, cannot access this page',
                status: 'error',
            });
            return navigate("/")
        }
        fetchData()
    }, []);
  return (
    <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxW="container.xl" position={'relative'} zIndex={'1'} top={'-3rem'}>
        <Stack p={[1, 1, 5]}>
        <Box
            w="full"
            display="flex"
            justifyContent="center"
            position="relative"
            overflow="hidden"
            className="home-video rounded"
            onClick={toggleVideo}
        >
            <Center
                bg="white"
                w="20"
                h="20"
                rounded="full"
                cursor="pointer"
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                style={{ position: 'absolute', zIndex: 9999 }}
                display={isPlaying ? 'none' : 'flex'}
            >
                <Image src="https://img.icons8.com/flat_round/64/000000/play--v1.png" />
            </Center>
            <video
                ref={videoRef}
                className="absolute top-0 left-0 bottom-0 right-0 home-video-main"
                width="100%"
                height="100%"
                onClick={toggleVideo}
            >
                <source src="https://d2jnbxtr5v4vqu.cloudfront.net/filemanager//mitra-2021_01_13_15_14_27.mp4" type="video/mp4" />
            </video>
        </Box>

            <Heading p={5} textAlign={'center'} fontWeight={'light'} size={'lg'}>
                BENEFITS OF BEING AN IMPORTIR PARTNER
            </Heading>

            <SimpleGrid columns={[1, 2, 3]} gap={2}>
                <Stack>
                    <Card 
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        h={'36'}
                    >
                        <Flex>
                            <Stack p={4} mt={'4'}>
                                <Icon as={AiFillMinusCircle} w={'10'} h={'10'}/> 
                            </Stack>
                            <CardBody>
                                <Text fontWeight="bold">
                                No selling skills required 
                                </Text>
                                <Text>Because all the items you choose, we will sell them for you.</Text>
                            </CardBody>
                        </Flex>
                    </Card>
                </Stack>
                <Stack>
                    <Card 
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        h={'36'}
                    >
                        <Flex>
                            <Stack p={4} mt={'4'}>
                                <Icon as={GiShop} marginRight="0.5rem" w={'10'} h={'10'}/>
                            </Stack>
                            <CardBody>
                                <Text fontWeight="bold">
                                    Wide Selection of Goods
                                </Text>
                                <Text>The large selection of goods allows you to choose the one that suits your needs and desires.</Text>
                            </CardBody>
                        </Flex>
                    </Card>
                </Stack>
                <Stack>
                    <Card 
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        h={'36'}
                    >
                        <Flex>
                            <Stack p={4} mt={'4'}>
                                <Icon as={BiMoney} marginRight="0.5rem" w={'10'} h={'10'}/>
                            </Stack>
                            <CardBody>
                                <Text fontWeight="bold">
                                    Profit Sharing
                                </Text>
                                <Text>With a profit sharing system you can precisely calculate your total profit.</Text>
                            </CardBody>
                        </Flex>
                    </Card>
                </Stack>
            </SimpleGrid>
            
            <Heading p={5} textAlign={'center'} fontWeight={'light'} size={'lg'}>
                Available Products
            </Heading>
            <SimpleGrid columns={[1, 2, 4]} gap={'4'}>
            {
                mitraList?.length > 0 && mitraList?.map((x, i) => {
                    return (
                        <Stack shadow='md' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
                            <Stack alignItems='center' cursor='pointer'>
                                {/* {product.image ? ( */}
                                <Image src={x?.image_mitra_products?.product_images[0]?.link} alt='img' borderRadius='md'/>
                                {/* ) : (
                                <Skeleton w='200px' h='300px' borderRadius='md' isLoaded={!isLoading} startColor='gray.100' endColor='gray.300' />
                                )} */}
                            </Stack>
                            <Stack px={3}>
                                <HStack alignItems='center' justifyContent='flex-end'>
                                <Stack>
                                    {/* <Tag size='sm'>{getProductPlatform()} </Tag> */}
                                </Stack>

                                {/* <Stack>
                                    {nonLartasFilter ? (
                                    <Tag colorScheme={'yellow'} size='sm'>Non Lartas</Tag>
                                    ) : (
                                    <Tag colorScheme={'red'} size='sm'>Lartas</Tag>
                                    )}
                                </Stack>

                                {wishlistFilter ? (
                                    <Stack>
                                    <HiHeart style={{ fontSize: 20, color: 'red' }} />
                                    </Stack>
                                ) : (
                                    <Stack onClick={() => handleWishlist(product)} cursor='pointer'>
                                    <HiOutlineHeart style={{ fontSize: 20, color: 'gray' }} />
                                    </Stack>
                                )} */}

                                </HStack>

                                <Countdown startDate={x?.start_date} endDate={x?.end_date} />
                                <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={2}>
                                    {x?.title}
                                </Text>
                            </Stack>
                            <Spacer />
                            <SimpleGrid columns={[1, 2, 3]} spacing={0} px={3}>
                                <Stack spacing={0} textAlign={'center'}>
                                    <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                                        Harga Beli
                                    </Text>
                                    <Text textTransform='capitalize' fontWeight='bold' color='black' fontSize='sm'>
                                        {formatFrice(parseInt(x?.price))}                    
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Stack spacing={0} textAlign={'center'}>
                                        <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                                        Harga Jual
                                        </Text>
                                        <Text textTransform='capitalize' color='black' fontWeight='bold' fontSize='sm'>
                                            {formatFrice(parseInt(x?.price) +  parseInt((x?.price * x?.roi) / 100))}
                                        </Text>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <Stack spacing={0} textAlign={'center'}>
                                        <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                                        Lot Tersedia
                                        </Text>
                                        <Text textTransform='capitalize' color='black' fontWeight='bold' fontSize='sm'>
                                            {x?.lot}
                                        </Text>
                                    </Stack>
                                </Stack>
                            </SimpleGrid>
                            <Stack p={3}>
                                <Button size='sm' bgColor='green.400' onClick={() => handleModal(x)}>
                                <Text color='white'>See Detail</Text>
                                </Button>
                            </Stack>
                        </Stack>
                    )
                })
            }
            </SimpleGrid>
            <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <SimpleGrid columns={[2, null, 2]} spacing={0} px={3}>
                        <Stack mt={5}>
                            <Stack borderRadius={'xl'} shadow={'2xl'} p={2}>
                                <Carousel showThumbs={true} showIndicators={true} showArrows={true} renderThumbs={(children) => children} thumbWidth={100}>
                                    {detail?.image_mitra_products?.product_images.map((x, i) => (
                                        <div key={i}>
                                        <Image style={imageStyles} src={x?.link} alt='No image' />
                                        </div>
                                    ))}
                                </Carousel>
                            </Stack>
                            <Stack minH={"300px"} overflowY={'auto'}>
                                <Text dangerouslySetInnerHTML={{__html: detail?.description}}></Text>
                            </Stack>
                        </Stack>
                        <Stack p={5}>
                            <Text fontWeight={'bold'} fontSize={'xl'}>
                                {detail?.title}
                            </Text>
                            <Text fontWeight={'bold'}>
                                Buy Price: {formatFrice(parseInt(detail?.price))}
                            </Text>
                            <Text fontWeight={'bold'} color={'green'}>
                                Sell Price: {formatFrice(parseInt(detail?.price) +  parseInt((detail?.price * detail?.roi) / 100))}
                            </Text>
                            <Text fontWeight={'bold'}>
                                Profit: {formatFrice(parseInt((detail?.price * detail?.roi) / 100))}
                            </Text>
                            <Text fontWeight={'bold'} color={'red'}>
                                {detail?.lot} Lot available (1 lot = {detail?.per_lot}pcs)
                            </Text>
                            <Text fontWeight={'bold'} color={'green'}>
                                3-month period ({threeMonthsFromNow.format('MMMM Do YYYY')})
                            </Text>
                            {/* <Text dangerouslySetInnerHTML={{__html: detail?.description}}></Text> */}
                            <SimpleGrid columns={[2]}>
                                <Stack>
                                    <Text>
                                        Max lot per order {detail?.max_lot_per_order}
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text textAlign={'end'} >
                                        <Badge>Profit {detail?.roi}%</Badge>
                                    </Text>
                                </Stack>
                            </SimpleGrid>
                            <Input
                                type="number"
                                placeholder="Enter Quantity"
                                value={quantity}
                                onChange={(e) => setQuantity(parseInt(e.target.value))}
                            />
                            {
                                quantity > 0 ? 
                                 <Text mt={2}>
                                    You Will Get This Profit Sharing On <span style={{"color": "green"}}>{threeMonthsFromNow.format('MMMM Do YYYY')}</span>, 
                                    in the amount of 
                                <span style={{"color": "green"}}>
                                    Rp. {formatFrice(isNaN((quantity * detail?.price * detail?.per_lot) + quantity * detail?.price * detail?.per_lot * detail?.roi/100) 
                                    ? 0 
                                    : (quantity * detail?.price * detail?.per_lot) + quantity * detail?.price * detail?.per_lot * detail?.roi/100)}
                                </span>
                            </Text>
                                : ""
                            } 
                            <Text mt={2}>
                                Total Price:  {formatFrice(isNaN(quantity * detail?.price * detail?.per_lot) ? 0 : quantity * detail?.price * detail?.per_lot)}
                            </Text>
                            <Button colorScheme='blue' mr={3} onClick={() => addToCart(detail)}>
                                Order
                            </Button>
                        </Stack>
                    </SimpleGrid>
                </ModalBody>

                <ModalFooter>
                    {/* <Button colorScheme='blue' mr={3} onClick={() => addToCart(detail)}>
                    Order
                    </Button> */}
                </ModalFooter>
                </ModalContent>
            </Modal>		
        </Stack>
        <style>
        {`
          .thumb img {
            height: 1% !important;
          }
        `}
      </style>
    </Container>
  )
}

export default IndexPage

