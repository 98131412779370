/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';

import {
    Box,
    Button,
    Container,
    Text,
    Radio,
    RadioGroup,
    useToast
} from '@chakra-ui/react';
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function QuizOnboardingIndex() {
    const {t} = useTranslation();
    const he = require('he');

    const params = useParams();
    const { loadingShow, loadingClose } = useContext(AuthContext);
    const [data, setData] = useState({});
    const [formData, setFormData] = useState({
        module_id: '',
        answer: [],
    });
    
    const toast = useToast({
        position: "top",
        align: "center",
    });

    useEffect(() => {
        fetchQuestion();
    }, []);

    const selectedAnswers = formData.answer.map(answer => answer.id);

    const isOptionSelected = (item, items) => {
        const answer = formData.answer.find(answer => answer.id === item._id);
        if (answer && answer.key === items.key) {
            return false;
        }
        return selectedAnswers.includes(item._id);
    };

    const changeOptions = (item, items) => {
        // Filter out the existing answer for the same question
        const updatedAnswer = formData.answer.filter(answer => answer.question_id !== item._id);
        
        // Add the new answer
        updatedAnswer.push({
            question_id: item._id,
            key: items.key,
        });
    
        // Update the formData
        setFormData({
            ...formData,
            answer: updatedAnswer,
            module_id: item.module_id,
        });
    };

    const fetchQuestion = async () => {
        try {
            loadingShow();
            let url = '/api/question';
            if (params.id !== undefined) url = `/api/${params.id}/detail-module`
            console.log(url)
            const {status, data} = await _axios.get(url); // Use axios to fetch data
            if (data === null) {
                if (params.id !== undefined) return window.location.href = '/list-module';;
                return window.location.href = '/profile';
            }
            if (params.id !== undefined){
                if (status) {
                    setData(data);
                    toast({
                        title: "importir.com",
                        description: "This quiz is for re-learning only, your answers will not be saved.",
                        status: "info",
                    });
                }
            }else{
                if (!status) {
                    setData(data);
                }
            }
            loadingClose();
        } catch (error) {
            loadingClose();
            return toast({
                title: "importir.com",
                description: error,
                status: "error",
            });
        }
    };

    const answerQuestion = async () => {
        try {
            loadingShow();
            let url = '/api/answer';
            if (params.id !== undefined) url = '/api/check-answer'; 
            const {status, message, data} = await _axios.post(url, formData); // Use axios to post data
            loadingClose();
            if (status) {
                if (window.confirm(message)) {
                    if (data == null) {
                        if (params.id !== undefined) return window.location.href = '/list-module';
                        return window.location.href = '/profile';
                    } else {
                        window.location.reload();
                    }
                }
            }else{
                return toast({
                    title: "importir.com",
                    description: message,
                    status: "error",
                });
            }
        } catch (error) {
            loadingClose();
            return toast({
                title: "importir.com",
                description: error,
                status: "error",
            });
        }
    };

    const processDescription = () => {
        const wrapperStyle = {
          position: 'relative',
          paddingTop: '56.25%', // 16:9 aspect ratio (height: 9/16 * 100%)
          height: 0,
          marginBottom: '20px', // Add some bottom margin to prevent overlap
        };
      
        let videoEmbedCode = '';
      
        if (data.description_new !== undefined) {
            // Decode HTML entities in the content
            const decodedContent = he.decode(data.description_new);
        
            // Find the YouTube iframe code within the content
            const youtubeEmbedRegex = /<iframe[^>]+src="https:\/\/www\.youtube\.com\/embed\/([^"]+)"/;
            const match = decodedContent.match(youtubeEmbedRegex);
        
            if (match) {
              const videoId = match[1];
        
              // Create a modified YouTube iframe directly with the correct attributes
              videoEmbedCode = (
                <div style={{ ...wrapperStyle }}>
                  <iframe
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      border: 'none', // Remove the border
                    }}
                    src={`https://www.youtube.com/embed/${videoId}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    title="YouTube video player"
                  ></iframe>
                </div>
              );
            }
          } else if (data.description) {
            const decodedContent = he.decode(data.description);
        
            const wistiaEmbedRegex = /<iframe[^>]+src="https:\/\/fast\.wistia\.net\/embed\/iframe\/([^"]+)"/;
            const match = decodedContent.match(wistiaEmbedRegex);
        
            if (match) {
                const videoId = match[1];
                videoEmbedCode = (
                    <div style={{ ...wrapperStyle }}>
                    <iframe
                        style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        border: 'none', // Remove the border
                        }}
                        src={`https://fast.wistia.net/embed/iframe/${videoId}`}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="Wistia video player"
                    ></iframe>
                    </div>
                );
            }
        }
      
        // Check if the YouTube embed code is in data.description_new
        
      
        // Render the video embed code and prevent displaying both videos
        if (videoEmbedCode) {
          return (
            <div>
              {/* Render the rest of the content */}
              <div dangerouslySetInnerHTML={{ __html: data.description_new || data.description }} />
            </div>
          );
        }
      
        // If there are no video embeds, return the original HTML content
        return <div dangerouslySetInnerHTML={{ __html: data.description_new || data.description }} />;
    };
    return (
        <Box>
            <Box bg="gray.100" my="2%">
                <Container maxW="container.lg">
                    <Box bgColor="white" p="5%">
                        <Text fontSize="3xl" fontWeight={'bold'} borderBottom="1px" borderColor="gray.300" mb="2%">
                            {data?.title}
                        </Text>
                        {processDescription()}
                        {data?.details_true?.map((item, index) => (
                            <Box bgColor="gray.50" border="2px solid #FFD600" borderRadius="10px" key={index} mt="5" py="4" px="6">
                                <Text fontSize="md" fontWeight="medium" color="gray.900" ml="4">
                                    {item.sort_number}. {item.description}
                                </Text>
                                <RadioGroup>
                                {item.options.map((items, idx) => (
                                    <Box key={idx} mt="2" ml="5">
                                    <Radio
                                        id={`q${index}a${idx}`}
                                        name={`q${index}`}
                                        size="sm"
                                        borderColor="gray.500"
                                        value={items.key}
                                        onChange={() => {
                                        changeOptions(item, items);
                                        isOptionSelected(item, items);
                                        }}
                                    >
                                        {items.key}. {items.value}
                                    </Radio>
                                    </Box>
                                ))}
                                </RadioGroup>
                            </Box>
                        ))}
                        <Box display={"flex"} justifyContent={"end"}>
                        <Button
                            colorScheme="blue"
                            size="md"
                            mt="2%"
                            onClick={answerQuestion}>
                            {t('submit')}
                        </Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default QuizOnboardingIndex;
