/* eslint-disable react-hooks/exhaustive-deps */
import {
	Stack,
	Heading,
	Divider,
	Text,
	Button,
	useToast,
	Grid,
	GridItem,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Image,
	Flex,
	Box,
	Center,
	List,
	ListItem,
} from "@chakra-ui/react";
import { IoMdArrowRoundBack } from "react-icons/io";
import React, { useContext, useEffect, useState } from "react";
import _axios from "../../Api/AxiosBarrier";
import moment from "moment";
import { errorSlack } from "../../Api/SlackApi";
import AuthContext from "../../Routes/hooks/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import store from "store";
import { formatFrice } from "../../Utils/Helper";
import CountdownBill from "../../Components/CountDown/CountdownBill";
import { useTranslation } from "react-i18next";
function CbmDetailInvoice() {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const [cbmBalanceData, setCbmbalanceData] = useState([]);
	const [payload, setPayload] = useState(null);
	const path = window.location.href ?? "#";
	const { loadingShow, loadingClose } = useContext(AuthContext);

	const toast = useToast({
		position: "top",
		align: "center",
	});

	const getCbmBalanceData = async () => {
		try {
			loadingShow();
			const { status, message, data } = await _axios.get(
				`api/cbm-balance/invoice/${params.id}/detail`
			);
			loadingClose();
			if (status === true) {
				setCbmbalanceData(data);
				if (
					data?.cbm_log_payments !== null &&
					data?.cbm_log_payments?.payload !== null
				) {
					setPayload(JSON.parse(data?.cbm_log_payments?.payload));
					console.log(JSON.parse(data?.cbm_log_payments?.payload));
				}
			} else {
				toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
				const resSlack = await errorSlack(
					message,
					"importir.com",
					path
				);
				console.log(resSlack, "CONNECTED ERROR IN SLACK");
			}
		} catch (error) {
			toast({
				title: "importir.com",
				description: error.message,
				status: "error",
			});
			const resSlack = await errorSlack(error, "importir.com", path);
			console.log(resSlack, "CONNECTED ERROR IN SLACK");
		}
	};

	useEffect(() => {
		getCbmBalanceData();
	}, []);

	const generateNewPayment = async (id, type) => {
		try {
			loadingShow();
			const { status, message, data } = await _axios.get(
				`api/cbm-balance/pay-cbm-price/${id}?type=${type}`
			);
			loadingClose();
			if (!status) {
				toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
				const resSlack = await errorSlack(
					message,
					"importir.com",
					path
				);
				console.log(resSlack, "CONNECTED ERROR IN SLACK");
			}
			const result = JSON.parse(data);
			if (type === 'midtrans') {
				window.location.reload()
			} else {
				return window.open(result?.invoice_url);
			}
		} catch (error) {
			loadingClose();
			toast({
				title: "importir.com",
				description: error.message,
				status: "error",
			});
			const resSlack = await errorSlack(error, "importir.com", path);
			console.log(resSlack, "CONNECTED ERROR IN SLACK");
		}
	};

	const cancelPayment = async (arg) => {
		try {
			loadingShow();
			const { status, message, data } = await _axios.get(
				`api/cbm-balance/cancel-cbm-price/${arg.id}`
			);
			loadingClose();
			if (!status) {
				toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
				const resSlack = await errorSlack(
					message,
					"importir.com",
					path
				);
				console.log(resSlack, "CONNECTED ERROR IN SLACK");
			}
			window.location.reload()
		} catch (error) {
			loadingClose();
			toast({
				title: "importir.com",
				description: error.message,
				status: "error",
			});
			const resSlack = await errorSlack(error, "importir.com", path);
			console.log(resSlack, "CONNECTED ERROR IN SLACK");
		}
	}

	const makePayment = async (arg, type = "") => {
		try {
			if (arg?.cbm_log_payments?.payload !== null) {
				const payload = JSON.parse(arg?.cbm_log_payments?.payload);
				const isExpired = moment().isAfter(
					moment(payload?.expiry_date)
				);
				if (!isExpired) {
					return window.open(payload?.invoice_url);
				}
			}
			return await generateNewPayment(arg?.id, type);
		} catch (error) {
			loadingClose();
			toast({
				title: "importir.com",
				description: error.message,
				status: "error",
			});
			const resSlack = await errorSlack(error, "importir.com", path);
			console.log(resSlack, "CONNECTED ERROR IN SLACK");
		}
	};

	const copyToClipboard = (text) => {
			const textArea = document.createElement('textarea');
			textArea.value = text;
			document.body.appendChild(textArea);
			textArea.select();
			
			document.execCommand('copy');
			
			document.body.removeChild(textArea);

			toast({
					title: 'importir.com',
					description: 'Copied to clipboard!',
					status: 'success',
			});
		};

	return (
		<Stack p={[1, 1, 5]} bgColor="white" shadow={"md"} borderRadius="md">
			<Flex justify={"end"}>
				<Button
					onClick={() => navigate("/cbm/invoice")}
					colorScheme="yellow"
				>
					<IoMdArrowRoundBack />
					&nbsp; {t('back')}
				</Button>
			</Flex>
			<Heading
				size={"xl"}
				textAlign={"center"}
				px={11}
				py={12}
				color={"green.300"}
			>
				Invoice # {cbmBalanceData?.cbm_log_payments?.invoice_number}
			</Heading>
			<Divider />
			<Grid
				templateColumns={{
					base: "repeat(1, 1fr)",
					md: "repeat(2, 1fr)",
					lg: "repeat(2, 1fr)",
				}}
				gap={5}
			>
				<GridItem>
					<Card
						direction={{ base: "column", sm: "row" }}
						overflow="hidden"
						variant="outline"
					>
						<Image
							objectFit="cover"
							maxW={{ base: "100%", sm: "200px" }}
							src={
								cbmBalanceData?.cbm_buy_package?.image !== null
									? cbmBalanceData?.cbm_buy_package?.image
									: "https://importir-new.s3.ap-southeast-1.amazonaws.com/cbm_prices/0znYbuMNkrguJwVyiMpgWkSeEK3m2dyEmifhsX4N.png"
							}
							alt="Package Image"
						/>

						<Stack>
							<CardBody>
								<Heading size="md">
									{cbmBalanceData?.cbm_buy_package?.name}
								</Heading>

								<Grid
									paddingY={"2em"}
									gap="3"
									templateColumns="repeat(2, 1fr)"
								>
									<GridItem>{t("price")}</GridItem>
									<GridItem>
										Rp.{" "}
										{formatFrice(
											parseFloat(
												cbmBalanceData?.cbm_buy_package
													?.price
											)
										)}
									</GridItem>
									<GridItem>{t("cbm_amount")}</GridItem>
									<GridItem>
										{parseFloat(
											cbmBalanceData?.cbm_buy_package
												?.cbm_amount
										).toFixed(2)}{" "}
										CBM
									</GridItem>
									<GridItem>{t("balance_type")}</GridItem>
									<GridItem>
										<Text
											color={
												cbmBalanceData?.cbm_buy_package
													?.is_lartas == 1
													? "red"
													: "green"
											}
										>
											{cbmBalanceData?.cbm_buy_package
												?.is_lartas == 1
												? `${t("all_product")}`
												: `${t("selected_product")}`}
										</Text>
									</GridItem>
								</Grid>
							</CardBody>
						</Stack>
					</Card>
				</GridItem>
				<GridItem>
					<Card align="center">
						<Center>
							{payload !== null ? (
								!moment().isAfter(
									moment(payload?.expiry_date !==  undefined ? payload?.expiry_date : payload?.expiry_time)
								) ? (
									<Box>
										<CountdownBill
											startDate={moment().format(
												"YYYY-MM-DD HH:mm:ss"
											)}
											endDate={moment(
												payload?.expiry_date  !==  undefined ? payload?.expiry_date : payload?.expiry_time
											).format("YYYY-MM-DD HH:mm:ss")}
										></CountdownBill>
									</Box>
								) : null
							) : null}
						</Center>
						<CardHeader>
							<Heading size="md">{t("payment_method")}</Heading>
						</CardHeader>
						<CardBody>
							<Flex
								gap="5"
								justifyContent={"center"}
								direction={"column"}
							>
								{cbmBalanceData?.cbm_log_payments?.payload !== null ? 
									<Box alignSelf={"center"}>
										{payload?.expiry_date === undefined ? 
												<List spacing={3} p={'4'}>
													<Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
															<Box>
																	<ListItem>BANK : </ListItem>
															</Box>
															<Box >
																	<ListItem>BCA</ListItem>
															</Box>
													</Grid>
													<Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
															<Box>
																	<ListItem>{t('va_number')} : </ListItem>
															</Box>
															<Box >
																	<ListItem>
																			{payload?.va_numbers[0].va_number} 
																			<Button ml={2} colorScheme='yellow' onClick={() => copyToClipboard(payload?.va_numbers[0].va_number)}>{t('copy')}</Button>
																	</ListItem>
															</Box>
													</Grid>
											</List>
										: ""}
										<Button
											onClick={() =>
												cancelPayment(cbmBalanceData)
											}
											colorScheme="red"
										>
											{t("cancel_payment")}
										</Button> 
									</Box>
							: 
								<Box alignSelf={"center"}>
									<Button
										onClick={() =>
											makePayment(cbmBalanceData)
										}
										colorScheme="green"
									>
										{t("pay_with_other")}
									</Button>
									<Button
										onClick={() =>
											makePayment(cbmBalanceData, 'midtrans')
										}
										colorScheme="facebook"
									>
										{t("pay_with_bca")}
									</Button>
								</Box>}
							</Flex>
						</CardBody>
					</Card>
				</GridItem>
			</Grid>
		</Stack>
	);
}

export default CbmDetailInvoice;
