import {
	Grid,
	GridItem,
	Stack,
	Card,
	CardBody,
	Heading,
	Text,
	useToast,
	UnorderedList,
	ListItem,
	Flex,
	Box,
	Spacer,
	Show,
	Image,
	Divider,
	CardFooter,
	ButtonGroup,
	Button,
	Link,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	useDisclosure,
	ModalCloseButton,
	Center,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _axios from "../../Api/AxiosBarrier";
import AuthContext from "../../Routes/hooks/AuthContext";
import { convertDateToDateString, formatFrice } from "../../Utils/Helper";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import store from "store";
function DetailResearch() {
	const userData = store.get("user_api");
	const secondGridItemRef = useRef();
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const toast = useToast({
		position: "top",
		align: "center",
	});
	const { loadingShow, loadingClose, kursRates, userSymbolCurrency } =
		useContext(AuthContext);

	const [research, setResearch] = useState({});
	const [customKey, setCustomKey] = useState([]);
	const [products, setProducts] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [researchList, setResearchList] = useState([]);
	const fetchResearch = async () => {
		try {
			loadingShow();
			const res = await _axios.get(
				`api/research/${params.slug}/detail-by-slug`
			);
			if (res.status === true) {
				setResearch(res.data);
				setProducts(res.data.prod_db);
				await addPageViewCount(res.data._id);

			}
			const jsonString = JSON.parse(res.data.custom_fields);
			console.log(jsonString);
			setCustomKey(jsonString);
			loadingClose();
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	const fetchResearchList = async () => {
		try {
			loadingShow();
			const res = await _axios.get(`api/research`);
			setResearchList(res.data);
			loadingClose();
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	const addPageViewCount = async (id) => {
		try{
			await _axios.patch(`api/research/${id}/page-view-count`);
		} catch (error) {
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	} 

	useEffect(() => {
		fetchResearch();
		fetchResearchList();
	}, []);
	useEffect(() => {
		if (isOpen) {
			document.body.focus();
		}
	}, [isOpen]);
	const calculatePrice = (p_price, platform) => {
		const productPlatform = platform;
		const price = Number(p_price);
		if (
			[
				"crowdfund_1688",
				"crowdfund_taobao",
				"taobao",
				"1688",
				"tmall",
			].includes(productPlatform)
		) {
			const kursRmb = kursRates?.rmb;
			return Number(kursRmb) * price;
		} else if (productPlatform === "JPN 🇯🇵") {
			const kursJpy = kursRates?.jpy;
			return kursJpy * price;
		} else if (productPlatform === "USA 🇺🇸") {
			const kursUsd = kursRates?.usd;
			return kursUsd * price;
		} else {
			return price;
		}
	};

	const handleScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		const position = Math.ceil(
			(scrollTop / (scrollHeight - clientHeight)) * 100
		);
		if (position > 18) {
			onOpen();
		}
		if (position == 0) {
			onClose();
		}
	};

	const handleModalClose = () => {
		onClose();
		if (secondGridItemRef.current) {
			secondGridItemRef.current.scrollTop = 0;
		}
	};

	const readMore = (val, maxLen) => {
		if (!val) return "";
		val = val.replace(/\s{2,}/g, " ");
		const strLength = val.length;
		if (strLength > maxLen) {
			{
				val = val.slice(0, maxLen) + "...";
			}
		}
		return val
			.toLowerCase()
			.replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
	};

	return (
		<Stack my={2} h={"100%"}>
			<Grid
				gap="4"
				maxH={"80vh"}
				templateColumns={{
					base: "repeat(3, 1fr)",
					lg: "repeat(4, 1fr)",
					xl: "repeat(5, 1fr)",
				}}
			>
				<Show breakpoint="(min-width: 992px)">
					<GridItem w={"100%"} overflowY={"auto"}>
						<Stack m={2}>
							<Card overflow="hidden" variant="outline" p={5}>
								<Text fontSize="xl" as={"b"} mb={3}>
									Research List
								</Text>
								<UnorderedList spacing={3}>
									{researchList?.map((item, index) => (
										<ListItem key={index}>
											<Link
												style={{ cursor: "pointer" }}
												color="blue"
												target="_blank"
												href={`/research/${item?.slug}`}
											>
												{readMore(item?.title, 30)}
											</Link>
										</ListItem>
									))}
								</UnorderedList>
							</Card>
						</Stack>
					</GridItem>
				</Show>
				<GridItem w={"100%"} colSpan={3}>
					<Stack my={2}>
						<Card
							variant="outline"
							verticalAlign={"middle"}
							overflow="auto"
							onScroll={(e) =>
								userData == undefined &&
								research?.is_member == "yes"
									? handleScroll(e)
									: ""
							}
							ref={secondGridItemRef}
						>
							<CardBody maxH={"85vh"}>
								<Stack>
									<Text fontSize={"5xl"} as="b">
										{research?.title}
									</Text>
									<Text>
										Published:{" "}
										{convertDateToDateString(
											moment(research?.created_at).format(
												"YYYY-MM-DD"
											)
										)}
									</Text>
									<Stack
										border={"1px solid #ffd600"}
										p="3"
										bg="#ffd600"
										rounded={"10"}
									>
										<Grid
											templateColumns={{
												base: "repeat(1, 1fr)",
												md: "repeat(3, 1fr)",
											}}
											gap={3}
										>
											<GridItem>
												<Center>
													<Stack
														direction={{
															base: "column",
															sm: "row",
														}}
														align={"center"}
													>
														<Image
															rounded={"full"}
															src={research?.image_cat}
															maxH={"100px"}
														></Image>
														<Text
															fontWeight={"bold"}
														>
															{research?.title_cat}
														</Text>
													</Stack>
												</Center>
											</GridItem>

											<GridItem
												colSpan={2}
												align="center"
												alignItems="center"
											>
												<Center mt="3">
													<Stack
														direction="row"
														align="center"
														spacing={3}
														overflowX={"auto"}
													>
														{customKey
															.slice(
																0,
																Math.min(
																	3,
																	customKey.length
																)
															)
															.map(
																(item,index) => (
																	<Stack
																		key={index}
																		direction="column"
																		gap={3}
																		align="center"
																		h={"100%"}
																	>
																		<Text fontWeight="bold">
																			{item?.value}
																		</Text>
																		<Text fontWeight="bold">
																			{item.key}
																		</Text>
																	</Stack>
																)
															)}
													</Stack>
												</Center>
											</GridItem>
										</Grid>
									</Stack>
									<Text
										dangerouslySetInnerHTML={{
											__html: research?.description,
										}}
									/>
								</Stack>
								<Grid
									templateColumns={{
										base: "repeat(1, 1fr)",
										md: "repeat(2, 1fr)",
										lg: "repeat(3, 1fr)",
									}}
									gap={3}
								>
									{products.map((x, index) => (
										<Center key={index}>
											<Card maxW="sm">
												<CardBody>
													<Image
														src={x?.image}
														alt="Product Image"
														borderRadius="lg"
													/>
													<Stack mt="6" spacing="3">
														<Text
															size="sm"
															fontWeight={"bold"}
														>
															{readMore(
																x?.name_en,
																70
															)}
														</Text>
														<Text
															color="blue.600"
															fontSize="lg"
															fontWeight={"bold"}
														>
															{userSymbolCurrency}{" "}
															{isNaN(
																calculatePrice(
																	x?.prices,
																	x?.platform_type
																)
															)
																? 0
																: formatFrice(
																		Math.ceil(
																			calculatePrice(
																				x?.prices,
																				x?.platform_type
																			)
																		)
																  )}
														</Text>
													</Stack>
												</CardBody>
												<Divider />
												<CardFooter>
													<ButtonGroup spacing="2">
														<Link
															target="_blank"
															href={`/product/${x?.platform_type}/${x?.product_id}`}
														>
															<Button
																variant="solid"
																colorScheme="blue"
															>
																🛒{" "}
																{t("order_now")}
															</Button>
														</Link>
													</ButtonGroup>
												</CardFooter>
											</Card>
										</Center>
									))}
								</Grid>
							</CardBody>
						</Card>
					</Stack>
				</GridItem>
				<GridItem w={"100%"} overflowY={"auto"}>
					<Stack m={2}>
						<Card overflow="hidden" variant="outline" p={5}>
							<Text fontSize="xl" as={"b"} mb={3}>
								{research?.title}
							</Text>
							<Stack spacing={3}>
								{customKey.map((item, index) => (
									<Flex key={index}>
										<Text>{item?.key}</Text>
										<Spacer />
										<Text>
											{item?.short_value !== undefined &&
											item?.short_value !== null
												? item?.short_value
												: item?.value}
										</Text>
									</Flex>
								))}
							</Stack>
						</Card>
					</Stack>
				</GridItem>
			</Grid>
			<Modal
				trapFocus={false}
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
				<ModalContent>
					<ModalHeader>Login To See Full Content</ModalHeader>
					<ModalCloseButton
						onClick={handleModalClose}
					></ModalCloseButton>
					<ModalBody>
						Find Your Niche Product With Importir.com. Join Our
						Community, FREE!
					</ModalBody>
					<ModalFooter>
						<Link href={"/login"}>
							<Button bgColor={"#ffd600"} color="black">
								Login
							</Button>
						</Link>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Stack>
	);
}

export default DetailResearch;
