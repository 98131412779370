/* eslint-disable react-hooks/exhaustive-deps */
import {
	Stack,
	useToast,
	Button,
	Grid,
	GridItem,
	Card,
	CardHeader,
	CardBody,
	Heading,
	FormControl,
	FormLabel,
	Input,
	Select,
	Flex,
	Image,
	RadioGroup,
	Radio,
	Box,
	Divider,
	Center,
	Tooltip,
	CardFooter,
	Text,
} from "@chakra-ui/react";
import _axios from "../../Api/AxiosBarrier";
import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Routes/hooks/AuthContext";
import store from "store";
import _ from "lodash";
import { IoMdCheckmark, IoMdClose, IoMdSearch } from "react-icons/io";
import { readMore, formatFrice } from "../../Utils/Helper";
import {
	collection,
	query,
	where,
	doc,
	getDoc,
	orderBy,
	limit,
	getDocs,
} from "firebase/firestore";
import { db } from "../../Config/firebase";
function PersonalizedIndex() {
	const { loadingShow, loadingClose, kursRates } = useContext(AuthContext);
	const toast = useToast({
		position: "top",
		align: "center",
	});
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		country_code: "",
		sortType: "desc",
	});

	const submitForm = async () => {
		try {
			const params = {
				name: formData?.name,
				email: formData?.email,
				phone: formData?.phone,
				country_code: formData?.country_code,
				categories: selectedCategories,
			};
			const newErrors = {};
			for (const key in params) {
				if (key == "name" && params[key] == "") {
					newErrors[key] = "Name Field Is Required";
				}
				if (key == "email" && params[key] == "") {
					newErrors[key] = "Email Field Is Required";
				}
				if (key == "phone" && params[key] == "") {
					newErrors[key] = "Phone Field Is Required";
				}
				if (key == "country_code" && params[key] == "") {
					newErrors[key] = "Country Field Is Required";
				}
				if (
					key == "categories" &&
					Object.keys(params[key]).length == 0
				) {
					newErrors[key] = "At least 1 Category Must Be Selected";
				}
			}
			if (Object.keys(newErrors).length > 0) {
				const message = [];
				Object.keys(newErrors).forEach((e) => {
					message.push(newErrors[e]);
				});

				return toast({
					title: "importir.com",
					description: message.join(", "),
					status: "error",
				});
			}
			loadingShow();
			const url = "api/personalized-catalog/store";
			const { status, message, data } = await _axios.post(url, params);
			loadingClose();
			if (!status) {
				return toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
			}
			return toast({
				title: "importir.com",
				description: message,
				status: "success",
			});
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};
	const navigate = useNavigate();

	const [categories, setCategories] = useState([]);
	const fetchDescription = async () => {
		try {
			loadingShow();
			const url = "api/general/get-category-data";
			const res = await _axios.get(url);
			loadingClose();
			const categoryData = JSON.parse(res);
			setCategories(categoryData?.data);
			store.set("category_personalized", categoryData?.data);
			return toast({
				title: "importir.com",
				description: "Success",
				status: "success",
			});
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	useEffect(() => {
		if (store.get("category_personalized") !== undefined) {
			setCategories(store.get("category_personalized"));
		} else {
			fetchDescription();
		}
	}, []);

	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const handleSelectFirst = (arg) => {
		try {
			const selected = _.find(categories, { category: arg });
			setSelectedCategory(selected);
		} catch (error) {
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	const getStartOfCurrentMonthTimestamp = () => {
		const now = new Date();
		const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
		// Convert to int32 format (milliseconds to seconds)
		return Math.floor(startOfMonth.getTime() / 1000);
	};

	const handleSelectCategory = async (arg) => {
		try {
			loadingShow();
			const q = query(
				collection(db, "products"),
				where("category", "==", String(arg["1688-id"])),
				orderBy("price", formData?.sortType),
				limit(20)
			);
			const querySnapshot = await getDocs(q);
			const arrProducts = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			const params = {
				title: arg?.title,
				image: arg?.image,
				products: arrProducts,
			};

			setSelectedCategories((prevSelectedCategories) => ({
				...prevSelectedCategories,
				[arg.category]: {
					...prevSelectedCategories[arg.category],
					[arg["1688-id"]]: params,
				},
			}));

			loadingClose();
		} catch (error) {
			loadingClose();
			console.error("Error fetching products:", error); // Log the error for debugging
			toast({
				title: "importir.com",
				description: error.message || "Unknown error occurred",
				status: "error",
			});
		}
	};

	const checkIfCategoryIsSelected = (item) => {
		if (
			selectedCategories[item.category]?.[item["1688-id"]] !== undefined
		) {
			return true;
		}
		return false;
	};

	const unselectCategory = (item) => {
		setSelectedCategories((prevSelectedCategories) => {
			const newSelectedCategories = { ...prevSelectedCategories };
			delete newSelectedCategories[item?.category][item["1688-id"]];
			if (
				Object.keys(newSelectedCategories[item?.category]).length === 0
			) {
				delete newSelectedCategories[item?.category];
			}

			return newSelectedCategories;
		});
	};

	const unselectSubCategory = async (category, id) => {
		setSelectedCategories((prevSelectedCategories) => {
			const newSelectedCategories = { ...prevSelectedCategories };
			delete newSelectedCategories[category][id];
			if (Object.keys(newSelectedCategories[category]).length === 0) {
				delete newSelectedCategories[category];
			}

			return newSelectedCategories;
		});
	};

	const unselectMainCategory = (category) => {
		setSelectedCategories((prevSelectedCategories) => {
			const newSelectedCategories = { ...prevSelectedCategories };
			delete newSelectedCategories[category];
			return newSelectedCategories;
		});
	};

	return (
		<Stack>
			<Stack>
				<Card>
					<CardHeader>
						<Heading>Form Personalized Catalog</Heading>
					</CardHeader>
					<CardBody>
						<Grid
							gap={2}
							templateColumns={{
								base: "1fr",
								md: "repeat(2, 1fr)",
							}}
						>
							<GridItem>
								<FormControl marginY={2}>
									<FormLabel>Country</FormLabel>
									<RadioGroup
										marginY={"4"}
										value={formData.country_code}
										onChange={(value) =>
											setFormData({
												...formData,
												country_code: value,
											})
										}
									>
										<Stack direction={"row"} spacing={3}>
											<Radio value="id">Indonesia</Radio>
											<Radio value="ph">
												Philippines
											</Radio>
										</Stack>
									</RadioGroup>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl marginY={2}>
									<FormLabel>Email</FormLabel>
									<Input
										onChange={(e) =>
											setFormData({
												...formData,
												email: e.target.value,
											})
										}
										type="email"
									></Input>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl marginY={2}>
									<FormLabel>Name</FormLabel>
									<Input
										onChange={(e) =>
											setFormData({
												...formData,
												name: e.target.value,
											})
										}
										type="text"
									></Input>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl marginY={2}>
									<FormLabel>Phone</FormLabel>
									<Input
										onChange={(e) =>
											setFormData({
												...formData,
												phone: e.target.value,
											})
										}
										type="text"
									></Input>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl marginY={2}>
									<FormLabel>Categories</FormLabel>
									<Select
										onChange={(e) =>
											handleSelectFirst(e.target.value)
										}
									>
										<option>-- Select --</option>
										{categories?.length > 0
											? categories?.map((item, index) => (
													<option
														value={item?.category}
														key={index}
													>
														{item?.category}
													</option>
											  ))
											: null}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl marginY={2}>
									<FormLabel>Sort Order</FormLabel>
									<RadioGroup
										marginY={"4"}
										value={formData.sortType}
										onChange={(value) =>
											setFormData({
												...formData,
												sortType: value,
											})
										}
									>
										<Stack direction={"row"} spacing={3}>
											<Radio value="desc">
												Descending
											</Radio>
											<Radio value="asc">Ascending</Radio>
										</Stack>
									</RadioGroup>
								</FormControl>
							</GridItem>
						</Grid>
					</CardBody>
				</Card>
			</Stack>
			<Grid
				marginY={"2"}
				templateColumns={{
					base: "1fr",
				}}
				gap="2"
			>
				<GridItem>
					<Card>
						<CardHeader>
							<Heading>
								Sub Categories Of:{" "}
								{selectedCategory !== null
									? selectedCategory?.category
									: "---"}
							</Heading>
						</CardHeader>
						<CardBody>
							{selectedCategory !== null ? (
								<Box
									maxHeight={"43vh"}
									overflowY={"scroll"}
									padding={2}
								>
									<Grid
										templateColumns={{
											base: "1fr",
											md: "repeat(3, 1fr)",
											lg: "repeat(4, 1fr)",
											xl: "repeat(6, 1fr)",
										}}
										gap={3}
									>
										{selectedCategory?.subCategory?.map(
											(item, index) => (
												<GridItem key={index}>
													<Card maxW="sm">
														<CardBody>
															<Center>
																<Image
																	alignSelf={
																		"center"
																	}
																	src={
																		item?.image
																	}
																	alt="Green double couch with wooden legs"
																	borderRadius="lg"
																	width={
																		"150px"
																	}
																	height={
																		"150px"
																	}
																/>
															</Center>
															<Stack
																mt="6"
																spacing="3"
															>
																<Tooltip
																	label={
																		item?.title
																	}
																>
																	<Heading
																		alignSelf={
																			"center"
																		}
																		size="sm"
																	>
																		{readMore(
																			item?.title
																		)}
																	</Heading>
																</Tooltip>
															</Stack>
														</CardBody>
														<Divider />
														<Stack
															padding={5}
															direction={"row"}
															justifyContent={
																"space-between"
															}
														>
															<Button
																size={"sm"}
																isDisabled={checkIfCategoryIsSelected(
																	item
																)}
																onClick={() =>
																	handleSelectCategory(
																		item
																	)
																}
																colorScheme="green"
															>
																<IoMdCheckmark />
															</Button>
															{![
																undefined,
																null,
															].includes(item) ? (
																checkIfCategoryIsSelected(
																	item
																) == true ? (
																	<Button
																		size={
																			"sm"
																		}
																		colorScheme="red"
																		onClick={() =>
																			unselectCategory(
																				item
																			)
																		}
																	>
																		<IoMdClose />
																	</Button>
																) : null
															) : null}
														</Stack>
													</Card>
												</GridItem>
											)
										)}
									</Grid>
								</Box>
							) : null}
						</CardBody>
					</Card>
				</GridItem>
				<GridItem>
					<Card>
						<CardHeader>
							<Heading>Selected Categories</Heading>
						</CardHeader>
						<CardBody maxHeight={"43vh"} overflowY={"scroll"}>
							<Stack
								direction={"column"}
								justifyContent={"center"}
								spacing={2}
							>
								{Object.keys(selectedCategories)?.length > 0
									? Object.keys(selectedCategories)
											?.reverse()
											.map((item, index) => (
												<Card shadow={"lg"} key={index}>
													<CardHeader>
														<Stack
															direction={"row"}
															justifyContent={
																"space-between"
															}
														>
															<Stack>
																<Text
																	alignSelf={
																		"center"
																	}
																>
																	<b>
																		Category:{" "}
																		{item}
																	</b>
																</Text>
															</Stack>
															<Button
																onClick={() =>
																	unselectMainCategory(
																		item
																	)
																}
																alignSelf={
																	"center"
																}
																size={"sm"}
																colorScheme="red"
															>
																<IoMdClose />
															</Button>
														</Stack>
													</CardHeader>
													<Divider />
													<CardBody>
														{Object.keys(
															selectedCategories[
																item
															]
														)?.length > 0
															? Object.keys(
																	selectedCategories[
																		item
																	]
															  )
																	?.reverse()
																	?.map(
																		(
																			e,
																			i
																		) => (
																			<Card
																				key={
																					i
																				}
																				direction={{
																					base: "column",
																					sm: "row",
																				}}
																				overflow="hidden"
																				variant="outline"
																				marginY={
																					2
																				}
																				shadow={
																					"lg"
																				}
																			>
																				<CardHeader
																					alignSelf={
																						"center"
																					}
																				>
																					<Center>
																						<Stack
																							direction={
																								"column"
																							}
																						>
																							<Text
																								fontSize={
																									"small"
																								}
																								alignSelf={
																									"center"
																								}
																							>
																								Sub
																								Category
																								Of:{" "}
																								{
																									item
																								}
																							</Text>
																							<Heading
																								textAlign={
																									"center"
																								}
																								alignSelf={
																									"center"
																								}
																								size="md"
																							>
																								{
																									selectedCategories[
																										item
																									][
																										e
																									]
																										?.title
																								}
																							</Heading>
																							<Image
																								alignSelf={
																									"center"
																								}
																								objectFit="cover"
																								maxW={{
																									base: "100%",
																									sm: "200px",
																								}}
																								src={
																									selectedCategories[
																										item
																									][
																										e
																									]
																										?.image
																								}
																								alt="Caffe Latte"
																							/>
																						</Stack>
																					</Center>
																				</CardHeader>

																				<Stack>
																					<CardBody
																						alignSelf={
																							"center"
																						}
																					>
																						<Grid
																							templateColumns={{
																								base: "repeat(1, 1fr)",
																								md: "repeat(2, 1fr)",
																								lg: "repeat(4, 1fr)",
																								xl: "repeat(5, 1fr)",
																							}}
																							gap="5"
																						>
																							{selectedCategories[
																								item
																							][
																								e
																							]
																								?.products
																								?.length >
																							0 ? (
																								selectedCategories[
																									item
																								][
																									e
																								]?.products?.map(
																									(
																										product,
																										productIndex
																									) => (
																										<GridItem
																											key={
																												productIndex
																											}
																										>
																											<Card
																												shadow={
																													"md"
																												}
																												maxW="sm"
																											>
																												<Center>
																													<CardBody>
																														<Center>
																															<Stack
																																direction={
																																	"column"
																																}
																															>
																																<Image
																																	alignSelf={
																																		"center"
																																	}
																																	src={
																																		product?.image
																																	}
																																	alt="Green double couch with wooden legs"
																																	borderRadius="lg"
																																	width={
																																		"80px"
																																	}
																																	height={
																																		"80px"
																																	}
																																/>
																																<Stack
																																	alignItems={
																																		"center"
																																	}
																																	mt="6"
																																	spacing="3"
																																>
																																	<Tooltip
																																		label={
																																			product[
																																				"title-en"
																																			]
																																				?.en
																																		}
																																	>
																																		<small>
																																			<b>
																																				{readMore(
																																					product[
																																						"title-en"
																																					]
																																						?.en
																																				)}
																																			</b>
																																		</small>
																																	</Tooltip>
																																	<small>
																																		Rp.{" "}
																																		{formatFrice(
																																			parseFloat(
																																				isNaN(
																																					product?.price
																																				)
																																					? 0
																																					: product?.price
																																			) *
																																				kursRates?.rmb
																																		)}
																																	</small>
																																</Stack>
																															</Stack>
																														</Center>
																													</CardBody>
																												</Center>
																											</Card>
																										</GridItem>
																									)
																								)
																							) : (
																								<Heading
																									color={
																										"red"
																									}
																								>
																									Data
																									Not
																									Found
																								</Heading>
																							)}
																						</Grid>
																					</CardBody>

																					<CardFooter
																						alignSelf={
																							"center"
																						}
																					>
																						<Button
																							onClick={() =>
																								unselectSubCategory(
																									item,
																									e
																								)
																							}
																							colorScheme="red"
																						>
																							<IoMdClose />
																						</Button>
																					</CardFooter>
																				</Stack>
																			</Card>
																		)
																	)
															: null}
													</CardBody>
												</Card>
											))
									: null}
							</Stack>
						</CardBody>
					</Card>
				</GridItem>
			</Grid>
			<Flex marginY={2} justifyContent={"space-between"}>
				<Button
					onClick={() => navigate("/")}
					size={"sm"}
					colorScheme="yellow"
				>
					Back
				</Button>
				<Button
					onClick={() => submitForm()}
					size={"sm"}
					colorScheme="green"
				>
					Submit
				</Button>
			</Flex>
		</Stack>
	);
}

export default PersonalizedIndex;
