/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Button, Grid, Heading, HStack, Image, Input, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, OrderedList, ListItem, Divider, Spacer, Link } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { MdOutlinePermMedia } from 'react-icons/md'
import AuthContext from '../Routes/hooks/AuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import _axios from '../Api/AxiosBarrier'
import { errorSlack } from '../Api/SlackApi'
import FormBiodataProfile from './Profile/FormBiodataProfile'
import FormChangePassword from './Profile/FormChangePassword'
import FormInputRekening from './Profile/FormInputRekening'
import { useTranslation } from 'react-i18next'

function ProfilePage() {

  const path = window.location.href ?? '#'
  const { loadingShow, loadingClose, userDb } = useContext(AuthContext)

  const [dataUser, setDataUser] = useState('')
  const [editPhoto, setEditPhoto] = useState(false)
  const [filesImage, setFilesImage] = useState([]);
  const [files, setFiles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [modalPromo, setModalPromo] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate()

  const { t } = useTranslation();

  const getData = async () => {
    try {
      loadingShow()
      const res = await _axios.get('api/me')
      loadingClose()
      if (res.status === true) {
        setDataUser(res.data)
      }
    } catch (error) {
      console.log(error, 'ini error')
      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
    }
  }

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id)
    toast({
      title: 'importir.com',
      description: 'Copy to clipboard.',
      status: 'success'
    })
  }



  useEffect(() => {
    getData()
    const searchParams = new URLSearchParams(location.search);
    const accountNumberParam = searchParams.get("account_number");
    const editNpwpParam = searchParams.get("edit_npwp");

    if (accountNumberParam === "true") {
      setActiveTab(3);
    } else if (editNpwpParam === "true") {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  }, [location.search])


  const handleInputData = async () => {
    try {
      const params = new FormData();
      params.append('file', filesImage[0]);

      const response = await _axios.post('api/storage?directory=profile-user', params);

      if (response.status === true) {
        const resUpdate = await _axios.put('api/update-me', {
          profile_image: response.data.toString()
        });

        if (resUpdate.status === true) {
          toast({
            title: 'importir.com',
            description: 'Success add new photo profile',
            status: 'success',
          });
          setFilesImage([]);
          setFiles([]);
          setEditPhoto(false);
          getData()
        }
      } else {
        toast({
          title: 'importir.com',
          description: response.message,
          status: 'error',
        });
        const resSlack = await errorSlack(response.message, 'importir.com', path);
        console.log(resSlack, 'CONNECTED ERROR IN SLACK');
      }
    } catch (error) {
      if (error.response && error.response.status === 413) {
        toast({
          title: 'importir.com',
          description: 'File size is too large',
          status: 'error',
        });
      } else {
        console.log(error);
        toast({
          title: 'importir.com',
          description: error.message,
          status: 'error',
        });
        const resSlack = await errorSlack(error, 'importir.com', path);
        console.log(resSlack, 'CONNECTED ERROR IN SLACK');
      }
      throw error;
    }
  };


  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles); // Mengubah state filesImage menjadi array baru dari selectedFiles
    }
  }

  const handleModalPromo = () => {
    if (dataUser?.promo_users.length > 0) {
      setModalPromo(true)

    } else {
      toast({
        title: 'importir.com',
        description: 'You dont have any promos.',
        status: 'warning'
      })
    }
  }

  const handleCloseModalPromo = () => {
    setModalPromo(false)
  }

  const expirationDate = new Date(userDb?.data_importir?.package_user?.expired_at);
	const currentDate = new Date();

  return (
    <Stack p={[1, 1, 5]}>
      <Stack>
        {
           currentDate > expirationDate && userDb?.data_importir?.user?.country_code === 'ph' ? 
          <Box bgColor={'red.500'} borderRadius={'md'} p={'5'} textAlign={'center'} color={'white'}>Your account has expired please renew your plan <Link href="/register" color={'blue.300'} fontWeight={'bold'}>Renew Plan</Link></Box>
          :
          <Text></Text>
        }
      </Stack>
      <Grid templateColumns={{ base: "1fr", md: "1fr 3fr" }} gap={3}>
        <Stack bgColor={'white'} borderRadius='md' shadow={'md'} w='100%'>
          <Stack spacing={5} p={5} m={5}>
            <HStack >
              <Heading size={'lg'}>{t("profile")}</Heading>
            </HStack>
            <Stack>
              <Avatar size='xl' name='Christian Nwamba' src={dataUser?.detail?.profile_image ? dataUser?.detail?.profile_image : 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Eko-IT/love-2022_10_10_16_44_07.png'} />
              {editPhoto ? (
                <Box>

                  <Stack>
                    <Input
                      type="file"
                      onChange={handleFileInputChange}
                      display="none"
                      id="fileInput"
                    />
                    <label htmlFor="fileInput">
                      <HStack cursor={'pointer'}>
                        <Stack>
                          <MdOutlinePermMedia />

                        </Stack>
                        <Text fontSize={'sm'} color='blue.600' fontStyle={'italic'}>
                          Add Image
                        </Text>
                      </HStack>
                    </label>
                  </Stack>



                  {files.length > 0 &&
                    <Stack >
                      <Image src={files[0].file} borderRadius='xl' alt={files[0].name} shadow='sm' />
                    </Stack>
                  }

                  <HStack alignItems={'flex-start'} py={2}>
                    <Button bgColor={'#ffd600'} size='sm' shadow={'sm'} onClick={() => handleInputData()}>
                      <Text>Submit</Text>
                    </Button>

                    <Button colorScheme={'red'} size='sm' shadow={'sm'} onClick={() => setEditPhoto(false)}>
                      <Text>Cancel</Text>
                    </Button>
                  </HStack>
                </Box>
              ) : (
                <Text onClick={() => setEditPhoto(true)} fontStyle='italic' cursor={'pointer'} color={'blue.500'} fontSize='sm'>Change Photo</Text>

              )}

            </Stack>



            <HStack py={5} spacing={5} alignItems='center' justifyContent={'center'}>
              <Stack>
                <Button size={'sm'} bgColor='red.300' shadow={'md'} onClick={() => handleModalPromo()}>
                  <Text color={'white'}>{t("promo_code")}</Text>
                </Button>
              </Stack>

              <Stack>
                <Button size={'sm'} bgColor='green.300' shadow={'md'} onClick={() => navigate('/cbm')}>
                  <Text color={'white'}>{t('cbm_balance')}</Text>
                </Button>
              </Stack>

              <Stack>
                <Button size={'sm'} bgColor='blue.300' shadow={'md'} onClick={() => navigate('/refund-history')}>
                  <Text color={'white'}>{t('refund_balance')}</Text>
                </Button>
              </Stack>
            </HStack>
            <Stack>
              <Button onClick={onOpen} bgColor={'green.400'} textColor={'white'} _hover={'green.300'}>{t("term_condition")}</Button>

              <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{t("term_condition")}</ModalHeader>
                  <ModalCloseButton />
                  <Divider />
                  <ModalBody>
                    <OrderedList>
                      <ListItem>Kuota free shipping gratis ongkos kirim China-Indonesia 6 (enam) cubic meter senilai Rp21.000.000,- dan/atau 3 (tiga) cubic meter senilai Rp10.500.000,- dapat di gunakan selama membership aktif yaitu 3 tahun</ListItem>
                      <Divider />
                      <ListItem>Berlaku untuk platform IMPORTIR.ORG dan IMPORTIR.COM</ListItem>
                      <Divider />
                      <ListItem>Berlaku untuk produk dengan HS Code NON LARTAS (tidak termasuk barang replika, bekas, branded, dan dangerous goods)</ListItem>
                      <Divider />
                      <ListItem>Dapat digunakan dengan minimum kubikasi 0,2 CBM (cubic meter) per shipment atau per link order</ListItem>
                      <Divider />
                      <ListItem>Berlaku handling fee normal 3 sampai 10%</ListItem>
                      <Divider />
                      <ListItem>Berlaku duty tax impor normal sesuai HS Code yang berlaku</ListItem>
                      <Divider />
                      <ListItem>Hanya berlaku untuk pengiriman LCL via laut dengan pengiriman melalui gudang IMPORTIR.ORG di China</ListItem>
                      <Divider />
                      <ListItem>Tidak termasuk biaya ongkir lokal China dan Indonesia</ListItem>
                      <Divider />
                      <ListItem>Pembayaran barang harus melalui platform IMPORTIR.COM dan IMPORTIR.ORG</ListItem>
                      <Divider />
                      <ListItem>Kuota free shipping tidak dapat dicairkan dalam bentuk dana, namun hanya dpat digunakan sebagai kouta free shipping selama membership berlaku</ListItem>
                      <Divider />
                      <ListItem>IMPORTIR.ORG berhak membatalkan penggunaan kuota free shipping apabila di temukan kecurangan atau transaksi yang tidak memenuhi Terms and Conditions</ListItem>
                    </OrderedList>
                  </ModalBody>
                  <Divider />
                  <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Stack>
          </Stack>
        </Stack>
        <Stack bgColor={'white'} borderRadius='md' shadow={'md'} >
          <Tabs variant='enclosed' index={activeTab} onChange={(index) => setActiveTab(index)}>
            <TabList>
              <Tab>
                <Stack p={3}>
                  <Text>{t("view_profile")}</Text>
                </Stack>
              </Tab>
              <Tab>
                <Stack p={3}>
                  <Text>{t("edit_profile")}</Text>
                </Stack>
              </Tab>
              <Tab>
                <Stack p={3}>
                  <Text>{t("change_password")}</Text>
                </Stack>
              </Tab>
              <Tab>
                <Stack p={3}>
                  <Text>{t("account_number")}</Text>
                </Stack>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack p={5}>
                  <FormBiodataProfile view={true} dataUser={dataUser} />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack p={5}>
                  <FormBiodataProfile />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack p={5}>
                  <FormChangePassword />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack p={5}>
                  <FormInputRekening />
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>

        </Stack>

      </Grid>

      <Modal isOpen={modalPromo} onClose={() => handleCloseModalPromo()}>
        <ModalOverlay />
        <ModalContent w={'100%'}>
          <ModalHeader>Promo Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            {dataUser?.promo_users?.length > 0 ? (
              <Stack spacing={2}>
                {dataUser?.promo_users?.map((x, i) => {
                  return (
                    <Stack key={i} py={1}>
                      <HStack>
                        <Text fontSize={'sm'} fontWeight={500}>{x?.promo_code?.code}</Text>
                        <Spacer />
                        <Text color="blue.600" cursor={'pointer'} fontSize='xs' onClick={() => handleCopy(x?.promo_code?.code)}>COPY</Text>
                      </HStack>
                      <Stack fontSize={'sm'} color='gray.600'>
                        <div dangerouslySetInnerHTML={{ __html: x?.promo_code?.description }} />

                      </Stack>

                      {/* <Text textAlign={'end'} color={'red'} fontSize='xx-small' fontWeight={500}> Expired: </Text> */}
                      <Divider />
                    </Stack>
                  )
                })}
              </Stack>
            ) : (<></>)}

          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={() => handleCloseModalPromo()}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default ProfilePage