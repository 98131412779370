import { 
    Stack,
    VStack, 
    Heading, 
    Divider,  
    useToast, 
    Table,
    Thead,
    Tbody,  
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Icon,
    HStack,
    Text,
    Input,
    SimpleGrid,
    Grid,
    Select,
 } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import { errorSlack } from '../../Api/SlackApi';
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatPrice, convertDateToDateString } from '../../Utils/Helper';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import colors from '../../Utils/colors'
import { useTranslation } from 'react-i18next';
import moment from 'moment';

function OrderPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const path = window.location.href ?? '#'

    const [voucherOrders, setVoucherOrders] = useState([])
    const [lastPage, setLastPage] = useState(0)
    const [invoiceNo, setInvoiceNo] = useState('')
    const [paidAt, setPaidAt] = useState('')
    const { loadingShow, loadingClose, userDb } = useContext(AuthContext)
    const toast = useToast({
        position: "top",
        align: "center",
    });

    const [pageNow, setPageNow] = useState(1)

    const getVoucherOrder = async (page = 1) => {
        try {
            loadingShow()
            const res = await _axios.get(`api/voucher/order?page=${page}&invoice_no=${invoiceNo}&paid_at=${paidAt}`)
            loadingClose()
            if (page === 1) {
                setVoucherOrders(res.data.data)
            } else {
                setVoucherOrders(prevDataList => [...prevDataList, ...res.data.data]);
            }
            setLastPage(res.data.last_page)
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error  ,
                status: 'error'
            })
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    const handlePagination = () => {
        const pageNext = pageNow + 1
        setPageNow(pageNext)
        getVoucherOrder(pageNext)
    }

    useEffect(() => {
        if (userDb !== undefined) {
            const currentDate = new Date();
            const expirationDate = new Date(userDb?.data_importir?.package_user?.expired_at);

            if (currentDate < expirationDate) {
                toast({
                    title: 'importir.com',
                    description: "You don't allow to access this page",
                    status: 'error'
                })
                setTimeout(function() {
                    window.location.href = '/profile' 
                }, 2000);
            } else {
                getVoucherOrder()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDb])

    return (
        <>
            <VStack p={[1, 1, 5]} bgColor="white" shadow="md" borderRadius="md" mt={2} mb={2}>
                <Heading size="xl" textAlign="center" my={5} color="green.300">
                    Order History List
                </Heading>
                <Divider />

            <Grid templateColumns={{ base: "1fr", md: "1fr 4fr" }}>
                <Stack p={[1, 1, 5]}>
                    <Heading size={'md'}>
                        Order History List
                    </Heading>
                </Stack>
                <SimpleGrid columns={[1, 3, 6]} gap={1} p={[1, 1, 5]}>
                    <Input
                        id="searchList"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        size='sm'
                        placeholder={t('invoice_number')}
                        onChange={(e) => setInvoiceNo(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                getVoucherOrder();
                            }
                        }}
                    />


                    <Select fontSize='sm' size={'sm'} bgColor={'white'} placeholder={t('all')} onChange={(e) => setPaidAt(e.target.value)}>
                        <option value="yes">{t('paid')}</option>
                        <option value="no">{t('unpaid')}</option>
                    </Select>


                    <HStack alignItems="center" justifyContent="center" space={3} >
                        <Button bgColor={colors.theme} onClick={() => getVoucherOrder()}>
                            <HStack alignItems="center" justifyContent="center">
                                <Text color={colors.black}>{t('search')}</Text>
                            </HStack>
                        </Button>
                    </HStack>

                </SimpleGrid>
            </Grid>
                <TableContainer>
                    <Table variant='simple' __css={{'table-layout': 'fixed', width: 'full'}} >
                        <Thead>
                        <Tr>
                            <Th>{t('invoice_number')}</Th>
                            <Th isNumeric>{t('total')}</Th>
                            <Th>Status</Th>
                            <Th>{t('created_at')}</Th>
                            <Th>#</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                            {voucherOrders.map((item, index) => (
                            <Tr>
                                <Td>{item.invoice_no}</Td>
                                <Td isNumeric>{formatPrice(item.total)}</Td>
                                <Td>{item.paid_at ? `${t('paid')}` : `${t('unpaid')}`}</Td>
                                <Td>{moment(item.created_at).format('DD-MM-YYYY HH:MM:SS')}</Td>
                                <Td>
                                    <Button bgColor={'blue.200'} textColor={'white'} mr={2} onClick={() => navigate('/voucher/order/' + item.id)} className='detail'>
                                        <Icon as={FaEye} w={4} h={4} color='black.500' />
                                    </Button>
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>

                {lastPage > pageNow ? 
                <Stack pt={5} alignItems='center' justifyContent={'center'}>
                    <Button onClick={() => handlePagination()} bgColor='#ffd600' shadow={'md'}>
                        <HStack alignite>
                            <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                                {t('load_more')}
                            </Text>
                        </HStack>
                    </Button>
                </Stack>
                : ''}
            </VStack>
        </>
    )
}

export default OrderPage