/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Heading, HStack, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Skeleton, Spacer, Stack, Tag, Text, useToast } from '@chakra-ui/react'
import { arrayUnion, collection, doc, documentId, increment, limit, onSnapshot, orderBy, query, setDoc, startAfter, updateDoc, where } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { SlArrowDown } from 'react-icons/sl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { db } from '../../Config/firebase'
import AuthContext from '../../Routes/hooks/AuthContext'
import { formatFrice } from '../../Utils/Helper'
import ProductCard from '../../Components/Card/ProductCard'
import ConvertPrice from '../../Utils/ConvertPrice'
import { clientTypessense } from '../../Api/Typesense'
import { errorSlack } from '../../Api/SlackApi'
import AppSideCategory from '../../Components/CategoryList/AppSideCategory'

import nonlartas from "../../Config/Json/NonLartas.json"
import { sendEcommerceEvent } from '../../Utils/gtm'
import { useTranslation } from 'react-i18next'




function ProductListPage() {
    const {t} = useTranslation();
    const { currentUser, kursRmb, loadingShow, loadingClose, productListWishlistId, userSymbolCurrency, categoryFrom } = useContext(AuthContext)

    let [searchParams] = useSearchParams()

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const navigate = useNavigate()




    const categoryActive = searchParams.get('detail')
    const subCategoryActive = searchParams.get('subCategory')
    const idCategory = searchParams.get('id')

    const [productList, setProductList] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [imageView, setImageView] = useState('')
    const [detailModal, setDetailModal] = useState(false)
    const [page] = useState(1)

    const path = window.location.href ?? '#'

    const height = window.innerHeight

    const { convertPriceFix } = ConvertPrice()


    const fetchData = async () => {
        loadingShow()
        try {
            const limitData = 20;
            const q = query(
                collection(db, "products"),
                where("category", "==", idCategory),
                orderBy("date", "desc"),
                limit(limitData)
            );
            
            onSnapshot(q, (querySnapshot) => {
                const arrProducts = [];

                querySnapshot?.forEach((doc) => {
                    const objData = doc.data();
                    objData.id = doc.id;
                    arrProducts.push(objData);
                });
                loadingClose()
                if (arrProducts.length === 0) {
                    getDataFromSearch()
                    loadingClose()
                } else {
                    // if (categoryFrom === "japan") {
                    //     const filteredProducts = arrProducts.filter((x) => x.domain === "amazon.co.jp");
                    //     arrProducts.splice(0, arrProducts.length, ...filteredProducts);
                    // }

                    if (categoryFrom === "us") {
                        const filteredProducts = arrProducts.filter((x) => x.domain === "category-amazon-com-v2");
                        arrProducts.splice(0, arrProducts.length, ...filteredProducts);
                    }
                    arrProducts.sort((a, b) => a.price - b.price);
                    setProductList(arrProducts);
                    loadingClose()

                }
                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                loadingClose()
            });

        } catch (error) {
            console.log(error);
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }


        loadingClose()



    };

    const getDataFromSearch = async () => {
        console.log("getdatafrom")
        if (idCategory) {
            let searchParameters;

            searchParameters = {
                q: subCategoryActive && subCategoryActive,
                query_by: "title-en",
                filter_by: `domain:${categoryFrom === "usa" ? "amazon.com" : categoryFrom === "korea" ? "coupang.com" : "detail.1688.com"} `,
                page: page,
                sort_by: "_text_match:desc"
            };

            try {
                const searchResult = await clientTypessense
                    .collections("products")
                    .documents()
                    .search(searchParameters);

                const searchResultIds = searchResult.hits.map((hit) => hit.document.id);

                if (searchResultIds.length === 0) {
                    loadingClose();
                }

                const q = query(
                    collection(db, "products"),
                    where(documentId(), "in", searchResultIds)
                );

                onSnapshot(q, (querySnapshot) => {
                    const arrProducts = [];

                    // Loop through querySnapshot in the order of searchResultIds
                    searchResultIds?.forEach((id) => {
                        const doc = querySnapshot.docs.find((doc) => doc.id === id);
                        if (doc) {
                            const objData = doc.data();
                            objData.id = doc.id;
                            arrProducts.push(objData);
                        }
                    });

                    if (categoryFrom === "us") {
                        const filteredProducts = arrProducts.filter((x) => x.domain === "category-amazon-com-v2");
                        arrProducts.splice(0, arrProducts.length, ...filteredProducts);
                    } else if (categoryFrom === "korea") {
                        const filteredProducts = arrProducts.filter((x) => x.domain === "coupang.com");
                        arrProducts.splice(0, arrProducts.length, ...filteredProducts);
                    }

                    arrProducts.sort((a, b) => a.price - b.price);
                    setProductList((prevProductList) => [...prevProductList, ...arrProducts]);
                    loadingClose();
                });
            } catch (error) {
                console.log(error);
                const resSlack = await errorSlack(error, 'importir.com', path);
                console.log(resSlack, 'CONNECTED ERROR IN SLACK');
            }
        }
    };




    const handleClick = async () => {
        const limitData = 8;
        try {
            loadingShow()
            const q = query(
                collection(db, "products"),
                where("category", "==", idCategory),
                orderBy("date", "desc"),
                limit(limitData),
                startAfter(lastDoc)
            );
            
            onSnapshot(q, (querySnapshot) => {
                const arrProducts = [];

                querySnapshot?.forEach((doc) => {
                    const objData = doc.data();
                    objData.id = doc.id;
                    arrProducts.push(objData);
                });

                // if (categoryFrom === "japan") {
                //     const filteredProducts = arrProducts.filter((x) => x.domain === "amazon.co.jp");
                //     arrProducts.splice(0, arrProducts.length, ...filteredProducts);
                // }

                if (categoryFrom === "usa") {
                    const filteredProducts = arrProducts.filter((x) => x.domain === "category-amazon-com-v2");
                    arrProducts.splice(0, arrProducts.length, ...filteredProducts);
                }
                arrProducts.sort((a, b) => a.price - b.price);
                setProductList((prevProductList) => [...prevProductList, ...arrProducts]);
                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                loadingClose()
            });
        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()

        }
        finally{
            loadingClose()
        }


    };

    const handleWishlist = async (data) => {
        loadingShow()

        let newData = data
        newData.createdAt_wishlist = new Date()
        newData.category_title = categoryActive


        try {

            const ref = doc(db, "wishlist", currentUser.uid);
            await setDoc(ref, {
                uid: currentUser.uid,
                data: arrayUnion(newData),
                createdAt: new Date()
            }, { merge: true });

            const docRef = doc(db, "products", data.id);
            try {
                await updateDoc(docRef, {
                    wishlist_count: increment(1),
                });



                const ecommerceData = {
                    'currency': 'IDR',
                    'value': parseFloat(data.price),
                    'items': [
                        {
                            'item_id': data.id,
                            'item_name': data.title,
                            'item_category': data.category,
                            // 'price': parseFloat(data.price),
                            // 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
                        },
                    ],
                };
    
                sendEcommerceEvent('add_to_wishlist', ecommerceData);
    
            } catch (error) {
                console.log(error);
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }

            loadingClose()
            toast({
                title: 'importir.com',
                description: 'Successfully added the product to the wishlist.',
                status: 'success'
            })

        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
        loadingClose()
    }

    const handleNavigateProduct = (idProduct, platformType) => {
        // if (userDb) {
        const navigateRouteProduct = `/product/${platformType}/${idProduct}`
        navigate(navigateRouteProduct)
        // } else {
        //     navigate('/signup')
        // }

    }

    const handleImage = (data) => {
        setImageView(data)
        setDetailModal(true)
    }   

    const dataNonLartas = nonlartas.map((x) => x.product_id)







    useEffect(() => {
        setProductList([])
        setLastDoc(null);
        fetchData();

        return () => {
            // cleanup
            setProductList([]);
            setLastDoc(null);

        }
    }, [idCategory])






    return (
        <Stack p={[1, 1, 5]} bgColor='gray.100' minH={height} mt={[10, 10, 0]}>
            <Grid templateColumns={{ base: "1fr", md: "1fr 4fr" }} gap={3}>
                <Stack display={['none', 'none', 'flex']} transition={'all 0.3s ease-in-out'}>
                    <AppSideCategory />
                </Stack>


                <Stack>

                    <HStack spacing={2} px={[1, 1, 5]}>
                        <Stack templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={2} color='gray.600' alignItems='center' justifyContent={'center'}>
                            <Stack>
                                <Heading size={['sm', 'md', 'md']} textTransform={'capitalize'}>{subCategoryActive && `${subCategoryActive}`}</Heading>
                                <Text fontSize={'xs'} color='red.300'>
                                    {t("product_category_info", {country: categoryFrom === "us" ? "USA" : categoryFrom === "korea" ? "Korea" : "China"})}
                                </Text>
                            </Stack>
                        </Stack>

                    </HStack>
                    <Stack p={[1, 1, 5]}>
                        {productList?.length > 0 ? (
                            <>
                                <SimpleGrid columns={[1, 2, 4]} gap={5} >
                                    {

                                        productList?.map((product, i) => {
                                            const wishlistFilter = productListWishlistId.includes(product.id)

                                            

                                            const nonLartasFilter = dataNonLartas.includes(product.id)

                                            return (
                                                <ProductCard
                                                    key={i}
                                                    product={product}
                                                    kursRmb={kursRmb}
                                                    handleNavigateProduct={handleNavigateProduct}
                                                    handleWishlist={handleWishlist}
                                                    handleImage={handleImage}
                                                    wishlistFilter={wishlistFilter}
                                                    nonLartasFilter={nonLartasFilter}
                                                    userSymbolCurrency={userSymbolCurrency}
                                                />
                                                )
                                        }


                                        )

                                    }

                                </SimpleGrid>
                                <Stack pt={5} alignItems='center' justifyContent={'center'}>
                                    <Button onClick={() => handleClick()} bgColor='#ffd600' shadow={'md'}>
                                        <HStack alignite>
                                            <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                                                {t("load_more")}
                                            </Text>
                                            <Stack>
                                                <SlArrowDown size={10} />
                                            </Stack>
                                        </HStack>
                                    </Button>
                                </Stack>
                            </>
                        ) : (
                            <Stack alignItems={'center'} justifyContent='center' h={height / 1.3}>
                                <Text>Product unavailable</Text>
                            </Stack>


                        )}
                    </Stack>

                </Stack>
            </Grid>

            {imageView !== "" && (
                <Modal isOpen={detailModal} onClose={() => setDetailModal(false)} >
                    <ModalOverlay />
                    <ModalContent bgColor={'white'} >
                        <ModalHeader>
                            <HStack spacing={2} alignItems='center' >
                                {/* <FontAwesome5 name="file-invoice" size={22} color="black" /> */}
                                <Text fontSize={'lg'} fontWeight='bold'>Detail</Text>
                                <Spacer />
                                <HStack alignItems={'center'} justifyContent='flex-end'>
                                    <Stack>
                                        <Tag>CN 🇨🇳</Tag>
                                    </Stack>


                                </HStack>
                            </HStack>
                        </ModalHeader>

                        <ModalBody >
                            <Stack borderRadius={'xl'} spacing={2} bgColor='white' >
                                <Stack alignItems={'center'} cursor='pointer'>
                                    {imageView.image ? (
                                        <Image w={'full'} src={imageView.image} alt='img' borderRadius={'md'} />
                                    ) : (
                                        <Skeleton w={'200px'} h='300px' borderRadius={'md'} />
                                    )}

                                </Stack>
                                <Stack px={3}>

                                    <Text textTransform={'capitalize'} fontWeight='bold' fontSize={'sm'} noOfLines={2}> {imageView["title-en"]?.en}</Text>
                                </Stack>

                                <Spacer />

                                <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                                    <Stack spacing={0} alignItems='flex-start' justifyContent={'flex-start'} >

                                        <Text textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>{t("price")}</Text>
                                        {/* <Text textTransform={'capitalize'} fontWeight='extrabold' color={'black'} fontSize={'md'}>{userSymbolCurrency} {formatFrice(x.price)}</Text> */}
                                        <Text textTransform={'capitalize'} fontWeight='bold' color={'black'} fontSize={'sm'}>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(imageView.price)))}</Text>
                                    </Stack>

                                    <Stack >

                                        <Stack spacing={0} alignItems='flex-end' justifyContent={'flex-end'}>
                                            <Text fontsize textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>Sold</Text>
                                            <Text textTransform={'capitalize'} color={'black'} fontWeight='bold' fontSize={'sm'}>{imageView.sold}</Text>
                                        </Stack>
                                    </Stack>


                                </SimpleGrid>

                            </Stack>
                        </ModalBody>

                        <ModalFooter>
                            <HStack spacing={5}>
                                <Button bgColor='green.400' onClick={() => handleNavigateProduct(imageView.id, '1688')}>
                                    <Text color={'white'}>🛒 {t("order_now")}</Text>
                                </Button>
                                <Button colorScheme='red' mr={3} onClick={() => setDetailModal(false)}>
                                    Close
                                </Button>
                            </HStack>
                        </ModalFooter>


                    </ModalContent>
                </Modal>
            )}
        </Stack>
    )
}

export default ProductListPage