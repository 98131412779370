import { Badge, Box, Button, Divider, Flex, Heading, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { HiOutlineBellAlert } from 'react-icons/hi2'
import { IoLogoWhatsapp } from 'react-icons/io5'
import ListingCard from '../../Components/Card/ListingCard'
import AuthContext from '../../Routes/hooks/AuthContext'
import { PriceTag } from '../../Utils/Helper'
import { FcApproval, FcBusinessContact } from 'react-icons/fc'
import { CarouselComponent } from '../../Components/Galleries/Carousel/CarouselComponent'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { auth } from '../../Config/firebase'
import moment from 'moment'


function BusinessPage() {



    const height = window.innerHeight

    const [detailModal, setDetailModal] = useState(false)
    const [detailActive, setDetailActive] = useState("")


    const { dataList } = useContext(AuthContext)

    const groupedData = dataList.reduce((groups, item) => {
        const category = item.category[0]
        if (!groups[category]) {
            groups[category] = [];
        }
        groups[category].push(item);
        return groups;
    }, {});


    const handleSubmit = () => {
        console.log('test')
    }

    const handleOpenModal = async (data) => {

        const updateData = {
            collection: "listings",
            docId: data.id,
            seen: auth?.currentUser?.uid || `visitor-${moment(new Date()).valueOf()}`

        }

        try {
            const res = await axios.post("https://deoapp-backend-awfnqqp6oq-et.a.run.app/last-seen", updateData)
            if(res){
                setDetailModal(true)
                setDetailActive(data)
                console.log("succes add seen")
            }
        } catch (error) {
            console.log(error, 'ini error')
        }
    }

    const handleCloseModal = () => {
        setDetailModal(false)
        setDetailActive("")
    }

    return (
        <Stack p={[1, 1, 5]} spacing={5}>
            <Stack px={[1, 1, 5]}>
                <Heading size={'md'} >Business Importir</Heading>
            </Stack>
            <Stack>
                <Stack overflowY={"scroll"} p={[1, 1, 5]}>
                    {Object.keys(groupedData).map((category, index) => (
                        <Stack key={index} spacing={2} py={2} alignItems='center' justifyContent={'center'}>
                            <Heading size={'md'} textTransform='uppercase'>{category}</Heading> {/* Judul kategori */}
                            <Divider />
                            <SimpleGrid columns={[1, 2, 4]} gap={3}>
                                {groupedData[category].map((x, itemIndex) => (
                                    <Stack key={itemIndex} cursor='pointer' onClick={() => handleOpenModal(x)}>
                                        <ListingCard listing={x} index={itemIndex} />
                                    </Stack>
                                ))}
                            </SimpleGrid>
                            <Divider />
                        </Stack>
                    ))}
                </Stack>
            </Stack>

            <Modal isOpen={detailModal} size='xl' onClose={handleCloseModal}>
                <ModalOverlay />
                <ModalContent w={'100%'}>
                    <ModalHeader>Detail</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <Box minHeight='90vh' pos='relative'>

                            <Stack w={'100%'}>
                                <Image src={detailActive?.image} aspectRatio={1 / 1} />
                            </Stack>

                            <Box m='2' p='2' w='100%'>
                                <Text>Start From</Text>
                                <HStack w='100%' >
                                    <Heading fontSize='lg'>{<PriceTag price={detailActive?.price} />}</Heading>
                                    {detailActive?.priceEnd &&
                                        <Flex align='center'>
                                            <Heading fontSize='lg' mr='2'>-</Heading>
                                            <Heading fontSize='lg'>{<PriceTag price={detailActive?.priceEnd} />}</Heading>
                                        </Flex>}
                                </HStack>
                            </Box>

                            <Divider />
                            <Stack py='5' px='2'>
                                <Flex justify={'space-between'}>
                                    <Heading>{detailActive?.title}</Heading>
                                    {detailActive?.logo &&
                                        <Image src={detailActive?.logo} aspectRatio={16 / 9} w='100px' h='50px' objectFit={'contain'} />
                                    }
                                </Flex>

                                <Text>{detailActive?.description}</Text>
                                <Stack>
                                    {detailActive?.category?.map((x, i) => (
                                        <Box key={i}>
                                            <Badge fontSize='2xs' mx='1' colorScheme='green'  >{x}</Badge>
                                        </Box>
                                    ))}
                                </Stack>
                            </Stack>

                            <Divider />

                            <SimpleGrid columns={2} m='2' my='4' >
                                {detailActive?.details?.map((x, i) =>
                                    <Flex boxShadow={'md'} borderRadius='md' m='1' p='1' key={i}>
                                        <Text pr='1' mt='2'>
                                            <FcApproval fontSize={'27px'} />
                                        </Text>
                                        <Spacer />
                                        <VStack spacing={2} justify={'left'} align={'left'} p='1.5'>

                                            <Text textTransform={'capitalize'} fontWeight={'medium'}>{x.key}</Text>
                                            {x?.value?.includes('https://') ?
                                                <Link onClick={() => window.open(x.value, '_blank')}>
                                                    <Text color='blue' >Click here</Text>
                                                </Link>
                                                :
                                                <Text overflowWrap={'break-word'} w={'148px'}>{x.value}</Text>

                                            }
                                        </VStack>
                                    </Flex>
                                )}
                            </SimpleGrid>

                        </Box>

                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button colorScheme='red' onClick={handleCloseModal}>
                                Close
                            </Button>
                            <Box position='sticky' bottom='0' width='full' p='2' >
                                <Link to={`https://api.whatsapp.com/send?phone=${detailActive?.contactPerson}&text=Halo, saya mendapatkan info *${detailActive?.title}* dari website *${detailActive?.projectName}*. Mohon informasi lebih lanjut untuk join kemitraan. Terima kasih.

                        :)`} target="_blank">

                                    <Button w='full' leftIcon={<IoLogoWhatsapp />} colorScheme='green'>  Contact seller</Button>
                                </Link>
                            </Box >
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Stack>
    )
}

export default BusinessPage