import { 
    Button, 
    Heading, 
    Flex, 
    Input, 
    Stack, 
    Card, 
    Text, 
    CardBody, 
    SimpleGrid, 
    Divider, 
    FormControl, 
    FormLabel, 
    Checkbox, 
    Link, 
    useToast,  
    Center,
    InputGroup,
    InputLeftAddon} from '@chakra-ui/react'
import React, { useState, useEffect, useContext } from 'react';

import _axios from '../../Api/AxiosBarrier';
import AuthContext from '../../Routes/hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import colors from '../../Utils/colors';
import ReCAPTCHA from "react-google-recaptcha"
import { MdEmail } from 'react-icons/md';
import { FaAddressCard, FaLock, FaLockOpen, FaPhoneAlt } from 'react-icons/fa';

function RegisterPage() {
    const { loadingShow, loadingClose, currentUser, login } = useContext(AuthContext);
    const toast = useToast({
        position: "top",
        align: "center",
    });

    const navigate = useNavigate();

    useEffect(() => {
        if(currentUser?.uid){
          return navigate('/')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        confirmation_password: "",
    });

    const createAccount = async () => {
        try {
            loadingShow();
            const resp = await _axios.post(`/api/register/free-member`, formData);
            loadingClose();
            if (resp.status) {
                login(formData.email, formData.password);
            } else {
                return toast({
                    title: "importir.com",
                    description: resp.message,
                    status: "error",
                });
            }
        } catch (error) {
            loadingClose();
            return toast({
                title: "importir.com",
                description: error,
                status: "error",
            });
        }
    }
    
    return (

    <Stack alignItems={'center'} justifyContent='center' w='full' bgColor={colors.theme} style={{ minHeight: '100vh' }} borderColor={'#ffd600'}>
        <Stack justifyContent='center' alignItems='center' spacing={5} pb={10} bgColor='blackAlpha.400' p={10} borderRadius='xl'>
            <Stack>
                <Heading>
                    <Center>
                    Register</Center>
                </Heading>
                <Divider borderStartColor={'#ffd600'} border={'1px'}/>
            </Stack>
            
            <Stack alignItems={'center'}>
                <InputGroup
                    w={{
                        base: "100%",
                        md: "285",
                    }}
                >
                    <InputLeftAddon
                        shadow={"md"}
                        children={
                            <FaAddressCard
                                name="email"
                                size={24}
                                color="black"
                            />}
                    />
                    <Input
                        w={{
                            base: "100%",
                            md: "100%",
                        }}
                        fontSize={"md"}
                        shadow={3}
                        bgColor={"white"}
                        color={colors.black}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                </InputGroup>
                <InputGroup
                    w={{
                        base: "100%",
                        md: "285",
                    }}
                >
                    <InputLeftAddon
                        shadow={"md"}
                        children={
                            <MdEmail
                                name="email"
                                size={24}
                                color="black"
                            />}
                    />
                    <Input
                        w={{
                            base: "100%",
                            md: "100%",
                        }}
                        type='email' 
                        placeholder='example@email.co'
                        fontSize={"md"}
                        shadow={3}
                        bgColor={"white"}
                        color={colors.black}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                </InputGroup>
                <InputGroup
                    w={{
                        base: "100%",
                        md: "285",
                    }}
                >
                    <InputLeftAddon
                        shadow={"md"}
                        children={
                            <FaPhoneAlt
                                name="email"
                                size={24}
                                color="black"
                            />}
                    />
                    <Input
                        w={{
                            base: "100%",
                            md: "100%",
                        }}
                        fontSize={"md"}
                        placeholder='Your phone number'
                        shadow={3}
                        bgColor={"white"}
                        color={colors.black}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    />
                </InputGroup>
                <InputGroup
                    w={{
                        base: "100%",
                        md: "285",
                    }}
                >
                    <InputLeftAddon
                        shadow={"md"}
                        children={
                            <FaLock
                                name="email"
                                size={24}
                                color="black"
                            />}
                    />
                    <Input
                        w={{
                            base: "100%",
                            md: "100%",
                        }}
                        fontSize={"md"}
                        type='password'
                        placeholder='Your password'
                        shadow={3}
                        bgColor={"white"}
                        color={colors.black}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    />
                </InputGroup>
                <InputGroup
                    w={{
                        base: "100%",
                        md: "285",
                    }}
                >
                    <InputLeftAddon
                        shadow={"md"}
                        children={
                            <FaLock
                                name="email"
                                size={24}
                                color="black"
                            />}
                    />
                    <Input
                        w={{
                            base: "100%",
                            md: "100%",
                        }}
                        fontSize={"md"}
                        type='password'
                        placeholder='Input your password again'
                        shadow={3}
                        bgColor={"white"}
                        color={colors.black}
                        onChange={(e) => setFormData({ ...formData, confirmation_password: e.target.value })}
                    />
                </InputGroup>
            </Stack>
            {/* <SimpleGrid>
                <Stack>
                    <FormControl>
                        <FormLabel>Name*</FormLabel>
                        <Input 
                            type='text' 
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                    </FormControl>
                </Stack>
                <Stack>
                    <FormControl>
                        <FormLabel>Email*</FormLabel>
                        <Input 
                            type='email' 
                            placeholder='example@email.co'
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </FormControl>
                </Stack>
                <Stack>
                    <FormControl>
                        <FormLabel>Phone*</FormLabel>
                        <Input 
                            type='text' 
                            placeholder='Your Phone Number'
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                    </FormControl>
                </Stack>
                <Stack>
                    <FormControl>
                        <FormLabel>Password*</FormLabel>
                        <Input 
                            type='password' 
                            placeholder='Password'
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        />
                    </FormControl>
                </Stack>
                <Stack>
                    <FormControl>
                        <FormLabel>Confirmation Password*</FormLabel>
                        <Input 
                            type='password' 
                            placeholder='Confirmation Password'
                            onChange={(e) => setFormData({ ...formData, confirmation_password: e.target.value })}
                        />
                    </FormControl>
                </Stack>
            </SimpleGrid> */}
            <Stack p={4} direction="column">
                <Flex alignItems="flex-start">
                    <Checkbox defaultChecked>I agree to the <span style={{color: 'blue'}}  fontWeight={'bold'}>Terms & Conditions</span></Checkbox>
                </Flex>
                <Flex justifyContent="center">
                    <Button
                        onClick={() => {createAccount()}} 
                        colorScheme='yellow'>
                        Create Account
                    </Button>
                </Flex>
                <Text fontWeight={'bold'} textAlign={'center'}>Already have an account? <Link onClick={() => navigate('/login')} style={{color: 'blue'}}>Login</Link></Text>
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible"/>
            </Stack>
        </Stack>
    </Stack>
    )
}

export default RegisterPage