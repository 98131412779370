import { Box, Heading, Text, Spacer, Link, Stack, HStack } from '@chakra-ui/react';

const ContactUs = () => {
  return (
    <Stack>
      <Stack p={5} mb={10} bgColor='white' spacing={3} shadow={'md'} borderRadius='md'>
        <Heading size="md" mb={4}>
          CONTACT US
        </Heading>
        <Box>
          <Text>Warehouse Indonesia: Sarah (+62 812-8892-0526)</Text>
          <Text>China Trip: Yudha (+62 812-8166-7786)</Text>
          <Text>WhatsApp Official: +62 878-0017-4596 WA Official IMPORTIR.ORG</Text>
          <Spacer mt={2} />
          <Text fontWeight={'bold'}>Customer Service Call: 02155714597</Text>
          <Spacer mt={2} />
          <Text>
            LIVE CHAT is available from 09.00-12.00 and 12.00-17.00 Monday - Friday (find the chatbox on the bottom page of your dashboard)
          </Text>
        </Box>
        <Spacer mt={6} />
        <Box>
          <Text fontWeight="bold">Address Guangzhou Warehouse:</Text>
          <Text>
            以下是我们的仓库代理，有关送货规定，地址和时间可以联系以下的联系人，
          </Text>
          <Text>
            其他的麻烦直接联系客户。
          </Text>
          <Spacer mt={2} />
          <Text>仓库地址: 广州市白云区白云湖街唐阁上村中街28号103B</Text>
          <Text>云诚达货运代理有限公司(原龙树实业有限公司 510000</Text>
          <Text>中国 联系人: 威力 18142827674 (微信，电话)</Text>
          <Text>印度尼西亚 联系人: Mr. Deandy // WeChat id: IMPORTIRORG_admin（仅微信）</Text>
          <Spacer mt={2} />
          <Text>收货时间: 周一到五 9.00 - 17.00</Text>
          <Text>周六 9.00 – 14.00</Text>
          <Spacer mt={2} />
          <Text>* 周天，及中国法定假日正常休假</Text>
          <Text>* 每一个箱子要用编织袋打包</Text>
          <Text>* 每一个箱子上必须贴客户的唛头 （唛头后面写上货物总数量）</Text>
          <Text>和海运代码（这些跟客户要）</Text>
          <Spacer mt={2} />
          <Text>* 如果唛头不正确本公司无法收货</Text>
          <Text>* 货物送到仓库必须带上装箱单和订单(超过一个箱子只需要在一个箱子上贴)</Text>
        </Box>
        <Spacer mt={6} />
        <Box>
          <Text fontWeight="bold">Warehouse Address:</Text>
          <Text>
            Guangzhou Baiyun District, Baiyunhu Avenue, Tangge Shangcun Middle Road No.28 Unit 103B Postal Code 510000
          </Text>
          <Text>Contact: Mr. Wei 18142827674 (Phone, Wechat)</Text>
          <Text>Mr. Deandy // WeChat id: IMPORTIRORG_admin (Wechat Only)</Text>
          <Spacer mt={2} />
          <Text>Working Hour: Mon – Fri, 9.00 - 17.00</Text>
          <Text>Sat, 9.00 – 14.00</Text>
          <Spacer mt={2} />
          <Text>* Closed on Sunday and Public Holidays</Text>
          <Text>* Every carton must be wrapped in a woven bag</Text>
          <Text>
            * It is compulsory to attach accurate MARKING CODE (please add total quantity behind the marking code) and SHIPPING CODE
          </Text>
          <Text>* Any wrong spelling will result in shipment delay even cancellation</Text>
          <Text>
            * It is compulsory to attach PACKING LIST and INVOICE on the carton sent to our warehouse (if more than one carton, only need to attach on one carton)
          </Text>
          <Spacer mt={6} />
          <Text fontWeight={'bold'}>
            Contoh
          </Text>
          <HStack>
            <Text>Invoice</Text>
            <Link color={'blue'} fontStyle='italic' href="https://d2jnbxtr5v4vqu.cloudfront.net/files/commercial-invoice-2017_12_22_15_38_21.xls" isExternal>
              (klik link ini untuk download)
            </Link>
            </HStack>
            <HStack>
              <Text>Packing List</Text>
            <Link color={'blue'} fontStyle='italic' href="https://d2jnbxtr5v4vqu.cloudfront.net/files/gaming-chair-packing-list-2017-2017-12-22-15-38-21-2017_12_24_10_56_49.xls" isExternal>
              (klik link ini untuk download)
            </Link>
          </HStack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ContactUs;