import axios from 'axios'

const TypesenseRestApi = async (data) => {
    const dataRes = await axios.get('https://8av36iqfzot4u2g5p-1.a1.typesense.net:443/collections/products/documents/search', {
        params: {
          q: data.q,
          query_by: data.query_by,
          filter_by: data.filter_by,
          sort_by: data.sort_by,
          page: data.page
        },
        headers: {
          'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API
        }
      })
    return dataRes.data
}

export default TypesenseRestApi