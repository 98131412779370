import React from 'react';
import { Box, Link, Icon, Image, HStack, Stack } from '@chakra-ui/react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../Routes/hooks/AuthContext';

const WhatsAppIcon = () => {
  const navigate = useNavigate();


  const handleClick = () => {
    const message = encodeURIComponent(`Hi Member Importir,\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
    const source = encodeURIComponent(window.location.href);
    const url = `https://api.whatsapp.com/send?phone=6287800174596&text=${message}%0A%0ASource:%20${source}`;
    window.open(url, '_blank');
  };

  return (
    <Link
      onClick={handleClick}
      position="fixed"
      bottom="20px"
      right="20px"
      zIndex="100"
      cursor="pointer"
    >
      <Icon as={FaWhatsapp} boxSize={50} color="white" bg="green.500" borderRadius="50%" p={2} />
    </Link>
  );
};

const InstagramIcon = () => {


  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/consultation');
  };

  return (
    <Stack
      onClick={handleClick}
      position="fixed"
      bottom="20px"
      left="20px"
      zIndex="100"
      cursor="pointer"
    >
      <Image src="https://firebasestorage.googleapis.com/v0/b/importir-com.appspot.com/o/Group%203242.png?alt=media&token=dda20e16-c02b-4758-ba23-12a1f5f26770" boxSize={[100, 130, 150]} color="white" borderRadius="50%" p={2} />
    </Stack>
  );
};

const AppFooterIcon = () => {

  const { userDb } = useContext(AuthContext)

  
  return (
    <HStack alignItems="center" justifyContent="center">
      <WhatsAppIcon />
      {userDb && (
        <InstagramIcon />
      )}
    </HStack>
  );
};

export default AppFooterIcon;
