import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Skeleton,
  SlideFade,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const AppImageCarousel = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) =>
        prevImage === images.length - 1 ? 0 : prevImage + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  const nextImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === images.length - 1 ? 0 : prevImage + 1
    );
  };

  const prevImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleRouteDashboard = (link) => {
    if(link !== "xxxx"){
      window.open(link, '_blank');
    }
  }

  const skeletonColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box position="relative">
      <Flex align="center" justify="center">
        <Button
          onClick={prevImage}
          position="absolute"
          top="50%"
          left="10px"
          transform="translateY(-50%)"
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
          _focus={{ boxShadow: "none" }}
        >
          <ChevronLeftIcon boxSize={6} color="gray.600" />
        </Button>
        <SlideFade in offsetX="20px" offsetY="0" transition={{ duration: 0.3 }}>
          <Box position="relative" width="100%">
            {isLoading ? (
              <Skeleton
                borderRadius="md"
                width="100%"
                height="400px"
                startColor={skeletonColor}
                endColor={skeletonColor}
              />
            ) : null}
            <Image
              onClick={() => handleRouteDashboard(images[currentImage]?.data)}
              src={images[currentImage]?.main_banner}
              alt="Carousel Image"
              h={["auto", "auto", "405px"]}
              w="100%"
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              display={isLoading ? "none" : "block"}
              cursor='pointer'
            />
          </Box>
        </SlideFade>
        <Button
          onClick={nextImage}
          position="absolute"
          top="50%"
          right="10px"
          transform="translateY(-50%)"
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
          _focus={{ boxShadow: "none" }}
        >
          <ChevronRightIcon boxSize={6} color="gray.600" />
        </Button>
      </Flex>
      <HStack  mt={-5} zIndex={2} position={'absolute'} w='100%' alignItems={'center'} justifyContent='center'>
        {images.length < 10 &&
          images.map((image, index) => (
            <Box
              key={index}
              w="6px"
              h="6px"
              shadow={'dark'} 
              borderRadius="full"
              bgColor={index === currentImage ? "gray.600" : "gray.300"}
              transition="background-color 0.3s ease"
              _hover={{ bgColor: "gray.600", cursor: "pointer" }}
              onClick={() => setCurrentImage(index)}
            ></Box>
          ))}
      </HStack>
    </Box>
  );
};

export default AppImageCarousel;
