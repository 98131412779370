/* eslint-disable react-hooks/exhaustive-deps */
import { 
    Box, Button, HStack, Stack, Table, Tbody, Text, Th, Thead, Tr, useToast, Grid, Td, Image, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure, Input, TableContainer, Icon, Badge } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { IoCaretBackOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import CustomStepperShipping from '../../Components/Stepper/CustomStepperShipping'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import { formatFrice } from '../../Utils/Helper'
import ShippingChatToAdmin from '../../Components/Chat/ShippingChatToAdmin'
import _axiosChina from '../../Api/AxiosChina'
import ConvertPrice from '../../Utils/ConvertPrice'
import store from 'store'
import { checkQuizOnboarding } from '../../Routes/UserMiddleware'
import { FaPrint } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { FcLink } from "react-icons/fc";

function DetailPage() {
    const {t} = useTranslation();
    const userDataLoc = store.get('user_api');
    useEffect(() => { 
        checkQuizOnboarding(userDataLoc?.detail?.must_answer);
    }, []);
    
    const { loadingShow, loadingClose, kursRates, userSymbolCurrency, userCurrency } = useContext(AuthContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const params = useParams()
    const toast = useToast({
		position: "top",
		align: "center",
	});
    const [shipping, setShipping] = useState()
    const [image, setImage] = useState('')
    const [title, setTitle] = useState('')
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedBill, setSelectedBill] = useState(null);
    const [editingData, setEditingData] = useState(null)
    const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])
    const [shippingId, setShippingId] = useState(0)
    const url = process.env.REACT_APP_API_COM
    const {convertPriceFix} = ConvertPrice()
    const user = store.get('user_api')
    const [shippingFile, setShippingFile] = useState([])
    const fetchShipping = async () => {
        try {
            loadingShow()
            const {status, data, message} = await _axios.get(`/api/shipping/${params.id}`)
            if (status === true) {
                setShipping(data)
                if (data?.statuses?.length > 0) {
                    const dataArr = data.statuses
                    setCurrentStep(data.statuses[0].name)
                    setMessageStep(data.statuses[0].message)
                    setShippingId(data.id)
                    setSteps(dataArr.reverse())
                }

            } else {
                Swal.fire({
                    title: 'Warning Message',
                    text: message,
                    icon: 'warning',
                    showCancelButton: false,
                })
            }
            loadingClose()
        } catch (error) {
            toast({
                title: 'importir.com',
                description: "error catch " + error,
                status: 'error',
            })
        }
    }

    const handleUploadImage = async (e) => {
        const uploadFile = e.target.files[0];
        try {
            const params = new FormData();
            params.append('file', uploadFile);
            loadingShow()
            const response = await _axios.post('api/storage', params);
            setImage(response.data)
            loadingClose()
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async () => {
        try {
            let response;
            const data = {
                name: title, 
                image: image
            };
    
            loadingShow(); 
            if (editingData) {
                response = await _axios.post(`/api/shipping/${editingData}/edit-file`, data);    
            } else {
                response = await _axios.post(`/api/shipping/${params.id}/additional-file`, data);
            }
    
            loadingClose(); 
    
            if (response.status) {
                window.location.reload()
            } else {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message || 'An error occurred',
                status: 'error',
            });
        }
    };

    const onDelete = async (item, index) => {
        try {
            loadingShow()
            const response = await _axios.delete(`/api/shipping/${item.id}/delete-file`)
            loadingClose()
            if (response.status) {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'success',
                });
                fetchShipping()
            } else {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const openDetailsModal = (bill) => {
        setSelectedBill(bill);
        setShowDetailsModal(true);
      };
    
      const closeDetailsModal = () => {
        setSelectedBill(null);
        setShowDetailsModal(false);
      };

    const handlePayLartas = async (invoice) => {
        const resp = await _axiosChina.get(`payment/shipping/v2/${invoice}/init-payment`)
        if (resp.status) {
            window.location.href = resp.data.redirect_url
        }
    }
    
    const caclPriceWithInvoiceRate = (invoice_id, total) => {
        let currency = 'RMB';
        if (user?.country_code === 'ph'){
            currency = 'PHP'
        }
        let gt = 0;
        shipping?.rates?.forEach((e,i) => {
            if (e.shipping_bill_id === invoice_id && e.from === currency){
                gt = total/e.rate;
            }
        })
        return gt;
    }

    const getShippingFile = async () => {
        try {
            const res = await _axios.get(`/api/shipping/${params.id}/fetch-file-shipping`)
            if (res.status === true) {
                setShippingFile(res.data)
            }  else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    useEffect(() => {
        fetchShipping()
        getShippingFile()
        return () => {
        }
    }, [])
  return (
    <Stack spacing={5} p={[1, 1, 5]} mt={[10, 10, 0]}>
        <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate("/shipping")}>
            <IoCaretBackOutline size={15} />
            <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
        </HStack>


        <Stack spacing={5} p={[1, 1, 5]}>
            <Stack bgColor={'white'} p={5} borderRadius='md' shadow={'md'}>
                <CustomStepperShipping steps={steps} currentStep={currentStep} message={messageStep} />
            </Stack>
            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('summary')}</Text>
                <Stack bgColor='white' spacing={4} borderRadius='xl' p={4} boxShadow='md' cursor='pointer'>
                    <Stack fontSize={{base: 'sm', sm: 'md', md: 'lg'}} spacing={2}>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                            <Box>
                                <Text fontWeight={'bold'} fontStyle={'italic'}>{`DEMO/REAL`}</Text>
                            </Box>
                            <Box >
                                <Text>{!shipping?.is_demo ? `REAL` : `DEMO`}</Text>
                            </Box>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} bgColor={"gray.200"} p={2}>
                            <Box>
                                <Text fontWeight={'bold'} fontStyle={'italic'}>{t('import_from')}</Text>
                            </Box>
                            <Box >
                                <Text>{shipping?.country === 'china'? '🇨🇳' : '🇹🇭'}</Text>
                            </Box>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                            <Box>
                                <Text fontWeight={'bold'} fontStyle={'italic'}>{t('shipping_number')}</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.shipping_number}</Text>
                            </Box>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} bgColor={"gray.200"} p={2}>
                            <Box>
                                <Text fontWeight={'bold'} fontStyle={'italic'}>{t('wooden_packing')}</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.is_wooden_frame === 0 ? 'No' : 'Yes'}</Text>
                            </Box>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                            <Box>
                                <Text fontWeight={'bold'} fontStyle={'italic'}>{t('note_to_admin')}</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.wooden_frame_note == null ? '-' : shipping.wooden_frame_note}</Text>
                            </Box>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' bgColor={"gray.200"} gap={6} p={2}>
                            <Box>
                                <Text fontWeight={'bold'} fontStyle={'italic'}>{t('shipping_by')}</Text>
                            </Box>
                            <Box>
                                <Text>{t(shipping?.freight?.freight_type)} - {shipping?.freight?.name}</Text>
                            </Box>
                        </Grid>
                        {
                            user?.country_code !== 'ph' ? 
                            <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                                <Box fontWeight='bold' fontStyle='italic'>
                                <Text>{shipping?.country === 'china' ? `${t('rate_to', {from: "RMB", to: "IDR"})}` : `${t('rate_to', {from: "RMB", to: "IDR"})}`}</Text>
                                </Box>
                                <Box>
                                <Text>Rp. {formatFrice(shipping?.country === 'china' ? kursRates?.rmb : kursRates?.thb)}</Text>
                                </Box>
                            </Grid>
                            : 
                            <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                                <Box fontWeight='bold' fontStyle='italic'>
                                <Text>{shipping?.country === 'china' ? 'Rate RMB to PHP'  : 'Rate THB to PHP'}</Text>
                                </Box>
                                <Box>
                                <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRates?.php))}</Text>
                                </Box>
                            </Grid>
                        }

                        <Grid templateColumns='repeat(2, 1fr)' bgColor={"gray.200"} gap={6} p={2}>
                            <Box>
                                <Text fontWeight={'bold'} fontStyle={'italic'}>{`${t('estimated_cbm')} / ${t('estimated_weight')}`}</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.volume_est} Cbm / {shipping?.weight_est} Kg</Text>
                            </Box>
                        </Grid>
                        {
                            user?.country_code === "id" ?
                                <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                                    <Box fontWeight={'bold'} fontStyle={'italic'}>
                                        <Text>{t('type')}</Text>
                                    </Box>
                                    <Box>
                                        <Text>{(shipping?.is_lartas === 1 || shipping?.is_lartas === true) ? 'Lartas' : 'Non Lartas'}</Text>
                                    </Box>
                                </Grid>
                            : ""
                        }
                        {
                            user?.country_code === "id" ?
                                <Grid templateColumns='repeat(2, 1fr)' bgColor={"gray.200"} gap={6} p={2}>
                                    <Box fontWeight={'bold'} fontStyle={'italic'}>
                                        <Text>{t('shipping_method')}</Text>
                                    </Box>
                                    <Box>
                                        <Text>{shipping?.shipping_method}</Text>
                                    </Box>
                                </Grid>
                            : ""
                        }
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                            <Box fontWeight={'bold'} fontStyle={'italic'}>
                                <Text>{t('last_status')}</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.last_status}</Text>
                            </Box>
                        </Grid>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            p={3}
                        >
                            <a href={`${url}frontend-shp-detail-generate-pdf/${params.id}`} target=''>
                                <Button colorScheme='blue' w={'30'}>
                                    <Icon as={FaPrint} w={4} h={4} color='black.500' /> {t('print_label')}
                                </Button>
                            </a>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('product')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <TableContainer width={'100%'}>
                        <Table variant='striped' colorScheme='teal'>
                            <Thead>
                            <Tr>
                                <Th textAlign={'center'}>{t('product_image')}</Th>
                                <Th textAlign={'center'}>{t('name')}</Th>
                                <Th textAlign={'center'}>Hscode</Th>
                                <Th textAlign={'center'}>{shipping?.country === 'china' ? 'Price/Pcs (RMB)' : 'Price/Pcs (THB)'}</Th>
                                <Th textAlign={'center'}>{t('quantity')}</Th>
                                <Th textAlign={'center'}>{shipping?.country === 'china' ? `${t('total')} (RMB)` : `${t('total')} (THB)`}</Th>
                                <Th textAlign={'center'}>{t('total')} ({userCurrency})</Th>
                                <Th textAlign={'center'}>{t('other')}</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                                {shipping?.products.map((x, i) => 
                                    <Tr key={i}>
                                        <Td>
                                            <Image src={x.image} height={'100'}></Image>
                                        </Td>
                                        <Td>{x.name}</Td>
                                        <Td>{x.hs_code}</Td>
                                        <Td>{shipping.country === 'china' ? '¥' : '฿'} {x.price / x.quantity}</Td>
                                        <Td>{x.quantity}</Td>
                                        <Td>{shipping.country === 'china' ? '¥' : '฿'} {x.price}</Td>
                                        <Td>{userSymbolCurrency} {formatFrice(convertPriceFix(parseInt(shipping.country === 'china' ? x.price * (kursRates?.rmb)  : x.price * (kursRates?.thb))))}</Td>
                                        <Td>

                                            {!x.is_batteray ? "" : ' Batteray '}
                                            {!x.is_liquid ? "" : ' Liquid '}
                                            {!x.is_msds ? "" : ' MSDS '}
                                        </Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('file_and_documents')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' overflowY={'auto'} p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray'>
                        <Tbody>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>{t('commercial_invoice_file')}</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    <a href={shipping?.commercial_file} target='_blank' rel="noreferrer">
                                        <Icon as={FcLink} w={10} h={10} />
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>{t('packing_list_file')}</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    <a href={shipping?.packing_list_file} target='_blank' rel="noreferrer">
                                        <Icon as={FcLink} w={10} h={10} />
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>{t('supplier_bank_info')}</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    <a href={shipping?.supplier_bank_file} target='_blank' rel="noreferrer">
                                        <Icon as={FcLink} w={10} h={10} />
                                    </a>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('additional_file')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <Button bgColor={'blue.500'} w={"15%"} textColor={'white'} onClick={() => { onOpen(); setTitle(''); setEditingData(null); }}>
                        +<Text display={{base: 'none', md:'block'}}>{t('file')}</Text>
                    </Button>
                    <Table variant='striped' colorScheme='gray' p={4}>
                        <Tbody>
                            {shipping?.shipping_additional_file.length > 0 && shipping?.shipping_additional_file.map((x, i) => {
                                return (
                                    <Tr>
                                        <Th fontSize='sm' textAlign='center'>{x?.name}</Th>
                                        <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                            <a href={x.image} target='_blank' rel="noreferrer">
                                                {x.image}
                                            </a>
                                        </Td>
                                        <Td>
                                            <Button bgColor={'orange.200'} onClick={() => { onOpen(); setTitle(x.name);
                                            setImage(x.image)    
                                            setEditingData(
                                                x.id
                                            )}} >
                                                {t('edit')}
                                            </Button>
                                            <Button colorScheme='red' ml={2} onClick={() => onDelete(x, i)}>
                                                {t('delete')}
                                            </Button>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('supplier_paid_file')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                <Table variant='striped' colorScheme='gray' p={4}>
                        <Tbody>
                            {shipping?.supplier_paid_file?.supplier_payment_files?.length > 0 && shipping?.supplier_paid_file?.supplier_payment_files?.map((x, i) => {
                                return (
                                    <Tr>
                                        <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                        <a href={x.link} target='_blank' rel="noreferrer">
                                                {x.link}
                                            </a>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('shipping_file')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                <Table variant='striped' colorScheme='gray' p={4}>
                        <Tbody>
                            {shippingFile?.length > 0 && shippingFile?.map((x, i) => {
                                return (
                                    <Tr>
                                        <Th fontSize='sm' textAlign='center'>{x.title}</Th>
                                        <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                        <a href={x.url} target='_blank' rel="noreferrer">
                                                {x.url}
                                            </a>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('shipping_address')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray' p={4}>
                        <Tbody>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>{shipping?.address?.receiver} - {shipping?.address?.phone}</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    {shipping?.address?.address}, {shipping?.address?.district}, {shipping?.address?.city}, {shipping?.address?.province}
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('invoice_list')}</Text>
                <Stack bgColor='white' spacing={1} overflowY={'auto'} borderRadius='xl' p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray'>
                        <Thead>
                            <Tr>
                                <Th fontSize='sm'>#</Th>
                                <Th fontSize='sm'>{t('invoice_number')}</Th>
                                <Th fontSize='sm'>Status</Th>
                                <Th fontSize='sm'>{t('status')}</Th>
                                <Th fontSize='sm'>{t('actions')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipping?.bills?.length > 0 && shipping?.bills?.map((x, index) => (
                                x.published_at ? ( 
                                    <Tr>
                                        <Td fontSize='sm'>{index + 1}</Td>
                                        <Td fontSize='sm'>{x?.name}</Td>
                                        <Td fontSize='sm'>{x?.paid_at ? <Badge colorScheme='purple'>Paid</Badge> : <Badge colorScheme='red'>Unpaid</Badge>}</Td>
                                        {
                                            userDataLoc === undefined ?
                                                <Td fontSize='sm'>{formatFrice(x?.total)}</Td>
                                            : 
                                                userDataLoc?.country_code === 'id' ?
                                                    <Td fontSize='sm'>{formatFrice(x?.total)}</Td>
                                                :
                                                    <Td>PHP {formatFrice(caclPriceWithInvoiceRate(x?.id, x?.total))}</Td>
                                        }
                                        {/* <Td fontSize='sm'>{formatFrice(x.total)}</Td> */}
                                        <Td fontSize='sm'>
                                            <Button colorScheme='green' onClick={() => openDetailsModal(x)}>Show All </Button> 
                                            {
                                                user?.country_code === "id" ?
                                                    !shipping?.is_lartas ? (
                                                        <Button colorScheme='blue' ml={'4'} onClick={() => navigate(`/shipping/payment/${x?.invoice}`)}>Detail</Button>
                                                    ) : (
                                                        <Button colorScheme='blue' ml={'4'} onClick={() => handlePayLartas(x?.invoice)}>Detail</Button>
                                                    )
                                                : 
                                                    <Button colorScheme='blue' ml={'4'} onClick={() => navigate(`/shipping/payment/${x?.invoice}`)}>Detail</Button>
                                            }
                                            
                                            <Button colorScheme='orange' ml={'4'} onClick={() => navigate(`/shipping/escrow/${x?.id}`)}>Escrow</Button>
                                        </Td>
                                    </Tr>
                                ) : null
                            ))}
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>{t('chat_to_admin')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <ShippingChatToAdmin shippingId={shippingId}/>
                </Stack>
            </Stack>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{editingData ? "Edit Additional File" : "Add Additional File"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={3}>
                        <Text>Title</Text>
                        <Input type="text" variant='outline' placeholder='Please input title' defaultValue={title} onChange={(e) => setTitle(e.target.value)}/>

                        <Text>Image</Text>
                        <Input type="file" variant='outline' onChange={(e) => handleUploadImage(e)}/>

                        <Stack direction='row'>
                            {image !== '' ? 
                                <Image 
                                    boxSize='150px'
                                    objectFit='cover'
                                    src={image}
                                    alt={title}
                                />
                                :
                                <Text></Text>
                            }
                        </Stack>
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' onClick={() => onSubmit()}>{editingData ? "Save Changes" : "Submit"}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

        {/* Bills Modal */}

        <Modal isOpen={showDetailsModal} size={'4xl'} onClose={closeDetailsModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Bills Detail</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Table variant='striped' colorScheme='gray' p={4}>
                        <Tbody>
                            {selectedBill?.details?.length > 0 && selectedBill?.details.map((x, i) => {
                                return (
                                    <Tr>
                                        <Th fontSize='sm'>{x?.title}</Th>
                                        <Td fontSize='sm'>
                                            {userSymbolCurrency} {formatFrice(convertPriceFix(x?.amount))}
                                        </Td>
                                    </Tr>
                                )
                                
                            })

                            }
                            <Tr>
                                <Td fontSize='xl' fontWeight={'bold'} textAlign={'center'}>TOTAL</Td>
                                <Td fontSize='xl' w={'14'}>
                                {userSymbolCurrency} {formatFrice(convertPriceFix(selectedBill?.total))}
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={closeDetailsModal}>
                    Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Stack>
  )
}

export default DetailPage