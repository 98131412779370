
import { Box, SimpleGrid, Stack, Heading, Card, CardHeader, CardBody, CardFooter, Image, Text, Divider, Button, ButtonGroup, useToast, Spacer } from '@chakra-ui/react'
import React, { useState, useEffect, useContext } from 'react'
import _axios from '../../Api/AxiosBarrier'
import { readMore } from '../../Utils/Helper'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../Routes/hooks/AuthContext'
import store from 'store'

function AcademyCourseList() {
    const navigate = useNavigate()
    const [course, setCourse] = useState([])

    const { loadingShow, loadingClose } = useContext(AuthContext)

    const height = window.innerHeight
    const width = window.innerWidth

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const getListAcademy = async () => {
        loadingShow()
        try {
            const packageUser = store.get('package_user')
            const res = await _axios.get('api/academy?id=39,41,42,43,44,45,46,50,51,53,57')
            if (packageUser.package.id === 8) {
                setCourse(res.filter(item => [39, 41, 45, 44, 43, 46].includes(item.id)))
            } else {
                setCourse(res)
            }
        loadingClose()
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
            loadingClose()
        }
        loadingClose()

    }

    useEffect(() => {
        getListAcademy()

        return () => {
        }
    }, [])
    return (
        <Stack p={[1, 1, 5]}>
            <Stack  px={[1, 1, 5]}>
            <Heading size={'md'}>
                Courses Lists
            </Heading>
            </Stack>
            <Stack p={[1, 1, 5]}>
            <SimpleGrid columns={[1, 2, 4]} gap={5}>
                {course.length > 0 ?
                    course.map((item, key) => {
                        return (
                            <Box>
                                <Card maxW='sm' h={'500px'} bg={'white'}>
                                    <CardBody>
                                        <Stack>
                                            <Stack>
                                                <Image
                                                    src={'https://d2jnbxtr5v4vqu.cloudfront.net/images/' + item.image}
                                                    alt='Green double couch with wooden legs'
                                                    borderRadius='md'
                                                    shadow={'md'}
                                                />
                                            </Stack>
                                            <Spacer />
                                            <Stack spacing='3'>
                                                <Heading size='sm' noOfLines={2}>{(item.title)}</Heading>
                                                <SimpleGrid columns={2}>
                                                    <Box>
                                                        <Text color={'green'}>
                                                            Total Lessons: {item.academy_details.length}
                                                        </Text>
                                                    </Box>
                                                </SimpleGrid>
                                            </Stack>
                                        </Stack>
                                    </CardBody>
                                    <Divider />
                                    <CardFooter>
                                        <ButtonGroup spacing='2'>
                                            <Button size={'sm'} variant='solid' colorScheme='blue' onClick={() => navigate('/courses/' + item.id)}>
                                                See Detail
                                            </Button>
                                        </ButtonGroup>
                                    </CardFooter>
                                </Card>
                            </Box>
                        )
                    })
                    :
                    <Stack h={height/1.5} w={width} alignItems='center' justifyContent={'center'}>
                    <Text color={'gray.600'} >Data Not Found</Text>
                    </Stack>
                }
            </SimpleGrid>
            </Stack>
        </Stack>
    )
}

export default AcademyCourseList