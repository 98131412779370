// File: utils/gtm.js

import { GTM_ID } from "../Config/gtmConfig";

const your_user_id = "nilai_user_id_anda"; // Gantilah 'nilai_user_id_anda' dengan nilai yang sesuai


export const initGTM = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
      'your_user_id': your_user_id, // Tambahkan your_user_id ke dalam dataLayer
    });
  }
};

export const trackEventGTM = (eventName, ecommerce) => {
  const data = {
    event: eventName,
    ecommerce: ecommerce,
    'your_user_id': your_user_id, // Tambahkan your_user_id ke dalam dataLayer

  };

  // Panggil fungsi untuk mengirim data ke Google Tag Manager
  sendToGTM(data);
};

const sendToGTM = (data) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  }
};

export const gtmConfig = (user) => {
  if (window.gtag) {
    window.gtag("config", 'GTM-5PT8VTL', {
      'user_id': user
    });
  }
};

export const sendEcommerceEvent = (eventName, ecommerceData) => {
  if (window.gtag) {
    try {
      window.gtag("event", eventName, {
        ecommerce: {
          ...ecommerceData,
        },
      });
    } catch (error) {
      console.log(error, 'ini error')
    }

  }
};

// Export GTM ID agar bisa digunakan pada komponen lain jika diperlukan.
export const gtmID = GTM_ID;
