import { 
    Box, HStack, Stack, Table, Tbody, Text, Th, Thead, Tr, Grid, Td, Image, TableContainer } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { IoCaretBackOutline } from 'react-icons/io5'
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice'
import colors from '../../Utils/colors'
import { useNavigate, useParams } from 'react-router-dom'
import CustomStepperOldSHP from '../../Components/Stepper/CustomStepperOldSHP';


function OldSHPDetail() {
    const {loadingShow, loadingClose, kursRates, userSymbolCurrency} = useContext(AuthContext)
    const params = useParams()
    const {convertPriceFix} = ConvertPrice()
    const navigate = useNavigate()
    const [shipping, setShipping] = useState()
    const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])

    const fetchData = async () => {
        try {
            loadingShow()
            const res = await _axios.get(`api/shipping/${params.id}/detail-old-shp`)
            loadingClose()
            setShipping(res[0])
            if (res[0]?.shipping_statuses?.length > 0) {
                const dataArr = res[0].shipping_statuses
                setCurrentStep(res[0].shipping_statuses[0].title)
                setMessageStep(res[0].shipping_statuses[0].message)
                setSteps(dataArr)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData();
    
    }, []);
  return (
    <Stack spacing={2} p={[1, 1, 5]}>
        <HStack cursor='pointer' w='100px'mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
            <IoCaretBackOutline size={15} />
            <Text fontSize={'xs'} letterSpacing={0.5}>Back</Text>
        </HStack>


        <Stack spacing={5} p={[1, 1, 5]}>
            <Stack bgColor={'white'} p={5} borderRadius='md' shadow={'md'}>
                <CustomStepperOldSHP steps={steps} currentStep={currentStep} message={messageStep} />
            </Stack>
            <Stack>
                <Text fontSize='xl' fontWeight='bold'>Summary</Text>
                <Stack bgColor='white' spacing={4} borderRadius='xl' p={4} boxShadow='md' cursor='pointer'>
                    <Stack spacing={2} fontSize={'lg'}>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} bgColor={"blue.200"} p={2}>
                            <Box>
                                <Text>Demo/Real</Text>
                            </Box>
                            <Box >
                                <Text>{!shipping?.is_demo == 1 ? 'Real' : 'Demo'}</Text>
                            </Box>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                            <Box>
                                <Text>Shipping Number</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.invoice_no}</Text>
                            </Box>
                        </Grid>
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} bgColor={"blue.200"} p={2}>
                            <Box>
                                <Text>Wooden Frame</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.use_wood_pack === 0 ? 'No' : 'Yes'}</Text>
                            </Box>
                        </Grid>
                            {
                                shipping?.user?.country_code !== 'ph' ?
                                    <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                                        <Box>
                                            <Text>Rate RMB to IDR</Text>
                                        </Box>
                                        <Box>
                                            <Text>Rp. {formatFrice(kursRates?.rmb)}</Text>
                                        </Box>
                                    </Grid>
                                :
                                    <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                                        <Box>
                                            <Text>Rate RMB to PHP</Text>
                                        </Box>
                                        <Box>
                                            <Text>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRates?.php))}</Text>
                                        </Box>
                                    </Grid>
                            }
                        {
                            shipping?.user?.country_code === "id" ?
                                <Grid templateColumns='repeat(2, 1fr)' bgColor={"blue.200"} gap={6} p={2}>
                                    <Box>
                                        <Text>Local Logistic</Text>
                                    </Box>
                                    <Box>
                                        <Text>{shipping?.logistic !== null ? shipping?.logistic : '-'}</Text>
                                    </Box>
                                </Grid>
                            : ""
                        }
                        <Grid templateColumns='repeat(2, 1fr)' gap={6} p={2}>
                            <Box>
                                <Text>Last Status</Text>
                            </Box>
                            <Box>
                                <Text>{shipping?.shipping_statuses[0].title}</Text>
                            </Box>
                        </Grid>
                    </Stack>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>Products</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <TableContainer width={'100%'}>
                        <Table variant='striped' colorScheme='teal'>
                            <Thead>
                            <Tr>
                                <Th textAlign={'center'}>#</Th>
                                <Th textAlign={'center'}>Image</Th>
                                <Th textAlign={'center'}>Name</Th>
                                <Th textAlign={'center'}>Hscode</Th>
                                <Th textAlign={'center'}>Quantity</Th>
                                <Th textAlign={'center'}>Restricted and Prohibited</Th>
                                <Th textAlign={'center'}>Product Contains</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                                {shipping?.product.map((x, i) => 
                                    <Tr key={i}>
                                        <Td>{i + 1}</Td>
                                        <Td>
                                            <Image src={`https://d2jnbxtr5v4vqu.cloudfront.net/images/${x.image}`} height={'32'} w={'32'}></Image>
                                        </Td>
                                        <Td>{x.name}</Td>
                                        <Td>{x.hs_code}</Td>
                                        <Td>{x.quantity}</Td>
                                        <Td>{x.is_lartas}</Td>
                                        <Td>{x.contains === null ? '-' : x.contains}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>File & Documents</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' overflowY={'auto'} p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray'>
                        <Tbody>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>Invoice File</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    <a href={`https://d2jnbxtr5v4vqu.cloudfront.net/files/${shipping?.invoice_file}`} target='_blank'>
                                        {`https://d2jnbxtr5v4vqu.cloudfront.net/files/${shipping?.invoice_file}`}
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>Packing List File</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    <a href={`https://d2jnbxtr5v4vqu.cloudfront.net/files/${shipping?.packing_list_file}`} target='_blank'>
                                        {`https://d2jnbxtr5v4vqu.cloudfront.net/files/${shipping?.packing_list_file}`}
                                    </a>
                                </Td>
                            </Tr>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>Supplier Bank Info</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    <a href={`https://d2jnbxtr5v4vqu.cloudfront.net/files/${shipping?.supplier_bank_info}`} target='_blank'>
                                        {`https://d2jnbxtr5v4vqu.cloudfront.net/files/${shipping?.supplier_bank_info}`}
                                    </a>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>Shipping Address</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray' p={4}>
                        <Tbody>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>{shipping?.user?.name} - {shipping?.user?.phone}</Th>
                                <Td fontSize='sm' textAlign='center' textColor={'blue.400'}>
                                    {shipping?.address}, {shipping?.district}, {shipping?.city}, {shipping?.province}
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='xl' fontWeight='bold'>Bill</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray'>
                        <Thead>
                            <Tr>
                                <Th fontSize='sm'>#</Th>
                                <Th fontSize='sm'>Invoice name</Th>
                                <Th fontSize='sm'>Total</Th>
                                <Th fontSize='sm'>Paid</Th>
                                <Th fontSize='sm'>Due Date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipping?.bill?.length > 0 && shipping?.bill?.map((x, index) => (
                                <Tr>
                                    <Td fontSize='sm'>{index + 1}</Td>
                                    <Td fontSize='sm'>{x.title}</Td>
                                    <Td>{userSymbolCurrency} {formatFrice(convertPriceFix(x.amount))}</Td>
                                    <Td fontSize='sm'>{x.paid_at ? <AiOutlineCheck /> : <AiOutlineClose />}</Td>
                                    <Td fontSize='sm'>{x.due_date}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>
        </Stack>
    </Stack>
  )
}

export default OldSHPDetail