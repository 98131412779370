import React from 'react';
import { Box, Divider, Flex, HStack, Spacer, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import CopyToClipboard from '../../Utils/CopyToClipboard';
import moment, { max } from 'moment';

const stepsDummy = [
  { title: 'PENDING REQUEST', description: 'Customer has paid' },
  { title: 'IN PROCESS', description: 'Supplier has paid' },
  { title: 'DONE', description: 'In warehouse' },
];

const Step = ({ title, description, isActive, isCompleted, lastStep }) => {
  return (
    <Flex align="center">
      <Box
        w="24px"
        h="24px"
        borderRadius="full"
        bg={isActive || isCompleted ? 'red.500' : 'gray.300'}
        mr={4}
        transition="background-color 0.3s"
      >
        {isCompleted && (
          <Text color="white" fontSize="sm" textAlign="center" mt="2px">
            ✓
          </Text>
        )}
      </Box>
      <Box>
        <Text fontWeight={isActive ? 'bold' : 'normal'} textTransform='uppercase'>{title}</Text>
        <Text fontSize="xs">{description ? moment(description).format('LLL') : ''}</Text>
        <Text fontSize="xs" fontStyle={'italic'} fontWeight={'bold'}>{ lastStep === title ? moment(description).subtract('YYYYMMDD').fromNow() : '' }</Text>
      </Box>
    </Flex>
  );
};

const CustomStepperSFY = ({ currentStep, message, steps }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getStepIndex = () => {
    return steps.findIndex((step) => step.title === currentStep);
  };

  return (
    <Stack>
      <Flex direction={isMobile ? 'column' : 'row'}>
        {steps.length > 0 ? (
          
            steps.map((step, index) => (
              <React.Fragment key={index}>
                {index !== 0 && !isMobile && (
                  <Box
                    flex="1"
                    h="2px"
                    bg={index <= getStepIndex() ? 'red.500' : 'gray.300'}
                    alignSelf="center"
                    transition="background-color 0.3s"
                  />
                )}
                <Stack>
                  <Step
                    title={step.title}
                    description={step.created_at}
                    isActive={step.title === currentStep}
                    isCompleted={index < getStepIndex()}
                    lastStep={currentStep}
                  />

                </Stack>
              </React.Fragment>
            ))
          
        ) : (
          stepsDummy.map((step, index) => (
            <React.Fragment key={index}>
              {index !== 0 && !isMobile && (
                <Box
                  flex="1"
                  h="2px"
                  bg={index <= getStepIndex() ? 'red.500' : 'gray.300'}
                  alignSelf="center"
                  transition="background-color 0.3s"
                />
              )}
              <Stack>
                <Step
                  title={step.title}
                  description={step.description}
                  isActive={step.title === currentStep}
                  isCompleted={index < getStepIndex()}
                />
  
              </Stack>
            </React.Fragment>
          ))
        )}

      </Flex>
      <Divider py={1} />
      <HStack>
        <Text fontSize={'sm'} color='gray.600'>Message :</Text>
        <Text fontSize={'sm'}>{message}</Text>
        <Spacer />
        <CopyToClipboard text={message} />
      </HStack>
    </Stack>
  );
};

export default CustomStepperSFY;