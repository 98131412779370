import { 
    VStack, 
    Heading, 
    Divider, 
    Text, Link, Stack, UnorderedList, ListItem
 } from '@chakra-ui/react'
import React, {  useEffect} from 'react'
import { useTranslation } from 'react-i18next'

function RefundPolicyPage() {
    const {t} = useTranslation();
    useEffect(() => {
    }, [])

    return (
        <>
            <VStack p={[1, 1, 5]} bgColor="white" shadow="md" borderRadius="md">
                <Heading size="xl" textAlign="center" my={5} color="green.300">
                    {t('refund_policy')}
                </Heading>
                <Divider />
                <Stack mt="8">
                    <Heading mb="4">{t("refund_policy_page.0")}</Heading>

                    {/* Section 1: Unboxing Video */}
                    <Text mb="4">1. {t("refund_policy_page.1")}</Text>

                    {/* Section 2: Contacting Support */}
                    <Text dangerouslySetInnerHTML={{ __html: t('refund_policy_page.2', { link: '<a href="https://wa.me/087800174596" style="color: blue; font-weight: bold;">0878 0017 4596</a>' }) }} />
                    {/* <Text mb="4">2. Informasikan kendala ke whatsapp Official IMPORTIR.COM <Link color="blue.500" href="https://wa.me/087800174596" isExternal>0878 0017 4596</Link> </Text> */}

                    {/* Section 3: Google Form */}
                    <Text mb="4">3. {t("refund_policy_page.3")}</Text>

                    {/* Additional Sections ... */}

                    {/* SYARAT DAN KETENTUAN PENGAJUAN KOMPLAIN */}
                    <Heading mt="6" mb="4" size="lg">{t("refund_policy_page.4")}</Heading>

                    {/* Condition 1 */}
                    <Text mb="4">1. {t("refund_policy_page.5")}</Text>

                    {/* Condition 2 */}
                    <Text mb="4">2. {t("refund_policy_page.6")}</Text>

                    <Text mb="4">3. {t("refund_policy_page.7")}</Text>
                    
                    <Text mb="4">4. {t("refund_policy_page.8")}</Text>
                    
                    <Text mb="4">5. {t("refund_policy_page.9")}</Text>
                    
                    <Text mb="4">6. {t("refund_policy_page.10")}</Text>
                    
                    <Text mb="4">7. {t("refund_policy_page.11")}</Text>
                    
                    <Text mb="4">8. {t("refund_policy_page.12")}</Text>
                    
                    <Text mb="4">9. {t("refund_policy_page.13")}</Text>


                    <Heading mt="6" mb="4" size="lg">{t('refund_policy_page.14')}:</Heading>

                    <Text mb="4">1. {t('refund_policy_page.15')}</Text>
                    <Text mb="4">2. {t('refund_policy_page.16')}</Text>
                    <Text mb="4">3. {t('refund_policy_page.17')}</Text>
                    <Text mb="4">4. {t('refund_policy_page.18')}</Text>
                    <Text mb="4">5. {t('refund_policy_page.19')}</Text>


                    <Heading mt="6" mb="4" size="lg">{t('refund_policy_page.20')}:</Heading>
                    <Text mb="4">1. {t('refund_policy_page.21')}</Text>
                    <Text mb="4">2. {t('refund_policy_page.22')}</Text>
                    <Text mb="4">3. {t('refund_policy_page.23')}</Text>
                    <Text mb="4">4. {t('refund_policy_page.24')}</Text>
                    <Text mb="4">5. {t('refund_policy_page.25')}</Text>

                    <Heading mt="6" mb="4" size="lg">{t('refund_policy_page.26')}</Heading>
                    <Text mb="4">
                        1. {t('refund_policy_page.27')}:
                        <UnorderedList>
                            <ListItem mb="4">{t('refund_policy_page.28')}</ListItem>
                            <ListItem mb="4">{t('refund_policy_page.29')}</ListItem>
                            <ListItem mb="4">{t('refund_policy_page.30')}</ListItem>
                            <ListItem mb="4">{t('refund_policy_page.31')}</ListItem>
                            <ListItem mb="4">{t('refund_policy_page.32')}</ListItem>
                            <ListItem mb="4">{t('refund_policy_page.33')}</ListItem>
                        </UnorderedList>
                    </Text>
                    <Text mb="4">2. {t('refund_policy_page.34')}</Text>


                    {/* Footer */}
                    <Text mt="8">{t('thank_you')}</Text>
                    <Text>Email: <Link color="blue.500" href="mailto:info@importir.org">info@importir.org</Link></Text>
                    <Text>Whatsapp: <Link color="blue.500" href="https://wa.me/087800174596" isExternal>0878-0017-4596</Link></Text>
                </Stack>
            </VStack>
        </>
    )
}

export default RefundPolicyPage