import { Box, Button, HStack, Icon, Image, Stack, Text, useToast} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { IoCaretBackOutline } from 'react-icons/io5'
import _axios from '../../Api/AxiosBarrier'
import { errorSlack } from '../../Api/SlackApi'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import { useNavigate, useParams } from 'react-router-dom'
import { FaCreditCard, FaDollarSign } from 'react-icons/fa'
import { formatPrice } from '../../Utils/Helper'
import { useTranslation } from 'react-i18next'
function OrderDetailPage() {
    const {t} = useTranslation();
    const params = useParams()
    const navigate = useNavigate()
    const path = window.location.href ?? '#'
    const toast = useToast({
        position: "top",
        align: "center",
    });

    const { loadingShow, loadingClose, userDb } = useContext(AuthContext)
    const [detailOrder, setDetailOrder] = useState('')
    const [details, setDetails] = useState([])

    const getDataDetail = async () => {
        if (params.id !== undefined) {
            try {
                loadingShow()
                const res = await _axios.get(`api/voucher/order/${params.id}/detail`)
                console.log("res", res)
                if (res.status === true) {
                    setDetailOrder(res.data)
                    setDetails(res.data.order_details)
                }
                loadingClose()
            } catch (error) {
                console.log(error)
                const res = await errorSlack(error, 'importir.com', path)
                console.log(res, 'CONNECTED ERROR IN SLACK')
                loadingClose()
            }
        }
    }

    const makeOrder = async (inv) => {
        try {
            loadingShow()
            const res = await _axios.post('api/make-payment-voucher', {
                "invoice_no" : inv,
                'type': 'xendit'
            })
            loadingClose()
            if (res.status) {
                console.log("res", res.data.payload.data.invoice_url)
                window.location.href = res.data.payload.data.invoice_url
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message  ,
                    status: 'error'
                })
            }
        } catch (error) {
            console.log(error)
            const res = await errorSlack(error, 'importir.com', path)
            console.log(res, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
    }

    useEffect(() => {
        if (userDb !== undefined) {
            const currentDate = new Date();
            const expirationDate = new Date(userDb?.data_importir?.package_user?.expired_at);

            if (currentDate < expirationDate) {
                toast({
                    title: 'importir.com',
                    description: "You don't allow to access this page",
                    status: 'error'
                })
                setTimeout(function() {
                    window.location.href = '/profile' 
                }, 2000);
            } else {
                getDataDetail()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDb])

    return (
        <Stack spacing={2} p={[1, 1, 5]}>
            <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                <IoCaretBackOutline size={15} />
                <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
            </HStack>


            <Stack spacing={5} p={[1, 1, 5]}>
                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('order_details')}</Text>
                    
                    <Stack bgColor='white' spacing={2} borderRadius='xl' p={4} boxShadow='md' direction={['column', 'column', 'row']}>
                        <Stack spacing={2}>
                            <Text fontWeight='bold' fontSize='sm'>
                                {t('invoice_number')}: {detailOrder?.invoice_no}
                            </Text>
                            <Text fontWeight='bold' fontSize='sm'>
                                {t('total')}: {formatPrice(detailOrder?.total)}
                            </Text>
                            <Text fontWeight='bold' fontSize='sm'>
                                Status: {detailOrder?.paid_at ? `${t('paid')}` : `${t('unpaid')}`}
                            </Text>
                        </Stack>
                    </Stack>
                    {details.map((item, index) => (
                        <Stack bgColor='white' spacing={2} borderRadius='xl' p={4} boxShadow='md' direction={['column', 'column', 'row']}>
                            <Box alignItems='center' justifyContent='center'>
                                <Image src={item?.voucher?.image} alt={item?.voucher?.title} w='25%' borderRadius='xl' />
                            </Box>
                            <Stack spacing={2}>
                                <Text fontWeight='bold' fontSize='sm' noOfLines={2}>
                                    Voucher: {item?.voucher?.title}
                                </Text>
                                <Text color='gray.700' fontSize='sm'>
                                    {t('price')}: {formatPrice(item?.voucher?.price)}
                                </Text>
                                <Text color='gray.700' fontSize='sm'>
                                    Tag: {item?.voucher?.tag}
                                </Text>
                            </Stack>
                        </Stack>
                    ))}

                    {!detailOrder?.paid_at ? 
                    <Button bgColor={'blue.900'} textColor={'white'} mr={2} onClick={() => makeOrder(detailOrder.invoice_no)} className='detail'>
                        <Icon as={FaCreditCard} w={4} h={4} color='black.500' />&nbsp;{t('pay')}
                    </Button>
                    : ""}
                </Stack>
            </Stack>
        </Stack>

    )
}

export default OrderDetailPage