import { Stack, Text, Box, Flex } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
function CountdownBill({ startDate, endDate }) {
  const {t} = useTranslation();
  const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
  });
  const [countdownText, setCountdownText] = useState("");
  const [day, setDay] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
        const start = new Date(startDate).getTime();
        const end = new Date(endDate).getTime();
        const totalSeconds = (end - Date.now()) / 1000;

        if (totalSeconds <= 0) {
            clearInterval(interval);
            setCountdownText("Crowdfund has ended");
        } else {
            const days = Math.floor(totalSeconds / 86400);
            const hours = Math.floor((totalSeconds % 86400) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = Math.floor(totalSeconds % 60);

            setTimeLeft({ days, hours, minutes, seconds });
            setCountdownText(
            `${days}  ${hours}  ${minutes}  ${seconds} `
            );
            setDay(days)
            setHours(hours)
            setMinutes(minutes)
            setSeconds(seconds)
            if(days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                window.location.reload()
            }
        }
    }, 1000);

    return () => {
        clearInterval(interval);
    };
  }, [startDate, endDate]);
  return (
        <Stack
            mt={2}
            textAlign={"center"}
            p={2}
        >
            <Flex gap={'5'} justifyContent={'center'} alignItems={'center'}>
                <Box bg={'#ffd600'} borderRadius={'full'} border={'1px'} borderColor={'black'} w={{base:'14', md: '24'}} h={{base:'14', md: '24'}}>
                    <Text mt={{base:'1', md: '6'}} fontWeight={'bold'} fontSize={{base: 'md', md: 'lg'}}>
                        {day}
                    </Text>
                    <Text fontWeight={'bold'} fontSize={{base: 'xs', md: 'sm'}}>
                        {t('days')}
                    </Text>
                </Box>
                <Box>
                    <Text mt={''}>:</Text>
                </Box>
                <Box bg={'#ffd600'} borderRadius={'full'} border={'1px'} borderColor={'black'} w={{base:'14', md: '24'}} h={{base:'14', md: '24'}}>
                    <Text mt={{base:'1', md: '6'}} fontWeight={'bold'} fontSize={{base: 'md', md: 'lg'}}>
                        {hours}
                    </Text>
                    <Text fontWeight={'bold'} fontSize={{base: 'xs', md: 'sm'}}>
                        {t('hours')}
                    </Text>
                </Box>
                <Box>
                    <Text mt={''}>:</Text>
                </Box>
                <Box bg={'#ffd600'} borderRadius={'full'} border={'1px'} borderColor={'black'} w={{base:'14', md: '24'}} h={{base:'14', md: '24'}}>
                    <Text mt={{base:'1', md: '6'}} fontWeight={'bold'} fontSize={{base: 'md', md: 'lg'}}>
                        {minutes}
                    </Text>
                    <Text fontWeight={'bold'} fontSize={{base: 'xs', md: 'sm'}}>
                        {t('minutes')}
                    </Text>
                </Box>
                <Box>
                    <Text mt={''}>:</Text>
                </Box>
                <Box bg={'#ffd600'} borderRadius={'full'} border={'1px'} borderColor={'black'} w={{base:'14', md: '24'}} h={{base:'14', md: '24'}}>
                    <Text mt={{base:'1', md: '6'}} fontWeight={'bold'} fontSize={{base: 'md', md: 'lg'}}>
                        {seconds}
                    </Text>
                    <Text fontWeight={'bold'} fontSize={{base: 'xs', md: 'sm'}}>
                        {t('seconds')}
                    </Text>
                </Box>
            </Flex>
        </Stack>
    );
}

export default CountdownBill;
