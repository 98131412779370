import { Box, Center, Image, SimpleGrid, Stack, Text, useToast, Divider, Link } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import { convertDateToDateString, formatFrice } from "../../Utils/Helper";

function DetailPage() {
    const [catalog, setCatalog] = useState({});
    const { loadingShow, loadingClose } = useContext(AuthContext);

    const param = useParams();
    const toast = useToast({
        position: "top",
        align: "center",
      });

      const fetcDataCatalog = async () => {
        try {
            loadingShow();
            const { status, message, data } = await _axios.get(`/api/catalog-hpp/${param.slug}/find-by-slug`);
            loadingClose();
            if (!status) {
                return toast({
                    title: "importir.com",
                    description: message,
                    status: "error",
                });
            }
            console.log(data)
            setCatalog(data);
        } catch (error) {
            return toast({
                title: "importir.com",
                description: error,
                status: "error",
            });
        }
    };
    useEffect(() => {
        fetcDataCatalog();
    }, []);
    return (
        <Center>
            <Stack border={"1px gray"} borderRadius={"sm"} bgColor={"gray.200"} shadow={"xl"} width={'60%'} px={3} pb={3}>
                <Box bgColor={'yellow.300'} align="center">
                    <Text fontSize={'3xl'} fontWeight={'bold'}>{catalog?.title}</Text>
                    <Text fontSize={'xl'} fontWeight={'bold'}>{convertDateToDateString(catalog?.date ?? "")}</Text>
                </Box>
                {catalog?.products?.length > 0 && catalog?.products?.map((item, index) => (
                    <Box mt={5} bgColor={"yellow.400"} border={"1px gray"} shadow={'xl'} borderRadius={'sm'} p={3}>
                        <SimpleGrid spacing={2} columns={{base:1, md:2}} bgColor={'white'} p={5}>
                            <Stack>
                                <Box align={"center"} fontWeight={'bold'} px={5}>
                                    {item?.title}
                                </Box>
                                <Stack align={'center'} mb={3}>
                                    <Image src={item?.image} height={250}></Image>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Text fontWeight={'bold'}>Harga Tokopedia : Rp. {formatFrice(parseInt(item?.toped_price))}</Text>
                                <Text fontWeight={'bold'}>Harga Shopee : Rp. {formatFrice(parseInt(item?.shopee_price))}</Text>
                                <Divider borderColor="black"></Divider>
                                <Text fontWeight={'bold'}>Harga Di IMPORTIR.COM : Rp. {formatFrice(parseInt(item?.price))}</Text>
                                <Text>Berat / Pcs: {parseFloat(item?.weight/1000)} KG</Text>
                                <Text>Ukuran / Pcs: {parseFloat(item?.cbm)} CBM</Text>
                                {item?.hscode ? <Text>Hscode: {item.hscode}</Text> : ''}
                                {item?.bm ? <Text>BM: {item.bm}%</Text> : ''}
                                {item?.ppn ? <Text>PPn: {item.ppn}%</Text> : ''}
                                {item?.pph ? <Text>PPh: {item.pph}%</Text> : ''}
                                <Text>
                                    Link Beli: <br></br>
                                    <Link isExternal color="blue.300" href={item?.link} target="_blank">{item?.link}</Link >
                                </Text>
                            </Stack>
                        </SimpleGrid>
                    </Box>
                ))}
            </Stack>
        </Center>
    )
}

export default DetailPage;