/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  // Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  Image,
  SimpleGrid,
  Spacer,
  Button,
  Badge,
  Select,
  Collapse,
  Tooltip,
} from '@chakra-ui/react';
import {
  FiHome,
  FiTrendingUp,
  FiMenu,
  FiChevronDown,
} from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import store from 'store'
import AuthContext from '../Routes/hooks/AuthContext';
import importirlogo from '../assets/importirlogo.png'
import { auth } from '../Config/firebase';
import { BsCart3 } from 'react-icons/bs';
import { IoLogInSharp } from "react-icons/io5";
import { FaRegistered, FaShippingFast } from "react-icons/fa";
import { HiAcademicCap, HiOutlineHeart, HiViewGrid } from 'react-icons/hi';
import { formatCategoryName, formatFrice } from '../Utils/Helper';
import { BiCategory } from 'react-icons/bi'

import { IoPeopleSharp } from 'react-icons/io5';
import AppSearchInput from './Search/AppSearchInput';
import AppCategoryV2 from './CategoryList/AppCategoryV2';
import { ImDropbox } from 'react-icons/im';
import { useTranslation } from 'react-i18next';

const LinkItems = [

  {
    name: 'Home',
    icon: FiHome,
    linklabel: '/',
  },

  {
    name: 'Academy',
    icon: HiAcademicCap,
    linklabel: '/academy',
  },

  {
    name: 'Consultation',
    icon: IoPeopleSharp,
    linklabel: '/consultation',
  },

  {
    name: 'News',
    icon: FiTrendingUp,
    linklabel: '/news',
  },

  {
    name: 'Crowdfund',
    icon: IoPeopleSharp,
    linklabel: '/crowdfund',
  },
  {
    name: 'Personalized_Catalog',
    icon: IoPeopleSharp,
    linklabel: '/personalized-catalog',
  },
];



export default function AppBarSideV2({
  children,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = auth.currentUser

  const { setCategoryFunc, signOut, userDb, kursRmb, productListWishlist, cartList, categoryList, setUsedCurrencyFunc, setSymbolCurrenyFunc, userSymbolCurrency, kursRates, categoryFrom } = useContext(AuthContext)

  const handleLogout = () => {
    signOut()
  }

  // const backgroundImage = {
  //   backgroundImage: `url("https://www.dropbox.com/scl/fi/nyh2agd6dra4bz6h0axc1/1691684628965-Merah-Putih-Modern-Ilustrasi-Dirgahayu-Republik-Indonesia-Flyer-1.png?rlkey=ekdbr5n2hqr4rs6gubprjpfle&dl=0&raw=1")`,
  // };
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent  setCategoryFunc={setCategoryFunc} categoryFrom={categoryFrom} onClose={onClose} userDb={userDb} kursRmb={kursRmb} categoryList={categoryList}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav setCategoryFunc={setCategoryFunc} categoryFrom={categoryFrom} isScrolled onOpen={onOpen} handleLogout={handleLogout} kursRates={kursRates} userSymbolCurrency={userSymbolCurrency} setUsedCurrencyFunc={setUsedCurrencyFunc} setSymbolCurrenyFunc={setSymbolCurrenyFunc} categoryList={categoryList} userDb={userDb} user={user} cartList={cartList} productListWishlist={productListWishlist} kursRmb={kursRmb} />
      <Stack zIndex={100} pt={[130, 120, 95]} >
        {children}
      </Stack>
    </Box>
  );
}


interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, categoryList, kursRmb, userDb, categoryFrom, ...rest }: SidebarProps) => {

  const navigate = useNavigate()

  const redirectMenu = (y) => {
    onClose()
    navigate(`/category?detail=${y.category}&subCategory=${y.title}&id=${y['1688-id'] ? y['1688-id'] : y['amazon-id']}`)
  }

  let [searchParams] = useSearchParams();

  return (
    <Box
      transition="2s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      overflowY='scroll'
      {...rest}>
      <Flex h="20" alignItems="center" mx="6" justifyContent="space-between">
        <Image onClick={() => navigate('/')} cursor='pointer' src={importirlogo} />


        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      <Stack px={5}>
        <Text fontWeight={'bold'} fontSize='md'>Category</Text>
      </Stack>



      <Accordion defaultIndex={[]} allowMultiple m={4} >
        {categoryList?.length > 0 && (
          categoryList?.map((x, index) => {


            return (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <HStack key={index} cursor='pointer' spacing={2} alignItems='center'>
                      <Text fontSize={'sm'} textTransform='capitalize' color={searchParams.get('detail') === x.category ? "blue.300" : "black"}>{formatCategoryName(x.category)}</Text>

                    </HStack>
                    <Spacer />
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel pb={4} >
                  <SimpleGrid columns={[3, 3, 3]} gap={1}>

                    {x?.subCategory?.map((y, index) => {
                      return (
                        <Stack key={index} borderWidth={1} borderColor={searchParams?.get('subCategory') === y.title ? "blue.300" : "tranparent"} p={1} borderRadius='xl' onClick={() => redirectMenu(y)}>
                          {y.image && (
                            <Image cursor='pointer' src={y.image} />
                          )}
                          <Text fontSize={'xx-small'} color='gray.600' textAlign={'center'} noOfLines={2}>{y.title}</Text>
                        </Stack>

                      )
                    })}

                  </SimpleGrid>
                </AccordionPanel>

              </AccordionItem>
            )
          })
        )}


      </Accordion>

    </Box>
  );
};

// interface NavItemProps extends FlexProps {
//   icon: IconType;
//   children: ReactText;
// }
// const NavItem = ({ icon, linklabel, children, navigate, ...rest }: NavItemProps) => {
//   return (
//     <Stack onClick={() => navigate(linklabel)} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
//       <Flex
//         align="center"
//         p="4"
//         borderRadius="lg"
//         role="group"
//         cursor="pointer"
//         _hover={{
//           bg: 'transparent',
//           color: 'cyan.400',
//         }}
//         {...rest}>
//         {icon && (
//           <Icon
//             mr="4"
//             fontSize="16"
//             _groupHover={{
//               color: 'cyan.400',
//             }}
//             as={icon}
//           />
//         )}
//         {children}
//       </Flex>
//     </Stack>
//   );
// };

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, setCategoryFunc, categoryFrom, isScrolled, handleLogout, userDb, kursRates, kursRmb, cartList, productListWishlist, user, setUsedCurrencyFunc, setSymbolCurrenyFunc, userSymbolCurrency, ...rest }: MobileProps) => {

  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false);

  const handleTextClick = () => {
    setIsOpen(true);
  };

  const handleContainerMouseLeave = () => {
    setIsOpen(false);
  };

  const as_member = store.get('as_member_email')
  const { userCurrency } = useContext(AuthContext)



  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  // const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  const handleCurrency = (val) => {
    if (val === "idr") {
      setUsedCurrencyFunc("idr")
      setSymbolCurrenyFunc("Rp.")
    }
    if (val === "php") {
      setUsedCurrencyFunc("php")
      setSymbolCurrenyFunc("₱")
    }
    if (val === "usd") {
      setUsedCurrencyFunc("usd")
      setSymbolCurrenyFunc("$")
    }
    if (val === "thb") {
      setUsedCurrencyFunc("thb")
      setSymbolCurrenyFunc("฿")
    }
  }

  const handleCountry = (val) => {
    setCategoryFunc(val)
  } 

  const startTour = () => {
    if (window.location.pathname === '/') {
      store.remove('stepsHome')
    } else if (window.location.pathname === '/cart') {
      store.remove('stepCarts')
    } else if (window.location.pathname === '/shipping') {
      store.remove('stepsShipping')
    } else if (window.location.pathname === '/shipping/form/create') {
      store.remove('stepCreateShp')
    }
    window.location.reload()
  }

  const showMenu = () => {
    
    if (userDb !== undefined) {
      const currentDate = new Date();
      const expirationDate = new Date(userDb?.data_importir?.package_user?.expired_at);

      if (currentDate < expirationDate) {
        return false
      }
    }
    return true
  }

  const packageUser = store.get('user_package');
  const { i18n, t } = useTranslation();

  const prefLang = store.get('lang');
  useEffect(() => {
    if (prefLang !== undefined){
      i18n.changeLanguage(prefLang)
    }else{
      store.set('lang', "id");
      i18n.changeLanguage("id")
    }
  }, [prefLang])
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    store.set('lang', lang_code)
    i18n.changeLanguage(lang_code);
    window.location.reload();
  };
  const firstCat = store.get("categoryFrom")
  useEffect(() => {
    if (firstCat === undefined){
      store.set('categoryFrom', 'china')
    }
  }, [firstCat])
  return (
    
    <Stack
      color="black"
      zIndex={100}
      justifyContent={'space-evenly'}
      transition={'all 0.3s ease-in-out'}
      position={'fixed'}
      w={'100%'}
      pt={[1]}
      bgColor={'white'}
      {...rest}>
      <HStack w={'100%'} h='25px' px={[0, 0, 20]} display={{ base: 'flex' }} pt={0.5} transition={'all 0.3s ease-in-out'}
      >
        <Stack spacing={0} >
          <Select placeholder='Language' borderRadius={'md'} fontSize='xs' size={'xs'} bgColor={'white'} defaultValue={prefLang !== undefined ? prefLang : 'id'} onChange={(e) => onChangeLang(e) }>
            {
              userDb !== undefined && userDb?.data_importir?.user?.country_code === 'id' ? 
              <>
                <option value="id">Indonesia</option>
                <option value="en">English</option>
              </>
               : userDb === undefined ? 
               <>
                <option value="id">Indonesia</option>
                <option value="en">English</option>
              </> : <option value="en">English</option>
            }
          </Select>

        </Stack>
        <Stack spacing={0} >

          <Select borderRadius={'md'} fontSize='xs' size={'xs'} bgColor={'white'} defaultValue={userCurrency} placeholder="Rates" onChange={(e) => handleCurrency(e.target.value)}>
            <option value="idr">Rp. {formatFrice(kursRates?.rmb)} - (RMB)</option>
            <option value="php">Rp. {formatFrice(kursRates?.php)} - (Peso)</option>
            <option value="usd">Rp. {formatFrice(kursRates?.usd)} - (USD)</option>
            <option value="thb">Rp. {formatFrice(kursRates?.thb)} - (THB)</option>
          </Select>

        </Stack>

        <Spacer />

        <HStack display={{ base: 'none', md: 'flex' }} spacing={5} alignItems='center' transition={'all 0.3s ease-in-out'}
        >
          {
            LinkItems.length > 0 && (
              LinkItems.map((x, index) =>
                <Stack
                  onClick={() => navigate(x.linklabel ?? '#')}
                  fontSize={'sm'}
                  fontWeight={500}
                  color={linkColor}
                  cursor='pointer'
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}
                  key={index} >
                  <Text fontSize={'sm'}>{t(x.name.toLowerCase())}</Text>
                </Stack>
              )
            )
          }
          <Button onClick={() => startTour()} bgColor={'transparent'} fontSize={'sm'}>{t('start_tour')}</Button>
        </HStack>
      </HStack>

      <HStack
        bgColor={'#ffd600'}
        justifyContent={'space-evenly'}
        px={[null, null, 20]}
        w={'100%'}
        h='60px'
        boxShadow={isScrolled ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none'}
      >
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<HiViewGrid />}
        />


        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }} >
          <HStack alignItems={'center'} justifyContent='center' spacing={0} px={5}>
            <Image onClick={() => navigate('/')} cursor='pointer' src={importirlogo} />
            <Menu>
              <MenuButton _hover={{ bg: 'black', rounded: 'lg' }}>
                <HStack spacing={2}>
                  <Avatar borderRadius="full" size={{base: 'xs', sm: 'sm'}} src={categoryFrom === "usa" ? 
                  'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/united-states-of-america-2023_12_08_14_22_39.png' : categoryFrom === "korea" 
                  ? 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/south-korea-2023_12_08_14_22_39.png' : 'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/china-2023_12_08_14_22_39.png'}>
                  </Avatar>
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleCountry('usa')} gap={2}>
                  <Image h={10} src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/united-states-of-america-2023_12_08_14_22_39.png'></Image>
                  <Text fontSize={'bold'}>USA</Text>
                </MenuItem>
                <MenuItem onClick={() => handleCountry('korea')} gap={2}>
                  <Image h={10} src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/south-korea-2023_12_08_14_22_39.png'></Image>
                  <Text fontSize={'bold'}>Korea</Text>
                </MenuItem>
                <MenuItem onClick={() => handleCountry('china')} gap={2}>
                  <Image h={10} src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/china-2023_12_08_14_22_39.png'></Image>
                  <Text fontSize={'bold'}>China</Text>
                </MenuItem>
              </MenuList>
            </Menu>
            
          </HStack>

          <Stack onClick={handleTextClick}
            cursor="pointer"
            alignItems='center'
            justifyContent={'center'}
            display={{ base: 'none', md: 'flex' }}
          >
            <Button size={'sm'} leftIcon={<BiCategory size={20} />} variant="ghost">
              <Text>
                {t('category')}
              </Text>
            </Button>
          </Stack>

          <Stack justifyContent={'center'} w={'100%'} px={3} display={{ base: 'none', md: 'flex' }}>
            <AppSearchInput />
          </Stack>


        </Flex>



        <Stack p={2}>
          {user ? (
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={'center'}
              justifyContent='center'
              direction={'row'}
              >

              <HStack alignItems={'center'} spacing={[-1, 0, -1]} overflow={'auto'}>
                <div className='wishlist'>
                  <Tooltip label='Wishlist' fontSize='md'>

                    <Stack>

                      <IconButton
                        size={{base:"sm", sm:"md"}}
                        variant="ghost"
                        aria-label="open menu"
                        icon={<HiOutlineHeart size={17} />}
                        onClick={() => navigate('/wishlist')}
                      />
                      <Badge bgColor='red' color='white' position={'absolute'} borderRadius='full' px={2} fontSize='xs'>{productListWishlist?.data?.length}</Badge>

                    </Stack>
                  </Tooltip>
                </div>

                <div className="cart">
                  <Tooltip label='Cart' fontSize='md'>

                    <Stack>
                      <IconButton
                        size={{base:"sm", sm:"md"}}
                        variant="ghost"
                        aria-label="open menu"
                        icon={<BsCart3 size={17} />}
                        onClick={() => navigate('/cart')}
                      />
                      <Badge bgColor='red' color='white' position={'absolute'} borderRadius='full' px={2} fontSize='xs'>{cartList?.length}</Badge>

                    </Stack>
                  </Tooltip>
                </div>

                <div className='menus'>
                  <Menu display={{ base: 'none', md: 'flex' }}>
                    <MenuButton
                      py={2}
                      transition="all 0.3s"
                      _focus={{ boxShadow: 'none' }}>
                      <Stack >

                        <Button leftIcon={<FiMenu size={17} />} variant="ghost">
                          <Text display={{base: 'none', sm:'block'}} >
                            Menu
                          </Text>
                        </Button>

                      </Stack>

                    </MenuButton>
                    <MenuList
                      bg={'white'}
                      borderColor={'gray.200'}
                    >
                      <MenuItem onClick={() => navigate('/')}>
                        <Text fontSize={'sm'} color='gray.900'>{t("home")}</Text>

                      </MenuItem>

                      <MenuItem onClick={() => navigate('/academy')}>
                        <Text fontSize={'sm'} color='gray.900'>{t("academy")}</Text>

                      </MenuItem>


                      <MenuItem onClick={() => navigate('/crowdfund')}>
                        <Text fontSize={'sm'} color='gray.900'>Crowdfund</Text>

                      </MenuItem>

                      <MenuItem onClick={() => navigate('/news')}>
                        <Text fontSize={'sm'} color='gray.900'>{t("news")}</Text>

                      </MenuItem>

                      <MenuItem onClick={() => navigate('/cbm')}>
                        <Text fontSize={'sm'} color='gray.900'>CBM</Text>

                      </MenuItem>

                      <MenuItem onClick={() => navigate('/refund')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('refund')}</Text>
                      </MenuItem>

                      <MenuItem onClick={() => navigate('/shipping')}>
                        <Text fontSize={'sm'} color='gray.900'>Shipping</Text>
                      </MenuItem>


                      <MenuItem onClick={() => navigate('/escrow')}>
                        <Text fontSize={'sm'} color='gray.900'>Escrow</Text>
                      </MenuItem>

                      <MenuItem onClick={() => navigate('/consultation')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('consultation')}</Text>

                      </MenuItem>
                      {
                        userDb?.data_importir?.user?.country_code === 'id' ? 
                        (
                          <>
                            <MenuItem onClick={() => navigate('/sourceforyou')}>
                              <Text fontSize={'sm'} color='gray.900'>Source for you</Text>
                            </MenuItem>
                            <MenuItem onClick={() => navigate('/mitra')}>
                              <Text fontSize={'sm'} color='gray.900'>Mitra</Text>
                            </MenuItem>
                          </>
                        )
                        : ""
                      }
                      <MenuItem onClick={() => navigate('/calculator')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('calculator')}</Text>
                      </MenuItem>

                      <MenuItem onClick={() => navigate('/cataloglist')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('catalog')}</Text>
                      </MenuItem>

                      {
                        userDb?.package_importir === "Diamond" ?
                        <MenuItem onClick={() => navigate('/dashboard-diamond')}>
                          <Text fontSize={'sm'} color='gray.900'>Dashboard Member Diamond</Text>
                        </MenuItem>
                        : ""
                      }
                      <MenuItem onClick={() => navigate('/list-module')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('onboarding_quiz')}</Text>
                      </MenuItem>
                      <MenuItem onClick={() => navigate('/refund-policy')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('refund_policy')}</Text>
                      </MenuItem>
                      {showMenu() ?
                      <MenuItem onClick={() => navigate('/voucher')}>
                        <Text fontSize={'sm'} color='gray.900'>Voucher</Text>
                      </MenuItem> : ''}

                    </MenuList>
                  </Menu>
                </div>

                <div className='profile'>
                  <Menu display={{ base: 'none', md: 'flex' }}>
                    <MenuButton
                      py={2}
                      transition="all 0.3s"
                      _focus={{ boxShadow: 'none' }}>
                      <Stack >
                        <HStack>

                          <HStack
                            spacing={2}
                          >
                            <Avatar
                              size={{base:'xs', sm:'sm'}}
                              src={
                                userDb?.data_importir?.detail_user?.profile_image ? userDb?.data_importir?.detail_user?.profile_image : 'https://www.dropbox.com/scl/fi/0gnyorw46ipnui3904t6b/1691682215605-importir-merdekauang-bendera-bg-putih.png?rlkey=qiq1bxq4zoj9998cd8yocg21i&dl=0&raw=1'
                              } />
                          </HStack>
                          <Box display={{ base: 'none', md: 'flex' }}>
                            <FiChevronDown />
                          </Box>
                        </HStack>
                      </Stack>

                    </MenuButton>
                    <MenuList
                      bg={'white'}
                      borderColor={'gray.200'}
                    >
                      <HStack alignItems={'flex-start'} p={3} bgColor='#ffd600' >
                        <Stack spacing={1} px={1}>
                          <Text fontWeight={'bold'} fontSize='sm' textTransform={'capitalize'}>{userDb?.name ? userDb?.name : 'Log in to your account'}</Text>
                          <HStack spacing={2}>
                            {userDb?.package_importir && (
                              < ImDropbox fontSize={15} />
                            )}
                            <Text fontSize='sm'>{packageUser !== undefined ? packageUser?.package_child?.name : 'Fill in my purchase request'}</Text>

                          </HStack>
                          {as_member && (
                            <HStack spacing={2}>
                              <Text fontSize='sm'>as {as_member && as_member}</Text>
                            </HStack>
                          )}

                        </Stack>
                      </HStack>
                      <MenuItem onClick={() => navigate('/profile')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('profile')}</Text>
                      </MenuItem>

                      <MenuItem onClick={() => navigate('/order')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('order_list')}</Text>
                      </MenuItem>

                      {showMenu() ?
                      <MenuItem onClick={() => navigate('/voucher/order')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('voucher_order')}</Text>
                      </MenuItem> : ''}

                      <MenuItem onClick={() => navigate('/invoice')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('invoice_list')}</Text>

                      </MenuItem>

                      <MenuItem onClick={() => navigate('/contact')}>
                        <Text fontSize={'sm'} color='gray.900'>{t('contact_us')}</Text>
                      </MenuItem>

                      <MenuDivider />
                      <MenuItem onClick={() => handleLogout()}>
                        <Text fontSize={'sm'} color='gray.900'>{t('sign_out')}</Text>

                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </HStack>
            </Stack>
          ) : (
            <Stack
              flex={{ base: 1, md: 0 }}
              justify={'flex-end'}
              direction={'row'}
              spacing={3}>
                
              <Tooltip label='Non Member Order Tracking' fontSize='md'>
                <Button
                  as={'a'}
                  display={{ base: 'flex', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  borderColor='black'
                  borderWidth={'2px'}
                  bgColor='transparent'
                  _hover={{
                    bg: 'blue.500',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate('/order-tracking-non-member')}>
                  <FaShippingFast size={20} />
                </Button>
              </Tooltip>

              <Tooltip label='Login' fontSize='md'>
                <Button
                  as={'a'}
                  display={{ base: 'flex', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  borderColor='black'
                  borderWidth={'2px'}
                  bgColor='transparent'
                  _hover={{
                    bg: 'blue.500',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate('/login')}>
                    <IoLogInSharp size={20} />
                </Button>
              </Tooltip>
              <Tooltip label='Register' fontSize='md'>
                <Button
                  as={'a'}
                  display={{ base: 'flex', md: 'inline-flex' }}
                  fontSize={'sm'}
                  fontWeight={600}
                  borderColor='black'
                  borderWidth={'2px'}
                  bgColor='transparent'
                  _hover={{
                    bg: 'blue.500',
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate('/register')}>
                    <FaRegistered size={20} />
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </HStack>

      <Stack justifyContent={'center'} w={'100%'} px={3} display={{ base: 'flex', md: 'none' }}>
        <AppSearchInput />
      </Stack>


      <Collapse
        in={isOpen}
        onMouseEnter={handleTextClick}
        onMouseLeave={handleContainerMouseLeave}
        transition="ease-in-out 0.3s">
        <Stack h={'520px'} w='full' bgColor={'white'} p={3}>
          <AppCategoryV2 handleFunc={handleContainerMouseLeave} />
        </Stack>
      </Collapse>

    </Stack>
  );
};