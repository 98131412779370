import { Box, Stack, Input, SimpleGrid, Text, Button, useToast, Image, Select, Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer, } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import Bank from '../../Config/Json/Bank.json'
import store from 'store'
import { useTranslation } from 'react-i18next';
function FormInputRekening() {
    const {t} = useTranslation();
    const user = store.get('user_api')
    const [formData, setFormData] = useState({
        "name": '',
        "bank_code": '',
        "user_id": user?.id,
        "name_by_server": '',
        "account_no": ''
    })
    const [accountData, setAccountData] = useState([])

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const handleChangeForm = (e) => {
        if (e.target.name) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    }

    const fetchAccountNo = async () => {
        try {
          const data = await _axios.get(`api/profile/${user.id}/get-account-info`)
          console.log(data, 'account')
          if (data.status === true) {
            if (data.data) {
                setAccountData(data.data) 
            }
          } else {
            toast({
                title: 'importir.co',
                description: data.message,
                status: 'success',
            });
          }
        } catch (error) {
            toast({
                title: 'importir.co',
                description: error,
                status: 'success',
            });
        }
    }

    const submitRekening = async () => {
        try {
            console.log('submit rek')
            const check = await _axios.get(`https://old.importir.com/api/check-inquiry/${formData.account_no}/${formData.bank_code}?token=syigdfjhagsjdf766et4wff6`)
            if (check.log.status === "INVALID_ACCOUNT_NUMBER" || check.log.status === "PENDING") {
                toast({
                    title: 'Invalid Account Number',
                    description: "Please Input Your Account Number Correctly",
                    status: 'error',
                });
            } else {
              formData.name_by_server = check.message.account_holder
              const data = await _axios.post('api/profile/save-account-no', formData)
              if (data.status) {
                fetchAccountNo()
                formData.account_no = ""
                formData.name = ""
                formData.bank_code = ""
                toast({
                    title: 'importir.com',
                    description: data.message,
                    status: 'success',
                });
              } else {
                toast({
                    title: 'importir.com',
                    description: data.message,
                    status: 'error',
                });
              }
            }
  
          } catch (error) {
            toast({
                title: 'importir.com',
                description: error,
                status: 'error',
            });
        }    
    }

    useEffect(() => {
        fetchAccountNo()
    
        return () => {
        }
      }, [])
  return (
    <Stack>
        <SimpleGrid columns={2} spacing={10}>
            <Box>
                <Text mb='8px'>{t("account_number")}</Text>
                <Input placeholder='Input Account Number' name='account_no' defaultValue={formData.account_no} onChange={handleChangeForm}/>
            </Box>
            <Box>
                <Text mb='8px'>{t("name")}</Text>
                <Input placeholder='Input Name' name="name" defaultValue={formData.name} onChange={handleChangeForm}/>
            </Box>
            <Box>
                <Text mb='8px'>Bank</Text>
                <Select placeholder={`${t("choose_bank")}`} value={formData.bank_code} onChange={handleChangeForm} name='bank_code'>
                    {Bank.map((item, index) => {
                        return (
                            <option value={item.bank_code}>{item.name}</option>
                        )
                    })

                    }
                </Select>
            </Box>
            <Box>
                <Button colorScheme='blue' mt={8} size='md' onClick={() => submitRekening()}>
                    Submit
                </Button>
            </Box>
        </SimpleGrid>

        <Box overflowX={'auto'}>
            <Table variant='simple' mt={10}>
                <Thead>
                <Tr>
                    <Th>No</Th>
                    <Th>{t("main_account_number")}</Th>
                    <Th>{`${t("name")} ${t("from")} ${t("form")}`}</Th>
                    <Th>{`${t("name")} ${t("from")} Bank`}</Th>
                    <Th>Bank</Th>
                    <Th>{t("account_number")}</Th>
                    <Th>Status</Th>
                </Tr>
                </Thead>
                <Tbody>
                {accountData.length > 0 ? 
                    accountData.map((item, index) => {
                        return (
                            <Tr>
                                <Td>{index + 1}</Td>
                                <Td>{item.default_inquiry !== null ? 'Yes' : "No"}</Td>
                                <Td>{item.name}</Td>
                                <Td>{item.name_by_server}</Td>
                                <Td>{item.bank_code}</Td>
                                <Td>{item.account_no}</Td>
                                <Td>{item.status_inquiry}</Td>
                            </Tr>
                        )
                    })
                    :
                    <TableCaption textAlign={'center'}>Data Not Found</TableCaption>
                }
                </Tbody>
            </Table>
        </Box>
    </Stack>
  )
}

export default FormInputRekening