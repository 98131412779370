import React, { useRef } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
const CopyToClipboard = ({ text }) => {
  const {t} = useTranslation();
  const inputRef = useRef(null);
  
  const toast = useToast({
    position: "top",
    align: "center",
});

  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand('copy');
    toast({
      title: 'Copied',
      description: 'The text has been copied to the clipboard.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <>
      <input
        ref={inputRef}
        value={text}
        style={{ position: 'fixed', top: '-9999px' }}
        readOnly
      />
      <Button
        leftIcon={<FaCopy />}
        onClick={copyToClipboard}
        size="xs"
        isDisabled={inputRef?.current?.value == null || inputRef?.current?.value == ''}
        colorScheme="facebook"
      >
        {t('copy')}
      </Button>
    </>
  );
};

export default CopyToClipboard;