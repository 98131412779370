import { Heading, HStack,  SimpleGrid, Spacer, Stack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import AuthContext from '../Routes/hooks/AuthContext'
import AppNavigationCategoryBox from '../Components/CategoryList/AppNavigationCategoryBox'
import ProductListPage from './ProductList/ProductListPage'
import { useSearchParams } from 'react-router-dom'

function CategoryPage() {
    const { categoryList } = useContext(AuthContext)
    const height = window.innerHeight
    let [searchParams] = useSearchParams()
    const idCategory = searchParams.get('id')

    if (idCategory) {
        return (
            <ProductListPage />
        )
    }
    else {
        return (
            <Stack p={5} bgColor='gray.100' minH={height} >


                <HStack spacing={2} px={5} py={3} mt={[10, 10, 0]}>
                    <Stack>
                        <Heading size={['sm', 'md', 'md']} >Category Trending</Heading>
                    </Stack>
                    <Spacer />
                    <Stack>
                    </Stack>
                </HStack>

                <Stack p={5} maxW='100%' overflow={'hidden'}>
                    <SimpleGrid columns={[1, 2, 4, 8]} gap={4}>
                        {
                            categoryList ? 
                                categoryList.length > 0 &&
                                categoryList.map((x, index) => (
                                    <AppNavigationCategoryBox key={index} category={x} />
                                ))
                            : ""
                        }
                    </SimpleGrid>
                </Stack>

            </Stack>
        )

    }

}

export default CategoryPage