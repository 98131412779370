import React, { useState } from "react";
import {
	Text,
	Heading,
	Stack,
	Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function AddressCard(props) {
	const { t } = useTranslation();
	if (typeof props.receiver != "undefined")
		return (
			<Stack bg="white" p={5} spacing={2} borderRadius="md" shadow={"md"}>
				<Stack>
					<Heading size="sm">{t("sent_to")}</Heading>
					<Stack spacing={1}>
						<Text
							fontWeight={"bold"}
							fontSize="sm"
							textTransform={"capitalize"}
						>
							{props.receiver} ({props.label})
						</Text>
						<Text fontSize="sm">{props.phone}</Text>
						<Text color="gray.500" fontSize="sm">
							{props.address}, {props.district}, {props.city},{" "}
							{props.province}{" "}
						</Text>
					</Stack>
					<Button
						onClick={() => props.changeAddress()}
						maxW={32}
						colorScheme="blue"
						size="sm"
					>
						<Text fontStyle={"italic"}>{t("change_address")}</Text>
					</Button>
				</Stack>
			</Stack>
		);

	return <></>;
}
