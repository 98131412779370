import { Box, Button, Grid, Heading, HStack, Image, Input, SimpleGrid, Spacer, Spinner, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { FaInfo } from "react-icons/fa";
import { BiSearchAlt } from 'react-icons/bi'
import { BsCircleFill } from 'react-icons/bs'
import { MdOutlinePermMedia,  } from 'react-icons/md'
import { SlArrowDown } from 'react-icons/sl'
import { useNavigate } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import { errorSlack } from '../../Api/SlackApi'

import AppImageSlide from '../../Components/Carousel/AppImageSlide';
import AuthContext from '../../Routes/hooks/AuthContext'
import ConvertPrice from '../../Utils/ConvertPrice'
import { formatFrice } from '../../Utils/Helper'
import store from "store";
import { FiMoreVertical } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
function SourceForYouPage() {
    const {t} = useTranslation()
    const [productName, setProductName] = useState('')
    const [loading, setLoading] = useState(false)
    const [productDescription, setProductDescription] = useState('')
    const [productLink, setProductLink] = useState('')
    const [productPriceEstimated, setProductPriceEstimated] = useState(0)
    const [count, setCount] = useState(1)
    const [sfyList, setSfyList] = useState('')
    const [filesImage, setFilesImage] = useState([]);
    const [files, setFiles] = useState([]);

    const navigate = useNavigate()

    const path = window.location.href ?? '#'

    const toast = useToast({
        position: "top",
        align: "center",
    });


    const { loadingShow, loadingClose, userSymbolCurrency, userDb } = useContext(AuthContext)

    const { convertPriceFix } = ConvertPrice()

    if (userDb?.package_importir === "basic") {
        toast({
            title: 'importir.com',
            description: 'Your package is basic, cannot access this page',
            status: 'error',
        });
        navigate('/error404')
    }

    const imageCarouselDashboard = [
        'https://firebasestorage.googleapis.com/v0/b/importir-com.appspot.com/o/source%20for%20you.png?alt=media&token=cce8b2da-7488-4633-8e92-997853f93dac',
    ]



    const height = window.innerHeight
    const packageUser = store.get('user_package');

    useEffect(() => {
        if (packageUser?.package_child?.name == 'FREE MEMBER'){
            toast({
                title: 'importir.com',
                description: 'Your package is FREE MEMBER, cannot access this page',
                status: 'error',
            });
            return navigate("/")
        }
        getDataSource(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const getDataSource = async (page) => {
        const user = store.get('user_api')
        loadingShow()
        try {
            const res = await _axios.get(`api/sfy/list-sfy/${user.id}?page=${page}`)
            if (res.status === true) {
                const dataArr = res.data.data
                if (page === 1) {
                    setSfyList(dataArr)
                } else {
                    setSfyList((prevList) => [...prevList, ...dataArr])
                }
            }
            loadingClose()
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
    }

    const handlePagination = async () => {
        const nextPage = count + 1
        setCount(nextPage)
        getDataSource(nextPage)
    }



    const handleSubmit = async () => {
        try {
            const responseData = []; // Array untuk menyimpan respons API
            for (let i = 0; i < filesImage.length; i++) {
                const formData = new FormData();
                formData.append('file', filesImage[i]);

                const response = await _axios.post(
                    'api/storage?directory=general',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                responseData.push(response.data);
            }

            const fileArrImage = responseData;
            if (fileArrImage.length === 0) {
                toast({
                    title: 'importir.com',
                    description: "Please fill image",
                    status: 'error',
                });
                return
            }

            const dataFix = {
                title: productName,
                description: productDescription,
                image: fileArrImage,
                link_market: productLink,
                expected_price: productPriceEstimated,
                current_status: 'pending request',
                link_product: productLink,
            };

            try {
                const res = await _axios.post('api/sfy', dataFix);
                if (res.status === true) {
                    toast({
                        title: 'importir.com',
                        description: res.message,
                        status: 'success',
                    });
                    getDataSource(1)
                    return
                }
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error',
                });
            } catch (error) {
                console.log(error, 'ini error');
                const resSlack = await errorSlack(error, 'importir.com', path);
                console.log(resSlack, 'CONNECTED ERROR IN SLACK');
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 413) {
                toast({
                    title: 'importir.com',
                    description: 'File size is too large',
                    status: 'error',
                });
            } else {
                // Handle error jika ada
                const resSlack = await errorSlack(error, 'importir.com', path);
                console.log(resSlack, 'CONNECTED ERROR IN SLACK');
            }
        }
    };

    const handleFileInputChange = (event) => {
        const { files: newFiles } = event.target;
        if (newFiles.length) {
            const newFileArray = [...files];
            for (let i = 0; i < newFiles.length; i++) {
                const reader = new FileReader();
                reader.readAsDataURL(newFiles[i]);
                reader.onload = () => {
                    newFileArray.push({
                        file: reader.result,
                        fileName: newFiles[i].name,
                        description: newFiles[i].type,
                    });
                    setFiles(newFileArray);
                };
            }
            setFilesImage(newFiles); // Mengubah state filesImage menjadi array baru dari selectedFiles
        }
    }

    return (
        <Stack alignItems={'center'} justifyContent='center' p={[1, 1, 5]}>


            <Box >
                <Stack >
                    {loading ? (
                        <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                            <Spinner />
                        </Stack>
                    ) : (
                        imageCarouselDashboard && (
                            <AppImageSlide images={imageCarouselDashboard && imageCarouselDashboard} />
                        )
                    )}

                </Stack>
            </Box>

            <SimpleGrid columns={[1, null, 2]} gap={10} >
                <Stack spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}  >
                    <HStack alignItems={'center'} spacing={1}  >
                        <BiSearchAlt />
                        <Heading size={'sm'}>Source For You</Heading>

                    </HStack>
                    <Stack>
                        <Text fontSize={'sm'}>
                            <b>Source For You (SFY) </b> {t('sfy_info')}
                        </Text>

                        <Text fontSize={'sm'}>
                            {t('already_know')} <b>SFY?</b> <br />
                            {t('confused_about_cogs')}
                        </Text>

                    </Stack>

                    <Stack >
                        <Text fontSize={'xs'} color='blue.500' fontStyle={'italic'} >{t('find_dream_product')}

                        </Text>
                    </Stack>

                    <Stack p={3} spacing={3} fontSize='sm' color={'gray.600'}  >
                        <Stack>
                            <Text>{t('product_name')}</Text>
                            <Input size='sm' borderRadius={'lg'} type='text' placeholder='Ex: Tumblr' onChange={(e) => setProductName(e.target.value)} />
                        </Stack>

                        <Stack>
                            <Text>{t('product_description')}</Text>
                            <Textarea size='sm' borderRadius={'lg'} type='text' placeholder='ex: detailed description of the items you desire' onChange={(e) => setProductDescription(e.target.value)} />
                        </Stack>


                        <Stack>
                            <Text>{t('product_link')}
                            </Text>
                            <Input size='sm' borderRadius={'lg'} type='text' placeholder='Ex: https://*********.com/***' onChange={(e) => setProductLink(e.target.value)} />
                        </Stack>

                        <Stack>
                            <Text>{t('estimated_price')}</Text>
                            <Input size='sm' borderRadius={'lg'} type='number' placeholder='Ex: 30000' onChange={(e) => setProductPriceEstimated(e.target.value)} />
                        </Stack>

                        <HStack spacing={2} alignItems='center' >

                            <Stack>
                                <Input
                                    type="file"
                                    onChange={handleFileInputChange}
                                    display="none"
                                    id="fileInput"
                                    multiple
                                />
                                <label htmlFor="fileInput">
                                    <HStack cursor={'pointer'}>
                                        <Stack>
                                            <MdOutlinePermMedia />

                                        </Stack>
                                        <Text fontSize={'sm'}>
                                            {t('add')} {t('product_image')} / Video
                                        </Text>
                                    </HStack>
                                </label>
                            </Stack>


                        </HStack>

                        <SimpleGrid columns={[1, 2, 3]} gap={3}>
                            {files.length > 0 && files.map((x, index) => {
                                return (
                                    <Stack key={index}>
                                        <Image src={x.file} borderRadius='xl' alt={x.name} shadow='sm' />
                                    </Stack>
                                )
                            })}
                        </SimpleGrid>

                        <Stack alignItems={'center'} p={2}>
                            <Button bgColor={'#ffd600'} size='sm' shadow={'sm'} onClick={() => handleSubmit()}>
                                <Text>{t('submit')}</Text>
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack bgColor='white' borderRadius={'xl'} shadow='sm' p={5} h={height / 1.1} overflowY='scroll' >
                    <Heading size={'sm'}>{t('list_sfy')}</Heading>
                    <Stack >
                        {sfyList.length > 0 ? (
                            sfyList.map((x, index) => {
                                return (
                                    <Stack bgColor={'white'} spacing={1} key={index} borderRadius='xl' m={2} p={2} shadow={'md'}  >
                                        <Stack>
                                            <Text fontSize='sm' color={'gray.600'}>{moment(x?.created_at).format('LLL')}</Text>

                                            <Grid templateColumns={{ base: "1fr", sm: "1fr 2fr 1fr" }} gap={3} m={1} justifyContent='space-between'>
                                                <Box width={'200px'}>
                                                    <Image src={x?.image[0]} />
                                                </Box>
                                                <Stack spacing={1}  >
                                                    <HStack>
                                                        <Text fontSize={'sm'}>{t('product_name')} : </Text>
                                                        <Text fontSize={'sm'} >{x.title}</Text>
                                                    </HStack>

                                                    <HStack>
                                                        <Text fontSize={'sm'}>{t('product_description')} : </Text>
                                                        <Text fontSize={'sm'} >{x.description}</Text>
                                                    </HStack>

                                                    <HStack>
                                                        <Text fontSize={'sm'}>{t('estimated_price')} : </Text>
                                                        <Text fontWeight={'bold'} fontSize='sm'>{userSymbolCurrency} {formatFrice(convertPriceFix(x.expected_price))} </Text>
                                                    </HStack>

                                                </Stack>



                                                <Stack justifyContent={'center'}>

                                                    <HStack alignItems={'center'} justifyContent='center' >
                                                        <Button size={'sm'} bgColor='#ffd600' onClick={() => navigate(`/sourceforyou/${x.id}`)} >
                                                            <FiMoreVertical color='black' />
                                                        </Button>
                                                        <a href={x.link_market} target="_blank" rel="noopener noreferrer">
                                                            <Button size={'sm'} bgColor='#ffd600' >
                                                                <FaInfo color='black' />
                                                            </Button>
                                                        </a>
                                                    </HStack>
                                                </Stack>
                                            </Grid>

                                            <Stack alignItems={'center'} justifyContent='center'>
                                                {x.current_status !== 'pending request' ? (
                                                    <HStack spacing={1} alignItems='center' justifyContent={'center'}>
                                                        <Text color={'gray.600'} fontSize='sm'>Status :</Text>
                                                        <Text color={'gray.600'} fontSize='sm' textTransform={'capitalize'}>{x.current_status}</Text>
                                                        <BsCircleFill size={8} color="green" />
                                                    </HStack>
                                                ) : (
                                                    <HStack spacing={1} alignItems='center' justifyContent={'center'}>
                                                        <Text color={'gray.600'} fontSize='sm'>Status :</Text>
                                                        <Text color={'gray.600'} fontSize='sm' textTransform={'capitalize'}>{x.current_status}</Text>
                                                        <BsCircleFill size={8} color="red" />
                                                    </HStack>
                                                )}
                                            </Stack>

                                        </Stack>
                                    </Stack>
                                )
                            })
                        ) : (
                            <Stack alignItems={'center'} justifyContent='center' h={height / 1.5}>
                                <Text color={'gray.500'}>{t('sfy_unavailable')}</Text>
                            </Stack>
                        )}
                    </Stack>

                    <Stack pt={5} alignItems='center' justifyContent={'center'}>
                        <Button onClick={() => handlePagination()} bgColor='#ffd600' shadow={'md'}>
                            <HStack alignite>
                                <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                                    {t('load_more')}
                                </Text>
                                <Stack>
                                    <SlArrowDown size={10} />
                                </Stack>
                            </HStack>
                        </Button>
                    </Stack>

                </Stack>



            </SimpleGrid>

        </Stack>
    )
}

export default SourceForYouPage