/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, SimpleGrid, Text, Input, Box, Heading, HStack, Textarea, Spacer, Button, Image, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlinePermMedia } from 'react-icons/md'
import _axios from '../../Api/AxiosBarrier';
import { formatFrice } from '../../Utils/Helper';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { errorSlack } from '../../Api/SlackApi';
import { FcFile } from "react-icons/fc";
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';


function EscrowCreatePage() {
    const {t} = useTranslation();
    const path = window.location.href ?? '#'

    const [files, setFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(0)
    const [formData, setFormData] = useState({
        "supplier_email": "",
        "customer_note": "",
        "supplier_phone": "",
        "supplier_wechat": "",
        "amount_rmb": "",
        "invoice_file_url": "",
        "other_files": []
    });
    const [amountFee] = useState(50000);
    const [kursRmb, setKursRmb] = useState(1)

    const navigate = useNavigate()

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const handleChangeForm = (e) => {
        if (e.target.name) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleSubmitForm = async () => {
        try {
            const response = await _axios.post('api/pay-care', formData);
            if (response.status === true) {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'success',
                });
                navigate('/escrow')
                return
            }
            toast({
                title: 'importir.com',
                description: response.message,
                status: 'error',
            });
        } catch (error) {
            alert(error.response.data.error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    const handleFileInputChange = async (event) => {
        const { files: newFiles } = event.target;

        if (newFiles.length === 1) {
            const uploadedFile = await handleFileUpload(newFiles[0]);
            if (uploadedFile) {
                setFormData((prevState) => ({
                    ...prevState,
                    invoice_file_url: uploadedFile,
                }));
                setFiles([{
                    file: URL.createObjectURL(newFiles[0]),
                    fileName: newFiles[0].name,
                    description: newFiles[0].type,
                }]);
            }
        }
    };


    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await _axios.post(
                'api/storage?directory=general',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.log(error, 'Error uploading file');
            toast({
                title: 'Error',
                description: 'Failed to upload file',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return null;
        }
    };



    const handleAnotherFile = async (event, index) => {
        const { files: newFiles } = event.target;

        if (newFiles.length === 1) {
            const uploadedFile = await handleFileUpload(newFiles[0]);
            if (uploadedFile) {
                setFormData((prevState) => {
                    const updatedOtherFiles = [...prevState.other_files];
                    setCurrentFile(index)
                    updatedOtherFiles[index] = {
                        inc: index,
                        file_url: uploadedFile,
                        description: prevState.other_files[index].description,
                    };
                    return {
                        ...prevState,
                        other_files: updatedOtherFiles,
                    };
                });
            }
        }
    };

    const productPrice = () => {
        if (!kursRmb) {
            return 0
        }

        const amount = formData.amount_rmb * kursRmb
        return amount
    }

    const totalInvoice = () => {
        return productPrice() + amountFee
    }

    const getDataRmb = async () => {
        try {
            const res = await axios.get('https://new-admin.importir.com/api/rates')
            if (res.status === 200) {
                setKursRmb(res.data.data.rmb)
            }
        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }


    const addAnotherFiles = () => {
        setFormData((prevData) => {
            const currentFilesArray = prevData.other_files || [];
            const newFile = {
                inc: currentFile,
                file_url: '',
                description: ''
            };
        
            const updatedFilesArray = [...currentFilesArray, newFile];
            return {
                ...prevData,
                other_files: updatedFilesArray,
            };
        });
    };

    const handleFormSubmit = async () => {
        const uploadedOtherFiles = [];

        try {
            for (const file of formData.other_files) {
                const uploadedFile = await handleFileUpload(file);
                if (uploadedFile) {
                    uploadedOtherFiles.push(uploadedFile);
                }
            }
        } catch (error) {
            console.log(error, 'Error uploading other files');
            toast({
                title: 'Error',
                description: 'Failed to upload other files',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setFormData((prevState) => ({
            ...prevState,
            other_files: uploadedOtherFiles,
        }));

        // Submit the form with the updated file URLs
        handleSubmitForm();
    };

    const deleteAnotherFiles = (index) => {
        Swal.fire({
          title: "Are you sure to delete this file?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                setFormData((prevData) => {
                    const otherFilesArray = prevData.other_files.filter(
                        (_, i) => i !== index
                    );
                    return {
                        ...prevData,
                        other_files: otherFilesArray,
                    };
                });
            }
        });
    };
      
    useEffect(() => {
        getDataRmb()
    }, [])

    return (
        <Stack px={5} py={6}>
            <SimpleGrid columns={[1, 1, 2]} gap={5} mt={[10, 10, 0]}>
                <Stack gap={4}>
                    <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                        <Heading size={'lg'} py={5} px={4}>
                            {t('supplier_data')}
                        </Heading>
                        <SimpleGrid columns={[1, 1, 2]} gap={4} spacing={10} py={5} px={4}>
                            <Box>
                                <Text mb='8px'>{t('supplier_email')}*</Text>
                                <Input
                                    value={formData.supplier_email}
                                    type='email'
                                    placeholder='Here is a sample placeholder'
                                    size='lg'
                                    name='supplier_email'
                                    px={3}
                                    py={4}
                                    borderRadius={'md'}
                                    onChange={handleChangeForm}
                                />
                            </Box>

                            <Box>
                                <Text mb='8px'>{t('supplier_phone')}*</Text>
                                <Input
                                    type='number'
                                    defaultValue={formData.supplier_phone}
                                    placeholder='Here is a sample placeholder'
                                    size='lg'
                                    name='supplier_phone'
                                    px={3}
                                    py={4}
                                    borderRadius={'md'}
                                    onChange={handleChangeForm}
                                />
                            </Box>

                            <Box>
                                <Text mb='8px'>{t('supplier_wechat')}*</Text>
                                <Input
                                    defaultValue={formData.supplier_wechat}
                                    placeholder='Here is a sample placeholder'
                                    size='lg'
                                    name='supplier_wechat'
                                    px={3}
                                    py={4}
                                    borderRadius={'md'}
                                    onChange={handleChangeForm}
                                />
                            </Box>
                        </SimpleGrid>
                    </Box>

                    <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                        <Heading size={'lg'} py={5} px={4}>
                            {t('invoice_file')}
                        </Heading>
                        <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4}>
                            <HStack spacing={2} alignItems='center' >
                                <Stack>
                                    <Text mb='8px'>{t('invoice_file')}</Text>
                                    <Input
                                        type="file"
                                        defaultValue={formData.invoice_file_url}
                                        name='invoice_file_url'
                                        display="none"
                                        id="fileInput"
                                        multiple
                                        onChange={handleFileInputChange}
                                    />
                                    <label htmlFor="fileInput">
                                        <HStack cursor={'pointer'}>
                                            <Stack>
                                                <MdOutlinePermMedia />
                                            </Stack>
                                            <Text fontSize={'sm'}>
                                                {`${t('add')} ${t('image')} / ${t('video')}`}
                                            </Text>
                                        </HStack>
                                    </label>
                                </Stack>
                            </HStack>
                            <SimpleGrid columns={[1, 2, 3]} gap={3}>
                                {files.length > 0 && files.map((x, index) => {
                                    return (
                                        <Stack key={index}>
                                            {x.description.includes("image/") ? (
                                                <Image src={x.file} borderRadius='xl' alt={x.name} shadow='sm' />
                                            ) : (
                                                <Stack>
                                                    < FcFile size={50} />
                                                    <Text fontSize={'xs'} noOfLines={2}>{x.description}</Text>
                                                </Stack>
                                            )}

                                        </Stack>
                                    )
                                })}
                            </SimpleGrid>
                        </SimpleGrid>
                    </Box>

                    <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                        <Heading size={'lg'} py={5} px={4}>
                            {t('total')}
                        </Heading>
                        <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4}>
                            <Box>
                                <Text mb='8px'>{t('total')} (RMB) *</Text>
                                <Input
                                    defaultValue={formData.amount_rmb}
                                    placeholder='Here is a sample placeholder'
                                    size='lg'
                                    name='amount_rmb'
                                    px={3}
                                    py={4}
                                    borderRadius={'md'}
                                    onChange={handleChangeForm}
                                />
                            </Box>
                        </SimpleGrid>
                    </Box>

                    <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                        <Heading size={'lg'} py={5} px={4}>
                            {t('note_to_admin')}
                        </Heading>
                        <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4}>
                            <Box>
                                <Text mb='8px'>{t('note_to_admin')}*</Text>
                                <Textarea
                                    defaultValue={formData.customer_note}
                                    placeholder='Here is a sample placeholder'
                                    size='lg'
                                    name='customer_note'
                                    px={3}
                                    py={4}
                                    borderRadius={'md'}
                                    onChange={handleChangeForm}
                                ></Textarea>
                            </Box>
                        </SimpleGrid>
                    </Box>

                    <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                        <Heading size={'lg'} py={5} px={4}>
                            {t('other_file')}
                        </Heading>
                        {
                            formData.other_files && formData.other_files.map((item, index) => {
                                return (
                                    <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4} key={index}>
                                        <Box>
                                            <Text mb='8px'>{t('other_file')}</Text>
                                            <HStack spacing={2} alignItems='center' key={item.inc}>
                                                <Stack>
                                                    <Input
                                                        type="file"
                                                        display="none"
                                                        name='file_url'
                                                        id={`inputFile-${index}`}
                                                        multiple
                                                        onChange={(event) => handleAnotherFile(event, index)}
                                                    />
                                                    <label htmlFor={`inputFile-${index}`}>
                                                        <HStack cursor='pointer'>
                                                            <Text fontSize='sm'>
                                                            {`${t('add')} ${t('image')} / ${t('video')}`}
                                                            </Text>
                                                        </HStack>
                                                    </label>
                                                </Stack>
                                            </HStack>

                                            <SimpleGrid columns={[1, 2, 3]} gap={3}>
                                                {formData.other_files[index].file_url && (
                                                    <a href={formData.other_files[index].file_url} target='_blank'>
                                                        <FcFile size={50} />
                                                    </a>
                                                )}

                                            </SimpleGrid>
                                        </Box>
                                        <Box>
                                            <Text mb='8px'>{t('description')}</Text>
                                            <Input
                                                placeholder='Here is a sample placeholder'
                                                defaultValue={item.description}
                                                size='lg'
                                                name='description'
                                                px={3}
                                                py={4}
                                                borderRadius={'md'}
                                                onChange={(event) => {
                                                    const newDescription = event.target.value;
                                                    setFormData((prevState) => {
                                                        const updatedOtherFiles = [...prevState.other_files];
                                                        updatedOtherFiles[index] = {
                                                            ...updatedOtherFiles[index],
                                                            description: newDescription,
                                                        };
                                                        return {
                                                            ...prevState,
                                                            other_files: updatedOtherFiles,
                                                        };
                                                    });
                                                }}
                                            />
                                        </Box>
                                        {
                                            formData.other_files.length-1 === index ?  
                                            <Stack w={'16'}>
                                                <Button colorScheme='red' onClick={() => deleteAnotherFiles(index)}>{t('delete')}</Button>
                                            </Stack>
                                            :
                                            ""
                                        }
                                    </SimpleGrid>
                                )
                            })
                        }
                        <Button colorScheme='green' onClick={addAnotherFiles}>{t('add')}</Button>
                    </Box>
                </Stack>

                <Stack spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5} h={'2xl'}>
                    <Heading size={'md'} textAlign={'center'} px={5} py={6}>
                        {t('summary')}
                    </Heading>
                    <Stack>
                        <HStack borderBottom={'1px'} borderColor={'gray.300'}>
                            <Text>
                                {t('total')}
                            </Text>
                            <Spacer />
                            <Text>
                                Rp. {formatFrice(productPrice())}
                            </Text>
                        </HStack>
                        <HStack borderBottom={'1px'} borderColor={'gray.300'}>
                            <Text>
                                {t('handling_fee')}
                            </Text>
                            <Spacer />
                            <Text>
                                Rp. {formatFrice(amountFee)}
                            </Text>
                        </HStack>
                        <HStack borderBottom={'1px'} borderColor={'gray.300'}>
                            <Text >
                                {t('grand_total')}
                            </Text>
                            <Spacer />
                            <Text>
                                Rp. {formatFrice(totalInvoice())}
                            </Text>
                        </HStack>

                        <Button colorScheme='green' onClick={() => handleFormSubmit()}>{t('submit')}</Button>
                    </Stack>
                </Stack>
            </SimpleGrid>
        </Stack>
    )
}

export default EscrowCreatePage