import React, { useState } from 'react';
import { Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Stack, Text } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
const NewsCard = ({ title, description, category_name, featured_image, slug }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const parsedDescription = description.replace(
    /<a href=/g,
    '<a style="color: blue; font-style: italic;" target="_blank" rel="noopener noreferrer" href='
  );

  return (
    <Stack w={'full'}>
      <Stack borderWidth="1px" p={4} rounded="md" spacing={3} cursor='pointer'  onClick={() => handleOpenModal()} justifyContent='space-between'>
        <Stack alignItems={'flex-end'} justifyContent='flex-end'>
            <BsArrowRight size={25}/>
        </Stack>
        <Stack>
          <Image src={`https://importir.s3.ap-southeast-1.amazonaws.com/images/${featured_image}`} alt={title} shadow='md' borderRadius={'xl'} />
        </Stack>
        <Stack>
          <Text fontWeight="bold" fontSize={'sm'}>{title}</Text>
          <Text fontSize={'sm'} >{category_name}</Text>
        </Stack>
        <Spacer />

      </Stack>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('description')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack py={5} spacing={5}>
              <Stack>
                <Image
                  src={`https://importir.s3.ap-southeast-1.amazonaws.com/images/${featured_image}`}
                  alt={title}
                  shadow="md"
                  borderRadius="xl"
                />
              </Stack>
              <Stack>
                <Text fontWeight="bold" fontSize="sm">
                  {title}
                </Text>
                <Text fontSize="sm">{category_name}</Text>
              </Stack>
              <Spacer />
              <Stack>
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: parsedDescription }}
                    style={{ maxWidth: '800px', width: '100%', overflow: 'hidden' }}
                  />
                </div>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

    </Stack>
  );
};

export default NewsCard;