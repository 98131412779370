import { Container, Stack, Heading, Divider, Flex, Text, Box, Button, Table, Thead, Tbody, Tr, Th, Td, TableContainer, useToast, HStack, Tag } from '@chakra-ui/react'
import { AiOutlineArrowRight } from "react-icons/ai";
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import moment from 'moment';
import { errorSlack } from '../../Api/SlackApi';
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatFrice, convertDateToDateString } from '../../Utils/Helper';
import { Link, useNavigate } from 'react-router-dom';

function CbmPage() {
    const navigate = useNavigate()
    const [refundBalanceData, setRefundbalanceData] = useState(0)
    const [refundBalanceLogsData, setRefundbalanceLogsData] = useState([])

    const path = window.location.href ?? '#'
    const { loadingShow, loadingClose } = useContext(AuthContext)

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const getRefundBalanceData = async () => {
        try {
            loadingShow()
            const res = await _axios.get('api/refund-transaction/refund-history')
            loadingClose()
            if (res.status === true) {
                setRefundbalanceData(res.data.balance_refund)
                setRefundbalanceLogsData(res.data.balance_refund_logs)
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error'
            })
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    useEffect(() => {
        getRefundBalanceData()
    }, [])

    return (
        <Stack p={[1, 1, 5]} bgColor='white' shadow={'md'} borderRadius='md'>
            <Heading size={'xl'} textAlign={'center'} px={11} py={12} color={'green.300'}>
                Your remaining Refund balance
            </Heading>
            <Divider />
            <Flex justify={'center'} alignItems={'center'} gap={10} px={5} py={6}>
                <Stack alignItems={'center'} justifyContent='center'>
                    <Heading size={'lg'}>Rp.{formatFrice(refundBalanceData)}</Heading>
                </Stack>
            </Flex>

            <TableContainer px={4} py={5}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Order ID</Th>
                            <Th>Invoice Number</Th>
                            <Th>Amount</Th>
                            <Th>Date</Th>
                        </Tr>
                    </Thead>
                    <Tbody fontSize={'sm'}>
                        {refundBalanceLogsData.length > 0 ?
                            refundBalanceLogsData.map((item, index) => {
                                return (
                                    <Tr>
                                        <Td>{index + 1}</Td>
                                        <Td>
                                            <Button onClick={() => navigate('/order/'+ (item.type === 'com' ? 'COM' : "SHP") + item.order_id)}>
                                                {item.type === 'com' ? 'COM' : "SHP"}{ item.order_id }
                                            </Button>
                                        </Td>
                                        <Td>
                                            
                                            <Button onClick={() => navigate('/invoice/'+ (item.type === 'com' ? 'BCOM' : "BSHP") + item.invoice_id)}>
                                                {item.type === 'com' ? 'BCOM' : "BSHP"}{ item.invoice_id }
                                            </Button>
                                        </Td>
                                        <Td>Rp.{ formatFrice(item.amount) }</Td>
                                        <Td>{ convertDateToDateString(item.created_at) }</Td>
                                    </Tr>
                                )
                            })
                            :
                            <Flex flex={1} h={'100%'} alignItems='center' justifyContent={'center'}>
                                <Text color={'gray.600'}>Data not found</Text>
                            </Flex>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>
    )
}

export default CbmPage