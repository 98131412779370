import { Box, Button, Center, Collapse, Divider, HStack, Heading, ListItem, OrderedList, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, UnorderedList, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../Routes/hooks/AuthContext';

function DetailPage() {
  const { loadingShow, loadingClose } = useContext(AuthContext)
  const height = window.innerHeight
  const params = useParams()
  const [hscode, setHscode] = useState(null)
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate()

  const getData = async () => {
    loadingShow()
    const res = await _axios.get(`api/hscode/${params.id}/detail`)
    setHscode(res.data)
    loadingClose()
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack bgColor={'gray.100'} p={[1, 1, 5]} minH={height}>
      <Center>
        <Text fontWeight={'bold'} fontSize={'3xl'}>Detail Hscode {params.id}</Text>
      </Center>
      <Box borderWidth="1px" borderRadius="md">
        <Box as="div" role="tab" id="headingCatatan" p="2" bg="gray.200">
          <Heading as="h4" size="md">
            <Link
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#catatan"
              aria-expanded={isOpen}
              aria-controls="catatan"
              fontWeight="bold"
            >
              Catatan
            </Link>
          </Heading>
        </Box>

        <Collapse in={isOpen}>
          <Box id="catatan" p="2" bgColor={'white'}>
            <Stack spacing="2" ml={4}>
              <Text fontWeight="bold">{hscode?.catatan?.bab_id}</Text>
              <Text fontWeight="bold">{hscode?.catatan?.desc_id}</Text>
            </Stack>
            <HStack mt="10px" />
            <Divider border="1px solid rgb(126, 123, 123)" mt="2"  ml={4} mb="4" />
            <Stack spacing="2" ml={4}>
              <Text fontWeight="bold">{hscode?.catatan?.bab_en}</Text>
              <Text fontWeight="bold">{hscode?.catatan?.desc_en}</Text>
            </Stack>
            <HStack mt="10px" />
          </Box>
        </Collapse>
      </Box>
      <Box borderWidth="1px" borderRadius="md">
        <Box as="div" role="tab" id="headingDetails" p="2" bg="gray.200">
          <Heading as="h4" size="md">
            <Link
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#details"
              aria-expanded={isOpen}
              aria-controls="details"
              fontWeight="bold"
            >
              01012100
            </Link>
          </Heading>
        </Box>

        <Collapse in={isOpen}>
          <Box id="details" p="2" bgColor={'white'}>
            <Box as="div" className="panel-body oke1">
              <Stack spacing="4">
                <Box className="row">
                  <Stack spacing="4">
                    <OrderedList listStyleType="none">
                      <ListItem>
                        <Text fontWeight="bold">{hscode?.bagian?.bagian}</Text>
                        <Text>{hscode?.bagian?.bagian_penjelasan}</Text>
                      </ListItem>
                      <ListItem>
                        <Text fontWeight="bold">{hscode?.bagian?.bab}</Text>
                        <Text>{hscode?.bagian?.bab_penjelasan}</Text>
                      </ListItem>
                      {
                        hscode?.bagian?.hscode_detail.map(x => 
                          <ListItem>
                            <Text fontWeight="bold">{x.hs_code}</Text>
                            <Text>{x.ur}</Text>
                            <Text color="rgb(126, 123, 123)">
                              {x.ur_en}
                            </Text>
                          </ListItem>
                        )
                      }
                    </OrderedList>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <Box borderWidth="1px" borderRadius="md" marginBottom="4" overflow="hidden">
        <Heading as="h4" size="md" backgroundColor="gray.200" padding="2" cursor="pointer">
          <b>MFN (Most Favorite Nation)</b>
        </Heading>
        <Collapse in={isOpen}>
          <Box padding="4" bgColor={'white'}>
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>Nama</Th>
                  <Th>Regulasi</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  hscode?.taxs.map(x =>
                    <Tr>
                      <Td>{x.title}</Td>
                      <Td>
                        {
                          x.regulation !== "" ?
                            <a href={"https://api.insw.go.id/" + x?.files?.replace("./", "")} target="_blank" rel="noreferrer">
                              {x.regulation}
                            </a>
                          : ""
                        }
                      </Td>
                    </Tr>
                  )
                }
              </Tbody>
            </Table>
          </Box>
        </Collapse>
      </Box>
      <Box borderWidth="1px" borderRadius="md" marginBottom="4" overflow="hidden">
        <Heading as="h4" size="md" backgroundColor="gray.200" padding="2" cursor="pointer">
          <b>Tarif Preferensi</b>
        </Heading>
        <Collapse in={isOpen}>
          <Box padding="4" bgColor={'white'}>
            <Table size="sm">
              <Tbody>
                {
                  hscode?.preferential.map((x, i) => 
                    <Tr>
                      <Td>{i+1}</Td>
                      <Td>
                          <Text fontWeight={'bold'}>{x.preference}</Text>  
                          <Text mt={4}>Regulation {x.no_skep}</Text>
                          <Text mt={1}>Berlaku Mulai {x.tgl_skep}</Text>  
                          {
                            x.years.map(ye => 
                              <Text mt={1}>{ye.tahun} : {ye.nilai}</Text>
                            )
                          }
                      </Td>
                    </Tr>
                  )
                }
              </Tbody>
            </Table>
          </Box>
        </Collapse>
      </Box>
      <Box borderWidth="1px" borderRadius="md" marginBottom="4" overflow="hidden">
        <Heading as="h4" size="md" backgroundColor="gray.200" padding="2" cursor="pointer">
          <b>Regulasi Impor (Tataniaga Border(LARTAS))</b>
        </Heading>
        <Collapse in={isOpen}>
          <Box padding="4" bgColor={'white'}>
            {
              hscode?.import_regulation?.map(x => 
                <>
                  <Text mt={3}>Nama Regulasi: {x.name}</Text>  
                  <Text mt={2}>Komoditi {x.komoditi}</Text>
                  <Text mt={2}>Legal: {x.legal}</Text>
                  <Text mt={2}>Deskripsi: {x.deskripsi}</Text>
                  <Divider mt={2}  border="1px solid rgb(126, 123, 123)" ></Divider>
                </>
              )
            }
            {
              hscode?.import_regulation_border?.map(x => 
                <>
                  <Text mt={3}>Nama Regulasi: {x.nama_ijin}</Text>  
                  <Text mt={2}>Komoditi {x.ur_komoditi}</Text>
                  <Text mt={2}>Legal: {x.nomor_skep}</Text>
                  <Text mt={2}>Deskripsi: {x.desc}</Text>
                  <Divider mt={2} ></Divider>
                </>
              )
            }
          </Box>
        </Collapse>
      </Box>
      <Button bgColor={'blue.300'} color={'white'} mt={3} onClick={() => navigate('/hscode')}>Back</Button>
    </Stack>
  )
}

export default DetailPage