import { Box, Stack, Input, SimpleGrid, Text, Button, useToast, Image, InputGroup, InputLeftAddon, InputRightElement } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { MdEmail, MdVpnKey, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import _axios from '../../Api/AxiosBarrier';
import AuthContext from '../../Routes/hooks/AuthContext';
import store from 'store'
import colors from '../../Utils/colors';
import { useTranslation } from 'react-i18next';
function FormChangePassword() {
    const {t} = useTranslation();
    const users = store.get('user_api')
    const { loadingShow, loadingClose } = useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [formData, setFormData] = useState({
        "old_password": '',
        "password": '',
        "confirm_password": '',
        'email': users?.email || ''
    })

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const handleChangeForm = (nameKey, valueKey) => {
        setFormData({ ...formData, [nameKey]: valueKey });
    }

    const changePassword = async () => {
        try {
            loadingShow()
            const data = await _axios.post('api/change-password', formData)
            loadingClose()
            if (data.status) {
                toast({
                    title: 'importir.com',
                    description: data.message,
                    status: 'success',
                });
                setFormData('')
            } else {
                toast({
                    title: 'importir.com',
                    description: data.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error,
                status: 'success',
            });
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfrimPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };
  return (
    <Stack>
        <SimpleGrid columns={2} spacing={10}>
            <Box>
                <Text mb='8px'>{`${t('old')} ${t('password')}`}</Text>
                <InputGroup w={{
                    base: '92%',
                    md: '92%'
                }}>
                    <Input
                    w={{
                        base: '100%',
                        md: '100%'
                    }}
                    placeholder="Password"
                    name='old_password'
                    fontSize={'md'}
                    shadow={'md'}
                    type={showOldPassword ? "text" : "password"}
                    bgColor={'white'}
                    color={colors.black}
                    onChange={(e) => handleChangeForm("old_password", e.target.value)}
                    />
                    <InputRightElement>
                    {showOldPassword ? (
                        <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={toggleOldPasswordVisibility}
                        style={{ cursor: 'pointer' }}
                        />
                    ) : (
                        <MdVisibility
                        size={20}
                        color="black"
                        onClick={toggleOldPasswordVisibility}
                        style={{ cursor: 'pointer' }}
                        />
                    )}
                    </InputRightElement>
                </InputGroup>
            </Box>
            <Box>
                <Text mb='8px'>{t('password')}</Text>
                <InputGroup w={{
                    base: '92%',
                    md: '92%'
                }}>
                    <Input
                    w={{
                        base: '100%',
                        md: '100%'
                    }}
                    placeholder="Password"
                    name='password'
                    fontSize={'md'}
                    shadow={'md'}
                    type={showPassword ? "text" : "password"}
                    bgColor={'white'}
                    color={colors.black}
                    onChange={(e) => handleChangeForm("password", e.target.value)}
                    />
                    <InputRightElement>
                    {showPassword ? (
                        <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                        />
                    ) : (
                        <MdVisibility
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: 'pointer' }}
                        />
                    )}
                    </InputRightElement>
                </InputGroup>
            </Box>
            <Box>
                <Text mb='8px'>{`${t("confirm")} ${t("password")}`}</Text>
                <InputGroup w={{
                    base: '92%',
                    md: '92%'
                }}>
                    <Input
                    w={{
                        base: '100%',
                        md: '100%'
                    }}
                    placeholder="Password"
                    name='confirm_password'
                    fontSize={'md'}
                    shadow={'md'}
                    type={showConfirmPassword ? "text" : "password"}
                    bgColor={'white'}
                    color={colors.black}
                    onChange={(e) => handleChangeForm("confirm_password", e.target.value)}
                    />
                    <InputRightElement>
                    {showConfirmPassword ? (
                        <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={toggleConfrimPasswordVisibility}
                        style={{ cursor: 'pointer' }}
                        />
                    ) : (
                        <MdVisibility
                        size={20}
                        color="black"
                        onClick={toggleConfrimPasswordVisibility}
                        style={{ cursor: 'pointer' }}
                        />
                    )}
                    </InputRightElement>
                </InputGroup>
            </Box>
            <Box>
                <Button colorScheme='blue' mt={8} size='md' onClick={() => changePassword()}>
                    Submit
                </Button>
            </Box>
        </SimpleGrid>
    </Stack>
  )
}

export default FormChangePassword