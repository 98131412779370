import { 
    Stack,
    VStack, 
    Heading, 
    Divider,  
    useToast, 
    Image,
    Grid,
    GridItem,
    Button,
    Text,
    Flex,
    Box,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input
 } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import { errorSlack } from '../../Api/SlackApi';
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatFrice, formatPrice } from '../../Utils/Helper';
import { BsCartPlus, BsCart, BsTrash, BsInfoCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function IndexPage() {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [voucher, setVoucher] = useState([])
    const path = window.location.href ?? '#'
    const { loadingShow, loadingClose, userDb } = useContext(AuthContext)
    const [selectedVoucher, setSelectedVoucher] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [totalPrice, setTotalPrice] = useState(0)
    const [userAnswers, setUserAnswers] = useState([]);
    const [formData, setFormData] = useState({
        "details": []
    });

    const handleAnswerChange = (index, answer) => {
        setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[index] = { question: selectedVoucher?.voucher_details[index]?.question, answer };
            return updatedAnswers;
        });
    };

    const checkIsRequired = (selectedVoucher, result) => {
        let isFalse = false
        selectedVoucher.voucher_details.forEach((x) => {
            if (x.is_required === 1) {
                const selAnswer = result.user_answers.find(rX => rX.question === x.question)
                if (selAnswer.answer === '') {
                    isFalse = true
                }
            }
        })
        return isFalse
    }

    const handleSubmit = async () => {
        // Do something with the collected user answers
        const result = {
          voucher_id: selectedVoucher?.id,
          user_answers: userAnswers,
        };

        const respV = await checkIsRequired(selectedVoucher, result)

        if (respV) {
            toast({
                title: 'importir.com',
                description: "Please answer the required question",
                status: 'error'
            })
            return
        }
      
        // Check if the voucher_id already exists in formData.details
        const voucherExists = formData.details.some(
          (item) => item.voucher_id === result.voucher_id
        );
        if (voucherExists) {
          // Update the existing entry with the new result
          setFormData((prevFormData) => ({
            ...prevFormData,
            details: prevFormData.details.map((item) =>
              item.voucher_id === result.voucher_id ? result : item
            ),
          }));
        } else {
          // Add the new result to formData.details
          setFormData((prevFormData) => ({
            ...prevFormData,
            details: [...prevFormData.details, result],
          }));
        }
        console.log("Format", formData)
        const calcTotalPrice = parseFloat(selectedVoucher?.price) + parseFloat(totalPrice)
        setTotalPrice(calcTotalPrice)
        // Close the modal
        onClose();
    };

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const getVoucherList = async () => {
        try {
            loadingShow()
            const res = await _axios.get('api/voucher')
            loadingClose()
            setVoucher(res)
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error  ,
                status: 'error'
            })
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    const handleAddToCart = (arg) => {
        setSelectedVoucher(arg)
        if (arg?.voucher_details?.length > 0){
            arg.voucher_details.forEach((x, index) => {
                setUserAnswers((prevAnswers) => {
                const updatedAnswers = [...prevAnswers];
                updatedAnswers[index] = { question: x.question, answer: '' };
                    return updatedAnswers;
                });
            })
            onOpen();
        }else{
            const result = {
                voucher_id: arg?.id,
                user_answers: [],
            };
            setFormData((prevFormData) => ({
                ...prevFormData,
                details: [...prevFormData.details, result],
            }));
            const calcTotalPrice = parseFloat(arg.price) + parseFloat(totalPrice)
            setTotalPrice(calcTotalPrice)
        }
    }

    const handleCheckout = async () => {
        try {
            if (formData?.details.length === 0){
                return toast({
                    title: 'importir.com',
                    description: "Cart Is Empty, Cannot Proceed Order Process"  ,
                    status: 'error'
                })
            }
            loadingShow()
            const {status, message, data} = await _axios.post('api/voucher/order/store', formData)
            loadingClose()
            if (!status){
                toast({
                    title: 'importir.com',
                    description: message  ,
                    status: 'error'
                })
            }
            navigate(`/voucher/order/${data.id}`)
        } catch (error){
            toast({
                title: 'importir.com',
                description: error  ,
                status: 'error'
            })
        }
    }

    const cancelVoucher = (id,price) => {
        console.log("show", formData.details)
        const details = formData.details
        let indexV = formData.details.findIndex(x => x.voucher_id === id)
        details.splice(indexV, 1); 
        setFormData((prevFormData) => ({
          ...prevFormData,
          details: details,
        }));

        const calcTotalPrice = parseFloat(totalPrice) - parseFloat(price)
        setTotalPrice(calcTotalPrice)
    }

    useEffect(() => {
        if (userDb !== undefined) {
            const currentDate = new Date();
            const expirationDate = new Date(userDb?.data_importir?.package_user?.expired_at);

            if (currentDate < expirationDate) {
                toast({
                    title: 'importir.com',
                    description: "You don't allow to access this page",
                    status: 'error'
                })
                setTimeout(function() {
                    window.location.href = '/profile' 
                }, 2000);
            } else {
                getVoucherList()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDb])

    return (
        <>
            <VStack p={[1, 1, 5]} bgColor="white" shadow="md" borderRadius="md" h="100vh">
                <Heading size="xl" textAlign="center" my={5} color="green.300">
                    {t('voucher_list')}
                </Heading>
                <Divider />
                <Box flex="1" overflowY="auto">
                    <Grid templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]} gap={5}>
                        {voucher.map((item, index) => (
                            <GridItem key={index}>
                                <Box maxW="sm"
                                    borderRadius="md"
                                    boxShadow="md"
                                    overflow="hidden"
                                    borderLeft={index === 0 ? "2px solid whitesmoke" : "none"}
                                >
                                    <Image
                                    src={item?.image}
                                    alt="Green double couch with wooden legs"
                                    borderRadius="md"
                                    />
                                    <Stack mt="6" spacing="3" p="4">
                                    <Heading size="md" textAlign="center" color="blue.500">
                                        {item?.title}
                                    </Heading>
                                    <Box
                                        textAlign="center"
                                        color="gray.600"
                                        h="200px" // Adjust the max height as per your requirement
                                        overflowY="auto"
                                    >
                                        <Text>{item?.description}</Text>
                                    </Box>
                                    <Text textAlign="center" color="blue.600" fontSize="2xl">
                                        {formatPrice(parseFloat(item?.price))}
                                    </Text>
                                    <Flex justifyContent={"center"}>
                                        {formData?.details.find(x => x.voucher_id === item.id) ? 
                                        <Button colorScheme='red' leftIcon={<BsTrash />} onClick={() => cancelVoucher(item.id, item.price)}>
                                            {t('cancel')}
                                        </Button>
                                        : 
                                        <Button
                                            leftIcon={<BsCartPlus />}
                                            variant="solid"
                                            colorScheme="green"
                                            borderRadius="full"
                                            px={8}
                                            onClick={() => {handleAddToCart(item)}}
                                        >
                                            {t('add_to_cart')}
                                        </Button>}
                                        </Flex>
                                    </Stack>
                                </Box>
                            </GridItem>
                        ))}
                    </Grid>
                    <Flex
                        my={3}
                        bg="gray.200"
                        justifyContent="space-between"
                        borderRadius="10px"
                        p="3"
                        w="100%"
                    >
                        <Text alignSelf="center" fontWeight={'bold'}>{t('total')}: IDR {formatFrice(totalPrice)}</Text>
                        <Button
                            leftIcon={<BsCart />}
                            variant="solid"
                            colorScheme="blue"
                            borderRadius="full"
                            px={8}
                            onClick={handleCheckout}
                        >
                            {t('checkout')}
                        </Button>
                    </Flex>
                </Box>
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('voucher_questions')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedVoucher?.voucher_details?.map((item, index) => {
                            return (
                            <FormControl my={2} key={index}>
                                <FormLabel>{item?.question} {item?.is_required === 1 ? `(${t('required')})` :  `(${t('optional')})`}</FormLabel>
                                {item?.description ? <FormLabel color={'red'} fontSize={'sm'} fontStyle={'italic'} mt={0}>
                                    <Flex>
                                        <BsInfoCircle /> <Text>{item.description}</Text>
                                        </Flex>
                                    </FormLabel> : ''}
                                <Input type='text' onChange={(e) => handleAnswerChange(index, e.target.value)}/>
                            </FormControl>
                            )
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            {t('cancel')}
                        </Button>
                        <Button colorScheme="green" onClick={handleSubmit}>
                            {t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default IndexPage