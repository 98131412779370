import { Button, Grid,  Image, Stack, Text, Container, HStack, Box, Spacer, Heading, useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice';
import _axios from '../../Api/AxiosBarrier';
import { useNavigate } from 'react-router-dom';

function FavoritePage() {
  const [carts, setCarts] = useState([])
  const { userSymbolCurrency } = useContext(AuthContext)
  const navigate = useNavigate()
  const { convertPriceFix } = ConvertPrice()
  const height = window.innerHeight
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [popupImage, setPopupImage] = useState("")

  const getCarts = async () => {
    console.log('getcarts mitra')
    const res = await _axios.get("api/mitra/cart/index")
    if (res.mitra_order_details !== undefined) {
        setCarts(res.mitra_order_details)
    }
  }

  const totalProductPrice = carts.reduce((total, cart) => {
    return total + (cart?.product_price_total || 0);
  }, 0);

  const handlePopupImage = (image) => {
    setPopupImage(image)
    onOpen()
  }

  useEffect(() => {
    getCarts()
  }, [])

  const handleDelete = async (cart) => {
    await _axios.delete("api/mitra/cart/" + cart?._id + "/delete")
    window.location.reload()
  }

  return (
    <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxW="container.xl" position={'relative'} zIndex={'1'} top={'-3rem'}>
      <Stack p={[1, 1, 5]} minH={height}>
        <Stack shadow={'md'} p={[1, 1, 5]} bgColor={'white'}>
          <HStack p={2}>
            <div className='price-total'>
              <Box>
                <Text color={'gray.700'}>Total Price</Text>
                <Heading size={'lg'} fontWeight="extrabold">
                  {userSymbolCurrency}  {formatFrice(convertPriceFix(totalProductPrice))}
                </Heading>
              </Box>
            </div>
            <Spacer />
            <div className='checkout'>
              <Stack alignItems={'center'} justifyContent="center">
                <Button bgColor={'green.600'} size="md" onClick={() => navigate('/mitra/checkout')}>
                  <HStack spacing={2} alignItems="center" justifyContent={'center'}>
                    <Text fontSize={'md'} color="white" fontWeight={'bold'}>
                      Checkout
                    </Text>
                  </HStack>
                </Button>
              </Stack>
            </div>
          </HStack>
        </Stack>
          {carts.length > 0 ?
              carts.map((cart, index) => (
                  <div className='list-product'>
                      <Stack bgColor={'white'} key={index} spacing={1} borderRadius="xl" p={2} pb={8} shadow={'md'}>
                          <Stack onClick={() => handleDelete(cart)} alignItems="flex-end" cursor={'pointer'}>
                              <AiOutlineCloseCircle size={22} color="red" />
                          </Stack>
        
                        <Grid templateColumns={{ base: "1fr", md: "1fr 5fr 3fr 2fr 1fr" }} gap={2} p={3} alignItems="center" justifyContent={'space-around'}>
        
                            <Stack shadow={5}>
                                <Image src={cart?.image[0]} cursor={'pointer'} alt={cart?.title} borderRadius={'xl'} w="150px" onClick={() => handlePopupImage(cart?.image[0])}/>
                            </Stack>
        
                            <Stack spacing={1}>
                                <Text numberOfLines={2} fontWeight="bold" fontSize="sm">
                                {cart?.title}
                                </Text>
                                <Text color={'gray.700'} fontSize="sm">
                                {userSymbolCurrency} {formatFrice(convertPriceFix(cart?.price_per_pcs))} / pcs
                                </Text>
                                <Text color={'gray.700'} fontSize="sm">
                                Quantity : {cart?.quantity_lot_order}
                                </Text>
                                <Text color={'gray.700'} fontSize="sm">
                                Per lot : {cart?.per_lot} pcs
                                </Text>
                            </Stack>
        
                            <Stack spacing={1}>
                              <Stack spacing={0}>
                                <Text color={'gray.700'} fontSize="sm">
                                    Total
                                </Text>
                                <Text color={'green.700'} fontWeight="bold">
                                    {userSymbolCurrency} {formatFrice(convertPriceFix(cart?.product_price_total))}
                                </Text>
                              </Stack>
                            </Stack>
                        </Grid>
                      </Stack>
                    </div>
                ))    
              : 
              <Stack h={height / 2} alignItems="center" justifyContent="center">
                  <Image src="https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/empty-cart-2023_06_23_11_04_12.png" w={'sm'}></Image>
                  <Text textColor={'gray'} fontWeight={'bold'} fontSize={'2xl'}>Whoa, your shopping cart is empty</Text>
                  <Text textColor={'gray'}>Let's fill it with your dream items!</Text>
              </Stack>  
          }

      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Image src={popupImage}></Image>
            </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  )
}

export default FavoritePage