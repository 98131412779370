import { useState } from 'react';
import { AspectRatio, Button, HStack, Image, SimpleGrid, Skeleton, Spacer, Stack, Tag, Text, useToast } from '@chakra-ui/react';
import { HiHeart, HiOutlineHeart } from 'react-icons/hi';
import { formatFrice } from '../../Utils/Helper';
import ConvertPrice from '../../Utils/ConvertPrice';
import { errorSlack } from '../../Api/SlackApi';
import { useTranslation } from 'react-i18next';



const ProductCard = ({ product, kursRmb, handleNavigateProduct, handleWishlist, handleImage, wishlistFilter, userSymbolCurrency, nonLartasFilter }) => {
  const {t} = useTranslation();
  const path = window.location.href ?? '#';

  const [isLoading, setIsLoading] = useState(true);

  const toast = useToast({
    position: 'top',
    align: 'center',
  });

  const { convertPriceFix } = ConvertPrice();

  const handleOrderNow = async () => {
    setIsLoading(true);

    const platform = getProductPlatform() === "CN 🇨🇳" ? "1688" : getProductPlatform() === "JPN 🇯🇵" ? "amazonjapan" : getProductPlatform() === "USA 🇺🇸" ? "amazonus" : getProductPlatform() === "KOREA 🇰🇷" ? 'coupang' : getProductPlatform() === "CN Taobao 🇨🇳" ? "taobao" : "";

    try {
      let productIdLocal = product.id
      if (platform === 'coupang') {
        productIdLocal = product.id.split("-")[1]
      }


      await handleNavigateProduct(productIdLocal, platform);
    } catch (error) {
      const res = await errorSlack(error, 'importir.com', path);
      console.log(res, 'CONNECTED ERROR IN SLACK');
      toast({
        title: 'importir.com',
        description: 'Failed to navigate to the product page.',
        status: 'error',
      });
    }
  };

  const getProductPlatform = () => {
    const productLink = product.link.toLowerCase();

    if (productLink.includes('1688.com')) {
      return 'CN 🇨🇳';
    } else if (productLink.includes('https://www.amazon.co.jp')) {
      return 'JPN 🇯🇵';
    } else if (productLink.includes('https://www.amazon.com')) {
      return 'USA 🇺🇸';
    } else if (productLink.includes('https://www.coupang.com')) {
      return 'KOREA 🇰🇷';
    } else if (productLink.includes('taobao.com')) {
      return 'CN Taobao 🇨🇳';
    }
  };

  const calculatePrice = () => {
    const productPlatform = getProductPlatform();
    const price = Number(product.price);

    if (['CN 🇨🇳', 'CN Taobao 🇨🇳'].includes(productPlatform)) {
      return Number(kursRmb) * price;
    } else if (productPlatform === 'JPN 🇯🇵') {
      return 106, 52 * price;
    }
    else if (productPlatform === 'USA 🇺🇸') {
      return 15298 * price;
    }
    else {
      return price;
    }
  };

  const formattedPrice = isNaN(calculatePrice()) ? 0 : convertPriceFix(calculatePrice());

  return (
    <Stack shadow='md' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
      <Stack alignItems='center' cursor='pointer' onClick={() => handleImage(product)}>
        {product.image ? (
            <Image 
            src={product.image} 
            alt='img'  
            borderRadius='md' 
            style={{ width: '100% ', height:'250px', objectFit: 'cover' }}
            onLoad={() => setIsLoading(false)} 
            onError={() => setIsLoading(false)} />
        ) : (
          <Skeleton w='200px' h='300px' borderRadius='md' isLoaded={!isLoading} startColor='gray.100' endColor='gray.300' />
        )}
      </Stack>
      <Stack px={3}>
        <HStack alignItems='center' justifyContent='flex-end'>
          <Stack>
            <Tag size='sm'>{getProductPlatform()} </Tag>
          </Stack>

          <Stack>
            {nonLartasFilter ? (
              <Tag colorScheme={'yellow'} size='sm'>Non Lartas</Tag>
            ) : (
              <Tag colorScheme={'red'} size='sm'>Lartas</Tag>
            )}
          </Stack>

          {wishlistFilter ? (
            <Stack>
              <HiHeart style={{ fontSize: 20, color: 'red' }} />
            </Stack>
          ) : (
            <Stack onClick={() => handleWishlist(product)} cursor='pointer'>
              <HiOutlineHeart style={{ fontSize: 20, color: 'gray' }} />
            </Stack>
          )}

        </HStack>
        <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={2}>
          {
            product['title-en'] !== undefined ?
              product['title-en']['en']
              : ""
          }
        </Text>
      </Stack>
      <Spacer />
      <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
        <Stack spacing={0} alignItems='flex-start' justifyContent='flex-start'>
          <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
            {t("price")}
          </Text>
          <Text textTransform='capitalize' fontWeight='bold' color='black' fontSize='sm'>
            {userSymbolCurrency} {formatFrice(formattedPrice)}
          </Text>
        </Stack>
        <Stack>
          <Stack spacing={0} alignItems='flex-end' justifyContent='flex-end'>
            <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
              {`${t("sold")}/${t("item")}`}Sold/item
            </Text>
            <Text textTransform='capitalize' color='black' fontWeight='bold' fontSize='sm'>
              {product.sold}
            </Text>
          </Stack>
        </Stack>
      </SimpleGrid>
      <Stack p={3}>
        <Button size='sm' bgColor='green.400' onClick={() => handleOrderNow()} isLoading={isLoading}>
          <Text color='white'>🛒 {t("order_now")}</Text>
        </Button>
      </Stack>
    </Stack>
  );
};

export default ProductCard;
