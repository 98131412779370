/* eslint-disable react-hooks/exhaustive-deps */
import {
  Image,
  Box,
  Stack,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../Routes/hooks/AuthContext";
import { formatFrice } from "../../Utils/Helper";
import _axios from "../../Api/AxiosBarrier";
import { useTranslation } from "react-i18next";
function CatalogPage() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [loading] = useState(false);
  const [catalog, setCatalog] = useState([]);
  const { loadingShow, loadingClose } = useContext(AuthContext);
  const param = useParams();
  const { kursRmb, userSymbolCurrency } = useContext(AuthContext);

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const fetcDataProduct = async () => {
    try {
      loadingShow();
      const { status, data } = await _axios.get(
        `/api/catalog-custom/${param.slug}`
      );
      loadingClose();
      if (status === true) {
        setCatalog(data);
      }
    } catch (error) {
      toast({
        title: "importir.com",
        description: error,
        status: "error",
      });
    }
  };

  useEffect(() => {
    fetcDataProduct();
  }, []);
  return (
    <Stack p={[1, 1, 5]}>
      {loading ? (
        <Stack h="100%" w="100%" alignItems={"center"} justifyContent="center">
          <Spinner />
        </Stack>
      ) : (
        <Stack mt={[10,10, 0]}>
          <Box p={[null, null, 5]}>
            <Stack>
              {catalog.banner_image !== "" ? (
                <Image
                  src={catalog.banner_image}
                  alt="Carousel Image"
                  h={["auto", "auto", "400px"]}
                  w="100%"
                />
              ) : (
                <Text>Banner Image</Text>
              )}
            </Stack>
          </Box>
          <Box p={[null, null, 5]}>
            <Stack
              textAlign="center"
              bg="white"
              borderRadius="md"
              px={5}
              py={3}
            >
              <Heading size={{base: 'xs', md: 'md', lg: 'lg'}} fontWeight={"bold"}>
                {catalog.info}
              </Heading>
            </Stack>
          </Box>

          <Stack p={5} spacing={3}>
            {catalog?.categories?.length > 0 ? (
              catalog?.categories?.map((item, key) => {
                return (
                  <Stack bgColor="white" p={4}>
                    <Heading color={"gray.700"} p={2} size={{base: 'xs', md: 'md', lg: 'lg'}} borderBottom={'1px'} borderColor={'gray.200'}>
                      {item?.category_title}
                    </Heading>
                    <Stack>
                      <SimpleGrid columns={[1, 2, 3, 4]} spacing={2}>
                        {item?.products?.map((data, index) => {
                          return (
                            <Stack
                              shadow="md"
                              key={index}
                              borderRadius={"xl"}
                              spacing={2}
                              bgColor="white"
                            >
                              <Stack alignItems={"center"} cursor="pointer">
                                <Image
                                  src={data?.image}
                                  alt="img"
                                  borderRadius={"md"}
                                />
                              </Stack>
                              <Stack px={3}>
                                <Text
                                  textTransform={"capitalize"}
                                  fontWeight="bold"
                                  fontSize={"sm"}
                                  noOfLines={2}
                                >
                                  {" "}
                                  {data?.title !== "" ? data?.title : "-"}
                                </Text>
                              </Stack>

                              <SimpleGrid
                                columns={[1, 2, 2]}
                                alignItems={"flex-end"}
                                px={4}
                                spacing={0}
                              >
                                <Stack spacing={0}>
                                  <Text
                                    textTransform={"capitalize"}
                                    fontWeight="extrabold"
                                    color={"gray.600"}
                                    fontSize={"sm"}
                                  >
                                    {t('price')}
                                  </Text>
                                  <Text
                                    textTransform={"capitalize"}
                                    fontWeight="extrabold"
                                    color={"black"}
                                    fontSize={"md"}
                                  >
                                    {userSymbolCurrency}{" "}
                                    {formatFrice(Number(kursRmb) * data.price)}
                                  </Text>
                                </Stack>
                              </SimpleGrid>

                              <Stack p={3}>
                                <Button
                                  size={"sm"}
                                  bgColor="green.400"
                                  onClick={() =>
                                    navigate(
                                      `/product/${data.flag}/${data.product_id}`
                                    )
                                  }
                                >
                                  <Text color={"white"}>🛒 {t('order_now')}</Text>
                                </Button>
                              </Stack>
                            </Stack>
                          );
                        })}
                      </SimpleGrid>
                    </Stack>
                  </Stack>
                );
              })
            ) : (
              <Stack alignItems="center" justifyContent={"center"}>
                <Text color={"gray.600"}>{t('data_not_found')}</Text>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default CatalogPage;
