import store from "store";
export function addFacebookPixel() {
	const script = document.createElement("script");
	const webConfig = store.get("webConfig");
	const pixelId = webConfig.facebook ?? "3918751638161726";
	script.textContent = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
  `;
    script.setAttribute('id', 'fb_pixel_euyyy')
	// Append the script to the body
	return document.head.appendChild(script);
}

export function triggerEventFacebookPixel(event, data) {
  const allowedEvent = ['Login', 'Logout', 'ViewContent', 'AddToCart', 'InitiateCheckout', 'Search'];
  if (!allowedEvent.includes(event)){
    return console.log(`Cannot Trigger Facebook Pixel Event: ${event}`);
  }
  if (window.fbq) return window.fbq('track', event, data);
}
