/* eslint-disable react-hooks/exhaustive-deps */
import { Heading, SimpleGrid, Stack, Button, Input, InputGroup, InputLeftElement, HStack, Text, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { SlArrowDown } from 'react-icons/sl';
import { errorSlack } from '../Api/SlackApi';
import NewsCard from '../Components/Card/NewsCard';
import AppImageSlide from '../Components/Carousel/AppImageSlide';
import AuthContext from '../Routes/hooks/AuthContext';
import store from 'store'
import { useTranslation } from 'react-i18next';

function NewsPage() {
    const {t} = useTranslation();
    // const [dataNews, setDataNews] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const [loadCount, setLoadCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [imageCarousel, setImageCarousel] = useState([])
    const [loading, setLoading] = useState(false)

    const { dataNews } = useContext(AuthContext)

    const path = window.location.href ?? '#'
    const packageUser = store.get('package_user')


    const getDataNews = async () => {
        setLoading(true)
        try {
            // const res = await axios.get('https://new-apiv2.'importir.com'/api/news');
            // setDataNews(res.data);
            const imageArr = dataNews.map((item, index) => ({
                "_id": item.slug,
                "main_banner": `https://importir.s3.ap-southeast-1.amazonaws.com/images/${item.featured_image}`,
                "country": "all",
                "is_active": 1,
                "data": "#",
                "sort": index + 1,
                "created_at": "2024-02-07T11:18:24.000000Z",
                "updated_at": "2024-03-13T04:59:19.563000Z"
            }));
            console.log(imageArr)
            setImageCarousel(imageArr);
            setLoading(false)
        } catch (error) {
            console.log(error, 'ini error');
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            setLoading(false)
        }
        setLoading(false)
    };

    const loadMore = () => {
        const newData = dataNews.slice(loadCount, loadCount + 20);
        setDisplayedData(prevData => [...prevData, ...newData]);
        setLoadCount(prevCount => prevCount + 20);
    };

    useEffect(() => {
        getDataNews();
    }, []);

    useEffect(() => {
        if (dataNews.length > 0) {
            const newData = dataNews.slice(0, 20);
            setDisplayedData(newData);
            setLoadCount(20);
        }
    }, [dataNews]);

    const handleSearch = () => {
        // Filter data berdasarkan judul (title) dan deskripsi (description)
        const filteredData = dataNews.filter(
            (item) =>
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.category_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setDisplayedData(filteredData);
        setLoadCount(20); // Set ulang loadCount ke 20 karena jumlah data yang ditampilkan berubah

        // Mengatur data yang sudah difilter ke state
        // setDataNews(filteredData); <-- Hapus baris ini karena menggunakan displayedData untuk menampilkan data yang sudah difilter
    };

    return (
        <Stack p={[1, 1, 5]}>
            <Stack alignItems="center" justifyContent="center">
                <Stack p={5} bgColor="white" borderRadius="xl" shadow="md">
                    <Stack py={3}>

                        <Heading size="md">{t('latest_news')}</Heading>

                    </Stack>
                    <Stack>

                        <Stack>
                            {loading ? (
                                <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                                    <Spinner />
                                </Stack>
                            ) : (
                                imageCarousel && (
                                    <AppImageSlide images={imageCarousel && imageCarousel} />
                                )
                            )}

                        </Stack>
                        <Stack alignItems={'center'} justifyContent='center' p={3}>
                            <Stack w={['100%', '100%', '50%']} spacing={4}>

                                <InputGroup size={'sm'} >
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<BiSearchAlt2 color='gray.300' />}
                                    />
                                    <Input
                                        bgColor='white' size={'sm'} borderRadius='md'
                                        placeholder={t('search_pl_news_page')}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <Button ml={3} bgColor='#ffd600' onClick={handleSearch}>
                                        {t('search')}
                                    </Button>
                                </InputGroup>


                            </Stack>
                        </Stack>
                        <SimpleGrid columns={[1, 2, 4]} gap={3}>
                            {
                                packageUser?.package?.id !== 8 ?
                                <>
                                    {displayedData.map((item, index) => (
                                        <NewsCard
                                            key={index}
                                            title={item.title}
                                            description={item.description}
                                            category_name={item.category_name}
                                            featured_image={item.featured_image}
                                            slug={item.slug}
                                        />
                                    ))}
                                </>
                                    :
                                    <Text></Text>
                            }
                            </SimpleGrid>
                    </Stack>

                    {loadCount < dataNews.length && (
                        <Stack pt={5} alignItems='center' justifyContent={'center'}>
                            <Button onClick={() => loadMore()} bgColor='#ffd600' shadow={'md'}>
                                <HStack alignite>
                                    <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                                        {t('load_more')}
                                    </Text>
                                    <Stack>
                                        <SlArrowDown size={10} />
                                    </Stack>
                                </HStack>
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default NewsPage;
