import { Stack, Card, CardHeader, CardBody, Button, useToast, Heading, StackDivider, Box, Text, Flex, Link } from '@chakra-ui/react'
import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import AuthContext from '../../Routes/hooks/AuthContext'
import _axios from '../../Api/AxiosBarrier'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice'

function DetailPage() {
    const [detail, setDetail] = useState({})
    const {loadingShow, loadingClose, userSymbolCurrency} = useContext(AuthContext)
    const toast = useToast({
        position: 'top',
        align: 'center',
    });
    const params = useParams()
    const {convertPriceFix} = ConvertPrice()
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Tambahkan 1 karena bulan dimulai dari 0, dan pad dengan 0 jika hanya satu digit.
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const [payload, setPayload] = useState(null)
    const [payloadID, setPayloadID] = useState(null)

    const fetchDetail = async () => {
        try {
            loadingShow()
            const res = await _axios.get(`api/register/${params.id}/detail-po`)
            loadingClose()
            if (res.status) {
                setDetail(res.data)
                if (res.data.package_order_payment !== null) {
                    setPayload(res.data.package_order_payment.payment_url);
                    setPayloadID(res.data.package_order_payment.id);
                }
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error,
                status: 'error',
            });
        }
    }

    useEffect(() => {
        fetchDetail()
        const snapSrcUrl = process.env.REACT_APP_MIDTRANS_URL;
        const myMidtransClientKey = process.env.REACT_APP_MIDTRANS_CLIENT_KEY;

        const script = document.createElement('script');
        script.src = snapSrcUrl;
        script.setAttribute('data-client-key', myMidtransClientKey);
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [])

    const showMidtrans = () => {
        window.snap.pay(detail.package_order_payment.payment_id, {
            onSuccess: function(result){
              /* You may add your own implementation here */
              console.log("pending")
              
            },
            onPending: function(result){
              /* You may add your own implementation here */
              console.log("pending")
              
            },
            onError: function(result){
              /* You may add your own implementation here */
              console.log("pending")
              
            },
            onClose: function(){
              /* You may add your own implementation here */
              console.log("pending")
              
            }
          })
    }

    const makePayment = async (type) => {
        try {
            loadingShow()
            let paramsPayment = {
                inv_registrasi: params.id,
                payment_method: "INVOICE_TRANSFER_VA",
                redirect_url: window.location.origin + `/package/${params.id}`
            };
            if (type === 'snap_token_midtrans') paramsPayment.payment_method = 'bank_transfer';
            const {status, messasge, data} = await _axios.post(`api/register/${type}/register-payments`, paramsPayment);
            loadingClose()
            if (status) {
                if (type === 'xendit'){
                    setPayload(data);
                    setPayload(data);
                    return window.location.href = data;
                }else{
                    window.location.reload()
                }
            } else {
                return toast({
                    title: 'importir.com',
                    description: messasge,
                    status: 'error',
                });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error,
                status: 'error',
            });
        }
    }

    const cancelPayment = async (id) => {
        try {
            loadingShow()
            const {status, messasge, data} = await _axios.delete(`api/register/${id}/cancel-payments`);
            loadingClose()
            if (status) {
                setPayload(null);
                return setPayload(null)
            } else {
                return toast({
                    title: 'importir.com',
                    description: messasge,
                    status: 'error',
                });
            }
        } catch (error) {
            loadingClose()
            toast({
                title: 'importir.com',
                description: error,
                status: 'error',
            });
        }
    }
  return (
    <Stack p={[1, 1, 5]} bgColor={'white'}>
        <Flex justifyContent={'center'} alignContent={'center'}>
            <Card w={'xl'} textAlign={'center'}>
                <CardHeader>
                    <Heading size='md'>Information</Heading>
                </CardHeader>

                <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Status
                        </Heading>
                        <Text pt='2' fontSize='sm' color={'red.300'} fontWeight={'bold'}>
                            { formattedDateTime > detail?.package_order_payment?.expired_at ? 'Expired' : 'Available' }
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                           Expired At
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            { detail?.package_order_payment?.expired_at }
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Package
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {detail?.package_child?.name}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                           Payment Total
                        </Heading>
                        <Text pt='2' fontSize='sm' color={'red.300'} fontWeight={'bold'}>
                            {userSymbolCurrency} {formatFrice(convertPriceFix(parseInt(detail?.total)))}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Paid at
                        </Heading>
                        <Text pt='2' fontSize='sm' fontWeight={'bold'}>
                            {detail?.paid_at !== null ? <Text color={'green.300'}>Paid</Text> : <Text color={'red.300'}>Not Paid</Text>}
                        </Text>
                    </Box>
                    </Stack>
                </CardBody>
                <Stack p={4}>
                    {
                        formattedDateTime > detail?.package_order_payment?.expired_at ? 
                        <Stack>
                            <Text color={'red.300'}>Your membership order package has expired, please purchase another package.</Text>
                            <Button colorScheme='green'>Buy Package</Button>
                        </Stack>
                        :
                        detail?.paid_at == null && payload == null ? 
                            <Stack>
                                <Button onClick={() => {makePayment('snap_token_midtrans')}}
                                        colorScheme='blue'>
                                    Pay with BCA
                                </Button>
                                <Button onClick={() => {makePayment('xendit')}}
                                        colorScheme='yellow'>
                                    Pay with transfer
                                </Button>
                            </Stack>
                        : 
                            <Stack>
                                {payload !== null && detail?.paid_at == null?
                                   <>
                                        {!payload.includes("BCA") ?
                                            payload === "" ?
                                                <>
                                                    <Text>You choose pay with BCA</Text>
                                                    <Button colorScheme='yellow' onClick={() => showMidtrans()}>Continue payment with BCA</Button>
                                                </>
                                            :
                                                <>
                                                    <Text>You choose pay with transfer</Text>
                                                    <Link href={payload}>
                                                        <Button colorScheme='yellow'>Continue Payment with Transfer</Button>
                                                    </Link>
                                                </>
                                        : 
                                            <Text>Transfer To: {payload}</Text>
                                        }
                                        <Button onClick={() => {cancelPayment(payloadID ? payloadID : detail?.package_order_payment?.id)}} colorScheme='red'>
                                            Cancel Payment
                                        </Button>
                                   </>
                                : ""}
                            </Stack>
                    }
                    
                </Stack>
            </Card>
        </Flex>
    </Stack>
  )
}

export default DetailPage