import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Input,
  Textarea,
  Select,
  Text,
  Box,
  Flex,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { Stepper } from "react-form-stepper";
import { useToast } from "@chakra-ui/react";
import _axios from "../../Api/AxiosBarrier";
import { formatFrice } from "../../Utils/Helper";
import { db } from "../../Config/firebase";
import { doc, setDoc } from "firebase/firestore";
import _axiosChina from "../../Api/AxiosChina";
import { errorSlack } from "../../Api/SlackApi";
import { useNavigate } from "react-router-dom";

function PaymentOrderNoMember({ data, is_lartas, platform }) {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [numberPhone, setNumberPhone] = useState("");
  const [recipients, setRecipients] = useState("");
  const [city, setCity] = useState("");
  const [cityFix, setCityFix] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [address, setAddress] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [logistic, setLogistic] = useState([]);
  const [selectedLogistic, setSelectedLogistic] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [submittingData, setSubmittingData] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState({});
  const [loading, setLoading] = useState(false)

  const [hasPaid, setHasPaid] = useState(false)

  const timeout = useRef(null);

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const navigate = useNavigate()

  const path = window.location.href ?? '#'


  const getDataSearch = async () => {
    await _axios
      .get(`api/address/search?keyword=${city}`, {
        headers: {
          Authorization: "Bearer 28618|bDNufNm82NohKj6c21p94kiiAUyaVORCGuhUWqFl",
        },
      })
      .then((res) => {
        setCityArr(res.data);
      })
      .catch((err) => console.log(err));
  };

  const fetchLogistic = async () => {
    await _axios
      .get(
        `api/delivery?weight=1&province=${cityFix[2]}&city=${cityFix[1]}&district=${cityFix[0]}`
      )
      .then((res) => {
        setLogistic(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const payloadData = {
    carts: [
      {
        variants: data.variants,
        variant_type: data.variant_type,
        price_type: data.price_type,
        quantity: data.quantity,
        freight: data.freight,
        product_id: data.product_id,
        weight: data.weight,
        cbm: data.cbm,
        shipping_method: String(selectedLogistic[1]),
        shipping_fee: Number(selectedLogistic[0]),
      },
    ],
    email: email,
    receiver_email: email,
    address: {
      receiver: recipients,
      phone: numberPhone,
      province: cityFix[2],
      city: cityFix[1],
      postal_code: postal_code,
      district: cityFix[0],
      address: address,
    },
  };

  const makeOrder = async () => {
    setSubmittingData(true);
    await _axios
      .post("api/carts/add-to-cart-checkout", payloadData, {
        headers: {
          Authorization: "Bearer 28618|bDNufNm82NohKj6c21p94kiiAUyaVORCGuhUWqFl",
        },
      })
      .then((res) => {
        if (res.status) {
          setOrderPlaced(res.data);
          setStep(4);
          setSuccessOrder(true);
          setSubmittingData(false);
        }

        if (!res.status) {
          setSubmittingData(false);
          toast({
            title: "importir.com",
            description: res.message,
            status: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmittingData(false);
        toast({
          title: "importir.com",
          description: err.message,
          status: "error",
        });
      });
  };

  const debounceHandler = (value) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setCity(value);
    }, 1000);
  };

  const handlePay = async () => {
    setLoading(true);
  
    let updateData = {
      ...orderPlaced,
      status: 'UNPAID',
      name: recipients,
      phone_number: numberPhone,
      email: email,
      paid_updated_at: new Date()
    };
  
    try {
      const docId = orderPlaced.invoice; // Custom doc ID
      const docRef = doc(db, 'payments', docId);
  
      // Menambahkan dokumen baru dengan custom doc ID
      await setDoc(docRef, updateData, { merge: true });
  
      // if (is_lartas > 0) {
      //   try {
      //     const res = await _axiosChina.get(`payment/order/v2/${updateData?.invoice}/init-payment`, {
      //       headers: {
      //         Authorization: "Bearer 28618|bDNufNm82NohKj6c21p94kiiAUyaVORCGuhUWqFl",
      //       },
      //     });
  
      //     if (res.status === true) {
      //       window.open(res.data.redirect_url, 'blank');
      //       toast({
      //         title: 'importir.com',
      //         description: 'success create payment',
      //         status: 'success'
      //       });
      //       setHasPaid(true);
      //     } else {
      //       toast({
      //         title: 'importir.com',
      //         description: res.message,
      //         status: 'error'
      //       });
      //       const resSlack = await errorSlack(res.message, 'importir.com', path);
      //       console.log(resSlack, 'CONNECTED ERROR IN SLACK');
      //     }
      //   } catch (error) {
      //     console.log(error, 'ini error');
      //     toast({
      //       title: 'importir.com',
      //       description: error.message,
      //       status: 'error'
      //     });
      //   }
      // } else {
        navigate(`/invoice-nomember/${updateData.invoice}`);
      // }
    } catch (error) {
      console.log('Terjadi kesalahan:', error);
      toast({
        title: 'importir.com',
        description: error.message,
        status: 'error'
      });
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (city.length > 0) {
      getDataSearch();
    }

    if (step === 2) {
      fetchLogistic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, step]);

  return (
    <Box padding={5}>
      <Stepper
        steps={[
          { label: "Info Detail" },
          { label: "City Address" },
          { label: "Shipping" },
          { label: "Payment" },
        ]}
        styleConfig={{
          activeBgColor: "#FFD600",
          activeTextColor: "black",
          completedBgColor: "#48BB78",
          completedTextColor: "black",
          fontWeight: 600,
        }}
        activeStep={step}
      />

      {step === 0 && (
        <Box>
          <Text fontWeight={600} fontSize={17} textAlign="center">
            Info Detail
          </Text>

          <Input
            placeholder="Email"
            value={email}
            mt={5}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <Input
            type="number"
            value={numberPhone}
            placeholder="Number Phone"
            mt={5}
            onChange={(e) => {
              setNumberPhone(e.target.value);
            }}
          />

          <Input
            placeholder="Recipients"
            value={recipients}
            mt={5}
            onChange={(e) => {
              setRecipients(e.target.value);
            }}
          />

          <Button
            width="100%"
            colorScheme="green"
            mt={10}
            onClick={() => {
              // eslint-disable-next-line no-lone-blocks
              {
                email.length === 0 ||
                  numberPhone.length === 0 ||
                  recipients.length === 0
                  ? toast({
                    title: "importir.com",
                    description: "Lengkapi form data",
                    status: "warning",
                  })
                  : setStep((prev) => prev + 1);
              }
            }}
          >
            Next
          </Button>
        </Box>
      )}

      {step === 1 && (
        <Box>
          <Text fontWeight={600} fontSize={17} textAlign="center">
            City And Address
          </Text>

          <Input
            onChange={(e) => debounceHandler(e.target.value)}
            placeholder="City/District. Ex: Jakarta"
            mt={5}
          />

          {city.length > 0 && (
            <Select
              value={cityFix}
              fontSize="md"
              bgColor={"white"}
              placeholder="Pilih alamat"
              onChange={(e) => setCityFix(e.target.value.split(","))}
              mt={5}
            >
              {cityArr.map((x, index) => (
                <option value={x} key={index}>
                  {x}
                </option>
              ))}
            </Select>
          )}

          <Input
            value={postal_code}
            type="number"
            onChange={(e) => setPostal_code(e.target.value)}
            placeholder="Postal Code"
            mt={5}
          />

          <Textarea
            value={address}
            placeholder="Full Address"
            onChange={(e) => setAddress(e.target.value)}
            mt={5}
          />

          <Flex flexDirection="row" gap={10} mt={10}>
            <Button
              width="100%"
              colorScheme="red"
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            >
              Back
            </Button>

            <Button
              width="100%"
              colorScheme="green"
              onClick={() => {
                // eslint-disable-next-line no-lone-blocks
                {
                  cityFix.length === 0 ||
                    postal_code.length === 0 ||
                    address.length === 0
                    ? toast({
                      title: "importir.com",
                      description: "Lengkapi form data",
                      status: "warning",
                    })
                    : setStep((prev) => prev + 1);
                }
              }}
            >
              Next
            </Button>
          </Flex>
        </Box>
      )}

      {step === 2 && (
        <Box>
          <Text fontWeight={600} fontSize={17} textAlign="center">
            Shipping
          </Text>

          <Select
            value={selectedLogistic}
            fontSize="md"
            bgColor={"white"}
            placeholder="Pilih Logistik"
            onChange={(e) => {
              setSelectedLogistic(e.target.value.split(","));
            }}
            mt={5}
          >
            {logistic?.map((data, index) => (
              <option value={[data.fee_total, data.service_code]} key={index}>
                {data.service_code}
              </option>
            ))}
          </Select>

          <Text mt={5}>Destination:</Text>
          <Text fontWeight={500}>
            {address}, {cityFix.join(" ")},{postal_code}
          </Text>

          {selectedLogistic.length !== 0 && (
            <>
              <Text mt={3}>Service Courier:</Text>
              <Text fontWeight={500}>{selectedLogistic[1]}</Text>

              <Text mt={3}>Fee Total:</Text>
              <Text fontWeight={500}>
                Rp {Number(selectedLogistic[0])?.toLocaleString()}
              </Text>
            </>
          )}

          <Flex flexDirection="row" gap={10} mt={10}>
            <Button
              width="100%"
              colorScheme="red"
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            >
              Back
            </Button>

            <Button
              width="100%"
              colorScheme="green"
              onClick={() => {
                // eslint-disable-next-line no-lone-blocks
                {
                  selectedLogistic.length === 0
                    ? toast({
                      title: "importir.com",
                      description: "Lengkapi form data",
                      status: "warning",
                    })
                    : setStep((prev) => prev + 1);
                }
              }}
            >
              Next
            </Button>
          </Flex>
        </Box>
      )}

      {step === 3 && (
        <Box>
          <Text fontWeight={600} fontSize={17} textAlign="center">
            Summary
          </Text>

          <Text mt={3}>Product Name:</Text>
          {data.variants.map((list) => (
            <Flex
              direction="row"
              justifyContent="space-between"
              key={list.skuId}
            >
              <Text fontWeight={500}>{list.name}</Text>
              <Text fontWeight={500}>{list.quantity}x</Text>
            </Flex>
          ))}

          <Text mt={3}>Cara Pembayaran:</Text>
          <Text fontWeight={500}>{selectedPayment}</Text>

          <Text mt={2}>Email:</Text>
          <Text fontWeight={500}>{email}</Text>

          <Text mt={2}>Number Phone:</Text>
          <Text fontWeight={500}>{numberPhone}</Text>

          <Text mt={2}>Recipients Name:</Text>
          <Text fontWeight={500}>{recipients}</Text>

          <Text mt={2}>City:</Text>
          <Text fontWeight={500}>{cityFix.join(",")}</Text>

          <Text mt={2}>Postal Code:</Text>
          <Text fontWeight={500}>{postal_code}</Text>

          <Text mt={2}>Full Address:</Text>
          <Text fontWeight={500}>{address}</Text>

          <Text mt={2}>Courier Logistic:</Text>
          <Text fontWeight={500}>{selectedLogistic.join(" ")}</Text>

          <Button
            width="100%"
            colorScheme="green"
            mt={10}
            isLoading={submittingData}
            loadingText="Submitting"
            onClick={() => {
              makeOrder();
            }}
          >
            Make Order
          </Button>
        </Box>
      )}

      {successOrder && (
        <Stack>
          <Text fontWeight={600} fontSize={17} textAlign="center">
            Order Success
          </Text>

          <Text>INVOICE:</Text>
          <Text fontWeight={500}>{orderPlaced?.invoice}</Text>

          <Text>Total Payment:</Text>
          <Text fontWeight={500}>Rp.{formatFrice(orderPlaced?.amount_total)}</Text>

          <Text>Status:</Text>
          <Text fontWeight={500}>Not Paid</Text>

          <HStack>
            {hasPaid ? (
              <Button
                width="100%"
                colorScheme="green"
                isLoading={loading}
                loadingText="Submitting"
                onClick={() => navigate(`/invoice-nomember/${orderPlaced?.invoice}`)}
              >
                Check Invoice
              </Button>
            ) : (
              <Button
              width="100%"
              colorScheme="green"
              isLoading={loading}
              loadingText="Submitting"
              onClick={() => handlePay()}
            >
              Pay Now
            </Button>
            )}
            


          </HStack>

        </Stack>
      )}
    </Box>
  );
}

export default PaymentOrderNoMember;
