import { Box, Button, Center, Checkbox, Divider, Flex, Grid, Heading, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Stack, Tag, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../Api/AxiosBarrier'
import AuthContext from '../Routes/hooks/AuthContext'
import { formatFrice } from '../Utils/Helper'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import { IoAirplaneSharp, IoCaretBackOutline } from 'react-icons/io5'
import colors from '../Utils/colors'
import { getCountry } from '../Utils/country'
import { FaShip } from 'react-icons/fa'
import ConvertPrice from '../Utils/ConvertPrice'
import { errorSlack } from '../Api/SlackApi'
import Joyride from 'react-joyride';
import store from 'store'
import { sendEcommerceEvent } from '../Utils/gtm'
import { EditIcon } from '@chakra-ui/icons'
import ButtonQuantity from '../Components/Basic/ButtonQuantity'
import { viewSingleProduct } from '../Api/importirApi'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

function CartPage() {
  const {t} = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userDataLoc = store.get('user_api');
  const { loadingShow, loadingClose, cartList, getCart, userDb, userSymbolCurrency } = useContext(AuthContext)
  const navigate = useNavigate()
  const { convertPriceFix } = ConvertPrice()
  const [totalPrice, setTotalPrice] = useState(0)
  const path = window.location.href ?? '#'
	const [variantType, setVariantType] = useState(null)
	const [variantActive, setVariantActive] = useState(null)
	const [variants, setVariant] = useState([])
	const [variantIndexActive, setIndexVariantActive] = useState(0)
  const [cartSelect, setCartSelect] = useState(null)
  const [diffTypeAlert, setDiffTypeAlert] = useState(false)

  const toast = useToast({
    position: 'top',
    align: 'center',
  })

  const [checkedItems, setCheckedItems] = useState([])
  const [runStep, setRunStep] = useState(false)
  const location = useLocation();

  const setSteps = () => {
    const steps = store.get('stepCarts')
    if (steps === undefined) {
      setRunStep(true)
    } else {
      setRunStep(false)
    }
  }

  useEffect(() => {
    // Mengatur semua item tercentang secara default
    initiateData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getSlack()
    if (userDataLoc?.country_code === "id") {
      let cType = null
      cartList.forEach((x) => {
        if (cType === null) {
          cType = x?.product_relation?.is_lartas;
          // if (x?.product_relation?.is_lartas > 0)
        } else {
          if (cType !== x?.product_relation?.is_lartas) {
            setDiffTypeAlert(true)
          }
        }
      })
    }
    const itemIds = cartList.map((item) => item.id)
    setCheckedItems(itemIds)
    const initialTotalPrice = cartList.reduce((total, item) => total + item.price, 0)
    setTotalPrice(initialTotalPrice)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartList])

  const getSlack = async() => {
    if (location.search.includes('test')) {
      try {
        const i = null
        console.log('i', i.b)
      } catch (error) {
        const resSlack = await errorSlack(error, 'importir.com', path)
        console.log(resSlack, 'CONNECTED ERROR IN SLACK')
      }
    }
  }

  const initiateData = async () => {
    await getCart(userDb) 
    if (cartList.length > 0) {
      const itemIds = cartList.map((item) => item.id)
      setCheckedItems(itemIds)
      const initialTotalPrice = cartList.reduce((total, item) => total + item.price, 0)
      setTotalPrice(initialTotalPrice)



      const ecommerceData = {
        'currency': 'IDR',
        'value': parseFloat(initialTotalPrice),
        'items': cartList,

      };

      sendEcommerceEvent('view_cart', ecommerceData);
    }
    setSteps()
  }

  const getItemById = async (cart) => {
			const res = await viewSingleProduct(cart.product_relation.product_id, cart.product_relation.platform_type);
      let newVar = [];
      for (let i = 0; i < res.data.variants.length; i++) {
        let newItems = [];
        const varLoc = res.data.variants[i] 
        for (let j = 0; j < varLoc?.variant_items.length; j++) {
          const itemLoc = varLoc?.variant_items[j]
          const itemSelect = cart.items.find(x => x.spec_id === itemLoc.specId)
          if (itemSelect !== undefined) {
              itemLoc.quantity = itemSelect.quantity
          } else {
            itemLoc.quantity = 0
          }
          newItems.push(itemLoc)
        }
        varLoc.variant_items = newItems
        newVar.push(varLoc)
      }
      if (res.status) {
        setVariant(newVar)
        setVariantType(res.data.variant_type)
        setVariantActive(res.data.variants[0]);
        setCartSelect(cart)
      } else {
        toast({
          title: 'importir.com',
          description: res.message,
          status: 'error',
        })
      }
      console.log("res", res)
  }

  const showModal = async (cart) => {
    loadingShow()
    await getItemById(cart)
    loadingClose()
    onOpen()
  }

  const handleCheckboxChange = (id, price) => {
    setCheckedItems((prevItems) => {
      if (prevItems.includes(id)) {
        const updatedItems = prevItems.filter((item) => item !== id)
        setTotalPrice((prevTotal) => prevTotal - price)
        return updatedItems
      } else {
        const updatedItems = [...prevItems, id]
        setTotalPrice((prevTotal) => prevTotal + price)
        return updatedItems
      }
    })
  }

  const handleNavigate = async () => {
    if (checkedItems.length === 0) {
      toast({
        title: 'importir.com',
        description: 'Please select at least one item.',
        status: 'warning',
      })
      return
    }

    try {
      loadingShow()
      const res = await _axios.post('api/carts/next/checkout', checkedItems)
      loadingClose()
      if (res.status === true) {
        toast({
          title: 'importir.com',
          description: 'Success to checkout.',
          status: 'success',
        })
        navigate('/checkout')
      }
    } catch (error) {
      console.log(error, 'ini error')
      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
    }
  }

  const handleDelete = async (data) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async(result) => {
      if (result.isConfirmed) {
        loadingShow()
        try {
          const res = await _axios.delete(`api/carts/${data.id}`)
          if (res) {
            window.location.reload()
          }
          loadingClose()
        } catch (error) {
          const resSlack = await errorSlack(error, 'importir.com', path)
          console.log(resSlack, 'CONNECTED ERROR IN SLACK')
          toast({
            title: 'importir.com',
            description: error.message,
            status: 'error',
          })
          loadingClose()
        }
        loadingClose()
      }
    });
  }

  const handleTourClose = () => {
    store.set('stepCarts', true)
    setRunStep(false)
  };

  const CustomTooltip = ({
    index,
    size,
    step,
    skipProps,
    primaryProps,
    tooltipProps,
    isLastStep,
    backProps
  }) => (
    <div {...tooltipProps} style={{ textAlign: 'center', background: "white", padding: "20px" }}>
      <div>{step.title}</div>
      <div>{step.content}</div>
      <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
        <button {...skipProps} onClick={() => handleTourClose()}>Close</button>
        <div>
          {index > 0 && <button {...backProps} style={{ marginLeft: '10px', background: 'green', color: 'white', borderRadius: '5px', padding: '5px' }}>Back</button>}
        </div>
        {isLastStep ? '' :
          <div style={{ marginLeft: '10px', background: 'red', color: 'white', borderRadius: '5px', padding: '5px' }} isLastStep>
            <button {...primaryProps} >Next</button>
          </div>
        }
      </div>
    </div>
  );

  const steps = [
    {
      target: '.price-total',
      title: 'Total Price',
      content: 'in this section you can see the total price of the items you will checkout',
      disableBeacon: true,
      placement: 'bottom',
    },
    {
      target: '.checkout',
      title: 'Checkout Button',
      content: "This button is used to checkout the item you have selected.",
    },
    {
      target: '.list-product',
      title: 'List Product',
      content: "this is the list of products that you put in the cart",
    },
  ]

	const renderMultipleVariant = () => {
		if (['multiple_items', 'single_items'].includes(variantType))
			return (
				<Box w='full'>{renderVariantItem()}</Box>
			)
	}

	const onSetVariant = (index) => {
		setVariantActive({ ...variants[index] })
		setIndexVariantActive(index)
	}

  const renderVariant = () => {
    return variants.map((x, index) => {
      return ( 
        x?.image.includes('http') ?
          <Image
            key={index}
            src={
              x?.image
            }
            p="1"
            cursor='pointer'
            bg={variantIndexActive === index ? 'green.400' : 'gray.100'}
            alt="Alternate Text"
            width={'70px'}
            onClick={() => onSetVariant(index)}
          />
        : 
          <Button p={5} m={2} bgColor={'blackAlpha.100'}
          onClick={() => onSetVariant(index)}>{x?.image}</Button>
      )
    })
  }

  const renderNoVariant = () => {
    if (variantType === 'no_variants')
      return (
        <Box bg="white" mb="5" p="4" width="100%" mx="auto" mt="5" borderColor="coolGray.200" borderWidth="1" >
          {/* <Stack space={[1]} justifyContent="center" justifyItems="center">
            <Stack w={'50%'}>
              <Image src={images[0]
              } alt="Alternate Text" borderRadius={5} width={'70px'} />
            </Stack>
            <Spacer />
            <Box>
              <ButtonQuantity
                quantity={totalOrder.toString()}
                pressButton={(arg) => onAddQuantity(arg, null)}
              />
            </Box>
          </Stack> */}
        </Box>
      )

    return <></>
  }
	const onAddQuantity = (types, index) => {
    const variantLocal = variants
    if (typeof types === 'boolean') {
      if (types === true) {
        variantLocal[variantIndexActive].variant_items[index].quantity = variants[variantIndexActive]?.variant_items[index]?.quantity + 1
      } else {
        variantLocal[variantIndexActive].variant_items[index].quantity = variants[variantIndexActive]?.variant_items[index]?.quantity - 1
      }
    } else {
      variantLocal[variantIndexActive].variant_items[index].quantity = types.target.value
    }
    if (variantLocal[variantIndexActive].variant_items[index].quantity < 0) {
      variantLocal[variantIndexActive].variant_items[index].quantity = 0
    }
    setVariant([...variantLocal])
  }

  const submitChange = async () => {
    let variantParams = [];
    let qtyTotal = 0
    for (let i = 0; i < variants.length; i++) {
      for (let j = 0; j < variants[i]?.variant_items.length; j++) {
        const itemSel = variants[i]?.variant_items[j]
        if (itemSel.quantity > 0) {
          qtyTotal = qtyTotal + itemSel.quantity
          variantParams.push({
            "quantity": itemSel.quantity,
            "specId": itemSel.specId,
            "skuId": itemSel.skuId,
            "name": itemSel.title,
            "nameOri": itemSel.titleOri
          })
        }
      }
    }

    const dataSubmit = {
      "variants": variantParams,
      "variant_type": cartSelect.product_relation.variant_type,
      "price_type": cartSelect.product_relation.price_type,
      "quantity": qtyTotal,
      "product_id": cartSelect.product_relation.product_id,
      "weight": cartSelect.weight/cartSelect.quantity,
      "cbm": cartSelect.cbm/cartSelect.quantity,
      "freight":cartSelect.freight
    }
    console.log('submit', dataSubmit)
    loadingShow()
    const res = await _axios.post('api/carts', dataSubmit)
    loadingClose()
    onClose()
    getCart(userDb)
    if (res.status === false) {
      toast({
        title: 'importir.com',
        description: res.message,
        status: 'error',
      })
    } else {
      toast({
        title: 'importir.com',
        description: res.message,
        status: 'success',
      })
    }
  }

  const redirectDetail = (x) => {
    navigate('/product/' + x.product_relation.platform_type + '/' + x.product_id)
  }

	const renderVariantItem = () => {
		if (!variantActive) return
		const children = () => {
			if (variantActive?.variant_items.length)
				return variantActive?.variant_items.map((x, index) => (
					<Stack key={index}>
						<HStack space={2} w='100%' justifyContent="center" px="2" key={x.skuId}>
							<HStack w={'65%'}>
								{
                  x.image_parent !== undefined ?
                    x?.image_parent.includes('http') ?
                    <Image
                      src={x?.image_parent}
                      alt="image"
                      width={'60px'}
                    />
                    : ""
                  : ""
								}
								<Stack rounded="md" >
									<Text fontSize="xs" lineHeight="sm" letterSpacing="sm">
										{x.title} 
									</Text>

									<Text fontSize="xs" color="gray.800">
										{userSymbolCurrency} {formatFrice(convertPriceFix(x.price))}
									</Text>
								</Stack>
							</HStack>
							<Spacer />
							<Stack w={'30%'} alignItems="center">
								<Text fontSize="xs" color="black">
									{t('stock')} {x.stock || x.amountOnSale}
								</Text>
								{x.stock || x.amountOnSale > 0 ? <ButtonQuantity
									// quantity={x.quantity}
                  quantity={isNaN(x.quantity) ? '0' : x.quantity.toString()}
									pressButton={(arg) => onAddQuantity(arg, index)}
                  // isCustom={true}
								/> : ''}

							</Stack>
						</HStack>
						<Divider />
					</Stack>

				))
			return <></>
		}

		return (
			<Stack borderRadius='md'>
				<HStack w={'100%'} overflowX='scroll' spacing={0}>
					{renderVariant()}
				</HStack>

				<Divider bg="gray.200" thickness="1" my="1.5" />
				<Box px="1">
					<Heading size="xs" color="yellow.600">
						{variantActive.name ? variantActive.name : '-'}
					</Heading>
				</Box>
				<Stack maxH={'260px'} w='100%' overflowY='scroll'>
					<Stack>{children()}</Stack>
				</Stack>
			</Stack>
		)
	}

  return (
    <Stack bgColor={'gray.100'}  mt={[10, 10, 5]}>
      {diffTypeAlert ? 
      <Center>
        <Text backgroundColor={'red'} color={'white'} fontStyle={'italic'} fontWeight={'bold'} p={2} rounded={'xl'}>
          {t('diff_alert')}
        </Text>
      </Center> : ""}
      <Stack shadow={'md'} p={[1, 1, 5]} bgColor={'white'}>
        <HStack p={2}>
          <div className='price-total'>
            <Box>
              <Text color={'gray.700'}>{`${t('total')} ${t("price")}`} </Text>
              <Heading size={'lg'} fontWeight="extrabold">
                {userSymbolCurrency} {formatFrice(convertPriceFix(totalPrice))}
              </Heading>
            </Box>
          </div>
          <Spacer />
          {cartList?.length > 0 && (
            <div className='checkout'>
              <Stack alignItems={'center'} justifyContent="center">
                <Button isDisabled={diffTypeAlert} bgColor={'green.600'} onClick={() => handleNavigate()} size="md">
                  <HStack spacing={2} alignItems="center" justifyContent={'center'}>
                    <Text fontSize={'md'} color="white" fontWeight={'bold'}>
                      Checkout
                    </Text>
                  </HStack>
                </Button>
              </Stack>
            </div>
          )}
        </HStack>
      </Stack>

      <Stack p={[1, 1, 5]}>
        <HStack cursor="pointer" w={'100px'} p={2} alignItems="center" shadow={'base'} justifyContent={'center'} borderRadius="full" bgColor={colors.theme} onClick={() => navigate(-1)}>
          <IoCaretBackOutline size={15} />
          <Text fontSize={'xs'} letterSpacing={0.5}>
            {t("back")}
          </Text>
        </HStack>

        {cartList?.length > 0 ? (
          cartList?.map((x, index) => (
            <div className='list-product'>
              <Stack bgColor={'white'} key={index} spacing={1} borderRadius="xl" p={2} pb={8} shadow={'md'} flexWrap={'wrap'}>
                

                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr 3fr 2fr 1fr 1fr"}} gap={2} p={3} alignItems="center" justifyContent={'space-around'}>
                  <Stack alignItems={['start', 'start', 'center']} justifyContent='center'>
                    <Checkbox
                      key={x.product_id}
                      onChange={() => handleCheckboxChange(x.id, x.price)}
                      size="lg"
                      bgColor={'gray.300'}
                      isChecked={checkedItems.includes(x.id)}
                    />
                  </Stack>

                  <Stack shadow={5}>
                    <Image src={x?.product_image} alt={x?.product_name} borderRadius={'xl'} w="150px" />
                  </Stack>

                  <Stack spacing={1}>
                    <HStack space={2}>
                      {
                        userDataLoc?.country_code === "id" ? 
                          <Tag
                            bgColor={x?.product_relation?.is_lartas > 0 ? 'red.300' : 'yellow.300'}
                            size="sm"
                          >
                            {
                                x?.product_relation?.is_lartas > 0 ? 'Lartas' : 'Non Lartas'
                            }
                          </Tag>
                        : ""
                      }
                      {x?.product_relation?.platform_type && getCountry(x?.product_relation?.platform_type)}

                      {x.freight === 'Sea' ? (
                        <Tag size="sm" bgColor={colors.black}>
                          <HStack>
                            <FaShip size={15} color="white" />
                          </HStack>
                        </Tag>
                      ) : (
                        <Tag size="sm" bgColor={colors.black}>
                          <HStack>
                            <IoAirplaneSharp size={15} color="white" />
                          </HStack>
                        </Tag>
                      )}
                    </HStack>
                    <Text numberOfLines={2} fontWeight="bold" fontSize="sm" onClick={() => redirectDetail(x)} color={'blue.300'} cursor={'pointer'}>
                      {x?.product_name}
                    </Text>
                    <Text color={'gray.700'} fontSize="sm">
                      {userSymbolCurrency} {formatFrice(convertPriceFix(x?.price / x?.quantity))} / pcs
                    </Text>
                    <Text color={'gray.700'} fontSize="sm">
                      {t("quantity")} : {x?.quantity} 
                      <Button colorScheme='red' fontWeight={'bold'} color={'blackAlpha.700'} marginLeft="3" variant='solid' size={'xs'} onClick={() => showModal(x)}>
                        <EditIcon /> {`${t("change")} ${t("quantity")}`}
                      </Button>
                    </Text>
                  </Stack>

                  <Spacer />

                  <Stack spacing={1}>
                    <Stack spacing={0}>
                      <Text color={'gray.700'} fontSize="sm">
                        {t('total')}
                      </Text>
                      <Text color={'green.700'} fontWeight="bold">
                        {userSymbolCurrency} {formatFrice(convertPriceFix(x?.price))}
                      </Text>
                    </Stack>
                  </Stack>

                  <Stack onClick={() => handleDelete(x)} alignItems="flex-end" cursor={'pointer'}>
                    <AiOutlineCloseCircle size={22} color="red" />
                  </Stack>
                </Grid>
              </Stack>
            </div>
          ))
        ) : (
          <Stack  alignItems="center" justifyContent="center">
            <Image src="https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/empty-cart-2023_06_23_11_04_12.png" w={'xs'}></Image>
            <Text textColor={'gray'} fontWeight={'bold'} fontSize={{base: 'md', lg: 'xl'}}>{t("shop_empty")}</Text>
            
            <Button p={5} m={2} bgColor={'#ffd600'} color={'black'}
              onClick={() => navigate('/') }>{t('lets_shop_now')}</Button>
          </Stack>
        )}
      </Stack>

      <div>
        <Joyride
          steps={steps}
          run={runStep}
          continuous
          tooltipComponent={CustomTooltip}
          disableScrolling={true}
          hideBackButton
          disableOverlayClose
          disableScrollParentFix
        />
      </div>
      <Modal isOpen={isOpen} size={"2xl"} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>
          {t('change')} {t('quantity')}
        </ModalHeader>
        <ModalCloseButton />
          <ModalBody>
          <Stack >
            <Stack p={5} borderRadius="md" shadow="md" spacing={1} bgColor="white" h={'510px'} >
              <Heading size={'md'}>{t('variant')}</Heading>
              {renderMultipleVariant()}
              {renderNoVariant()}
              <Center>
                <Flex>
                  <Button bgColor={'red.300'} color={'white'} onClick={() => onClose()}>{t('cancel')}</Button>
                  <Button bgColor={'blue.400'} ml={3} color={'white'} onClick={() => submitChange()}>{t('submit')}</Button>
                </Flex>
              </Center>
            </Stack>
          </Stack>
          </ModalBody>
        </ModalContent>
    </Modal>
    </Stack>
  )
}

export default CartPage
