/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Heading, HStack, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Skeleton, Spacer, Stack, Tag, Text, useToast, Spinner, Box, Divider } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { HiOutlineHeart } from 'react-icons/hi';
import { useNavigate, } from 'react-router-dom';
import { HiAcademicCap,  } from 'react-icons/hi';
import { FaNewspaper } from "react-icons/fa";
import AppSideHome from '../Components/AppSideHome';
import AppImageSlide from '../Components/Carousel/AppImageSlide';
import AppCategory from '../Components/CategoryList/AppCategory';
import AuthContext from '../Routes/hooks/AuthContext';
import { formatFrice } from '../Utils/Helper';
import { IoPeopleSharp } from 'react-icons/io5';
import ConvertPrice from '../Utils/ConvertPrice';
import moment from 'moment';
import Joyride from 'react-joyride';
import store from 'store'
import CatalogListPage from './CatalogList/CatalogListPage';
import _axios from '../Api/AxiosBarrier';
import axios from 'axios';
import ProductCardCrowdfund from '../Components/Card/ProductCardCrowdfund';
import { useTranslation } from 'react-i18next';
import { GiArchiveResearch } from "react-icons/gi";
import { TbPackages } from "react-icons/tb";

function HomePageV2() {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const { kursRmb, userSymbolCurrency, imageCarouselDashboard, currentUser, signOut, userDb } = useContext(AuthContext)
    const [crowdfunds, setCrowdfunds] = useState([]);
    useEffect(() => {
        const expiredSession = store.get('expiredAt');
        if (store.get('user_api') !== undefined){
            if (expiredSession === undefined || moment().isAfter(moment(expiredSession))){
                signOut()
                .then(() => {
                    navigate("/login", { replace: true });
                    store.clearAll();
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isVisible, setIsVisible] = useState(true);

    const getDataCrowd = async () => {
      try {
        let res = "";
        if (userDb === undefined) {
            res = await axios.get(`${process.env.REACT_APP_API_COM}api/home/get-list-crowdfund?page=1&keyword=&shipping_from=`
            );
            if (res && res.data && res.data.data && res.data.data.data) {
                const dataArr = res.data.data.data;
                setCrowdfunds(dataArr);
            }
        } else {
            res = await _axios.get(
                `${process.env.REACT_APP_API_COM}api/home/get-list-crowdfund-v2?page=1&keyword=&shipping_from=`
            );
            if (res.status) {
                const dataArr = res.data.data;
                setCrowdfunds(dataArr);
            }
        }
      } catch (error) {
      }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prevIsVisible) => !prevIsVisible);
        }, 500); // Waktu kedap-kedip dalam milidetik (misalnya setiap 500ms)
        getDataCrowd()

        return () => {
            clearInterval(interval);
        };
    }, []);

    const toast = useToast({
        position: "top",
        align: "center",
    });

    // const path = window.location.href ?? '#'

    const [loading] = useState(false);
    const [imageView] = useState('')
    const [detailModal, setDetailModal] = useState(false)
    // const [seminarMember, setSeminarMember] = useState([])
    // const [seminar, setSeminar] = useState([])
    const [runStep, setRunStep] = useState(false)


    const { convertPriceFix } = ConvertPrice()

    const handleNavigateProduct = (platformType, idProduct) => {
        const navigateRouteProduct = `/product/${platformType}/${idProduct}`;
        navigate(navigateRouteProduct);
    };

    // const getSeminarMemberData = async () => {
    //     try {
    //         const res = await getSeminarMember()
    //         const data = res.data
    //         setSeminarMember(data)
    //     } catch (error) {
    //         console.log(error.message)

    //         const resSlack = await errorSlack(error, 'importir.com', path)
    //         console.log(resSlack, 'CONNECTED ERROR IN SLACK')
    //     }
    // }

    // const getSeminarData = async () => {
    //     if (!currentUser) {
    //         try {
    //             const res = await getSeminar();
    //             const data = res.data;

    //             const currentDate = new Date();

    //             const filteredData = data.filter(item => {
    //                 const eventDate = new Date(item.event_date);
    //                 return eventDate > currentDate;
    //             });

    //             setSeminar(filteredData);
    //         } catch (error) {
    //             console.log(error.message);
    //             const resSlack = await errorSlack(error, 'importir.com', path)
    //             console.log(resSlack, 'CONNECTED ERROR IN SLACK')
    //         }
    //     }

    // }

    // const groupedData = dataList.reduce((groups, item) => {
    //     const category = item.category[0]
    //     if (!groups[category]) {
    //         groups[category] = [];
    //     }
    //     groups[category].push(item);
    //     return groups;
    // }, {});





    const setSteps = () => {
        const steps = store.get('stepsHome')
        if (steps === undefined) {
            setRunStep(true)
        } else {
            setRunStep(false)
        }
    }

    const handleTourClose = () => {
        store.set('stepsHome', true)
        setRunStep(false)
    };

    useEffect(() => {
        // getSeminarMemberData();
        // getSeminarData();
        setSteps();
    }, [currentUser]);

    useEffect(() => {
        if (userDb !== undefined){
            const currentPackage = store.get('user_package');
            if (userDb?.data_importir?.user?.country_code === 'id'){
                if (moment().isAfter(currentPackage?.expired_at) && currentPackage?.package_child?.name !== 'FREE MEMBER'){
                    updatePackageToFreeMember();
                }
            }
            getDataCrowd()
        }
    }, [userDb]);

    const updatePackageToFreeMember = async () => {
        try {
            const url = '/api/change-to-free-member'
            const res = await _axios.post(url);
            if (res.status === true){
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'warning',
                });

                signOut()
                .then(() => {
                    navigate("/login", { replace: true });
                    store.clearAll();
                })
            }
        } catch (error) {
            return toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const CustomTooltip = ({
        index,
        step,
        skipProps,
        primaryProps,
        tooltipProps,
        isLastStep,
        backProps
    }) => (
        <div {...tooltipProps} style={{ textAlign: 'center', background: "white", padding: "20px" }}>
            <div>{step.title}</div>
            <div>{step.content}</div>
            <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                <button {...skipProps} onClick={() => handleTourClose()}>Close</button>
                <div>
                    {index > 0 && <button {...backProps} style={{ marginLeft: '10px', background: 'green', color: 'white', borderRadius: '5px', padding: '5px' }}>{t('back')}</button>}
                </div>

                {!isLastStep && (
                    <div style={{ marginLeft: '10px', background: 'red', color: 'white', borderRadius: '5px', padding: '5px' }}>
                        <button {...primaryProps} >{isLastStep ? '' : 'Next'}</button>
                    </div>

                )}
            </div>
        </div>
    );

    const steps = [
        {
            target: '.category',
            title: `${t('category')}`,
            content: `${t('tour_home_category')}`,
            disableBeacon: true,
            placement: 'right',
        },
        {
            target: '.banner',
            title: 'Banner',
            content: `${t('tour_home_banner')}`,
        },
        {
            target: '.menu',
            title: 'Quick Menu',
            content: `${t('tour_home_quick_menu')}`,
        },
        {
            target: '.wishlist',
            title: `${t('wishlist')}`,
            content: `${t('tour_home_wishlist')}`,
        },
        {
            target: '.cart',
            title: 'Cart',
            content: `${t('tour_home_cart')}`,
        },
        {
            target: '.menus',
            title: 'Menu',
            content: `${t('tour_home_menu')}`,
        },
        {
            target: '.search-image',
            title: `${t('search_by_image')}`,
            content: `${t('tour_home_search_by_image')}`,
        },
        {
            target: '.profile',
            title: 'Profile',
            content: `${t('tour_home_profile')}`,
        },
        {
            target: '.isLogin',
            title: `${t('user_information')}`,
            content: `${t('tour_home_user_info')}`,
        },
        {
            target: '.news',
            title: 'Crowdfund',
            content: `${t('tour_home_crowdfund')}`,
        },
    ]




    return (
        <Stack py={1}>
            <Stack px={[1, 1, 5]} mt={[10, 10, 0]}>
                <Grid templateColumns={{ base: "1fr", md: "1.2fr 3fr 1.5fr" }} color='gray.600' alignItems='center' justifyContent={'center'} >
                    <div className='category'>
                        <Stack display={{ base: 'none', md: 'flex' }}>
                            <AppCategory />
                        </Stack>
                    </div>

                    <Stack spacing={3} >

                        <Stack mx={3}>
                            {loading ? (
                                <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                                    <Spinner />
                                </Stack>
                            ) : (
                                imageCarouselDashboard && (
                                    <div className='banner'>
                                        <Stack w={'100%'}>
                                            <AppImageSlide images={imageCarouselDashboard && imageCarouselDashboard} />
                                        </Stack>
                                    </div>
                                )
                            )}

                        </Stack>

                        <div className='menu'>
                            <Stack display={'flex' } bgColor={'white'} borderRadius='md' shadow={'md'} mx={3} p={5} alignItems='center' justifyContent='center'>
                                <Stack >
                                    <Heading size={'md'}>{t('menu_list')}</Heading>
                                </Stack>

                                <SimpleGrid columns={[2,4, 2, 4]} alignItems="center" justifyContent="center" p={2} gap={5} pb={3}  >

                                    <Stack alignItems={'center'} justifyContent='center' shadow={'md'} _hover={{ transform: "scale(1.05)" }} transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => navigate('/academy')} textAlign={'center'} bgColor={'#ffd600'} borderRadius='xl' p={[1, 3, 10]}>
                                        <HiAcademicCap fontSize='50px' />
                                        <Text display={{base: 'none', lg: 'block'}} fontSize='sm' >{t('academy')}</Text>
                                    </Stack>

                                    {/* <Stack alignItems={'center'} justifyContent='center' shadow={'md'} _hover={{ transform: "scale(1.05)" }} transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => navigate('/consultation')} textAlign={'center'} bgColor={'#ffd600'} borderRadius='xl' p={[1, 3, 10]}>
                                        <IoPeopleSharp fontSize='50px' />
                                        <Text display={{base: 'none', lg: 'block'}}  fontSize='sm'>{t('consultation')}</Text>
                                    </Stack> */}

                                    <Stack alignItems={'center'} justifyContent='center' shadow={'md'} _hover={{ transform: "scale(1.05)" }} transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => navigate('/cbm/package')} textAlign={'center'} bgColor={'#ffd600'} borderRadius='xl' p={[1, 3, 12, 8 ,"24%"]}>
                                        <TbPackages fontSize='50px' />
                                        <Text display={{base: 'none', lg: 'block'}} fontSize='xs'>CBM Package</Text>
                                    </Stack>

                                    <Stack alignItems={'center'} justifyContent='center' shadow={'md'} _hover={{ transform: "scale(1.05)" }} transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => navigate('/news')} textAlign={'center'} bgColor={'#ffd600'} borderRadius='xl' p={[1, 3, 10]}>
                                        <FaNewspaper fontSize='50px' />
                                        <Text display={{base: 'none', lg: 'block'}}  fontSize='sm'>{t('news')}</Text>
                                    </Stack>
                                    
                                    <Stack alignItems={'center'} justifyContent='center' shadow={'md'} _hover={{ transform: "scale(1.05)" }} transition={"0.3s ease-in-out"} cursor='pointer' onClick={() => navigate('/research')} textAlign={'center'} bgColor={'#ffd600'} borderRadius='xl' p={[1, 3, 10]}>
                                        <GiArchiveResearch fontSize='50px' />
                                        <Text display={{base: 'none', lg: 'block'}}  fontSize='sm'>Research</Text>
                                    </Stack>
                                </SimpleGrid>
                            </Stack >
                        </div>

                    </Stack>
                    <Stack>
                        <AppSideHome />
                    </Stack>

                </Grid>

                {
                    userDb?.data_importir?.user?.country_code === 'id' ? 
                        <Stack px={3}>
                            <SimpleGrid  >
                                <Stack borderRadius='md' shadow='lg' bgColor={'white'} spacing={3}  >
                                    <div className='news'>
                                        <Stack width='100%' fontSize={'20px'} p={5}>
                                            <HStack width='100%' px={5} >
                                                <Box
                                                    bgColor={'red'}
                                                    p={1}
                                                    borderRadius='md'
                                                    visibility={isVisible ? 'visible' : 'hidden'}
                                                >
                                                    <Text fontSize={'xs'} color='white'>
                                                        {t('new')}
                                                    </Text>
                                                </Box>
                                                <Heading size={'md'}>Crowdfund</Heading>

                                                <Spacer />
                                                
                                                <Text fontSize={'sm'} fontWeight='bold' color={'blue.500'} fontStyle='italic' cursor={'pointer'} onClick={() => navigate('/crowdfund')} >{t('see_all')}</Text>
                                            </HStack>

                                            <Divider shadow='md' />

                                            <Stack shadow={"md"} p={1} overflowY='scroll'>
                                                {crowdfunds.length > 0 ? (
                                                    <>
                                                    <SimpleGrid columns={[1, 2, 3, 4]} gap={5} mx={[1, 3, 5]}>
                                                        {crowdfunds.map((product, i) => (
                                                        <Stack key={i}>
                                                            <ProductCardCrowdfund
                                                            product={product}
                                                            userSymbolCurrency={userSymbolCurrency}
                                                            handleNavigateProduct={handleNavigateProduct}
                                                            />
                                                        </Stack>
                                                        ))}
                                                    </SimpleGrid>
                                                    </>
                                                ) : (
                                                    <Stack alignItems={'center'} justifyContent='center' h={'100%'}>
                                                    <Text>{t('product_unavailable')}</Text>
                                                    </Stack>
                                                )}
                                            </Stack>


                                        </Stack>
                                    </div>
                                </Stack>
                            </SimpleGrid>
                        </Stack>
                    : ""
                }

                <Stack py={2}>
                    <Divider />
                    <CatalogListPage />
                </Stack>
            </Stack >
            <div>
                <Joyride
                    steps={steps}
                    run={runStep}
                    continuous
                    tooltipComponent={CustomTooltip}
                    disableScrolling={true}
                    hideBackButton
                    disableOverlayClose
                    disableScrollParentFix
                />
            </div>

            {
                imageView !== "" && (
                    <Modal isOpen={detailModal} onClose={() => setDetailModal(false)} >
                        <ModalOverlay />
                        <ModalContent bgColor={'white'} >
                            <ModalHeader>
                                <HStack spacing={2} alignItems='center' >
                                    {/* <FontAwesome5 name="file-invoice" size={22} color="black" /> */}
                                    <Text fontSize={'lg'} fontWeight='bold'>Detail</Text>
                                    <Spacer />
                                    <HStack alignItems={'center'} justifyContent='flex-end'>
                                        <Stack>
                                            <Tag>CN 🇨🇳</Tag>
                                        </Stack>
                                        <Stack onClick={() => console.log(imageView)} cursor='pointer'>
                                            <HiOutlineHeart
                                                style={{ fontSize: 20, color: 'red', }} />
                                        </Stack>

                                    </HStack>
                                </HStack>
                            </ModalHeader>

                            <ModalBody >
                                <Stack borderRadius={'xl'} spacing={2} bgColor='white' >
                                    <Stack alignItems={'center'} cursor='pointer'>
                                        {imageView.image ? (
                                            <Image w={'full'} src={imageView.image} alt='img' borderRadius={'md'} />
                                        ) : (
                                            <Skeleton w={'200px'} h='300px' borderRadius={'md'} />
                                        )}

                                    </Stack>
                                    <Stack px={3}>

                                        <Text textTransform={'capitalize'} fontWeight='bold' fontSize={'sm'} noOfLines={2}> {imageView["title-en"]?.en}</Text>
                                    </Stack>

                                    <Spacer />

                                    <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                                        <Stack spacing={0} alignItems='flex-start' justifyContent={'flex-start'} >

                                            <Text textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>{t('price')}</Text>
                                            {/* <Text textTransform={'capitalize'} fontWeight='extrabold' color={'black'} fontSize={'md'}>{userSymbolCurrency} {formatFrice(x.price)}</Text> */}
                                            <Text textTransform={'capitalize'} fontWeight='bold' color={'black'} fontSize={'sm'}>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(imageView.price)))}</Text>
                                        </Stack>

                                        <Stack >

                                            <Stack spacing={0} alignItems='flex-end' justifyContent={'flex-end'}>
                                                <Text fontsize textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>{t('sold')}</Text>
                                                <Text textTransform={'capitalize'} color={'black'} fontWeight='bold' fontSize={'sm'}>{imageView.sold}</Text>
                                            </Stack>
                                        </Stack>


                                    </SimpleGrid>

                                </Stack>
                            </ModalBody>

                            <ModalFooter>
                                <HStack spacing={5}>

                                    <Button bgColor='green.400' onClick={() => handleNavigateProduct(imageView.id, imageView.category)}>
                                        <Text color={'white'}>🛒 {t('order_now')}</Text>
                                    </Button>
                                    <Button colorScheme='red' mr={3} onClick={() => setDetailModal(false)}>
                                        {t('close')}
                                    </Button>
                                </HStack>
                            </ModalFooter>


                        </ModalContent>
                    </Modal>
                )
            }
        </Stack >
    )
}

export default HomePageV2