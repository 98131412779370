import axios from "axios";
import store from "store";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.post['Content-Type'] = 'application/json';

const config = {
  // baseURL: 'https://dev-api.importir.com',
  baseURL: "https://china2.id/",
  timeout: 60 * 1000, // Timeout
};

const _axiosChina = axios.create(config);

_axiosChina.interceptors.request.use(async (config) => {
  let user = await store.get("user_token");
  const userStorage = user && user;
  const token = userStorage;

  if (userStorage) {
    // config.headers['Authorization'] = `Bearer 3126|yb7B1pgGNiQ33FLAb809ekt7s1F7HpbKhs0dCla5`
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// Add a response interceptor
_axiosChina.interceptors.response.use(
  function (response) {
    // Do something with response data
    response = typeof response.data !== "undefined" ? response.data : response;
    return response;
  },

  function (error) {
    if (error.response.status === 404) {
      // return window.location.href = '/error/error-404'
    } else if (error.response.status === 401) {
      //  return window.location.href = '/error/error-401'
    }
    console.log(error);

    return Promise.reject(error);
  }
);

export default _axiosChina;
