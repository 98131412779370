/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Flex, Button, Textarea, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import _axios from "../../Api/AxiosBarrier";
import AuthContext from "../../Routes/hooks/AuthContext";
import { useTranslation } from "react-i18next";
const ShippingChatToAdmin = ({ shippingId }) => {
  const {t} = useTranslation();
  const { loadingShow, loadingClose } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [adminSelf, setAdminSelf] = useState(0);
  const params = useParams();
  const toast = useToast({
    position: "top",
    align: "center",
  });
  const messageRef = useRef();

  const initMessage = async () => {
    try {
      loadingShow();
      const dbRef = await _axios.get(
        `/api/shipping/chat-admin-to-users/${params.id}`
      );
      loadingClose();
      if (dbRef.status) {
        setMessages(dbRef.data.data_chat);
      }
    } catch (error) {
      toast({
        title: "importir.com",
        description: error.message,
        status: "error",
      });
    }
  };

  const submitMessage = async () => {
    if (inputMessage.length === 0) {
      return false;
    }
    if (inputMessage.trim() === "") {
      return false
    }
    await _axios
      .post("/api/shipping/chat-admin-to-users/" + shippingId, {
        message: inputMessage,
      })
      .then((res) => {
        console.log(res);
        setInputMessage("")
        if (res.status) {
          if (adminSelf === 0) {
            setAdminSelf(res.data.id_has_login);
          }
          initMessage();
        }
      });
  };


  useEffect(() => {
    initMessage();

    return () => {};
  }, []);



  return (
    <Flex direction="column" h="50vh" p={4}>
      <Box flex="1" overflow="auto">
        {messages.map((message, index) => (
          <Box
            key={index}
            bg={message.from === "user" ? "blue.200" : "gray.200"}
            borderRadius="md"
            p={2}
            my={2}
            ml={message.from === "user" ? "auto" : 0}
            maxW="90%"
            ref={messageRef}
          >
            {message.message}
          </Box>
        ))}
      </Box>
      <Flex align="center">
        <Textarea
          flex="1"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <Button ml={2} colorScheme="blue" onClick={submitMessage}>
          {t('submit')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ShippingChatToAdmin;
