/* eslint-disable react-hooks/exhaustive-deps */
import { Image, Stack, Heading, Card, CardBody, CardFooter, Text, Button, SimpleGrid,  Spinner, useToast, Center } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import store from 'store'
import { useTranslation } from 'react-i18next';
function AcademyPage() {
    const {t} = useTranslation();
    const handleNavigate = useNavigate()

    const [loading] = useState(false)
    const user = store.get('user_api');

    return (
        <Stack p={[1, 1, 5]}>
            {loading ? (
                <Stack
                    h="100%"
                    w="100%"
                    alignItems={"center"}
                    justifyContent="center"
                >
                    <Spinner />
                </Stack>
            ) : (
                <Stack bg={'white'} mt={[10,10,0]} rounded={'md'}>
                    {/* <Box p={[null, null, 5]}>
                            <Stack >
                                {loading ? (
                                    <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                                        <Spinner />
                                    </Stack>
                                ) : (
                                    imageCarouselDashboard && (
                                        <AppImageSlide images={imageCarouselDashboard && imageCarouselDashboard} />
                                    )
                                )}

                            </Stack>
                        </Box> */}

                    <Stack p={5} spacing={3}>
                        <Heading color={"#FFD600"} size={"xl"} textAlign={'center'}>
                            {/* Start Learning Today */}
                            {t('start_learning_today')}
                        </Heading>
                        {/* <SimpleGrid columns={2}> */}
                        <Heading size={"sm"}>
                            {/* Find a class that suits your needs */}
                            {t('find_your_class')}
                        </Heading>
                        {/* </SimpleGrid> */}

                        <SimpleGrid columns={[1, null, 2]} spacing={5}>
                            <div className="">
                                <Card
                                    direction={{ base: "column", sm: "row" }}
                                    overflow="hidden"
                                    variant="outline"
                                    borderRadius={"3xl"}
                                    bg={"#6CDDFF"}
                                >
                                    <Stack p={3}>
                                        <CardBody color={"white"}>
                                            <Heading size="xl">
                                                {/* No product yet. */}
                                                {t('no_product_yet')}
                                            </Heading>
                                            <Text py="1" fontSize={"xl"}>
                                                {t('beginner_class')}
                                            </Text>
                                        </CardBody>

                                        <Center>
                                            <CardFooter>
                                                <Button
                                                    bg={"gray.300"}
                                                    borderRadius={"full"}
                                                    onClick={() =>
                                                        handleNavigate(
                                                            "/courses/50"
                                                        )
                                                    }
                                                >
                                                    {/* Learn now */}
                                                    {t('learn_now')}
                                                </Button>
                                            </CardFooter>
                                        </Center>
                                    </Stack>

                                    <Stack
                                        alignItems={"center"}
                                        justifyContent="center"
                                        p={5}
                                    >
                                        <Image
                                            src="https://stage.importir.com/images/new-academy/empty-box.png"
                                            alt="Caffe Latte"
                                            w={"50%"}
                                        />
                                    </Stack>
                                </Card>
                            </div>

                            <div className="">
                                <Card
                                    direction={{ base: "column", sm: "row" }}
                                    overflow="hidden"
                                    variant="outline"
                                    borderRadius={"3xl"}
                                    bg={"#FFD600"}
                                >
                                    <Stack p={3}>
                                        <CardBody>
                                            <Heading size="xl">
                                                {/* Already Have a Product. */}
                                                {t('already_have_product')}
                                            </Heading>

                                            <Text py="1" fontSize={"xl"}>
                                                {t('advance_class')}
                                            </Text>
                                        </CardBody>

                                        <Center>
                                            <CardFooter>
                                                <Button
                                                    bg={"gray.300"}
                                                    borderRadius={"full"}
                                                    onClick={() =>
                                                        handleNavigate(
                                                            "/courses/51"
                                                        )
                                                    }
                                                >
                                                    {/* Learn now */}
                                                    {t('learn_now')}
                                                </Button>
                                            </CardFooter>
                                        </Center>
                                    </Stack>
                                    <Stack
                                        alignItems={"center"}
                                        justifyContent="center"
                                        p={5}
                                    >
                                        <Image
                                            src="https://stage.importir.com/images/new-academy/giveaway.png"
                                            alt="Caffe Latte"
                                            w={"50%"}
                                        />
                                    </Stack>
                                </Card>
                            </div>
                        </SimpleGrid>
                        <Stack marginTop={2}>
                            <Card
                                direction={{ base: "column", sm: "row" }}
                                overflow="hidden"
                                variant="outline"
                                borderRadius={"3xl"}
                                bg={"#88AB8E"}
                            >
                                <Stack p={3}>
                                    <CardBody color={"white"}>
                                        <Heading size="xl">
                                            {t('exclusive_for_member')}
                                        </Heading>

                                        <Text py="1" fontSize={"xl"}>
                                            {t('member_only_class')}
                                        </Text>
                                    </CardBody>

                                    <Center>
                                        <CardFooter>
                                            <Button
                                                isDisabled={user === undefined}
                                                bg={"gray.300"}
                                                borderRadius={"full"}
                                                onClick={() =>
                                                    handleNavigate("/course/list")
                                                }
                                            >
                                                {/* Learn now */}
                                                {t('learn_now')}
                                            </Button>
                                        </CardFooter>
                                    </Center>
                                </Stack>

                                <Stack
                                    alignItems={["center", "end"]}
                                    justifyContent={["center", "end"]}
                                    p={5}
                                >
                                    <Image
                                        src="https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/group-4028-2023_12_21_14_27_55.png"
                                        alt="Caffe Latte"
                                        w={["50%", "40%"]}
                                        mb={5}
                                        ml={[0, 5, 24]}
                                    />
                                </Stack>
                            </Card>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

export default AcademyPage