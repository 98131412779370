import { Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function ErrorPage() {

    const navigate = useNavigate()

    const height = window.innerHeight
  return (
    <Stack w={'100%'} h={height/1.2} alignItems='center' justifyContent={'center'}>
        <Button bgColor={'#ffd600'} onClick={() => navigate('/')}>Back to Home</Button>
    </Stack>
  )
}

export default ErrorPage