import { Avatar, Box, Flex, Heading, HStack, Image, Stack, Text,  SimpleGrid, Spacer,  GridItem } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import AuthContext from '../Routes/hooks/AuthContext'
import NewsCard from './Card/NewsCard'
import store from 'store'
import { ImDropbox } from 'react-icons/im'
import { useNavigate } from 'react-router-dom'
import _axios from '../Api/AxiosBarrier';
import { formatPrice } from '../Utils/Helper'
import { useTranslation } from 'react-i18next';
 

function AppSideHome() {
	const {t} = useTranslation();

	const { userDb, dataList, dataNews } = useContext(AuthContext)
    const [vouchers, setVouchers] = useState([])
	const packageUser = store.get('user_package');
	dataList.reduce((groups, item) => {
		const category = item.category[0]
		if (!groups[category]) {
			groups[category] = [];
		}
		groups[category].push(item);
		return groups;
	}, {});

	const navigate = useNavigate()

	const as_member = store.get('as_member_email')



    const getVoucherList = async () => {
        try {
			console.log("get voucher")
            const res = await _axios.get('api/voucher')
            setVouchers(res)
        } catch (error) {
        }
    }


	useEffect(() => {
		if (userDb?.data_importir?.package_user?.package_child?.name === 'FREE MEMBER') {
			getVoucherList()
		}
	}, [userDb]);



	return (
		<Stack bgColor={'white'} display={{base: 'none', sm: 'none', md: 'block'}} spacing={3} m='2' p={2} borderRadius='md' shadow='lg' h='670px' overflowY={'scroll'} alignItems={'center'}>
			<div className='isLogin' style={{ width: '100%' }}>
				<HStack alignItems={'flex-start'} p={3} py={5} bgColor='#ffd600' w={'100%'} borderRadius='md'>
					<Stack p={1}>
						<Avatar src={userDb?.data_importir?.detail_user?.profile_image ? userDb?.data_importir?.detail_user?.profile_image : 'https://www.dropbox.com/scl/fi/0gnyorw46ipnui3904t6b/1691682215605-importir-merdekauang-bendera-bg-putih.png?rlkey=qiq1bxq4zoj9998cd8yocg21i&dl=0&raw=1'} />
					</Stack>
					<Stack spacing={1}>
						<Text fontWeight={'bold'} fontSize='sm'>{t('welcome_to_importir')}</Text>
						<Text fontWeight={'bold'} fontSize='sm' textTransform={'capitalize'}>{userDb?.name ? userDb?.name : `${t('login_to_account')}`}</Text>
						<HStack spacing={2}>
							{userDb?.package_importir && (
								< ImDropbox fontSize={15} />
							)}
							<Text fontSize='sm'>{packageUser !== undefined ? packageUser?.package_child?.name : `${t('fill_in_my_purchase_request')}`}</Text>
							{as_member && (
								<HStack spacing={2}>
									<Text fontSize='sm'>as {as_member && as_member}</Text>
								</HStack>
							)}
						</HStack>
					</Stack>
				</HStack>
			</div>
			<Stack >
				<Image src={'https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Imam/get-your-imported-product-2023_10_12_10_53_18.png'} />
			</Stack>

		
			{
				userDb?.data_importir?.package_user?.package_child?.name === 'FREE MEMBER' ?
					<Stack width='100%' fontSize={'20px'} p={5}>
						<HStack>
							<Heading size={'md'} fontWeight='bold'>{t('voucher_code')}</Heading>
							<Spacer />
							<Text fontSize={'sm'} fontWeight='bold' color={'blue.500'} fontStyle='italic' cursor={'pointer'} onClick={() => navigate('/voucher')} >{t('see_all')}</Text>
						</HStack>
						<Stack h='400px' overflowY='scroll'>
							<Flex gap={3} flexDirection={'column'} alignItems={'center'}>
								{vouchers.map((item, index) => (
									<GridItem key={index}>
										<Box maxW="sm"
											borderRadius="xl"
											boxShadow="sm"
											overflow="hidden"
											borderLeft={index === 0 ? "2px solid whitesmoke" : "none"}
										>
											<Image
											src={item?.image}
											alt="Green double couch with wooden legs"
											borderRadius="md"
											/>
											<Stack mt="6" spacing="3" p="4">
												<Heading size="md" textAlign="center" color="blue.500">
													{item?.title}
												</Heading>
												<Box
													textAlign="center"
													color="gray.600"
													h="100px" // Adjust the max height as per your requirement
													overflowY="auto"
												>
													<Text fontSize={'sm'}>{item?.description}</Text>
												</Box>
												<Text textAlign="center" color="blue.600" fontSize="2xl">
													{formatPrice(parseFloat(item?.price))}
												</Text>
											</Stack>
										</Box>
									</GridItem>
								))}
							</Flex>
						</Stack>

					</Stack>
				: 
					<Stack width='100%' fontSize={'20px'} p={5}>
						<HStack>
							<Heading size={'md'} fontWeight='bold'>{t('importir_news')}</Heading>
							<Spacer />
							<Text fontSize={'sm'} fontWeight='bold' color={'blue.500'} fontStyle='italic' cursor={'pointer'} onClick={() => navigate('/news')} >{t('see_all')}</Text>
						</HStack>
						<Stack h='400px' overflowY='scroll'>
							<SimpleGrid columns={[1]} gap={3}>
								{dataNews.slice(0, 3).map((item, index) => (
									<NewsCard
										key={index}
										title={item.title}
										description={item.description}
										category_name={item.category_name}
										featured_image={item.featured_image}
										slug={item.slug}
									/>
								))}
							</SimpleGrid>
						</Stack>
		
					</Stack>
			}


		</Stack>
	)
}

export default AppSideHome