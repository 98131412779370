/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex, HStack, Image, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { IoCaretBackOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import { errorSlack } from '../../Api/SlackApi'
import CustomStepper from '../../Components/Stepper/CustomStepper'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import ConvertPrice from '../../Utils/ConvertPrice'
import { formatFrice } from '../../Utils/Helper'
import { useTranslation } from 'react-i18next'

function OrderDetailPage() {
    const {t} = useTranslation();
    const params = useParams()

    const { loadingShow, loadingClose, userSymbolCurrency } = useContext(AuthContext)

    const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])


    const { convertPriceFix } = ConvertPrice()

    const path = window.location.href ?? '#'

    const navigate = useNavigate()


    const [detailOrder, setDetailOrder] = useState('')


    const getDataDetail = async () => {
        setDetailOrder('')
        loadingShow()
        if (params.id !== undefined) {
            try {
                const res = await _axios.get(`api/orders/${params.id}`)
                if (res.status === true) {
                    setDetailOrder(res.data)
                    console.log(res.data, 'detail')
                    if (res?.data?.status.length > 0) {
                        const dataArr = res.data.status
                        setCurrentStep((res.data.status[0].title))
                        setMessageStep((res.data.status[0].message))
                        setSteps(dataArr.reverse())
                    }
                    else {
                        setCurrentStep('')
                    }
                }

            } catch (error) {
                console.log(error)
                const res = await errorSlack('Detail order page error: ' + error.message, 'importir.com', path)
                console.log(res, 'CONNECTED ERROR IN SLACK')
                loadingClose()
            }
            loadingClose()
        }
    }

    useEffect(() => {
        getDataDetail()

        return () => {
        }
    }, [])



    return (
        <Stack spacing={2} p={[1, 1, 5]} mt={[5, 5, 0]}>
            <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                <IoCaretBackOutline size={15} />
                <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
            </HStack>


            <Stack spacing={5} p={[1, 1, 5]}>
                <Stack bgColor={'white'} p={5} borderRadius='md' shadow={'md'}>
                    <CustomStepper steps={steps} currentStep={currentStep} message={messageStep} />
                </Stack>
                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('product_info')}</Text>
                    <Stack bgColor='white' spacing={4} borderRadius='xl' p={4} boxShadow='md' direction={['column', 'column', 'row']} cursor='pointer' onClick={() => navigate(`/product/${detailOrder.platform_type}/${detailOrder.product_id}`)}>
                        <Box flex={['none', 'none', 1]} display='flex' alignItems='center' justifyContent='center'>
                            <Image src={detailOrder?.product_image} alt={detailOrder?.product_name} w='70%' borderRadius='xl' />
                        </Box>
                        <Stack maxW={['100%', '100%', '60%']} spacing={2}>
                            <Text fontWeight='bold' fontSize='sm' noOfLines={2}>
                                {detailOrder?.product_name}
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('quantity')}: </Text>{detailOrder?.quantity}
                                </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('shop_from')}: </Text>{detailOrder?.platform_type}
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('shipping_by')}: </Text>{t(detailOrder?.freight)}
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('created_at')}: </Text>{moment(detailOrder?.created_at).format('LLL')}
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{`${t('estimated_cbm')} / ${t('actual_cbm')}`}: </Text>{detailOrder?.orderPackingReceipt?.cbm_total} CBM
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                            <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{`${t('estimated_weight')} / ${t('actual_weight')}`}: </Text>{detailOrder?.orderPackingReceipt?.weight_total} KG
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('packages')}: </Text>{detailOrder?.orderPackingReceipt?.carton_total} CTN
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('updated_at')}: </Text>{moment(detailOrder?.updated_at).format('LLL')}
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('product')}: </Text>{detailOrder?.is_lartas > 0 ? 'Lartas' : 'Non-Lartas'}
                            </Flex>
                            </Text>
                            <Text color='gray.700' fontSize='sm'>
                                <Flex gap={2}>
                                    <Text fontWeight={'bold'}>{t('last_status')}: </Text>{detailOrder?.last_status === null ? `${t('waiting_for_payment')}` : detailOrder?.last_status}
                            </Flex>
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('variant')}</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md' overflow={'auto'}>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm' textAlign='center'>{t('product_image')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('product_name')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('quantity')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {detailOrder?.items?.length > 0 && detailOrder?.items?.map((x, index) => (
                                    <Tr key={index}>
                                        <Td fontSize='sm' >
                                            {x.image === "" ? (
                                                <Stack alignItems={'center'} justifyContent='center'>
                                                    <Image src={detailOrder?.product_image} alt={x.name} w='50px' />
                                                </Stack>
                                            ) : (
                                                <Stack alignItems={'center'} justifyContent='center'>
                                                    <Image src={x.image} alt={x.name} w='50px' />
                                                </Stack>
                                            )}

                                        </Td>
                                        <Td fontSize='sm' textAlign='center'>{x.name}</Td>
                                        <Td fontSize='sm' textAlign='center'>{x.quantity}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('use_cbm_balance')}</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md' overflow={'auto'}>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm' textAlign='center'>{t('product_type')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('transaction_type')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('cbm_amount')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('approved_at')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {detailOrder?.cbm_balance_logs_pure?.length > 0 && detailOrder?.cbm_balance_logs_pure?.map((x, index) => (
                                    <Tr key={index}>
                                        <Td fontSize='sm' textAlign='center'>{x.is_lartas > 0 ? `${t('all_product')}` : `${t('selected_product')}`}</Td>
                                        <Td fontSize='sm' textAlign='center'>{x.transaction_type}</Td>
                                        <Td fontSize='sm' textAlign='center'>{x.cbm_amount}</Td>
                                        <Td fontSize='sm' textAlign='center'>{x.approved_at}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('invoice_list')}</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md' overflow={'auto'}>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm' textAlign='center'>{t('invoice_number')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('total')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('paid_at')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('actions')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {detailOrder?.invoiceDetailsGroupByInvoiceId?.length > 0 && detailOrder?.invoiceDetailsGroupByInvoiceId?.map((x, index) => (
                                    <Tr key={index}>
                                        <Td fontSize='xs' textAlign='center'>{x?.invoice?.invoice}</Td>
                                        <Td fontSize='xs' textAlign='center'>{userSymbolCurrency} {formatFrice(convertPriceFix(x?.invoice?.amount_total))}</Td>
                                        <Td fontSize='xs' textAlign='center'>{x?.invoice?.paid_at === null ? `${t('waiting_for_payment')}` : x?.invoice?.paid_at}</Td>
                                        <Td fontSize='xs' textAlign='center'>
                                            <Button fontSize='sm' bgColor={'#ffd600'} shadow='md' onClick={() => navigate(`/invoice/${x?.invoice?.invoice}`)}>
                                                Detail
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('address')}</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Text textTransform='capitalize' fontWeight='bold' fontSize='sm'>{detailOrder?.address?.receiver}</Text>
                        <Text fontSize='sm'>{detailOrder?.address?.phone}</Text>
                        <Text fontSize='sm'>{detailOrder?.address?.address}</Text>
                        <HStack>
                            <Text fontSize='sm'>{detailOrder?.address?.district}</Text>
                            <Text fontSize='sm'>, {detailOrder?.address?.city}</Text>
                            <Text fontSize='sm'>, {detailOrder?.address?.province}</Text>
                        </HStack>
                        <Text fontSize='sm'>{detailOrder?.address?.postal_code}</Text>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('shipping_method')}</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Text textTransform='capitalize' fontWeight='bold' fontSize='sm'>{detailOrder?.shipping_method}</Text>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>Detail</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm'>{t('title')}</Th>
                                    <Th fontSize='sm'>{t('amount')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {detailOrder?.details?.length > 0 && detailOrder?.details?.map((x, index) => (
                                    <Tr key={index}>
                                        <Td fontSize='sm'>{x.title}</Td>
                                        <Td fontSize='sm'>{userSymbolCurrency} {formatFrice(convertPriceFix(x.amount))}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>
                {/* <Stack>
                    <Text fontSize={'xs'} color='red.500'>{t('order_invoice_attention_msg')}</Text>
                </Stack> */}
            </Stack>
        </Stack>

    )
}

export default OrderDetailPage