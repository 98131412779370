import React, { useEffect, useState } from 'react'
import { Container, Stack, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Box, Link, Icon } from '@chakra-ui/react';
import _axios from '../../../Api/AxiosBarrier';
import { useNavigate } from 'react-router-dom';
import { formatFrice } from '../../../Utils/Helper';
import { SearchIcon } from '@chakra-ui/icons';
import { FcMultipleSmartphones, FcSearch } from 'react-icons/fc';
import { HiCreditCard } from 'react-icons/hi';

function IndexPage() {
    const [orders, setOrders] = useState()
    const navigate = useNavigate()

    const fetchOrderList = async () => {
        try {
            const res = await _axios.get('api/mitra/order/index')
            setOrders(res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchOrderList()
    }, []);
  return (
    <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxW="container.xl" position={'relative'} zIndex={'1'} top={'-3rem'}>
        <Container maxW={'container.2xl'} p={5}>
            <Stack bgColor={'#d9edf7'} borderColor={'#bcdff1'} className='old-order' spacing={1} borderRadius='md' p={2} shadow={3} display={'flex'} justifyContent={'center'} width={'full'}>
                <Box textAlign={'center'} fontSize={'2xl'} fontStyle={'italic'}>
                    <Text>This is the new page, You can <Link href='/mitra/old-order' textColor={'blue.400'} fontWeight={'bold'}>click</Link> to see the old page</Text>
                </Box>
            </Stack>
        </Container>
        <Stack p={[1, 1, 5]}>
            <TableContainer>
                <Table variant='striped' colorScheme='teal'>
                    <Thead>
                        <Tr>
                            <Th>Invoice</Th>
                            <Th>Items</Th>
                            <Th>Price</Th>
                            <Th>Paid At</Th>
                            <Th>Checkout At</Th>
                            <Th>Detail</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            orders?.length > 0 ? 
                            orders?.map((x, i) => {
                                const totalPrice = x?.mitra_order_details.reduce((total, detail) => {
                                    return total + parseInt(detail?.product_price_total || 0);
                                  }, 0);
                                return (
                                    <Tr>
                                        <Td>{x?.order_number}</Td>
                                        <Td>{x?.mitra_order_details.length}</Td>
                                        <Td>Rp. {formatFrice(totalPrice)}</Td>
                                        <Td>{x?.paid_at == null ? 'Not Paid' : 'Paid'}</Td>
                                        <Td>{x?.checkout_at}</Td>
                                        <Td>
                                            <Button colorScheme={'blue'} onClick={() => navigate(`/mitra/order/${x._id}/detail`)}>
                                                <Icon as={FcSearch} />
                                            </Button>
                                        </Td>
                                    </Tr>
                                )
                            })
                            :
                            <Stack
                                position={'absolute'}
                                top={'85%'}
                                left={'50%'}
                                transform={'translate(-50%, -50%)'}
                                textAlign={'center'}
                                >
                                <Text>Data not found</Text>
                            </Stack>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </Stack>
    </Container>
  )
}

export default IndexPage
