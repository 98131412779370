import { Stack, Image, Skeleton, Tag, Text, SimpleGrid, Spacer, Button, useToast, HStack } from '@chakra-ui/react';
import { AiOutlineDelete } from 'react-icons/ai';
import { formatFrice } from '../../Utils/Helper';
import ConvertPrice from '../../Utils/ConvertPrice';
import { errorSlack } from '../../Api/SlackApi';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const ProductCardWishlist = ({ product, kursRmb, handleImage, handleDelete, handleNavigateProduct, userSymbolCurrency }) => {
    const {t} = useTranslation();
    const path = window.location.href ?? '#';

    const toast = useToast({
        position: 'top',
        align: 'center',
    });
    const [isLoading, setIsLoading] = useState(true);


    const { convertPriceFix } = ConvertPrice();

    const handleOrderNow = async () => {
        setIsLoading(true);

        const platform = getProductPlatform() === "CN 🇨🇳" ? "1688" : getProductPlatform() === "JPN 🇯🇵" ? "amazonjapan" : "";

        try {
            await handleNavigateProduct(product.id, platform);
        } catch (error) {
            const res = await errorSlack(error, 'importir.com', path);
            console.log(res, 'CONNECTED ERROR IN SLACK');
            toast({
                title: 'importir.com',
                description: 'Failed to navigate to the product page.',
                status: 'error',
            });
        }
    };

    const getProductPlatform = () => {
        const productLink = product.link.toLowerCase();

        if (productLink.includes('https://detail.1688.com/')) {
            return 'CN 🇨🇳';
        } else if (productLink.includes('https://www.amazon.co.jp')) {
            return 'JPN 🇯🇵';
        } else if (productLink.includes('https://www.amazon.com.')) {
            return 'USA us';
        }
    };

    const calculatePrice = () => {
        const productPlatform = getProductPlatform();
        const price = Number(product.price);

        if (productPlatform === 'CN 🇨🇳') {
            return Number(kursRmb) * price;
        } else if (productPlatform === 'JPN 🇯🇵') {
            return 106, 52 * price;
        } else {
            return price;
        }
    };

    const formattedPrice = isNaN(calculatePrice()) ? 0 : convertPriceFix(calculatePrice());



    return (
        <Stack shadow='md' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.2s ease-in-out'>
            <Stack alignItems='center' cursor='pointer' onClick={() => handleImage(product)}>
                {product.image ? (
                    <Image src={product.image} alt='img' borderRadius='md' />
                ) : (
                    <Skeleton w='200px' h='300px' borderRadius='md' />
                )}
            </Stack>
            <Stack px={3}>
                <HStack alignItems='flex-end' justifyContent='flex-end'>
                    <Stack>
                        <Tag>{getProductPlatform()} </Tag>
                    </Stack>
                    <Stack cursor='pointer' onClick={() => handleDelete(product)}>
                        <AiOutlineDelete style={{ fontSize: 20, color: 'red' }} />
                    </Stack>
                </HStack>
                <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={2}>
                    {product['title-en']?.en}
                </Text>
            </Stack>
            <Spacer />
            <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                <Stack spacing={0} alignItems='flex-start' justifyContent='flex-start'>
                    <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                        {t("price")}
                    </Text>
                    <Text textTransform='capitalize' fontWeight='bold' color='black' fontSize='sm'>
                        {userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(product.price)))}
                    </Text>
                </Stack>
                <Stack>
                    <Stack spacing={0} alignItems='flex-end' justifyContent='flex-end'>
                        <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                            {t("sold")}
                        </Text>
                        <Text textTransform='capitalize' color='black' fontWeight='bold' fontSize='sm'>
                            {product.sold}
                        </Text>
                    </Stack>
                </Stack>
            </SimpleGrid>
            <Stack p={3}>
                <Button size='sm' bgColor='green.400' onClick={() => handleOrderNow()}>
                    <Text color='white'>🛒 {t("order_now")}</Text>
                </Button>
            </Stack>
        </Stack>
    );
};

export default ProductCardWishlist;
