import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../Routes/hooks/AuthContext'
import _axios from '../../Api/AxiosBarrier'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, HStack, Image, Spacer, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import colors from '../../Utils/colors'
import { IoCaretBackOutline } from 'react-icons/io5'
import moment from 'moment'
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice'
import CustomStepperOldOrder from '../../Components/Stepper/CustomStepperOldOrder'
import { useTranslation } from 'react-i18next'


function OldOrderDetailPAge() {
    const {t} = useTranslation();
    const [detail, setDetail] = useState({})
    const {loadingShow, loadingClose, userSymbolCurrency} = useContext(AuthContext)
    const params = useParams()
    const navigate = useNavigate()
    const { convertPriceFix } = ConvertPrice()
    const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])

    const fetchData = async () => {
        try {
            loadingShow()
            const res = await _axios.get(`/api/orders/old-order/${params.id}/detail`)
            loadingClose()
            setDetail(res.data)
            if (res?.data?.status_front_end.length > 0) {
                const dataArr = res?.data?.status_front_end
                setCurrentStep((res?.data?.status_front_end[0]?.title))
                setMessageStep((res?.data?.status_front_end[0]?.message))
                setSteps(dataArr.reverse())
            }
            else {
                setCurrentStep('')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData();
        
        return () => {
        };
    }, []);
  return (
    <Stack bgColor={'gray.100'} p={[1, 1, 5]}>
        <Stack bgColor={'white'} p={5} borderRadius='md' shadow={'md'}>
            <CustomStepperOldOrder steps={steps} currentStep={currentStep} message={messageStep} />
        </Stack>
        <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
            <IoCaretBackOutline size={15} />
            <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
        </HStack>
        <Stack spacing={5} p={[1, 1, 5]}>
            <Stack>
                <Text fontSize='md' fontWeight='bold'>{t('product_info')}</Text>
                {detail?.yg_order_detail?.length > 0 && detail.yg_order_detail[0] ? (
                <Stack bgColor='white' spacing={4} borderRadius='xl' p={4} boxShadow='md' direction={['column', 'column', 'row']} cursor='pointer' onClick={() => navigate(`/product/${detail?.flag}/${detail?.yg_order_detail[0]?.yg_product_detail?.product_id}`)}>
                    <Box flex={['none', 'none', 1]} display='flex' alignItems='center' justifyContent='center'>
                        <Image src={detail.yg_order_detail[0].product_image} w='70%' borderRadius='xl' /> 
                    </Box>
                    <Stack maxW={['100%', '100%', '60%']} spacing={2}>
                        <Text fontWeight='bold' fontSize='sm' noOfLines={2}>
                            {detail?.yg_order_detail[0]?.yg_product_detail?.title_en}
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('quantity')}: {detail?.yg_order_detail[0]?.quantity}
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('shop_from')}: {detail?.flag}
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('shipping_by')}: {t(detail?.yg_order_detail[0]?.delivery_type)}
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('created_at')}: {moment(detail?.yg_order_detail[0]?.created_at).format('LLL')}
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {`${t('estimated_cbm')} / ${t('actual_cbm')}`}: {detail?.yg_order_detail[0]?.cbm} CBM
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {`${t('estimated_weight')} / ${t('actual_weight')}`}: {detail?.yg_order_detail[0]?.weight_total} KG
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('packages')}: {detail?.carton_total} CTN
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('updated_at')}: {moment(detail?.updated_at).format('LLL')}
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('product')}: {detail?.is_lartas != null ? 'Lartas' : 'Non-Lartas'}
                        </Text>
                        <Text color='gray.700' fontSize='sm'>
                            {t('last_status')}: {detail?.current_status === "" ? `${t('waiting_for_payment')}` : detail?.current_status}
                        </Text>
                    </Stack>
                </Stack>
                ) : (
                    <span>Loading or No Data</span>
                )}
            </Stack>
            <Stack>
                <Text fontSize='md' fontWeight='bold'>{t('variant')}</Text>
                {detail?.yg_order_detail?.length > 0 && detail.yg_order_detail[0] ? (
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray'>
                        <Thead>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>{t('product_image')}</Th>
                                <Th fontSize='sm' textAlign='center'>{t('product_name')}</Th>
                                <Th fontSize='sm' textAlign='center'>{t('quantity')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {detail?.yg_order_detail[0]?.yg_order_spec?.length > 0 && detail?.yg_order_detail[0]?.yg_order_spec?.map((x, index) => (
                                <Tr key={index}>
                                    <Td fontSize='sm' >
                                        {x.image === "" ? (
                                            <Stack alignItems={'center'} justifyContent='center'>
                                                <Image src={detail.yg_order_detail[0].product_image} alt={x?.name != '' ? x?.name : '-'} w='50px' />
                                            </Stack>
                                        ) : (
                                            <Stack alignItems={'center'} justifyContent='center'>
                                                <Image src={x?.image} alt={x?.name != '' ? x?.name : '-' } w='50px' />
                                            </Stack>
                                        )}

                                    </Td>
                                    <Td fontSize='sm' textAlign='center'>{x?.title != '' ? x?.title : '-'}</Td>
                                    <Td fontSize='sm' textAlign='center'>{x.quantity}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Stack>
                ) : (
                    <span>Loading or No Data</span>
                )}
            </Stack>
            <Stack>
                <Text fontSize='md' fontWeight='bold'>{t('invoice_list')}</Text>
                <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                    <Table variant='striped' colorScheme='gray'>
                        <Thead>
                            <Tr>
                                <Th fontSize='sm' textAlign='center'>{t('invoice')}</Th>
                                <Th fontSize='sm' textAlign='center'>{t('amount')}</Th>
                                <Th fontSize='sm' textAlign='center'>{t('paid_at')}</Th>
                                <Th fontSize='sm' textAlign='center'>{t('amount')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {detail?.first_bill !== null ? 
                             <Tr>
                                <Td fontSize='xs' textAlign='center'>{detail?.first_bill?.com_invoice.title}</Td>
                                <Td fontSize='xs' textAlign='center'>{userSymbolCurrency} {formatFrice(convertPriceFix(detail?.first_bill?.com_invoice.amount))}</Td>
                                <Td fontSize='xs' textAlign='center'>{detail?.first_bill?.com_invoice.paid_at === null ? 'Waiting for payment' : detail?.first_bill?.com_invoice.paid_at}</Td>
                                <Td fontSize='xs' textAlign='center'>
                                    <Button fontSize='sm' bgColor={'#ffd600'} shadow='md' onClick={() => navigate(`/old-invoice/${detail?.first_bill?.com_invoice.id}`)}>
                                        Detail
                                    </Button>
                                </Td>
                            </Tr>
                             : ""}

                            {detail?.com_invoice?.length > 0 && detail?.com_invoice?.map((x, index) => (
                                <Tr key={index}>
                                    <Td fontSize='xs' textAlign='center'>{x?.title}</Td>
                                    <Td fontSize='xs' textAlign='center'>{userSymbolCurrency} {formatFrice(convertPriceFix(x?.amount))}</Td>
                                    <Td fontSize='xs' textAlign='center'>{x?.paid_at === null ? 'Waiting for payment' : x?.paid_at}</Td>
                                    <Td fontSize='xs' textAlign='center'>
                                        <Button fontSize='sm' bgColor={'#ffd600'} shadow='md' onClick={() => navigate(`/old-invoice/${x?.id}`)}>
                                            Detail
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Stack>
            </Stack>

            <Stack>
                <Text fontSize='md' fontWeight='bold'>{t('address')}</Text>
                {detail?.yg_order_detail?.length > 0 && detail.yg_order_detail[0] ? (
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Text fontSize='sm'>{detail?.yg_order_detail[0]?.phone}</Text>
                        <Text fontSize='sm'>{detail?.yg_order_detail[0]?.address}</Text>
                        <HStack>
                            <Text fontSize='sm'>{detail?.yg_order_detail[0]?.district}</Text>
                            <Text fontSize='sm'>, {detail?.yg_order_detail[0]?.city}</Text>
                            <Text fontSize='sm'>, {detail?.yg_order_detail[0]?.province}</Text>
                        </HStack>
                        <Text fontSize='sm'>{detail?.yg_order_detail[0]?.postal_code}</Text>
                    </Stack>
                    ) : (
                    <Text></Text>
                )}
            </Stack>
        </Stack>
    </Stack>
  )
}

export default OldOrderDetailPAge