import { Stack, useToast, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,Heading, Button } from '@chakra-ui/react'
import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import AuthContext from '../../Routes/hooks/AuthContext'
import _axios from '../../Api/AxiosBarrier'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDateTime } from '../../Utils/Helper'
import store from 'store'

function IndexPage() {
    const [packages, setPackages] = useState([])
    const {loadingShow, loadingClose} = useContext(AuthContext)
    const toast = useToast({
        position: 'top',
        align: 'center',
    });
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const user = store.get('user_api')
            const params = {
                email: user.email
            }
            console.log(params)
            loadingShow()
            const res = await _axios.post('api/register/get-po', params)
            console.log(res)
            loadingClose()
            if (res.status == true) {
                setPackages(res.data)
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error',
                  });
            }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
  return (
    <Stack p={[1, 1, 5]} bg={'white'}>
        <Heading p={5}>
            Package List
        </Heading>
        <TableContainer>
            <Table variant='striped' colorScheme='teal'>
                <Thead>
                <Tr>
                    <Th>Invoice Number</Th>
                    <Th>Package</Th>
                    <Th>#</Th>
                </Tr>
                </Thead>
                <Tbody>
                    { packages?.length > 0 && packages?.map((x, index) => {
                        return (
                            <Tr>
                                <Td>{x?.invoice_number}</Td>
                                <Td>{x?.package_child?.name}</Td>
                                <Td>
                                    <Button colorScheme='orange' onClick={() => navigate(`/package/${x?.invoice_number}`)}>
                                        Detail
                                    </Button>
                                </Td>
                            </Tr>
                        )
                    })
                    }
                </Tbody>
            </Table>
        </TableContainer>
    </Stack>
  )
}

export default IndexPage