import { Box, Stack, Text, Heading, Divider, Icon, Flex, Center } from '@chakra-ui/react';
import React from 'react'
import { BsCartFill, BsListUl } from "react-icons/bs";
import { FaHome, FaHeart } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function AppHeader() {
    const navigate = useNavigate()
  return (
    <Stack spacing={0}>
        <Stack
        as="nav"
        justify="space-between"
        align="center"
        padding="1rem"
        bg="#ffd600"
        color="black"
        >
            <Box justifyItems={'center'} alignContent={'center'}>
                <Text>
                    Welcome to <b>IMPORTIR MITRA</b> store 1000+ new products trending 2023
                </Text>
            </Box>
        </Stack>

        <Stack
        as="nav"
        align="center"
        padding="1rem"
        bg="black"
        height={'52'}
        >
            <Box justifyItems={'center'} alignContent={'center'}>
                <Heading color={'#ffd600'}>MITRA IMPORTIR</Heading>
            </Box>
            
            <Divider />

            <Flex color={'white'} p={'6'} gap={5}>
                <Stack onClick={() => navigate('/mitra')} marginRight="1rem" className={window.location.pathname === '/mitra' ? 'active-link' : ''} color={window.location.pathname === '/mitra' ? 'yellow' : 'white'}>
                    <Flex>
                        <Center>
                            <Icon as={FaHome} mr={0.5} /> <Text display={{base: 'none', md:'block'}}>Dashboard</Text>
                        </Center>
                    </Flex>
                </Stack>
                <Stack onClick={() => navigate('/mitra/order/list')}  marginRight="1rem" className={window.location.pathname === '/mitra/order/list' ? 'active-link' : ''} color={window.location.pathname === '/mitra/order/list' ? 'yellow' : 'white'}>
                    <Flex>
                        <Center>
                            <Icon as={BsListUl} mr={0.5} /> <Text display={{base: 'none', md:'block'}}>History Order</Text>
                        </Center>
                    </Flex>
                </Stack>
                <Stack onClick={() => navigate('/mitra/cart')} marginRight="1rem" className={window.location.pathname === '/mitra/cart' ? 'active-link' : ''} color={window.location.pathname === '/mitra/cart' ? 'yellow' : 'white'}>
                    <Flex>
                        <Center>
                            <Icon as={BsCartFill} mr={0.5} /> <Text display={{base: 'none', md:'block'}}>Cart</Text>
                        </Center>
                    </Flex>
                </Stack>
                <Stack onClick={() => navigate('/mitra/wishlist')} marginRight="1rem" className={window.location.pathname === '/mitra/wishlist' ? 'active-link' : ''} color={window.location.pathname === '/mitra/wishlist' ? 'yellow' : 'white'}>
                    <Flex>
                        <Center>
                            <Icon as={FaHeart} mr={0.5} /> <Text display={{base: 'none', md:'block'}}>Wishlist</Text>
                        </Center>
                    </Flex>
                </Stack>
            </Flex>
        </Stack>
    </Stack>
  )
}

export default AppHeader