import { Box, Button, Grid, HStack, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Skeleton, Spacer, Stack, Tag, Text, useToast, AspectRatio, List, ListItem, ListIcon, OrderedList, UnorderedList } from '@chakra-ui/react'
import { arrayUnion, collection, doc, documentId, getDocs, increment, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { SlArrowDown } from 'react-icons/sl'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { errorSlack, productSearchSlack } from '../Api/SlackApi'
import { clientTypessense } from '../Api/Typesense'
import ProductCard from '../Components/Card/ProductCard'
import { db } from '../Config/firebase'
import AuthContext from '../Routes/hooks/AuthContext'
import ConvertPrice from '../Utils/ConvertPrice'
import { formatFrice } from '../Utils/Helper'
import nonlartas from "../Config/Json/NonLartas.json"
import { sendEcommerceEvent } from '../Utils/gtm'
import axios from 'axios'
import moment from 'moment'


function SearchPageByImage() {
    let [searchParams, setSearchParams] = useSearchParams()
    const { currentUser, kursRmb, loadingShow, loadingClose, productListWishlistId, userSymbolCurrency, categoryFrom } = useContext(AuthContext)

    const path = window.location.href ?? '#'
    const toast = useToast({
        position: "top",
        align: "center",
    });


    const [search, setSearch] = useState('')
    const [productList, setProductList] = useState([])
    const [page, setPage] = useState(1)

    const [imageView, setImageView] = useState('')
    const [detailModal, setDetailModal] = useState(false)
    const [lastPage, setLastPage] = useState(0)

    const navigate = useNavigate()

    const { convertPriceFix } = ConvertPrice()

    const urlSearchParams = searchParams.get("url")
    const height = window.innerHeight
    const dataNonLartas = nonlartas.map((x) => x.product_id)


    const handleSearchParams = async () => {
        loadingShow();
        
        try {
            let urlSearch = urlSearchParams;
            console.log("detail search", urlSearch)

            setSearch(urlSearch);

            loadingShow();
            const fetchURL = await axios.post('https://cloudrunai-es6-awfnqqp6oq-uc.a.run.app/importir/image-search', {
                "image":urlSearch,
                "id":"x.id",
                "domain":"detail.1688.com",
                "topK": 30
            })
            loadingShow();
            const matches = fetchURL.data.matches;
            let searchResultIds = []
            if (matches.length > 0) {
                matches.forEach((x) => {
                    searchResultIds.push(x.metadata.id)
                })
            }
            loadingShow();
            const q = query(
                collection(db, "products"),
                where(documentId(), "in", searchResultIds)
              );
  
              onSnapshot(q, (querySnapshot) => {
                const arrProducts = [];
  
                const imageResults = []
                // Loop through querySnapshot in the order of searchResultIds
                searchResultIds.forEach((id) => {
                  const doc = querySnapshot.docs.find((doc) => doc.id === id);
                  if (doc) {
                    const objData = doc.data();
                    objData.id = doc.id;
                    imageResults.push(objData.image)
                    arrProducts.push(objData);
                  }
                });
  
                if (arrProducts.length > 0) {
                  arrProducts.sort((a, b) => a.price - b.price);
                }
                console.log("product ids", arrProducts)
  
                if (page > 1) {
                  setProductList((prevProductList) => [...prevProductList, ...arrProducts]);
                } else {
                  setProductList(arrProducts);
                }
                const searchParamSave = {
                  'url': urlSearch,
                  'id_from_embed': searchResultIds,
                  'id_from_embed_length': searchResultIds.length,
                  'id_from_db': imageResults,
                  'id_from_db_length': imageResults.length
                }
                console.log("save", JSON.stringify(searchParamSave))
  
                loadingClose();
              });

        } catch (error) {
            if (error.code === "aborted") {
                console.log("Network error occurred");
            } else {
                const resSlack = await errorSlack(error, 'importir.com', path);
                console.log(resSlack, 'CONNECTED ERROR IN SLACK');
            }
            loadingClose();
        }
    };

    useEffect(() => {
        setProductList([]);
        setPage(1);
        handleSearchParams()
    }, []);

    const handleWishlist = async (data) => {
        loadingShow()

        let newData = data
        newData.createdAt_wishlist = new Date()

        try {

            const ref = doc(db, "wishlist", currentUser.uid);
            await setDoc(ref, {
                uid: currentUser.uid,
                data: arrayUnion(newData),
                createdAt: new Date()
            }, { merge: true });

            const docRef = doc(db, "products", data.id);
            try {
                await updateDoc(docRef, {
                    wishlist_count: increment(1),
                });


                const ecommerceData = {
                    'currency': 'IDR',
                    'value': parseFloat(data.price),
                    'items': [
                        {
                            'item_id': data.id,
                            'item_name': data.title,
                            'item_category': data.category,
                            // 'price': parseFloat(data.price),
                            // 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
                        },
                    ],
                };

                sendEcommerceEvent('add_to_wishlist', ecommerceData);


            } catch (error) {
                console.log(error);
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
            loadingClose()
            toast({
                title: 'importir.com',
                description: 'Successfully added the product to the wishlist.',
                status: 'success'
            })

        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }

        loadingClose()
    }

    const handleImage = (data) => {
        setImageView(data)
        setDetailModal(true)
    }

    const handleNavigateProduct = (idProduct, platformType) => {
        const navigateRouteProduct = `/product/${platformType}/${idProduct}`
        navigate(navigateRouteProduct)
    }

    return (
        <Stack px={[1, 1, 5]} pb={10} bgColor='gray.100' minH={height}>
            <Grid templateColumns={{ base: "1fr", md: "1fr " }} gap={3} >

                <Stack>
                    <HStack alignItems={'center'} justifyContent='center' p={[1, 1, 3]}>

                    </HStack>

                    <Stack spacing={2} px={[1, 1, 3]}>
                        <Stack >
                            {productList?.length >= 1 ? (
                                <>
                                    <Text fontSize={'xl'} textColor={'red.300'} textAlign={'center'} fontWeight={'bold'} fontFamily={'monospace'}>
                                        The Searched Image
                                    </Text>
                                    <Image 
                                        src={urlSearchParams} 
                                        alt='img'  
                                        borderRadius='md' 
                                        style={{ width: '250px ', height:'250px', objectFit: 'cover', textAlign: 'center' }} />
                                    <Text fontSize={'xl'} textColor={'black.300'} textAlign={'center'} fontWeight={'bold'} fontFamily={'monospace'}>
                                        The Results
                                    </Text>
                                    <SimpleGrid columns={[2, 3, 5]} gap={5} >
                                        {
                                            productList?.map((product, i) => {
                                                const wishlistFilter = productListWishlistId.includes(product.id)
                                                const nonLartasFilter = dataNonLartas.includes(product.id)
                                                return (
                                                    <ProductCard
                                                        key={i}
                                                        product={product}
                                                        kursRmb={kursRmb}
                                                        handleNavigateProduct={handleNavigateProduct}
                                                        handleWishlist={handleWishlist}
                                                        handleImage={handleImage}
                                                        nonLartasFilter={nonLartasFilter}
                                                        wishlistFilter={wishlistFilter}
                                                        userSymbolCurrency={userSymbolCurrency}
                                                    />
                                                )
                                            }
                                            )
                                        }

                                    </SimpleGrid>
                                </>
                            ) :
                                <Stack bg={'white'} px={6} py={6} w={'full'}>
                                    <SimpleGrid columns={2}>
                                        <Box marginTop={'30%'}>
                                            <Text fontSize={'xl'} textColor={'red.300'} textAlign={'center'} fontWeight={'bold'} fontFamily={'monospace'}>Sorry, Search engine under maintenence.</Text>
                                            <Text fontSize={'3xl'} textColor={'#407BFF'} textAlign={'center'} fontWeight={'bold'} fontFamily={'monospace'}>Opps!!! the product you are looking for is not found.</Text>
                                            <Stack px={6} py={6} marginLeft={'10%'} textColor={'GrayText'}>
                                                <Text>Try:</Text>
                                                <UnorderedList>
                                                    <ListItem>if the keywords you enter are too long, please search using more specific words</ListItem>
                                                    <ListItem>Use English to search for the keywords you want</ListItem>
                                                    <ListItem>Or please click the video below to search for products from 1688.com</ListItem>
                                                </UnorderedList>
                                            </Stack>
                                        </Box>
                                        <Box>
                                            <Image src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/no-data-rafiki-2-2023_06_22_11_14_32.png'></Image>
                                        </Box>
                                    </SimpleGrid>
                                    <Box style={{ marginLeft: '12%', marginRight: '10%' }}>
                                        <AspectRatio maxW='960px' ratio={16 / 9}>
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ArDbLsB_inU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </AspectRatio>
                                    </Box>
                                </Stack>

                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>

            {imageView !== "" && (
                <Modal isOpen={detailModal} onClose={() => setDetailModal(false)} >
                    <ModalOverlay />
                    <ModalContent bgColor={'white'} >
                        <ModalHeader>
                            <HStack spacing={2} alignItems='center' >
                                {/* <FontAwesome5 name="file-invoice" size={22} color="black" /> */}
                                <Text fontSize={'lg'} fontWeight='bold'>Detail</Text>
                                <Spacer />
                                <HStack alignItems={'center'} justifyContent='flex-end'>
                                    <Stack>
                                        <Tag>CN 🇨🇳</Tag>
                                    </Stack>

                                </HStack>
                            </HStack>
                        </ModalHeader>

                        <ModalBody >
                            <Stack borderRadius={'xl'} spacing={2} bgColor='white' >
                                <Stack alignItems={'center'} cursor='pointer'>
                                    {imageView.image ? (
                                        <Image w={'full'} src={imageView.image} alt='img' borderRadius={'md'} />
                                    ) : (
                                        <Skeleton w={'200px'} h='300px' borderRadius={'md'} />
                                    )}

                                </Stack>
                                <Stack px={3}>

                                    <Text textTransform={'capitalize'} fontWeight='bold' fontSize={'sm'} noOfLines={2}> {imageView["title-en"]?.en}</Text>
                                </Stack>

                                <Spacer />

                                <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                                    <Stack spacing={0} alignItems='flex-start' justifyContent={'flex-start'} >

                                        <Text textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>Price</Text>
                                        {/* <Text textTransform={'capitalize'} fontWeight='extrabold' color={'black'} fontSize={'md'}>{userSymbolCurrency} {formatFrice(x.price)}</Text> */}
                                        <Text textTransform={'capitalize'} fontWeight='bold' color={'black'} fontSize={'sm'}>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(imageView.price)))}</Text>
                                    </Stack>

                                    <Stack >

                                        <Stack spacing={0} alignItems='flex-end' justifyContent={'flex-end'}>
                                            <Text fontsize textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>Sold</Text>
                                            <Text textTransform={'capitalize'} color={'black'} fontWeight='bold' fontSize={'sm'}>{imageView.sold}</Text>
                                        </Stack>
                                    </Stack>


                                </SimpleGrid>

                            </Stack>
                        </ModalBody>

                        <ModalFooter>
                            <HStack spacing={5}>
                                <Button bgColor='green.400' onClick={() => handleNavigateProduct(imageView.id, '1688')}>
                                    <Text color={'white'}>🛒 Order now</Text>
                                </Button>
                                <Button colorScheme='red' mr={3} onClick={() => setDetailModal(false)}>
                                    Close
                                </Button>
                            </HStack>
                        </ModalFooter>


                    </ModalContent>
                </Modal>
            )}
        </Stack>
    )
}

export default SearchPageByImage