import { HStack, Image, Link, Stack, IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import React, {  useState } from "react";
import { useTranslation } from "react-i18next";

export default function Preloader({data_x, link_ref, link_mobile, link_web, handlePopupClose}) {
  const screenHeight = window.innerHeight;
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth < 768;
  const {t} = useTranslation();

  const [view, setView] = useState(true)

 
  const closePopup = () => {
    setView(false);
    handlePopupClose(data_x._id)
  };


  return (
    view && (
      <HStack
        space={2}
        justifyContent="center"
        position="fixed"
        zIndex={1000}
        height={screenHeight}
        width="100%"
        bg="rgba(0, 0, 0, 0.7)"
      >
        <Stack 
        top={{base:150, sm: 160, md: 100, lg: 30}} 
        position="absolute" 
        right={{base:2, md: 30, lg:30}}>
          <IconButton
            icon={<CloseIcon />}
            aria-label="Close"
            onClick={() => closePopup()}
            bgColor="#ffd600"
          />
        </Stack>
        <Link
          href={link_ref}
          isExternal
        >
          <Stack alignItems={"center"} justifyContent="center" mr={2}>
            <Image
              src={isMobile ? link_mobile : link_web}
              w={["100%", "80%"]}
            />
            <Text fontWeight={500} bgColor="#ffd600" px={2} borderRadius='md' py={1} color='black'>
              {t('click_here_now')} 
            </Text>
          </Stack>
        </Link>
      </HStack>
    )
  );
}
