export default {
  primary : '#fc5c65',
  secondary: '#4ecdc4',
  black: '#1a202c',
  white: '#fff',
  medium : '#6e6969',
  light : '#d8d8d8',
  dark: '#2B2B2B',
  darklight: '#413F42',
  danger: '#ff5252',
  theme: '#FFD600',
  text: '#00B5D8'

}