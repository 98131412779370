import { Stack, Grid, Card, CardBody, Text, Select, Box, Input, StackDivider, ListItem, List, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Flex, Tr, Table, Tbody, Td } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import store from 'store'
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatFrice, getShippingFee } from '../../Utils/Helper';
import ConvertPrice from '../../Utils/ConvertPrice';
import { useTranslation } from 'react-i18next';

function SimulatorPage() {
    const {t} = useTranslation();
    const resultRef = useRef()
    const queryParams = new URLSearchParams(window.location.search);
    const [freight, setFreight] = useState([])
    const [freightInput, setFreightInput] = useState(queryParams.get('freight') || ""); 
    const [volumeEst, setVolumeEst] = useState(queryParams.get('volume_est') || 0)
    const [weightEst, setWeightEst] = useState(queryParams.get('weight_est') || 0)
    const [lartasInput, setLartasInput] = useState(queryParams.get('lartas') || "")
    const [handlingFeePercentage, setHandlingPrecentageFee] = useState(queryParams.get('handling_fee_percentage') || "")
    const [totalShipping, setTotalShipping] = useState(parseFloat(queryParams.get('total_shipping')) || 0)
    const [products, setProducts] = useState({
        hs_code: {
            ppn:queryParams.get('p1_hs_ppn_percent') ||  0,
            pph: queryParams.get('p1_hs_bm_percent') || 0,
            bm:queryParams.get('p1_hs_pph_percent') || 0
        },
        hs_format: queryParams.get('p1_hs_format') || "",
        price: parseFloat(queryParams.get('p1_price')) || 0,
        ppn: parseFloat(queryParams.get('p1_ppn')) || 0,
        pph: parseFloat(queryParams.get('p1_pph')) || 0,
        bm: parseFloat(queryParams.get('p1_bm')) || 0,
        bmFee: parseFloat(queryParams.get('p1_hs_bm_percent')) || 0,
        ppnFee: parseFloat(queryParams.get('p1_hs_ppn_percent')) || 0,
        pphFee: parseFloat(queryParams.get('p1_hs_pph_percent')) || 0,
        ppn_transaction:queryParams.get('p1_ppn_transaction') || 0,
    })
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [hscodeKeyword, sethscodeKeyword] = useState("")
    const [hscode, setHscode] = useState([])
    const [hscodeCurrentPage, setHscodeCurrentPage] = useState(0)
    // const [hscodeLastPage, setHscodeLastPage] = useState(0)
    // const [showLoadMore, setShowLoadMore] = useState(false)
    const {kursRates, loadingShow, loadingClose, userSymbolCurrency} = useContext(AuthContext)
    const [handlingFee, setHandlingFee] = useState(parseFloat(queryParams.get('handling_fee')) || 0)
    const [totalPrice, setTotalPrice] = useState(parseFloat(queryParams.get('total_price')) || 0)
    const [ppnHandlingFee, setPpnHandlingFee] = useState(parseFloat(queryParams.get('ppn_hanlding_fee')) || 0)
    const [additionalFee, setAdditionalFee] = useState(queryParams.get('additional_fee') || 0)
    const [additionalFeeCalc, setAdditionalFeeCalc] = useState(parseFloat(queryParams.get('additional_fee_calc')) || 0)
    const [totalBill, setTotalBill] = useState(0)
    const [copyLink, setCopyLink] = useState('')
    const [importFrom, setImportFrom] = useState(queryParams.get('import_from') || 'china')
    const user = store.get('user_api')
    const { convertPriceFix } = ConvertPrice();

    const getShippingFreight = async (val = null) => {
        try {
            let country;
            if (val == null) {
                country = importFrom;
            } else {
                country = val;
            }
            const res = await _axios.get(
                `api/shipping/freight?from=${country}`
            );
            if (res.status) {
                setFreight(res.data);
            } else {
                alert(res.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    
    
    const handlePriceChange = (newPrice) => {
        setProducts(data => ({...data, price: newPrice}))
    };
    

    const calculateFreight =  () => {
        loadingShow()
        const weight = findWeight()
        const minCbm = 0.2
        const volume = volumeEst ? volumeEst : minCbm;
        const selectedFreight = freight.find(data => data.id === parseInt(queryParams.get('freight'), 10));
        const inputFreight = selectedFreight !== undefined ? selectedFreight : freightInput
        if (inputFreight) {
            if (user?.country_code === 'ph'){
                inputFreight.price_air = 780 * kursRates?.php;
                inputFreight.price = 11500 * kursRates?.php;
            }else{
                
            }
            if (inputFreight.freight_type === "AIR") {
                const cbmWeight = volume * 168;
                if (cbmWeight > weight){
                    setTotalShipping(inputFreight.price_air * cbmWeight);
                }else{
                    setTotalShipping(inputFreight.price_air * weight);
                }
            } else {
                const packageUser = store.get('package_user')
                const isLartas = lartasInput === 'non_lartas'? 0 : 1; 
                if (user?.country_code === 'id'){
                    const seaFee = getShippingFee(packageUser?.package?.name, inputFreight.freight_type, isLartas, user?.country_code);
                    setTotalShipping(seaFee * volume)
                }else{
                    setTotalShipping(inputFreight.price * volume)
                }
            }
            onSetPriceProduct()
            const totalPriceCalc = importFrom === 'china' ? products.price * kursRates.rmb : products.price * kursRates.thb;

            const handlingFeeCalc = (totalPriceCalc * handlingFeePercentage) / 100;
            const ppnHandlingFeeCalc = (handlingFeeCalc * 11) / 100;
            const additionalFeeCalc = importFrom === 'china' ? parseFloat(additionalFee) * kursRates.rmb : kursRates.thb;
            
            setTotalPrice(totalPriceCalc);
            setHandlingFee(handlingFeeCalc);
            setPpnHandlingFee(ppnHandlingFeeCalc);
            setAdditionalFeeCalc(additionalFeeCalc);
            
            loadingClose()
            resultRef.current.scrollIntoView({ behavior: 'smooth' })
            return totalShipping
        } else {
            setTotalShipping(0)
        }
    }
    const findWeight = () => {
        let weight = weightEst ? weightEst : 0;
        const volume = volumeEst ? volumeEst : 0;
        const weightByCbm = volume * 168;
        if (weight < weightByCbm) {
            weight = weightByCbm;
        }
        console.log(weight)
        return weight;
    }

    const onSearchHscode = async (fresh = false) => {
        try {
            loadingShow()
            if (!hscodeKeyword.length) return;

            const nextPage = fresh ? 1 : hscodeCurrentPage + 1;
            const { data, meta } = await _axios.get(`/api/hscode/search?keyword=${hscodeKeyword}&page=${nextPage}`);
            
            if (fresh) {
                setHscode(data);
            } else {
                setHscode([...hscode, ...data]);
            }
            
            // setHscodeLastPage(meta.last_page);
            setHscodeCurrentPage(meta.current_page);
            // setShowLoadMore(meta.current_page < meta.last_page); 
            
            loadingClose()
        } catch (error) {
            console.log(error);
        }
    };
    

    const onSetHscode = (item) => {
        console.log('onsetha')
        const selectedFreight = freight.find(
            (data) => data.id === parseInt(freightInput?.id)
        );
        const inputFreight =
            selectedFreight !== undefined ? selectedFreight : freightInput;
        if (inputFreight) {
            if (inputFreight.freight_type === 'AIR') {
                const product = products;
                const productPrice =
                    importFrom === "china"
                        ? products.price * kursRates.rmb
                        : products.price * kursRates.thb;
                let ppn =
                    product.hs_code.ppn !== ""
                        ? parseFloat(product.hs_code.ppn)
                        : 0;
                let pph =
                    product.hs_code.pph !== ""
                        ? parseFloat(product.hs_code.pph)
                        : 0;
                let bm =
                    product.hs_code.bm !== ""
                        ? parseFloat(product.hs_code.bm)
                        : 0;

                const priceInDolar = productPrice / 15000;
                if (priceInDolar <= 3) {
                    bm = 0;
                    ppn = 11;
                    pph = 0;
                } else if (priceInDolar > 3 && priceInDolar < 1500) {
                    bm = 7.5;
                    ppn = 11;
                    pph = 0;
                }

                setProducts((prevProducts) => ({
                    ...prevProducts,
                    hs_format: item.hs_format,
                    hs_code: {
                        bm: bm,
                        ppn: ppn,
                        pph: pph,
                    },
                }));
            }else{
                setProducts((prevProducts) => ({
                    ...prevProducts,
                    hs_format: item.hs_format,
                    hs_code: {
                        bm: item.bm,
                        ppn: item.ppn,
                        pph: item.pph,
                    },
                }));
            }
        }
        
        onClose();

        return products
    };
    
    const onSetPriceProduct = () => {
        
        const item = products
        const productPrice = importFrom === 'china' ? products.price * kursRates.rmb : products.price * kursRates.thb;

        let ppn = item.hs_code.ppn !== "" ? parseFloat(item.hs_code.ppn) : 0;
        let pph = item.hs_code.pph !== "" ? parseFloat(item.hs_code.pph) : 0;
        let bm = item.hs_code.bm !== "" ? parseFloat(item.hs_code.bm) : 0;
        
        if (freightInput.freight_type === 'AIR') {
            const priceInDolar = productPrice / 15000;
            if (priceInDolar <= 3) {
                bm = 0;
                ppn = 11;
                pph = 0;
            } else if (priceInDolar > 3 && priceInDolar < 1500) {
                bm = 7.5;
                ppn = 11;
                pph = 0;
            }
        }
    
        const bmLoc = (productPrice * bm) / 100 
        const pphLoc = (productPrice + bmLoc ) * pph / 100
        const ppnLoc = (productPrice + bmLoc ) * ppn / 100 
        const ppnTransactionLoc = (productPrice * 11) / 10
        setProducts(data => ({...data, bm: bmLoc, bmFee: bm, pph: pphLoc, pphFee: pph, ppn: ppnLoc, ppnFee: ppn, pph_transaction: ppnTransactionLoc}))
    };
    
    const handleCopyClick = () => {
        navigator.clipboard.writeText(copyLink)
          .then(() => {
            alert('Success copied to clipboard');
          })
          .catch(err => {
            console.error('Error copying text:', err);
          });
      };
    
    useEffect(() => {
        getShippingFreight()
        const total = totalPrice + handlingFee + ppnHandlingFee + additionalFeeCalc + products.bm + products.ppn + products.pph + totalShipping;
        setTotalBill(total);

        const copyLink = String(window.location.host + `/shipping/simulator?share=true&import_from=${importFrom}&weight_est=${weightEst}&volume_est=${volumeEst}&freight=${freightInput?.id || ''}&lartas=${lartasInput}&handling_fee_percentage=${handlingFeePercentage}&p1_hs_ppn_percent=${products.ppnFee}&p1_hs_bm_percent=${products.bmFee}&p1_hs_pph_percent=${products.pphFee}&p1_hs_format=${products.hs_format}&p1_price=${products.price}&p1_ppn=${products.ppn}&p1_pph=${products.pph}&p1_bm=${products.bm}&p1_ppn_transaction=${products.ppn_transaction}&total_shipping=${totalShipping}&additional_fee_calc=${additionalFeeCalc}&total_price=${totalPrice}&handling_fee=${handlingFee}&ppn_hanlding_fee=${ppnHandlingFee}&additional_fee=${additionalFee}`).replace(' ', '')

            setCopyLink(copyLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPrice, handlingFee, ppnHandlingFee, additionalFeeCalc, products.bm, products.ppn, products.pph, totalShipping])

    const handleImportFrom = async (e) => {
        loadingShow();
        setImportFrom(e.target.value);
        await getShippingFreight(e.target.value);
        loadingClose();
    };

    return (
        <Stack p={[1, 1, 5]}>
            <Grid templateColumns={{base:'repeat(1, 1fr)', md:'repeat(2, 1fr)'}} gap={5} mt={[10, 10, 0]}>
                <Stack>
                    <Card bgColor={'white'} borderRadius={'md'}>
                        <CardBody>
                            <Text fontWeight={'bold'}>{t('import_from')}</Text>
                            <Select defaultValue={importFrom} onChange={(e) => handleImportFrom(e)}>
                                <option value='china'>China</option>
                                {/* <option value='thailand'>Thailand</option> */}
                            </Select>
                        </CardBody>
                    </Card>
                    <Card bgColor={'white'} borderRadius={'md'}>
                        <CardBody>
                            <Text fontWeight={'bold'}>{t('freight')}</Text>
                            <Select
                                placeholder={t('select_placeholder')}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    try {
                                        const parsedValue =
                                            JSON.parse(selectedValue);
                                            setFreightInput(parsedValue);
                                    } catch (error) {
                                        console.error("Error parsing JSON:", error);
                                    }
                                }}
                                defaultValue={freightInput ? JSON.stringify(freightInput) : ""}
                            >
                                {freight.map((data, index) => {
                                const optionValue = JSON.stringify(data);
                                
                                const isSelected = data.id === parseInt(queryParams.get('freight'), 10);
                                
                                return (
                                    <option value={optionValue} key={index} selected={isSelected}>
                                        {data.name}
                                    </option>
                                );
                                })}
                            </Select>
                        </CardBody>

                    </Card>

                    <Card>
                        <CardBody bgColor={'white'} borderRadius={'md'}>
                            <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}} gap={3}>
                                <Box>
                                    <Text>Lartas/Non Lartas</Text>
                                    <Select placeholder={t('select_placeholder')} onChange={(e) => setLartasInput(e.target.value)} defaultValue={lartasInput}>
                                        <option value='lartas'>Lartas</option>
                                        <option value='non_lartas'>Non Lartas</option>
                                    </Select>
                                </Box>
                                
                                <Box>
                                    <Text>{t('handling_fee')}</Text>
                                    <Select placeholder={t('select_placeholder')} onChange={(e) => setHandlingPrecentageFee(e.target.value)} defaultValue={handlingFeePercentage}>
                                        <option value='3'>3%</option>
                                        <option value='5'>5%</option>
                                        <option value='7'>7%</option>
                                        <option value='10'>10%</option>
                                    </Select>
                                </Box>
                            </Grid>
                        </CardBody>
                    </Card>

                    <Card bgColor={'white'} borderRadius={'md'}>
                        <CardBody>
                            <Text fontWeight={'bold'}>{t('estimate')}</Text>
                            <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}} gap={3}>
                                <Box>
                                    <Text>{t('total')} {t('estimated_cbm')}</Text>
                                    <Input placeholder='0' onChange={(e) => setVolumeEst(e.target.value )} defaultValue={volumeEst}/>
                                </Box>
                                
                                <Box>
                                    <Text>{t('total')} {t('estimated_weight')} (KG)</Text>
                                    <Input placeholder='Basic usage' onChange={(e) => setWeightEst(e.target.value )} defaultValue={weightEst}/>
                                </Box>
                            </Grid>
                        </CardBody>
                    </Card>

                    <Card bgColor={'white'} borderRadius={'md'}>
                        <CardBody>
                            <Text fontWeight={'bold'}>{t('additional_fee')} ({t('optional')})</Text>
                            <Box>
                                <Text> {t('total')} {t('additional_fee')} {importFrom === 'china' ? '(RMB)' : '(THB)'}</Text>
                                <Input placeholder='0' onChange={(e) => setAdditionalFee(e.target.value)} defaultValue={additionalFee}/>
                            </Box>
                        </CardBody>
                    </Card>

                    <Card bgColor={'white'} borderRadius={'md'}>
                        <CardBody>
                            <Text fontWeight={'bold'}>{t('product')}</Text>
                            <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}} gap={3}>
                                
                                <Box>
                                    <Text>{t('total')} {t('product_price')} {importFrom === 'china' ? '(RMB)' : '(THB)'}</Text>
                                    <Input placeholder='0' onChange={(e) => handlePriceChange(e.target.value)} defaultValue={products.price}/>
                                </Box>
                                <Box>
                                    <Text>HsCode</Text>
                                    <Input onClick={onOpen} defaultValue={products.hs_format}/>
                                </Box>
                            </Grid>

                            {/* <Stack divider={<StackDivider />} spacing='4' p={3} mt={3}>
                                <List spacing={3}>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>BM {products ? products.bmFee : 0}%</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(Math.ceil(products?.bm))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>PPn {products ? products.ppnFee : 0}%</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem defaultValue={products.ppn}>{userSymbolCurrency} {formatFrice(Math.ceil(products.ppn))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>PPh {products ? products.pphFee  : 0}%</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(Math.ceil(products?.pph))}</ListItem>
                                        </Box>
                                    </Grid>
                                </List>
                            </Stack> */}
                        </CardBody>
                    </Card>

                    <Button bgColor={'green.400'} color={'white'} onClick={calculateFreight} ref={resultRef}>{t('calculate')}</Button>
                </Stack>
                <Stack>
                    <Card bgColor={'white'} borderRadius={'md'}>
                        <CardBody>
                            <Text fontWeight={'bold'}>{t('calculate_result')}</Text>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <List spacing={3}>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('product_price')}</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(totalPrice)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('shipping_fee')}</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(totalShipping)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('handling_fee')}</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(handlingFee)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('ppn_handling_fee')}</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(ppnHandlingFee)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('bm')} {products ? products.bmFee : 0}%</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(products?.bm)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('ppn')} {products ? products.ppnFee : 0}%</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(products?.ppn)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    {
                                        user?.country_code !== 'ph' ?
                                        <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                            <Box>
                                                <ListItem>{t('pph')} {products ? products.pphFee : 0}%</ListItem>
                                            </Box>
                                            <Box textAlign={'end'}>
                                                <ListItem>Rp. {formatFrice(Math.ceil(products.pph))}</ListItem>
                                            </Box>
                                        </Grid>
                                        :
                                        <Text></Text>
                                    }
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('additional_fee')}</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(additionalFeeCalc)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    <Grid templateColumns={'repeat(2, 1fr)'} borderBottom={'1px'} borderColor={'gray.300'}>
                                        <Box>
                                            <ListItem>{t('grand_total')}</ListItem>
                                        </Box>
                                        <Box textAlign={'end'}>
                                            <ListItem>{userSymbolCurrency} {formatFrice(convertPriceFix(Math.ceil(totalBill)))}</ListItem>
                                        </Box>
                                    </Grid>
                                    {/* <HStack overflowX={'scroll'} maxW={'xl'}>
                                        <Text wordBreak={'break-all'}>{copyLink}</Text>
                                    </HStack> */}
                                    <Button bgColor={'orange.500'} textColor={'white'} onClick={() => handleCopyClick()}>{t('copy')}</Button>
                                </List>
                            </Stack>
                        </CardBody>
                    </Card>
                </Stack>
            </Grid>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} zIndex={1}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('search')} Hscode</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex gap={3}>
                            <Box colSpan={6} w={'70%'}>
                                <Input
                                    placeholder='Basic usage'
                                    value={hscodeKeyword}
                                    onChange={(e) => sethscodeKeyword(e.target.value)}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearchHscode(true);
                                        }
                                    }}
                                />
                            </Box>
                            <Box>
                                <Button bgColor={'green.400'} onClick={() => onSearchHscode(true)}>{t('submit')}</Button>
                            </Box>
                        </Flex>
                        <Table variant='simple' colorScheme='teal' overflowY={'hidden'}>
                            <Tbody>
                                {
                                    hscode?.length > 0 && hscode?.map((x, index) => (
                                        <Tr key={index}>
                                            <Td>{x.hs_format}</Td>
                                            <Td>
                                                <Text wordBreak={'break-all'}>
                                                    {user?.country_code !== 'ph' ? x.desc_text : x.description}
                                                </Text>
                                            </Td>
                                            <Td>
                                                {
                                                    x.hs_format.length > 7 
                                                    ?                                                    
                                                    <Button
                                                        bgColor={'blue.400'}
                                                        textColor={'white'}
                                                        size={'xs'}
                                                        onClick={() => onSetHscode(x)} 
                                                    >
                                                        {t('use')}
                                                    </Button>
                                                    :
                                                    <Text></Text>
                                                }
                                            </Td>
                                        </Tr>
                                    ))
                                }
                            </Tbody>
                        </Table>
                    </ModalBody>

                    <ModalFooter justifyContent={'center'} p={3}>
                        
                        <Button
                            onClick={() => onSearchHscode()}
                        >
                            {t('load_more')}
                        </Button>
                            
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
  )
}

export default SimulatorPage