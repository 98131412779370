import { Stack, Box, Heading, Table, Tbody, Tr, Td, Text, Center } from '@chakra-ui/react'
import React from 'react'
import store from 'store'

function ContactPage() {
  const height = window.innerHeight
  const userDataLoc = store.get('user_api');

  return (
    <Stack minH={height} bgColor={'white'} mx={20} my={5} p={3} borderRadius={'2xl'} shadow={'xl'}>
      {
        userDataLoc?.country_code === "id" ?
          <Box>
            <Center>
              <Box as="div" className="col-lg-12">
                <Box as="div" className="box box-default">
                  <Heading as="h3">
                    Contact Us
                  </Heading>
                </Box>
              </Box>
            </Center>
            <Box as="div" className="col-lg-12">
              <Text fontSize="14px" fontWeight={'bold'}>CONTACT US!</Text>
              <Table height="275px" width="656px">
                <Tbody>
                  <Tr>
                    <Td fontSize="14px">Warehouse Indonesia</Td>
                    <Td fontSize="14px">Sarah (+62 812-8892-0526)</Td>
                  </Tr>
                  {/* <Tr>
                    <Td fontSize="14px">China Trip</Td>
                    <Td fontSize="14px">Yudha (+62 812-8166-7786)</Td>
                  </Tr> */}
                  <Tr>
                    <Td>
                      <Text fontSize="14px">WhatsApp Official</Text>
                      <Text fontSize="14px">IMPORTIR.ORG</Text>
                    </Td>
                    <Td fontSize="14px">+62 878-0017-4596 WA Official IMPORTIR.ORG</Td>
                  </Tr>
                </Tbody>
              </Table>
              <Text fontSize="14px" fontWeight="bold" mt={10}>Customer Service Call: 02155714597</Text>
              <Text fontSize="14px">LIVE CHAT is available from 09.00-12.00 and 12.00-17.00 Monday - Friday (find the chatbox on the bottom page of your dashboard)</Text>
              <Text>Address Guangzhou Warehouse:</Text>
              <Text>以下是我们的仓库代理，有关送货规定，地址和时间可以联系以下的联系人，</Text>
              <Text>其他的麻烦直接联系客户。</Text>
              <Text>仓库地址 : 广州市白云区白云湖街唐阁上村中街28号103B</Text>
              <Text>云诚达货运代理有限公司(原龙树实业有限公司 510000</Text>
              <Text>中国 联系人 : 威力 18142827674 (微信，电话)</Text>
              <Text>印度尼西亚 联系人 : Mr. Deandy // WeChat id: IMPORTIRORG_admin（仅微信）</Text>
              <Text>收货时间 : 周一到五 9.00 - 17.00</Text>
              <Text>周六 9.00 – 14.00</Text>
              <Text>* 周天，及中国法定假日正常休假</Text>
              <Text>* 每一个箱子要用编织袋打包</Text>
              <Text>* 每一个箱子上必须贴客户的唛头 （唛头后面写上货物总数量）</Text>
              <Text>和海运代码（这些跟客户要）</Text>
              <Text>* 如果唛头不正确本公司无法收货</Text>
              <Text>* 货物送到仓库必须带上装箱单和订单(超过一个箱子只需要在一个箱子上贴)</Text>
              <Text>Warehouse Address : Guangzhou Baiyun District, Baiyunhu Avenue, Tangge Shangcun Middle Road No.28 Unit 103B Postal Code 510000</Text>
              <Text>Contact : Mr. Wei 18142827674 (Phone, Wechat)</Text>
              <Text>Mr. Deandy // WeChat id: IMPORTIRORG_admin (Wechat Only)</Text>
              <Text>Working Hour : Mon – Fri, 9.00 - 17.00</Text>
              <Text>Sat, 9.00 – 14.00</Text>
              <Text>* Closed on Sunday and Public Holidays</Text>
              <Text>* Every carton must be wrapped in a woven bag</Text>
              <Text>* It is compulsory to attach accurate MARKING CODE (please add total quantity behind the marking code) and SHIPPING CODE</Text>
              <Text>* Any wrong spelling will result in shipment delay even cancellation</Text>
              <Text>* It is compulsory to attach PACKING LIST and INVOICE on the carton sent to our warehouse (if more than one carton, only need to attach on one carton)</Text>
              <Text>Contoh</Text>
              <Text>Invoice <a href="https://d2jnbxtr5v4vqu.cloudfront.net/files/commercial-invoice-2017_12_22_15_38_21.xls">(klik link ini untuk download)</a></Text>
              <Text>Packing List <a href="https://d2jnbxtr5v4vqu.cloudfront.net/files/gaming-chair-packing-list-2017-2017-12-22-15-38-21-2017_12_24_10_56_49.xls">(klik link ini untuk download)</a></Text>
            </Box>
          </Box>
        : 
          <Box>
            <Box as="header" borderBottom="1px solid #ddd" pb="1rem" mb="1rem">
              <Heading as="h3" fontSize="xl">
                Contact Us
              </Heading>
            </Box>
            <Text fontSize="sm">CONTACT US !</Text>
            <Text fontWeight="bold">Shipping Team: Alti (+62 822-8888-0630)</Text>
            <Text fontWeight="bold">Whatsapp Official IMPORTIR.ORG: +62 878-0017-4596</Text>
            <Text fontWeight="bold">Viber Official IMPORTIR.ORG: +6287788088567</Text>
            <Text fontWeight="bold">PH Email Support: support.ph@importir.co</Text>
            <Text fontSize="sm">
              LIVE CHAT is available from 09.00-12.00 and 13.00-17.00 Monday - Friday (find the chat box on the bottom page of your dashboard)
            </Text>
            <Text fontSize="sm">Address Guangzhou Warehouse:</Text>
            <Text fontWeight="bold">
              仓库地址: 广州市白云区鹤龙二横路3号鹤丰创意园A104-105 510440 广州派派国际物流有限公司
            </Text>
            <Text fontSize="sm">中国 联系人: 张先生 15011945903 (微信，电话) // 王先生 15986217392 (微信，电话)</Text>
            <Text fontSize="sm">印度尼西亚 联系人: IMPORTIRORG_ADMIN（仅微信）</Text>
            <Text fontWeight="bold">收货时间: 周一到六 9.00 - 24.00 (China Time)</Text>
            <Text fontWeight="bold">
              * 周天，及中国法定假日正常休假
              <br />
              * 每一个箱子要用编织袋打包
              <br />
              * 每一个箱子上必须贴客户的唛头 （唛头后面写上货物总数量）
              <br />
              和海运代码（这些跟客户要）
              <br />
              * 如果唛头不正确本公司无法收货
              <br />
              * 货物送到仓库必须带上装箱单和订单(超过一个箱子只需要在一个箱子上贴)
            </Text>
            <Text fontSize="sm">Warehouse Address: A104-105, HeFeng Industrial Parks, HeLong 2 HengRoad, BaiYun District GuangZhou 510440</Text>
            <Text fontWeight="bold">Contact Person: Mr. Zhang 15011945903 (WeChat, Phone) // Mr. Wang 15986217392 (WeChat, Phone)</Text>
            <Text fontWeight="bold">Working Hour (China Time): Monday – Saturday, 09.00 - 24.00</Text>
            <Text fontWeight="bold">
              * Closed on Sunday and Public Holidays
              <br />
              * Every carton must be wrapped in woven bag
              <br />
              * It is compulsory to attach accurate MARKING CODE (please add total quantity behind the marking code) and SHIPPING CODE
              <br />
              * Any wrong spelling will result in shipment delay even cancellation
              <br />
              * It is compulsory to attach PACKING LIST and INVOICE on the carton sent to our warehouse (if more than one carton, only need to attach on one carton)
            </Text>
            <Text fontSize="sm">Contoh</Text>
            <Text>
              Invoice{" "}
              <a href="https://d2jnbxtr5v4vqu.cloudfront.net/files/commercial-invoice-2017_12_22_15_38_21.xls" target="_blank" rel="noopener noreferrer">
                (click here to download)
              </a>
            </Text>
            <Text>
              Packing List{" "}
              <a href="https://d2jnbxtr5v4vqu.cloudfront.net/files/gaming-chair-packing-list-2017-2017-12-22-15-38-21-2017_12_24_10_56_49.xls" target="_blank" rel="noopener noreferrer">
                (click here to download)
              </a>
            </Text>
          </Box>
      }
    </Stack>
  )
}

export default ContactPage
