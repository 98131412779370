import { Box, Button, Heading, HStack, Image, SimpleGrid, Skeleton, Spacer, Stack, Tag, Text, useToast } from '@chakra-ui/react';
import moment from 'moment';
import React, { useState } from 'react';
import { errorSlack } from '../../Api/SlackApi';
import colors from '../../Utils/colors';
import ConvertPrice from '../../Utils/ConvertPrice';
import { formatFrice } from '../../Utils/Helper';
import Countdown from '../CountDown/CountDown';
import { useTranslation } from 'react-i18next';

const ProductCardCrowdfun = ({ product, userSymbolCurrency, handleNavigateProduct }) => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    position: 'top',
    align: 'center',
  });

  const path = window.location.href ?? '#';

  const handleOrderNow = async () => {
    setIsLoading(true);
    try {
      handleNavigateProduct(product?.platform_type, product?.product_id)

    } catch (error) {
      toast({
        title: 'importir.com',
        description: 'Failed to navigate to the product page.',
        status: 'error',
      });

      const res = await errorSlack('Failed to navigate to the product page.', 'importir.com', path);
      console.log(res, 'CONNECTED ERROR IN SLACK');
    }
    finally {
      setIsLoading(false);
    }
  };

  const getTitleTimer = (startDate, endDate) => {
    const now = moment();
    const start = moment(startDate);
    const end = moment(endDate);

    if (now.isBefore(start)) {
      return t('start_on');
    } else if (now < end) {
      return t('will_end_on');
    } else {
      return t('crowdfund_has_ended');
    }
  };

  const { convertPriceFix } = ConvertPrice();

  const renderCountDown = (startDate, endDate) => {
    return (
      <Box>
        <Heading size='xs'>
          <Text color={getTitleTimer(startDate, endDate) === 'Product crowdfund has ended' ? colors.danger : colors.dark}>
            {getTitleTimer(startDate, endDate)}
          </Text>
        </Heading>
        {getTitleTimer(startDate, endDate) !== 'Product crowdfund has ended' ? (
          <Countdown startDate={startDate} endDate={endDate} />
        ) : (
          false
        )}
      </Box>
    );
  };

  return (
    <Stack shadow='md' borderRadius='xl' spacing={2} bgColor='white' minH={'500px'} _hover={{ transform: 'scale(1.05)' }} transition={'0.3s ease-in-out'}>
      <Stack alignItems='center' cursor='pointer' onClick={() => console.log(product)}>
        {product.image ? (
          <Image
            src={product.image}
            alt='img'
            w={'full'}
            objectFit='fit'
            h={'250px'}
            borderRadius='md'
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
          />
        ) : (
          <Skeleton w='200px' h='300px' borderRadius='md' isLoaded={!isLoading} startColor='gray.100' endColor='gray.300' />
        )}
      </Stack>
      <Stack px={3}>
        <HStack>
          <Stack pt={1}>
            <Tag bgColor={'#ffd600'}>
              <Text fontSize={'sm'}>{t("ready_from", {country: product?.detail?.shipping_from})}</Text>
            </Tag>
          </Stack>
        </HStack>
        <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
          {product?.name_en}
        </Text>
        {product?.detail?.start_date && renderCountDown(product?.detail?.start_date, product?.detail?.end_date)}
      </Stack>
      <Spacer />
      <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
        <Stack spacing={0} alignItems='flex-start' justifyContent='flex-start'>
          <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
            {t("price")}
          </Text>
          <Text textTransform='capitalize' fontWeight='bold' color='black' fontSize='sm'>
            {userSymbolCurrency} {formatFrice(convertPriceFix(Number(product.prices)))}
          </Text>
        </Stack>
      </SimpleGrid>
      <Stack p={3}>
        <Button size='sm' bgColor='green.400' onClick={() => handleOrderNow()} isLoading={isLoading}>
          <Text color='white'>🛒 {t("order_now")}</Text>
        </Button>
      </Stack>
    </Stack>
  );
};

export default ProductCardCrowdfun;
