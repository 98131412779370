import { Box, Flex, Text, Link } from '@chakra-ui/react';
import React from 'react'

function AppFooter() {
  return (
    <Box
      bg="black"
      color="white"
      py={4}
      // position="fixed"
      bottom="0"
      left="0"
      right="0"
      zIndex="999"
    >
      <Flex justifyContent="center" alignItems="center">
        <Text>
          &copy; {new Date().getFullYear()}{' '}
          <b style={{ color: '#ffd600' }}>IMPORTIR MITRA</b>, All Rights Reserved
        </Text>
      </Flex>
    </Box>

  )
}

export default AppFooter