import { Stack, Card, CardBody, Image, Divider, Input, Button, Text, Heading, Link, useToast } from '@chakra-ui/react'
import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier';
import AuthContext from '../../Routes/hooks/AuthContext';

function ConfirmationEmailPage() {
    const navigate = useNavigate();
    const { loadingShow, loadingClose } = useContext(AuthContext)
    const [formData, setFormData] = useState({
        'email' : '',
        'base_url': process.env.REACT_APP_BASE_URL
    });

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const handleChangeForm = (e) => {
        if (e.target.name) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    }

    const sendEmail = async () => {
        try {
            loadingShow()
            const data = await _axios.post('api/forgot-password', formData)
            loadingClose()
            if (data.status === true) {
                toast({
                    title: 'Success',
                    description: data.message,
                    status: 'success',
                });
                navigate('/login')
            } else {
                toast({
                    title: 'Upps!!!',
                    description: data.message,
                    status: 'error',
                }); 
            }
        } catch (error) {
            toast({
                title: 'Upps!!!',
                description: error,
                status: 'error',
            });
        }
    }
  return (
    <Stack alignItems={'center'} px={10} py={10}>
        <Card maxW='sm' bgColor={'white'}>
            <CardBody>
                <Heading size='md'>Forgot Password</Heading>
                <Text>
                    Enter your registered email below to receive password reset instruction
                </Text>
                <Image
                src='https://d2jnbxtr5v4vqu.cloudfront.net/images/amico-2020_12_28_13_59_26.png'
                alt='image not found'
                borderRadius='lg'
                />
                <Stack mt='6' spacing='3'>
                    <Text mb='8px'>Email</Text>
                    <Input placeholder='Input your email' name='email' size='md' onChange={handleChangeForm}/>
                    <Button bg={'#ffd600'} textColor={'black'} onClick={() => sendEmail()}>
                        Send
                    </Button>
                </Stack>
                <Text mt={5}>
                    Remember password ? <Link onClick={() => navigate('/login')} color={'blue'}>Login</Link> 
                </Text>
            </CardBody>
        </Card>
    </Stack>
  )
}

export default ConfirmationEmailPage