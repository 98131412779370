/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _axios from "../../Api/AxiosBarrier";
import { errorSlack } from "../../Api/SlackApi";
import AuthContext from "../../Routes/hooks/AuthContext";
import { formatFrice } from "../../Utils/Helper";
import ConvertPrice from '../../Utils/ConvertPrice';
import {
	Button,
	Center,
	Heading,
	HStack,
	Image,
	SimpleGrid,
	Skeleton,
	Spacer,
	Stack,
	Text,
	useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
function CatalogListPage() {
	const { t } = useTranslation();
	const [visibleProducts, setVisibleProducts] = useState({});
	const [productListDashboard, setProductListDashboard] = useState([]);
  const { convertPriceFix } = ConvertPrice();
	const {
		kursRmb,
		userSymbolCurrency,
		catalogDashboard,
		loadingShow,
		loadingClose,
	} = useContext(AuthContext);
	const path = window.location.href ?? "#";
	const toast = useToast({
		position: "top",
		align: "center",
	});
	const navigate = useNavigate();

	const getDataDashboard = async () => {
		try {
			loadingShow();
			let url = `/api/catalog-custom/get-catalog/homepage`;
			if (path.includes("cataloglist")) {
				url = `/api/catalog-custom`;
			}
			const { status, data, message } = await _axios.get(url);
			loadingClose();
			if (status == false) {
				return toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
			}
			setProductListDashboard(data);
		} catch (error) {
			loadingClose();
			await errorSlack(error.message, "importir.com", path);
			return;
		}
	};

	useEffect(() => {
		getDataDashboard();
	}, [catalogDashboard]);

	const handleLoadMore = (categoryIndex) => {
		if (window.location.pathname !== "/cataloglist") {
			return navigate("/cataloglist");
		}
		setVisibleProducts((prevVisibleProducts) => ({
			...prevVisibleProducts,
			[categoryIndex]: (prevVisibleProducts[categoryIndex] || 0) + 5,
		}));
	};

	return (
		<Stack>
			{productListDashboard.map((catalog, catalogIndex) => (
				<SimpleGrid columns={[1]} key={catalogIndex}>
					<Stack p={3}>
						<Stack py={3} mx={1}>
							<Heading
								size={{ base: "md", md: "lg", lg: "2xl" }}
								textAlign="center"
								textTransform="capitalize"
							>
								{catalog.info}
							</Heading>
						</Stack>
						<Stack>
							{catalog?.categories?.length > 0 ? (
								<>
									<SimpleGrid columns={[1]} gap={3} mx={[1]}>
										{catalog?.categories?.map(
											(category, categoryIndex) => (
												<Stack
													key={categoryIndex}
													spacing={3}
													p={2}
													rounded={"xl"}
													mt={5}
													bg={"white"}
												>
													<Center>
														<HStack>
															<Heading
																size={{
																	base: "xs",
																	md: "md",
																	lg: "lg",
																}}
																fontWeight={
																	"bold"
																}
																textTransform="capitalize"
																textColor={
																	"red"
																}
																shadow={"lg"}
																bg={"gray.100"}
																p={2}
																// rounded={'lg'}
																textAlign={
																	"center"
																}
															>
																{
																	category.category_title
																}
															</Heading>
														</HStack>
													</Center>
													{category.products?.length >
														0 && (
														<SimpleGrid
															columns={[
																1, 2, 3, 5,
															]}
															gap={3}
															mx={[1]}
														>
															{category.products
																// .slice(
																//     0,
																//     visibleProducts[categoryIndex] || 5
																// )
																.map(
																	(
																		data,
																		index
																	) => (
																		<Stack
																			shadow="md"
																			borderRadius={
																				"xl"
																			}
																			spacing={
																				2
																			}
																			bgColor="white"
																			key={
																				index
																			}
																		>
																			{/* Konten Produk */}
																			<Image
																				src={
																					data?.image
																				}
																				alt="img"
																				borderRadius={
																					"md"
																				}
																			/>
																			<Stack
																				px={
																					3
																				}
																			>
																				<Text
																					textTransform={
																						"capitalize"
																					}
																					fontWeight="bold"
																					fontSize="sm"
																					noOfLines={
																						2
																					}
																				>
																					{" "}
																					{data?.title !==
																					""
																						? data?.title
																						: "-"}
																				</Text>
																			</Stack>
																			<Spacer />

																			<SimpleGrid
																				columns={[
																					1,
																					2,
																					2,
																				]}
																				alignItems="flex-end"
																				px={
																					4
																				}
																				spacing={
																					0
																				}
																			>
																				<Stack
																					spacing={
																						0
																					}
																				>
																					<Text
																						textTransform={
																							"capitalize"
																						}
																						fontWeight={
																							500
																						}
																						color={
																							"gray.600"
																						}
																						fontSize={
																							"sm"
																						}
																					>
																						{t(
																							"price"
																						)}
																					</Text>
																					<Text
																						textTransform={
																							"capitalize"
																						}
																						fontWeight="bold"
																						color={
																							"black"
																						}
																						fontSize={
																							"md"
																						}
																					>
																						{
																							userSymbolCurrency
																						}{" "}
																						{/* {formatFrice(
																							Number(
																								kursRmb
																							) *
																								data.price
																						)} */}
																						{formatFrice(convertPriceFix(Number(Number(
																								kursRmb
																							) *
																								data.price)))}
																					</Text>
																				</Stack>
																			</SimpleGrid>
																			<Stack
																				p={
																					3
																				}
																			>
																				<Button
																					size="sm"
																					bgColor="green.400"
																					onClick={() =>
																						navigate(
																							`/product/${data.flag}/${data.product_id}`
																						)
																					}
																				>
																					<Text color="white">
																						🛒{" "}
																						{t(
																							"order_now"
																						)}
																					</Text>
																				</Button>
																			</Stack>
																		</Stack>
																	)
																)}
														</SimpleGrid>
													)}
													{/* <Stack alignItems={'center'} justifyContent='center'>
                                                        {category.products &&
                                                            category.products.length && (
                                                                <Button
                                                                    size='sm'
                                                                    colorScheme={'blackAlpha'}
                                                                    onClick={() =>
                                                                        handleLoadMore(categoryIndex)
                                                                    }
                                                                >
                                                                    {t('load_more')}
                                                                </Button>
                                                            )}
                                                    </Stack> */}
												</Stack>
											)
										)}
									</SimpleGrid>
								</>
							) : (
								<Stack
									alignItems="center"
									justifyContent="center"
								>
									<Text
										textAlign="center"
										fontSize="sm"
										letterSpacing={0.5}
										color="gray.500"
									>
										{t("searching_product")} ...
									</Text>
									<SimpleGrid
										columns={[2, 4, 6]}
										gap={5}
										mx={5}
									>
										{[...Array(12)].map((_, index) => (
											<Skeleton
												key={index}
												w={"100%"}
												h="400px"
												borderRadius={"xl"}
											/>
										))}
									</SimpleGrid>
								</Stack>
							)}
						</Stack>
					</Stack>
				</SimpleGrid>
			))}
		</Stack>
	);
}

export default CatalogListPage;
