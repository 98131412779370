/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Container,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Link
} from '@chakra-ui/react';
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import { useTranslation } from 'react-i18next';
function HistoryPage() {
    const {t} = useTranslation();
    const { loadingShow, loadingClose } = useContext(AuthContext);
    const [modules, setModules] = useState([]);
    
    const toast = useToast({
        position: "top",
        align: "center",
    });

    const fetchHistory = async () => {
        try {
            loadingShow();
            const {status, message, data} = await _axios.get('/api/list-module'); // Use axios to fetch data
            if (data === null) {
                window.location.href = '/profile';
                return;
            }
            if (status) {
                setModules(data);
            }else{
                return toast({
                    title: "importir.com",
                    description: message,
                    status: "error",
                });
            }
            loadingClose();
        } catch (error) {
            loadingClose();
            return toast({
                title: "importir.com",
                description: error,
                status: "error",
            });
        }
    };
    useEffect(() => {
        fetchHistory(); 
    }, []);
    return (
        <Box>
            <Box bg="gray.100" my="2%">
                <Container maxW="container.lg">
                    <Box rounded="5px" bgColor="white" p="2%">
                        <TableContainer borderRadius="lg">
                            <Table variant='striped' bgColor="#FFD600">
                                <Thead>
                                    <Tr>
                                        <Th>No</Th>
                                        <Th>{t('title')}</Th>
                                        <Th>{t('minimum_passing_standards')} (%)</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {modules?.map((item, index) => (
                                        <Tr key={index}>
                                            <Td>{index + 1}</Td>
                                            <Td>
                                                <Link color='blue.500' href={`/quiz-onboarding/${item?._id}`}>{item?.title}</Link>
                                            </Td>
                                            <Td>{item?.min_percent_correct_answer}%</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default HistoryPage;
