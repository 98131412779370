/* eslint-disable react-hooks/exhaustive-deps */
import {
	Image,
	SimpleGrid,
	Stack,
	useToast,
	Card,
	CardHeader,
	Heading,
	CardBody,
	CardFooter,
	Button,
	Center,
	FormControl,
	FormLabel,
	Input,
	VStack,
	Select,
	Switch,
	Grid,
	GridItem,
	RadioGroup,
	Radio,
} from "@chakra-ui/react";
import _axios from "../Api/AxiosBarrier";
import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Routes/hooks/AuthContext";
// import AppIframe from "../Components/Form/AppIframe";
import store from "store";
function ConsultationPage() {
	const { loadingShow, loadingClose } = useContext(AuthContext);
	const toast = useToast({
		position: "top",
		align: "center",
	});
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		is_member: false,
		type: "",
		country_code: "",
		reserved_date: "",
		reserved_time: "",
	});

	const resetForm = () => {
		setFormData({
			name: "",
			email: "",
			phone: "",
			is_member: false,
			type: "",
			country_code: "",
			reserved_date: "",
			reserved_time: "",
		});
	};

	const submitForm = async () => {
		try {
			loadingShow();
			const url = "api/consultation/store";
			const { status, message, data } = await _axios.post(url, formData);
			loadingClose();
			if (!status) {
				return toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
			}
			resetForm();
			return toast({
				title: "importir.com",
				description: message,
				status: "success",
			});
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};
	const navigate = useNavigate();

	const [description, setDescription] = useState(null);
	const fetchDescription = async () => {
		try {
			loadingShow();
			const url = "api/consultation/get-description";
			const { status, message, data } = await _axios.get(url);
			loadingClose();
			if (!status) {
				return toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
			}
			console.log(data);
			setDescription(data?.description);
			store.set("consultation_description", data?.description);
			return toast({
				title: "importir.com",
				description: message,
				status: "success",
			});
		} catch (error) {
			loadingClose();
			return toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	};

	useEffect(() => {
		if (store.get("consultation_description") !== undefined) {
			setDescription(store.get("consultation_description"));
		} else {
			fetchDescription();
		}
	}, []);

	return (
		<Stack p={[1, 1, 5]}>
			<Stack>
				<Stack
					p={5}
					bgColor="white"
					borderRadius={"xl"}
					shadow="md"
					w={"100%"}
				>
					<Stack alignItems={"center"} justifyContent="center">
						<SimpleGrid columns={[1, null, 2]}>
							<Image
								borderLeftRadius={["none", "none", "xl"]}
								onClick={() => navigate("/")}
								cursor="pointer"
								src={
									"https://firebasestorage.googleapis.com/v0/b/importir-com.appspot.com/o/1%20(3).png?alt=media&token=6ce1ef41-2f3e-4c43-8bec-898bee455ecc"
								}
							/>
							<Image
								borderRightRadius={["none", "none", "xl"]}
								onClick={() => navigate("/")}
								cursor="pointer"
								src={
									"https://firebasestorage.googleapis.com/v0/b/importir-com.appspot.com/o/2%20(4).png?alt=media&token=a06afa9a-99ee-4d86-8a28-bd58a02f563b"
								}
							/>
						</SimpleGrid>
					</Stack>
					<Stack
						width={{ base: "100%", xl: "82%" }}
						alignSelf={"center"}
					>
						<Grid
							templateColumns={{
								base: "1fr",
								md: "repeat(2, 1fr)",
							}}
							templateAreas={{
								base: `"desc" "form"`,
								md: `"form desc"`,
							}}
							gap="3"
						>
							<GridItem area={"form"}>
								<Stack>
									<Card shadow={"lg"}>
										<CardHeader>
											<Heading size="md">
												Form Consultation
											</Heading>
										</CardHeader>
										<CardBody>
											<Grid
												templateColumns={{
													base: "1fr",
													lg: "repeat(2, 1fr)",
												}}
												gap={3}
											>
												<GridItem>
													<FormControl marginY={2}>
														<FormLabel>
															Country
														</FormLabel>
														<RadioGroup
															marginY={"4"}
															value={
																formData.country_code
															}
															onChange={(value) =>
																setFormData({
																	...formData,
																	country_code:
																		value,
																})
															}
														>
															<Stack
																direction={
																	"row"
																}
																spacing={3}
															>
																<Radio value="id">
																	Indonesia
																</Radio>
																<Radio value="ph">
																	Philippines
																</Radio>
															</Stack>
														</RadioGroup>
													</FormControl>
												</GridItem>
												<GridItem>
													<FormControl>
														<FormLabel>
															Name
														</FormLabel>
														<Input
															onChange={(e) =>
																setFormData({
																	...formData,
																	name: e
																		.target
																		.value,
																})
															}
														></Input>
													</FormControl>
												</GridItem>
												<GridItem>
													<FormControl>
														<FormLabel>
															Email
														</FormLabel>
														<Input
															type="email"
															onChange={(e) =>
																setFormData({
																	...formData,
																	email: e
																		.target
																		.value,
																})
															}
														></Input>
													</FormControl>
												</GridItem>
												<GridItem>
													<FormControl>
														<FormLabel>
															Phone
														</FormLabel>
														<Input
															onChange={(e) =>
																setFormData({
																	...formData,
																	phone: e
																		.target
																		.value,
																})
															}
														></Input>
													</FormControl>
												</GridItem>
												<GridItem>
													<FormControl>
														<FormLabel
															marginTop={2}
														>
															Already a member?
														</FormLabel>
														<RadioGroup
															marginY={"4"}
															value={
																formData.is_member
															}
															onChange={(value) =>
																setFormData({
																	...formData,
																	is_member:
																		value,
																})
															}
														>
															<Stack
																direction={
																	"row"
																}
																spacing={3}
															>
																<Radio
																	value={true}
																>
																	Yes
																</Radio>
																<Radio
																	value={
																		false
																	}
																>
																	No
																</Radio>
															</Stack>
														</RadioGroup>
													</FormControl>
												</GridItem>
												<GridItem>
													<FormControl>
														<FormLabel>
															Consultation Type
														</FormLabel>
														<Select
															onChange={(e) =>
																setFormData({
																	...formData,
																	type: e
																		.target
																		.value,
																})
															}
															placeholder="Consultation Type"
														>
															<option value="online">
																Online (Via
																ZOOM)
															</option>
															<option value="offline">
																Offline
															</option>
														</Select>
													</FormControl>
												</GridItem>
												<GridItem>
													<FormControl>
														<FormLabel>
															Schedule Date
														</FormLabel>
														<Input
															onChange={(e) =>
																setFormData({
																	...formData,
																	reserved_date:
																		e.target
																			.value,
																})
															}
															type="date"
														></Input>
													</FormControl>
												</GridItem>
												<GridItem>
													<FormControl>
														<FormLabel>
															Schedule Time
															(WIB/GMT+7)
														</FormLabel>
														<Select
															onChange={(e) =>
																setFormData({
																	...formData,
																	reserved_time:
																		e.target
																			.value,
																})
															}
															placeholder="Select Schedule Time"
														>
															<option value="10.00-11.00">
																10.00-11.00
															</option>
															<option value="11.00-12.00">
																11.00-12.00
															</option>
															<option value="13.00-14.00">
																13.00-14.00
															</option>
															<option value="14.00-15.00">
																14.00-15.00
															</option>
															<option value="15.00-16.00">
																15.00-16.00
															</option>
															<option value="16.00-17.00">
																16.00-17.00
															</option>
														</Select>
													</FormControl>
												</GridItem>
											</Grid>
										</CardBody>
										<CardFooter alignSelf={"center"}>
											<Button
												onClick={() => submitForm()}
												colorScheme="blue"
											>
												Submit
											</Button>
										</CardFooter>
									</Card>
								</Stack>
							</GridItem>
							<GridItem area="desc">
								<Stack>
									<Card shadow={"lg"}>
										<CardHeader>
											<Heading size="md">
												Description
											</Heading>
										</CardHeader>
										<CardBody>
											<div
												style={{ padding: "2.5%" }}
												dangerouslySetInnerHTML={{
													__html: description,
												}}
											/>
										</CardBody>
									</Card>
								</Stack>
							</GridItem>
						</Grid>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
}

export default ConsultationPage;
