import { 
	Stack,
	Flex,
	TableContainer,
	Divider,
	Table,
	Thead,
	Tr,
	Th,
	Td,
	Tbody,
	HStack,
	Text,
	Box,
	Image,
	UnorderedList,
	ListItem,
	Heading,
	SimpleGrid,
	Button,
	useToast
} from '@chakra-ui/react'
import React, { useEffect, useState, useContext } from 'react'
import { IoCaretBackOutline } from 'react-icons/io5'
import colors from '../../Utils/colors'
import { useNavigate, useParams } from 'react-router-dom'
import CustomStepperDetailMember from '../../Components/Stepper/CustomStepperDetailMember'
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import { formatFrice } from '../../Utils/Helper'
import moment from 'moment'
import store from 'store';

function DetailMemberPage() {
	const userPackage = store.get('package_user');
	const { kursRmb, userSymbolCurrency, loadingShow, loadingClose } = useContext(AuthContext);
	const navigate = useNavigate()
	const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])
	const [catalog, setCatalog] = useState([])
	const [listOrder, setListOrder] = useState([])
	const [marketplace, setMarketplace] = useState({})
	const [showSelling, setShowSelling] = useState(false);
	const [reports, setReports] = useState([]);
	const [totalAmountReports, setTotalAmountReports] = useState({
		income: 0,
		outcome: 0,
		margin: 0
	});
	const toast = useToast({
		position: "top",
		align: "center",
	});

	const getDataCatalog = async () => {
		try {
			loadingShow()
			const res = await _axios.get('api/catalog-custom/catalog-diamond/list')
			if (res.status === true) {
				setCatalog(res.data)
			}
			loadingClose()
		} catch (error) {
			toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	}

	const getListOrder = async() => {
		try {
			loadingShow()
			const res = await _axios.get('api/membership-diamond/list-order')
			if (res.status) {
				setListOrder(res.data)
			}
			loadingClose()
		} catch (error) {
			toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	}

	const getStatus = async () => {
		try {
			loadingShow()
			const res = await _axios.get('api/membership-diamond/list-status')
			if (res.status === true) {
				if (res.data.length > 0) {
					const dataArr = res.data.reverse()
                        setCurrentStep((dataArr[0].title))
                        setMessageStep((dataArr[0].message))
                        setSteps(dataArr.reverse())
						const includesSelling = dataArr.some(item => item.title.includes("SELLING"));
						setShowSelling(includesSelling);
						if (includesSelling){
							getAnnualReport();
						}
				}else {
					setCurrentStep('')
				}
			} 
			loadingClose()
		} catch (error) {
			toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	}

	const caclAnnualReport = (data) => {
		if (data.length > 0){
			let income = 0;
			let outcome = 0;
			let margin = 0;

			data.map((item, index) => {
				income += parseFloat(item.income);
				outcome += parseFloat(item.outcome);
				margin += parseFloat(item.margin);
			});
			return setTotalAmountReports({
				income: income,
				outcome: outcome,
				margin: margin
			});
		}
	}

	const getAnnualReport = async () => {
		try {
			loadingShow()
			const {status, message, data} = await _axios.get('api/membership-diamond/list-reports')
			if (status === true) {
				setReports(data);
				caclAnnualReport(data);
			}else{
				toast({
					title: "importir.com",
					description: message,
					status: "error",
				});
			} 
			loadingClose()
		} catch (error) {
			toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	}

	const getMarketplace = async () => {
		try {
			loadingShow()
			const res = await _axios.get('api/membership-diamond/list-marketplace')
			if (res.status) {
				setMarketplace(res.data)
			}
			loadingClose()
		} catch (error) {
			toast({
				title: "importir.com",
				description: error,
				status: "error",
			});
		}
	}

	useEffect(() => {
		if (userPackage.package.name !== 'Diamond'){
			return navigate("/");
		}
		getDataCatalog()
		getListOrder()
		getStatus()
		getMarketplace()
	}, []);
  return (
    <Stack p={[1, 1, 5]} shadow={'md'} borderRadius='md' >
		<HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate("")}>
            <IoCaretBackOutline size={15} />
            <Text fontSize={'xs'} letterSpacing={0.5}>Back</Text>
        </HStack>
		
		<Stack bgColor={'white'} p={5} borderRadius='md' shadow={'md'}>
			<CustomStepperDetailMember steps={steps} currentStep={currentStep} message={messageStep} />
		</Stack>

		<Divider />
		{
			showSelling ? 
			<>
				<Stack>
					<Heading size={'lg'} textAlign="center">Annual Report</Heading>
					<Stack bg={'white'}>
						<TableContainer>
							<Table variant='simple'>
								<Thead>
								<Tr>
									<Th>Period</Th>
									<Th>Income</Th>
									<Th>Outcome</Th>
									<Th>Margin</Th>
								</Tr>
								</Thead>
								<Tbody>
								{
									reports?.length > 0 ? (
										reports?.map((item, index) => (
											<Tr>
												<Td>
													{item.period}
												</Td>
												<Td>
													{userSymbolCurrency} { formatFrice(item.income) }
												</Td>
												<Td>
													{userSymbolCurrency} {formatFrice(item.outcome)}
												</Td>
												<Td>
													{userSymbolCurrency} {formatFrice(item.margin)}
												</Td>
											</Tr>
										))
									) : (
										<Heading size={'md'} textAlign={'center'} position={'relative'} style={{left: '32rem'}}>Data Empty</Heading>
									)
								}
								<Tr>
									<Td><b>Total</b></Td>
									<Td><b>{userSymbolCurrency} { formatFrice(totalAmountReports.income) }</b></Td>
									<Td><b>{userSymbolCurrency} { formatFrice(totalAmountReports.outcome) }</b></Td>
									<Td><b>{userSymbolCurrency} { formatFrice(totalAmountReports.margin) }</b></Td>
								</Tr>
								</Tbody>
							</Table>
						</TableContainer>
					</Stack>
				</Stack>
			</>
			: ""
		}
		<Stack mt={'5'}>
			<Heading size={'lg'}>Detail Product</Heading>
			<Stack bg={'white'}>
				<TableContainer>
					<Table variant='simple'>
						<Thead>
						<Tr>
							<Th>Order Number</Th>
							<Th>Product Image</Th>
							<Th>Product Name</Th>
							<Th>Quantity</Th>
							<Th>#</Th>
						</Tr>
						</Thead>
						<Tbody>
						{
							listOrder?.length > 0 ? (
								listOrder?.map((item, index) => (
									<Tr>
										<Td>
											{item.order_number}
										</Td>
										<Td>
											<Image src={item?.product_image} height={'24'} width={'24'} alt='' rounded={'md'}/>
										</Td>
										<Td whiteSpace="break-spaces">
											{item.product_name}
										</Td>
										<Td>{item.quantity}</Td>
										<Td>
											<Button colorScheme='orange' textColor={'white'} onClick={() => navigate(`/order/${item.order_number}`)}>Detail</Button>
										</Td>
									</Tr>
								))
							) : (
								<Heading size={'md'} textAlign={'center'} position={'relative'} style={{left: '32rem'}}>Data not found</Heading>
							)
						}
						</Tbody>
					</Table>
				</TableContainer>
			</Stack>
		</Stack>
		<Stack mt={'5'}>
			<Heading size={'lg'} textAlign="center">
				Catalog
			</Heading>
			<Stack>
				{
					catalog?.length > 0 ? (
						catalog.map((item, key) => (
							<Stack overflowY={'auto'} height={'2xl'}>
								<Box p={[null, null, 5]}>
									<Stack
									textAlign="center"
									bg="white"
									borderRadius="md"
									px={5}
									py={3}
									>
										<Heading size={'lg'} fontWeight={"bold"}>
											{item.info}
										</Heading>
									</Stack>
								</Box>
								{item.categories.map((category, i) => (
									<Stack bgColor="white" mt={'5'} p={4} key={i}>
										<Heading color={"black"} p={2} size={"lg"} borderBottom={'1px'} borderColor={'gray.200'}>
											{category.category_title}
										</Heading>
										<Stack>
											<SimpleGrid columns={[2, null, 3, 4]} spacing={2}>
												{category?.products?.slice(0,4).map((data, index) => {
												return (
														<Stack
														shadow="md"
														key={index}
														borderRadius={"xl"}
														spacing={2}
														bgColor="white"
														>
															<Stack alignItems={"center"} cursor="pointer">
																<Image
																src={data?.image}
																alt="img"
																borderRadius={"md"}
																/>
															</Stack>
															<Stack px={3}>
																<Text
																textTransform={"capitalize"}
																fontWeight="bold"
																fontSize={"sm"}
																noOfLines={2}
																>
																{" "}
																{data?.title !== "" ? data?.title : "-"}
																</Text>
															</Stack>
	
															<SimpleGrid
																columns={[1, 2, 2]}
																alignItems={"flex-end"}
																px={4}
																spacing={0}
															>
																<Stack spacing={0}>
																<Text
																	textTransform={"capitalize"}
																	fontWeight="extrabold"
																	color={"gray.600"}
																	fontSize={"sm"}
																>
																	harga
																</Text>
																<Text
																	textTransform={"capitalize"}
																	fontWeight="extrabold"
																	color={"black"}
																	fontSize={"md"}
																>
																	{userSymbolCurrency}{" "}
																	{formatFrice(Number(kursRmb) * data.price)}
																</Text>
																</Stack>
															</SimpleGrid>
	
															<Stack p={3}>
																<Button
																size={"sm"}
																bgColor="green.400"
																onClick={() =>
																	navigate(
																	`/product/${data.flag}/${data.product_id}`
																	)
																}
																>
																<Text color={"white"}>🛒 Order now</Text>
																</Button>
															</Stack>
														</Stack>
													);
												})}
											</SimpleGrid>
											{
												category?.products?.length >= 4 ?
												<Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
													<Button colorScheme='green' onClick={() => navigate(`/catalogs/${item.slug}`)}>See All</Button>
												</Stack> 
												:
												""
											}
										</Stack>
									</Stack>
								))}
							</Stack>
						))
					) : (
						<Stack bg={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
							<Image src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/no-data-rafiki-2023_06_22_11_14_06.png' height={'lg'} width={'lg'}/>
							<Heading size={'lg'} position={'relative'} bottom={'14'}>Data not found</Heading>
						</Stack>
					)
				}
			</Stack>
		</Stack>
		<Stack>
			<Heading size={'lg'} textAlign="center">
				Marketplace
			</Heading>
			{
				marketplace !== null ?
				<Stack
					bg={'white'}
					p={[4, 8]}
				>
					<Flex
					direction={['column', 'column', 'row']}
					>
					<Box
						w={['full', 'full', '5xl']}
						mb={['4', '4', '0']} 
					>
						<Image
						src={marketplace.store_image}
						maxH={'3xl'}
						maxW={'4xl'}
						alt=''
						/>
					</Box>
					<Box mt={['4', '4', '16']} ml={[0, 0, 0]}>
						<UnorderedList fontSize={['md', 'lg']} listStyleType={'none'} fontWeight={'bold'} color={'#494b4d'}>
							<ListItem>Channel : {marketplace.channel}</ListItem>
							<ListItem>Nama Toko : {marketplace.store_name}</ListItem>
							<ListItem>Created At : {moment(marketplace.created_at).format('MMM Do YY')}</ListItem>
							<ListItem>Total Product : {marketplace.total_product} Listing</ListItem>
						</UnorderedList>
					</Box>
					</Flex>
				</Stack>
				:
				<Stack bg={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
					<Image src='https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/closed-stores-rafiki-2023_12_14_14_23_52.png' height={'lg'} width={'lg'}/>
					<Heading size={'lg'} position={'relative'} bottom={'14'}>Data not found</Heading>
				</Stack>
			}

		</Stack>
	</Stack>
  )
}

export default DetailMemberPage

