/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Heading, HStack, Input, Select, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { SlArrowDown } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { errorSlack } from '../../Api/SlackApi';
import ProductCardCrowdfund from '../../Components/Card/ProductCardCrowdfund';
import AuthContext from '../../Routes/hooks/AuthContext';
import store from 'store';
import _axios from '../../Api/AxiosBarrier';
import { useTranslation } from 'react-i18next';

function CrowdfundPage() {
  const {t} = useTranslation();
  const userDataLoc = store.get("user_api");
  const [dataList, setDataList] = useState([]);
  const { loadingShow, loadingClose, userSymbolCurrency } = useContext(AuthContext);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState('');
  const [shippingFrom, setShippingFrom] = useState('');
  const [showLoadmore, setShowLoadmore] = useState(true)
  const path = window.location.href ?? '#'
  const navigate = useNavigate();

  const getDataCrowd = async () => {
    loadingShow();
    setShowLoadmore(true)
    try {
      let res = "";
      if (userDataLoc === undefined){
        res = await axios.get(
            `${process.env.REACT_APP_API_COM}api/home/get-list-crowdfund?page=${count}&keyword=${search}&shipping_from=${shippingFrom}`
        );
        if (res && res.data && res.data.data && res.data.data.data) {
            const dataArr = res.data.data.data;
            if (count > 1) {
                if (dataArr.length < 8) {
                    setShowLoadmore(false);
                }
                setDataList((prevDataList) => [...prevDataList, ...dataArr]);
            } else {
                setDataList(dataArr);
                // console.log(dataArr, 'xxx');
            }
        }
      }else{
        res = await _axios.get(
            `${process.env.REACT_APP_API_COM}api/home/get-list-crowdfund-v2?page=${count}&keyword=${search}&shipping_from=${shippingFrom}`
        );
        if (res.status) {
            const dataArr = res.data.data;
            if (count > 1) {
                if (dataArr.length < 8) {
                    setShowLoadmore(false);
                }
                setDataList((prevDataList) => [...prevDataList, ...dataArr]);
            } else {
                setDataList(dataArr);
                // console.log(dataArr, 'xxx');
            }
        }
      }
      loadingClose();
    } catch (error) {
      console.log(error);
      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
      loadingClose();
    }
  };

  const handlePagination = () => {
    setCount(prevCount => prevCount + 1);
  };

  const handleNavigateProduct = (platformType, idProduct) => {
    const navigateRouteProduct = `/product/${platformType}/${idProduct}`;
    navigate(navigateRouteProduct);
  };

  const handleReset = () => {
    setCount(1);
    setSearch('');
    setShippingFrom('');
    getDataCrowd();
  };

  const handleSearch = () => {
    setCount(1)
    getDataCrowd();
  }


  useEffect(() => {
    getDataCrowd();

    return () => {
    };
  }, [count]);

  return (
    <Stack p={[1, 1, 5]} mt={[5, 5, 0]}>

      <Box p={4} bg="blue.100" color={'blue.800'} borderRadius="md" fontSize={'sm'}>
        <Heading as="h2" size="md" mb={2}>
        {t('crowdfund_feature')}
        </Heading>
        <Text>
          {t('crowdfund_feature_exp')}
        </Text>
        <Heading as="h3" size="sm" mt={4} mb={2}>
          {t('crowdfund_feature_list_title')}
        </Heading>
        <Text>
          1. {t('crowdfund_feature_list_1')}
        </Text>
        <Text>
          2. {t('crowdfund_feature_list_2')}
        </Text>
      </Box>
      <Grid py={2} templateColumns={{ base: "1fr", md: "1fr 4fr 1fr 1fr 1fr" }} gap={2} mt={[10, 10, 0]}>

        <Stack>
          <Heading size={'md'}>
            Crowdfund
          </Heading>
        </Stack>

        <Spacer />
        <Stack>
          <Input
            borderRadius='md'
            placeholder={t('product_name')}
            size={'sm'}
            bgColor={'white'}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                getDataCrowd();
              }
            }}
          />
        </Stack>

        <Stack>
          <Select fontSize='sm' borderRadius='md' size={'sm'} placeholder={t('from')} bgColor={'white'} onChange={(e) => setShippingFrom(e.target.value)}>
            <option value="indonesia">Indonesia</option>
            <option value="china">China</option>
          </Select>
        </Stack>

        <HStack>
          <Button size={'sm'} bgColor={'blue.400'} onClick={() => handleSearch()}>
            <Text>{t('search')}</Text>
          </Button>
          <Button size={'sm'} bgColor={'orange.300'} onClick={() => handleReset()}>
            <Text>{t('reset')}</Text>
          </Button>
        </HStack>
      </Grid>

      {dataList.length > 0 ? (
        <>
          <SimpleGrid columns={[1, 2, 4, 4]} gap={5} mx={[1, 3, 5]}>
            {dataList.map((product, i) => (
              <Stack key={i}>
                <ProductCardCrowdfund
                  product={product}
                  userSymbolCurrency={userSymbolCurrency}
                  handleNavigateProduct={handleNavigateProduct}
                />
              </Stack>
            ))}
          </SimpleGrid>
          {
            showLoadmore ? 
              <Stack pt={5} alignItems='center' justifyContent={'center'}>
                <Button onClick={() => handlePagination()} bgColor='#ffd600' shadow={'md'}>
                  <HStack>
                    <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'} >
                      {t('load_more')}
                    </Text>
                    <Stack>
                      <SlArrowDown size={10} />
                    </Stack>
                  </HStack>
                </Button>
              </Stack>
            : ""
          }
        </>
      ) : (
        <Stack alignItems={'center'} justifyContent='center' h={'100%'} fontWeight={'bold'} mb={2} fontStyle={'italic'}>
          <Text>{t('product_unavailable')}</Text>
        </Stack>
      )}
    </Stack>
  );
}

export default CrowdfundPage;