import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Box,
  Flex,
  Text,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Tfoot,
  Center,
  Divider,
  VStack,
  Stack,
  Link,
  Badge,
  useToast,
  Grid,
  TableContainer,
  Th,
  Heading
} from "@chakra-ui/react";
import AuthContext from "../../../Routes/hooks/AuthContext";
import _axios from "../../../Api/AxiosBarrier";
import { useParams } from "react-router-dom";
import { formatFrice } from "../../../Utils/Helper";

function EscrowShippingDetail() {
    const { loadingShow, loadingClose } = useContext(AuthContext);
    const params = useParams();
    const toast = useToast({
        position: 'top',
        align: 'center',
    });
    const [bill, setBill] = useState({})

    const fetchEscrow = async () => {
        try {
        loadingShow();
        const response = await _axios.get(
            `/api/shipping/${params.id}/escrow-shp`
        );
        loadingClose();
        if (response.status) {
            setBill(response.data)
        } else {
            toast({
                title: 'importir.com',
                description: response.message,
                status: 'error',
            });
        }
        } catch (error) {
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    };

    const printing = () => {
        const printContents = document.getElementById("print").innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
    }

    useEffect(() => {
        fetchEscrow();

        return () => {};
    }, []);
    return (
        <Box mx="auto" maxW="7xl" px={4} py={6} sm="px-6" md="px-8">
            <Button
                m={4}
                right={0}
                bgColor="black"
                color="white"
                p={2}
                rounded="lg"
                _hover={{ color: "white" }}
                onClick={() => window.history.back()}
            >
                Back
            </Button>

            <Box id="print" overflow="hidden" bgColor="white" >
                <Flex flexDir="column" spacing={4} p={5}>
                <Flex
                    h="150px"
                    w="full"
                    justifyContent="space-between"
                    bgColor="#ffd600"
                    p={4}
                    fontWeight="semibold"
                >
                    <Text my="auto">Importir.org REMITTENCE</Text>
                    <Box my="auto" textAlign="right" fontSize="xs">
                        <div>Importir.org</div>
                        <div>
                            Metland Tangerang
                            <br />
                            Jl. Puri Utama Blok B1 | No. 23, RT.004/RW.008
                            <br />
                            Petir, Cipondoh, Tangerang City,
                            <br />
                            Banter 15147
                        </div>
                        <div>(021) 55715045</div>
                        <div>
                            <a href="mailto:sales@importir.org">sales@importir.org</a>
                        </div>
                    </Box>
                </Flex>

                <Stack borderTop={'4px'} borderBottom={'4px'} borderColor={'black'} mt={4}>
                    <Text textAlign={'center'} fontSize={'lg'} p={5} fontWeight={'bold'}>
                        BSHP71
                    </Text>
                </Stack>

                <Stack spacing={4} mt={4}>
                    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        <VStack align="start" spacing={1}>
                            <Text>Service :  Shipping Importir</Text>
                            <VStack align="start" spacing={1}>
                                <Text>Buyer : {bill?.buyer?.name}</Text>
                            </VStack>
                            <VStack align="start" spacing={1}>
                                <Text>Phone : {bill?.buyer?.phone}</Text>
                            </VStack>
                            <VStack align="start" spacing={1}>
                                <Text>Email :
                                    <Link href={`mailto:${bill?.buyer?.email}`} color="black" ml={1}>
                                        { bill?.buyer?.email}
                                    </Link>
                                </Text>
                                
                            </VStack>
                            <VStack align="start" spacing={1}>
                                <Text>Status : 
                                    <Badge colorScheme={bill?.bill?.paid_at ? "green" : "red"} ml={1}>
                                        { bill?.bill?.paid_at ? "PAID" : "UNPAID"}
                                    </Badge>
                                </Text>
                            </VStack>
                        </VStack>
                        <VStack align="end" spacing={1}>
                            <Text>Seller Email: {bill?.bill?.shipping ? bill?.bill?.shipping?.supplier_email : '---'}</Text>
                            <Text>Seller Phone: {bill?.bill?.shipping ? bill?.bill?.shipping?.supplier_phone : '---'}</Text>
                            <Text>Seller Wechat: {bill?.bill?.shipping ? bill?.bill?.shipping?.supplier_wechat : '---'}</Text>
                            <Text>China</Text>
                        </VStack>
                    </Grid>
                </Stack>

                <Stack mt={5}>
                    <Text fontWeight={'bold'}>
                        Bill Title : {bill?.bill?.name} 
                    </Text>
                </Stack>

                <TableContainer mt={5}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Item Description</Th>
                                <Th>Amount</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                bill?.bill_details?.length > 0 && bill?.bill_details?.map((x, i) => {
                                    return (
                                        <Tr>
                                            <Td>{x?.title}</Td>
                                            <Td>{formatFrice(x?.amount)}</Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>

                <Box pt={2} ml={5} mt={5}>
                    {!bill?.bill?.paid_at && !bill?.is_refund ? 
                        <Stack>
                            <Text fontWeight={'semibold'}>
                                NOTICE:
                            </Text>
                            <Text>
                                Silahkan transfer menggunakan online payment gateway di dalam dashboard
                                shipping Anda sebelum <b>XXXXXX</b> ke:
                            </Text>
                            <Text>
                                Klik
                                <a href="" target="_blank" style={{color: '#be1d13'}}> PEMBAYARAN</a>, untuk
                                membayar transaksi ini
                            </Text>
                        </Stack>
                        :
                        <Stack>
                            <Text>Please transfer manually before <b>XXXXX</b></Text>
                            <Text>With transfer amount {bill?.bill?.total}</Text>
                            <Text>With transfer amount {bill?.bill?.total}</Text>
                            <Text>Bank Name :  BDO</Text>
                            <Text>Account Number : 0024 5029 5085</Text>
                            <Text>Branch : SM City Cebu B</Text>
                            <Text>Account Name : Rogelio P. Sungahid,Jr.</Text>
                        </Stack>
                    }
                </Box>

                <Box  pt={2} ml={5} mt={5}>
                    {
                        !bill?.is_refund ? 
                        <Stack>
                            <Text fontWeight={'semibold'}>
                                NOTICE:
                            </Text>
                            <Text>
                                Please provide your account number to admin via chat message in your shipping information
                            </Text>
                        </Stack>
                        :
                        <Stack>
                            <Text fontWeight={'semibold'}>
                                NOTICE:
                            </Text>
                            <Text>
                                PAID PAYMENT
                            </Text>
                        </Stack>
                    }
                </Box>

                <Divider mt={4} borderColor="slate.300" />

                <Center mt={4} fontSize="sm">
                    Invoice was created on a computer and is valid without the signature
                    and seal.
                </Center>
                </Flex>
            </Box>

            <Button
                w="full"
                border="1px"
                borderColor="pink.500"
                bgColor="pink.500"
                color="white"
                rounded="md"
                px={4}
                py={2}
                m={2}
                transition="all 0.5s ease"
                _hover={{ bgColor: "pink.600" }}
                onClick={() => {
                    printing()
                }}
            >
                Print
            </Button>
        </Box>
    );
}

export default EscrowShippingDetail;
