import React, { useContext } from "react";
import AuthContext from "../Routes/hooks/AuthContext";

const ConvertPrice = () => {
  const { kursRates, userCurrency } = useContext(AuthContext);

  const convertPriceFix = (amount) => {
    if (!userCurrency) {
      return amount;
    }

    if (userCurrency === "idr") {
      return parseFloat(amount);
    }

    if (userCurrency === "php") {
      return parseFloat(amount) / parseFloat(kursRates.php);
    }

    if (userCurrency === "usd") {
      return parseFloat(amount) / parseFloat(kursRates.usd);
    }

    if (userCurrency === "thb") {
      return parseFloat(amount) / parseFloat(kursRates.thb);
    }

    return parseFloat(amount);
  };

  return {
    convertPriceFix,
  };
};

export default ConvertPrice;
