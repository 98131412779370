import { Box, Button, Divider, Flex, Heading, HStack, Image, SimpleGrid, Spacer, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { IoCaretBackOutline, IoHelpCircleOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import { errorSlack } from '../../Api/SlackApi'
import AuthContext from '../../Routes/hooks/AuthContext'
import colors from '../../Utils/colors'
import ConvertPrice from '../../Utils/ConvertPrice'
import CopyToClipboard from '../../Utils/CopyToClipboard'
import { sendEcommerceEvent } from '../../Utils/gtm'
import { formatFrice } from '../../Utils/Helper'
import CountdownBill from '../../Components/CountDown/CountdownBill'
import store from 'store'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

function InvoiceDetailPage() {
    const {t} = useTranslation();
    const userDb = store.get("user_api")

    const params = useParams()

    const { loadingShow, loadingClose, userSymbolCurrency } = useContext(AuthContext)
    const [bankAccount, setBankAccount] = useState('')
    const [bankAccountImage, setBankAccountImage] = useState('')
    const [invoiceProduct, setInvoiceProduct] = useState('')

    const [isRefund] = useState(false)
    const [currency] = useState("idr")

    const path = window.location.href ?? '#'

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const { convertPriceFix } = ConvertPrice()

    const navigate = useNavigate()
    const startDate = moment().format('YYYY-MM-DD HH:mm:ss');



    const [checkUseVoucher, setCheckUseVoucher] = useState(false);
    const [detailInvoice, setDetailInvoice] = useState('')
    const [xenditPaymentOption, setXenditPaymentOption] = useState([]);
    const bankLogos = {
        PERMATA: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425815-bank-indonesia-permata-permatabank-syariah-icon-2024_07_18_15_47_44.png"
        },
        MANDIRI: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425804-bank-indonesia-mandiri-icon-2024_07_18_16_14_23.png"
        },
        BRI: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425806-bank-bri-indonesia-indonesian-rakyat-icon-2024_07_18_16_14_55.png"
        },
        BNI: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425812-bank-bni-indonesia-indonesian-negara-icon-2024_07_18_16_15_23.png"
        },
        BCA: {
            image: "https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/2425807-asia-bank-bca-central-indonesian-icon-2024_07_18_16_22_57.png"
        }
    };
    const getDataDetail = async () => {
        setDetailInvoice('')
        loadingShow()
        if (params.id !== undefined) {
            try {
                const res = await _axios.get(`api/invoices/${params.id}`)
                if (res.status === true) {
                    setDetailInvoice(res.data)
                    const checkUseDiscountTag = _.filter(res?.data?.details, {tag: 'discount'});
                    if (checkUseDiscountTag.length !== 0){
                        checkVoucherUsage(res?.data?.id);
                    }
                }

            } catch (error) {
                console.log(error)
                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
                loadingClose()
            }
            loadingClose()
        }
    }

    useEffect(() => {
        getDataDetail()

        return () => {
        }
    }, [])

    const handleCopy = (id) => {
        navigator.clipboard.writeText(id)
        toast({
            title: 'importir.com',
            description: 'Copy to clipboard.',
            status: 'success'
        })
    }

    const checkVoucherUsage = async(id) => {
        try {
            const {status, message} = await _axios.get(`/api/voucher/order/com/${id}/check-voucher-availability`)
            if (!status){
                setCheckUseVoucher(true);
                return toast({
                    title: 'importir.com',
                    description: message,
                    status: 'error',
                });
            }
            return setCheckUseVoucher(false)
        } catch (error){
            toast({
                title: 'importir.com',
                description: error.message,
                status: 'error',
            });
        }
    }

    const handlePrintContent = () => {
        const contentToPrint = document.getElementById('content-to-print');
        const windowToPrint = window.open('', '_blank');
        windowToPrint.document.write(contentToPrint.outerHTML);
        windowToPrint.document.close();
        // windowToPrint.print();
        // windowToPrint.close();
    };

    const handlePayment = async (method) => {
        loadingShow()
        const data = {
            invoiceId: detailInvoice?.id,
            payment_method: method,
            redirect_url: path
        }
        try {
            const res = await _axios.post('api/payments/xendit', data)
            if (res.status === true) {
                const ecommerceData = {
                    'currency': 'IDR',
                    'value': parseFloat(detailInvoice?.amount_total),
                    'items': [
                        {
                            'item_name': data.title,
                            'paid_with': 'xendit',
                            // 'price': parseFloat(data.price),
                            // 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
                        },
                    ],
                };
    
                sendEcommerceEvent('add_payment_info', ecommerceData);
                // window.open(res.data, 'blank')
                if (res.data.available_banks.length == 0){
                    loadingClose()
                    return window.open(res.data.invoice_url, 'blank')
                }
                setXenditPaymentOption(res.data.available_banks);
                loadingClose()
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                loadingClose()
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
        loadingClose()
    }

    const handlePaymentBca = async () => {
        loadingShow()
        setBankAccount("BCA")
        setBankAccountImage("https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png")
        const data = {
            invoiceId: detailInvoice?.id,
            payment_method: "bank_transfer",
        }

        try {
            const res = await _axios.post('api/payments/midtrans', data)
            if (res.status === true) {
                const ecommerceData = {
                    'currency': 'IDR',
                    'value': parseFloat(detailInvoice?.amount_total),
                    'items': [
                        {
                            'item_name': data.title,
                            'paid_with': 'midtrans',
                            // 'price': parseFloat(data.price),
                            // 'quantity': 1, // Jumlah produk yang ditambahkan ke keranjang
                        },
                    ],
                };
    
                sendEcommerceEvent('add_payment_info', ecommerceData);
                setInvoiceProduct(res.data)
                loadingClose()
            } else {
                toast({
                    title: 'importir.com',
                    description: res.message,
                    status: 'error'
                })
                loadingClose()
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
        } catch (error) {
            console.log(error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose()
        }
        loadingClose()
    }

    const handleSelectPaymentVA = async (arg) => {
        arg.type = 'xendit';
        setInvoiceProduct(arg);
    }

    return (
        <Stack spacing={2} p={[1, 1, 5]} mt={[5, 5, 0]}>
            <HStack>
                <HStack cursor='pointer' w='100px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                    <IoCaretBackOutline size={15} />
                    <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
                </HStack>


                <Spacer />

                <Button onClick={handlePrintContent} cursor='pointer' w='150px' mx={5} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme}>
                    <Text fontSize={'xs'} letterSpacing={0.5}>{t('print_invoice')}</Text>
                </Button>
            </HStack>

            <Stack 
                display={'flex'}
                justifyContent={'center'} 
                alignItems={'center'}
                bg={'transparent'}
            >
                {
                    !detailInvoice.paid_at ? (
                        startDate > detailInvoice?.expired_at ? (
                            <Text fontWeight={'bold'} fontSize={'xs'}>
                                {t('expired_order_invoice')}
                            </Text>
                        ) : (
                            <CountdownBill startDate={startDate} endDate={detailInvoice?.expired_at} />
                        )
                    ) : null
                }
            </Stack>


            <Stack spacing={5} p={[1, 1, 5]}>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>{t('product_info')}</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm' textAlign='center'>{t('invoice_number')}</Th>
                                    <Th fontSize='sm' textAlign='center'>{t('total')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr >
                                    <Td fontSize='sm' textAlign='center'>{detailInvoice?.invoice}</Td>
                                    <Td fontSize='sm' textAlign='center'>{userSymbolCurrency} {formatFrice(convertPriceFix(Number(detailInvoice?.amount_total)))}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>

                <Stack>
                    <Text fontSize='md' fontWeight='bold'>Detail</Text>
                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                        <Table variant='striped' colorScheme='gray'>
                            <Thead>
                                <Tr>
                                    <Th fontSize='sm'>{t('title')}</Th>
                                    <Th fontSize='sm'>{t('total')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {detailInvoice?.details?.length > 0 && detailInvoice?.details?.map((x, index) => (
                                    <Tr key={index}>
                                        <Td fontSize='sm'>{x.title}</Td>
                                        <Td fontSize='sm'>{userSymbolCurrency} {formatFrice(convertPriceFix(x.amount))}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </Stack>
                <Stack>
                    <Text fontSize={'xs'} color='red.500'>{t("order_invoice_attention_msg")}</Text>
                </Stack>


                <Stack >
                    <HStack alignItems={'center'} spacing={2} >
                        <Text fontSize={'lg'} fontWeight='bold'>{t('payment_method')}</Text>
                        <Stack onClick={() => navigate('/information')}>
                            <IoHelpCircleOutline size={20} color="black" />
                        </Stack>
                    </HStack>
                    {detailInvoice.paid_at ? (
                        <Stack bgColor={'white'} spacing={5} borderRadius='xl' m={2} p={3} shadow={'md'} alignItems='center' justifyContent={'center'}>
                            <Heading size='sm'>{t('paid')}</Heading>
                            <Text size='sm'>{moment(detailInvoice.paid_at).format('LLL')}</Text>
                        </Stack>
                    ) : 
                        
                            userDb?.country_code === "id" ?
                                <Stack bgColor={'white'} spacing={5} borderRadius='xl' m={2} p={3} shadow={'md'}>
                                    {
                                        invoiceProduct ? (
                                            <Stack>
                                                {
                                                    invoiceProduct?.type !== 'xendit' ? 
                                                    <>
                                                        <HStack w='full' px={5}>
                                                            <Image
                                                                src={
                                                                    bankLogos.BCA.image
                                                                }
                                                                alt={"BCA"}
                                                                w="80px"
                                                                borderRadius={'xl'}
                                                            />
                                                            <Spacer />
                                                            <Text fontSize={'sm'} textTransform='uppercase'>{invoiceProduct?.transaction_status}</Text>
                                                        </HStack>

                                                        <Box bg='white' px={5}>
                                                            <Text>{t('va_number')} : </Text>
                                                            <Divider my={2} />
                                                            <Box display='flex' flexDirection='row' justifyContent="space-between">
                                                                <Text fontSize={20} color="gray.500">{invoiceProduct ? (invoiceProduct?.va_numbers[0]?.va_number) : "none"}</Text>
                                                                <Text color="blue.600" cursor={'pointer'} onClick={() => handleCopy(invoiceProduct?.va_numbers[0]?.va_number)}>{t('copy')}</Text>
                                                            </Box>
                                                            <Divider my={2} />
                                                            <HStack py={1}>
                                                                <Text fontSize={'sm'}>IMPORTIR.COM</Text>
                                                                <Spacer />
                                                                <Text  >{userSymbolCurrency} {formatFrice(convertPriceFix(Number(invoiceProduct?.gross_amount)))}</Text>
                                                                <CopyToClipboard text={invoiceProduct?.gross_amount} />

                                                            </HStack>
                                                            <Divider my={2} />

                                                            <Text fontSize={10} color="gray.600">{t("payment_verif_va_info_1")}</Text>
                                                            <Spacer />
                                                            <Text fontSize={10} color="gray.600">{t("payment_verif_va_info_2")}</Text>
                                                        </Box>
                                                        <Box bg='white' p={5}>
                                                            <Text fontSize={10} color="gray.600">
                                                                {t('mbanking_trf_guide')} :
                                                            </Text>
                                                            <Divider />
                                                            <Text fontSize={10} color="gray.600">
                                                                1. {t('mbanking_trf_guide_1')}
                                                            </Text>
                                                            <Text fontSize={10} color="gray.600">
                                                                2. {t('mbanking_trf_guide_2')}
                                                            </Text>
                                                            <Text fontSize={10} color="gray.600">
                                                                3. {t('mbanking_trf_guide_3')} 
                                                            </Text>
                                                        </Box> 
                                                    </>
                                                    : 
                                                    <>
                                                        <HStack w='full' px={5}>
                                                            <Image
                                                                src={
                                                                    bankLogos[invoiceProduct?.bank_code].image
                                                                }
                                                                alt={invoiceProduct?.bank_code}
                                                                w="80px"
                                                                borderRadius={'xl'}
                                                            />
                                                            <Spacer />
                                                            <Text fontSize={'sm'} textTransform='uppercase'>PENDING</Text>
                                                        </HStack>

                                                        <Box bg='white' px={5}>
                                                            <Text>{t('va_number')} : </Text>
                                                            <Divider my={2} />
                                                            <Box display='flex' flexDirection='row' justifyContent="space-between">
                                                                <Text fontSize={20} color="gray.500">{invoiceProduct ? (invoiceProduct?.bank_account_number) : "none"}</Text>
                                                                <Text color="blue.600" cursor={'pointer'} onClick={() => handleCopy(invoiceProduct?.bank_account_number)}>{t('copy')}</Text>
                                                            </Box>
                                                            <Divider my={2} />
                                                            <HStack py={1}>
                                                                <Text fontSize={'sm'}>IMPORTIR.COM</Text>
                                                                <Spacer />
                                                                <Text  >{userSymbolCurrency} {formatFrice(convertPriceFix(Number(invoiceProduct?.transfer_amount)))}</Text>
                                                                <CopyToClipboard text={invoiceProduct?.transfer_amount} />

                                                            </HStack>
                                                            <Divider my={2} />

                                                            <Text fontSize={10} color="gray.600">{t("payment_verif_va_info_1")}</Text>
                                                            <Spacer />
                                                            <Text fontSize={10} color="gray.600">{t("payment_verif_va_info_2")}</Text>
                                                        </Box>
                                                    </>
                                                }

                                                <Stack alignItems={'center'} justifyContent='center'>
                                                    <Box>
                                                        <Button colorScheme={'red'} size='sm' onClick={() => setInvoiceProduct('')}>{t('cancel_payment')}</Button>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        )
                                            : (
                                                <>
                                                    {
                                                        checkUseVoucher || startDate > detailInvoice.expired_at ? 
                                                        <Text >
                                                            
                                                        </Text>
                                                        :
                                                        xenditPaymentOption.length == 0 ? (
                                                            <SimpleGrid columns={[1,1,3]} spacing={3} p={[1, 1, 3]} alignItems='center' justifyContent={'center'}>

                                                                <Button onClick={() => handlePayment("INVOICE_TRANSFER_VA")} bg="green.600">

                                                                    <HStack alignItems={'center'} justifyContent='center' space={2}>
                                                                        {/* <Ionicons name="ios-cart-outline" size={25} color="white" /> */}
                                                                        <Text color="gray.100" fontWeight='bold'>{t('pay_with_other')}</Text>
                                                                    </HStack>
                                                                </Button>

                                                                <Button onClick={() => handlePayment("CREDIT_CARD")} bg="green.600">

                                                                    <HStack alignItems={'center'} justifyContent='center' space={2}>
                                                                        {/* <Ionicons name="ios-cart-outline" size={25} color="white" /> */}
                                                                        <Text color="gray.100" fontWeight='bold'>{t('pay_with_cc')}</Text>
                                                                    </HStack>
                                                                </Button>

                                                                <Button onClick={() => handlePaymentBca()} colorScheme="facebook">

                                                                    <HStack alignItems={'center'} justifyContent='center' space={2}>
                                                                        {/* <Ionicons name="ios-cart-outline" size={25} color="white" /> */}
                                                                        <Text color="gray.100" fontWeight='bold'>{t('pay_with_bca')}</Text>
                                                                    </HStack>
                                                                </Button>
                                                            </SimpleGrid>
                                                        ) : 
                                                            <>
                                                                <Flex justify={"end"}>
                                                                    <div className="">
                                                                        <Button 
                                                                            onClick={() => setXenditPaymentOption([])} 
                                                                            colorScheme='red'>
                                                                            {t('cancel_payment')}
                                                                        </Button>
                                                                    </div>
                                                                </Flex>
                                                                <SimpleGrid columns={[1,1,2,4]} spacing={3} p={[1, 1, 3]} alignItems='center' justifyContent={'center'}>
                                                                    {
                                                                        xenditPaymentOption?.length > 0 ? xenditPaymentOption.map((el, index) => (
                                                                            <Box
                                                                                key={index}
                                                                                borderWidth="1px"
                                                                                borderRadius="lg"
                                                                                overflow="hidden"
                                                                                p={4}
                                                                                textAlign="center"
                                                                                width={"50%"}
                                                                                marginX={"auto"}
                                                                                _hover={{borderColor: "red", cursor: "pointer"}}
                                                                                onClick={() => handleSelectPaymentVA(el)}
                                                                            >
                                                                                <Image marginX={"auto"} width={"100%"} src={bankLogos[el?.bank_code].image ?? null} alt="PERMATA" />
                                                                            </Box>
                                                                        )) : null
                                                                    }
                                                                </SimpleGrid>
                                                            </>
                                                    }
                                                </>
                                            )
                                    }


                                </Stack>
                            : 
                                <Stack bgColor={'white'} spacing={5} borderRadius='xl' m={2} p={3} shadow={'md'}>
                                    <div style={{ fontSize: '14px', color: '#718096' }}>
                                        <p>Please transfer manually before <b>{detailInvoice.expired_at}</b></p>
                                        <p>With transfer amount {userSymbolCurrency} {formatFrice(convertPriceFix(detailInvoice.amount_total))}</p>
                                        <p style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>Bank Name : <span>BDO</span></div>
                                            <div>Account Number : <span>0024 5029 5085</span></div>
                                            <div>Branch : <span>SM City Cebu B</span></div>
                                            <div>Account Name: <span>Rogelio P. Sungahid,Jr.</span></div>
                                        </p>
                                    </div>
                                </Stack>
                        
                    }




                </Stack>
            </Stack >

            <div id="content-to-print">
                <Stack display={'none'}>
                    <div id="print" style={{ overflow: 'hidden', background: 'white' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1.25rem' }}>
                            <div style={{ display: 'flex', height: '150px', width: '100%', flexDirection: 'row', justifyContent: 'space-between', background: '#F6E05E' }}>
                                <span style={{ margin: 'auto 0', padding: '0 1rem', fontWeight: 'bold' }}>Importir.org {t('remittence').toUpperCase()}</span>
                                <span style={{ margin: 'auto 0', padding: '0 1rem', textAlign: 'right', fontSize: '12px' }}>
                                    <div>Importir.org</div>
                                    <div>
                                        Metland Tangerang<br />
                                        Jl. Puri Utama Blok B1 | No. 23, RT.004/RW.008<br />
                                        Petir, Cipondoh, Tangerang City,<br />
                                        Banter 15147
                                    </div>
                                    <div>(021) 55715045</div>
                                    <div>
                                        <a href="mailto:sales@importir.org">sales@importir.org</a>
                                    </div>
                                </span>
                            </div>

                            <div style={{ background: `url('https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ami/dimension-2022_09_13_17_24_14.png')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', display: 'flex', justifyContent: 'center', borderTop: '2px solid black', borderBottom: '2px solid black', padding: '0.75rem', fontWeight: 'bold' }}>
                                {detailInvoice?.invoice}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '0.5rem', fontSize: '12px', color: '#718096' }}>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                                        <span>{t('service')}</span>
                                        <span style={{ color: 'black' }}>Shipping Importir</span>
                                        <div>
                                            <span>{t('buyer')}</span>
                                            <span style={{ color: 'black' }}>{detailInvoice?.detail?.order?.user?.name}</span>
                                        </div>
                                        <div>
                                            <span>{t('phone_number')}</span>
                                            <span style={{ color: 'black' }}>{detailInvoice?.detail?.order?.user?.phone}</span>
                                        </div>
                                        <div>
                                            <span>Email</span>
                                            <a href={`mailto:${detailInvoice?.detail?.order?.user?.email}`}>
                                                <span style={{ color: 'black' }}>{detailInvoice?.detail?.order?.user?.email}</span>
                                            </a>
                                        </div>
                                        <div>
                                            <span>Status</span>
                                            <span style={{ color: detailInvoice.paid_at ? '#48BB78' : '#F56565' }}>{detailInvoice.paid_at ? 'PAID' : 'UNPAID'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                    <span>{detailInvoice?.detail?.order?.product?.suppliers?.name}</span>
                                    <span>{detailInvoice?.detail?.order?.product?.platform_type}</span>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', padding: '1.25rem' }}>
                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('bill_title')} :</div>
                            <div style={{ fontSize: '14px' }}>{detailInvoice.title}</div>
                        </div>

                        <div>
                            <table style={{ width: '100%', fontSize: '14px', color: '#718096' }}>
                                <thead style={{ borderBottom: '2px solid #718096' }}>
                                    <tr>
                                        <td>{t('bill_detail')}</td>
                                        <td>{t('total')}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {detailInvoice?.details?.map((item, key) => (
                                        <tr key={key} style={{ background: key % 2 !== 0 ? '#E2E8F0' : '' }}>
                                            <td style={{ padding: '0.75rem' }}>{item.title}</td>
                                            <td>{userSymbolCurrency} {formatFrice(convertPriceFix(item.amount))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    {currency !== 'idr' && (
                                        <tr style={{ background: '#4A5568', color: 'black' }}>
                                            <td style={{ padding: '0.75rem' }}>{t('grand_total')} IDR</td>
                                            <td className="total">{userSymbolCurrency} {formatFrice(convertPriceFix(Number(detailInvoice?.amount_total)))}</td>
                                        </tr>
                                    )}
                                    <tr style={{ background: '#4A5568', color: 'white' }}>
                                        <td style={{ padding: '0.75rem' }}>
                                            {t('grand_total')} <span style={{ textTransform: 'uppercase' }}>{currency}</span>
                                        </td>
                                        <td>{userSymbolCurrency} {formatFrice(convertPriceFix(Number(detailInvoice?.amount_total)))}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div style={{ paddingTop: '0.5rem' }}>
                            {
                                !detailInvoice.paid_at && !isRefund ? 
                                    <>
                                        <div style={{ paddingTop: '0.5rem' }}>
                                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('notice').toUpperCase()}:</div>
                                            {
                                                currency === 'idr' ? 
                                                <div style={{ fontSize: '14px', color: '#718096' }} v-if={currency === 'idr'}>
                                                    <p>
                                                        Silahkan transfer menggunakan online payment gateway di dalam dashboard shipping Anda sebelum <b>{detailInvoice?.expired_at}</b> ke:
                                                    </p>
                                                    <br />
                                                    <p>
                                                        Klik <a style={{ color: '#be1d13' }} href="" target="_blank">PEMBAYARAN</a>, untuk membayar transaksi ini
                                                    </p>
                                                </div> 
                                                :
                                                <div style={{ fontSize: '14px', color: '#718096' }} v-else>
                                                    <p>Please transfer manually before <b>{detailInvoice?.expired_at}</b></p>
                                                    <p>With transfer amount {detailInvoice.amount_total}</p>
                                                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <div>Bank Name : <span>BDO</span></div>
                                                        <div>Account Number : <span>0024 5029 5085</span></div>
                                                        <div>Branch : <span>SM City Cebu B</span></div>
                                                        <div>Account Name: <span>Rogelio P. Sungahid,Jr.</span></div>
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </>
                                : 
                                !isRefund ? 
                                    <div style={{ paddingTop: '0.5rem' }}>
                                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('notice').toUpperCase()}:</div>
                                        <div style={{ textAlign: 'center', fontSize: '14px', color: '#718096' }}>
                                            <p>
                                            {currency === 'idr' ? 
                                            "Silahkan memberikan nomor rekening Anda ke Admin melalui pesan chat di informasi Shipping Anda" 
                                            : "Please provide your account number to admin via chat message in your shipping information"}
                                            </p>
                                        </div>
                                    </div>
                                :
                                <div style={{ paddingTop: '0.5rem' }} v-else>
                                    <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{t('notice').toUpperCase()}:</div>
                                    <div style={{ textAlign: 'center', fontSize: '14px', color: '#718096' }}>
                                        <p>{currency === 'idr' ? "Pembayaran Telah Lunas" : "PAID PAYMENT"}</p>
                                    </div>
                                </div>
                            }
                        </div>

                        {/* <footer style={{ borderTop: '2px solid #718096', textAlign: 'center', fontSize: '14px' }}>
                            {t('digital_invoice_policy')}
                        </footer> */}
                    </div>
                </Stack>
            </div>
        </Stack >

    )
}

export default InvoiceDetailPage