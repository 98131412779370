import axios from 'axios'
const baseUrl = 'http://localhost:5001/importir-com/us-central1'

export const getApifyActor = async() =>{
try {
	const res=await	axios.get(`${baseUrl}/getApifyActor`)
	// console.log(res)
	return(res.data)
} catch (error) {
	return(error.message)
}
}

export const getApifyRuns = async(id) =>{
	try {
		const res=await	axios.get(`${baseUrl}/getApifyRuns?id=${id}`)
		// console.log(res)
		return(res.data)
	} catch (error) {
		return(error.message)
	}
	}

	export const getApifyStorage = async(url) =>{
		try {
			const res=await	axios.get(`${baseUrl}/scrapCallback?url=${url}`)
			console.log(res)
			return(res.data)
		} catch (error) {
			return(error.message)
		}
		}