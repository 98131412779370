import { Stack, SimpleGrid, Text, Input, Box, Heading, HStack, Textarea, Spacer, Button, Image, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { MdOutlinePermMedia, mdOutlinePermMedia } from 'react-icons/md'
import _axios from '../../Api/AxiosBarrier';
import { formatFrice } from '../../Utils/Helper';
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { errorSlack } from '../../Api/SlackApi';
import { FcFile } from "react-icons/fc";
import AuthContext from '../../Routes/hooks/AuthContext';
import Swal from 'sweetalert2'

function EscrowEditPage() {
    const path = window.location.href ?? '#'

    const [files, setFiles] = useState([]);
    const [additionalFiles, setAdditionalFiles] = useState([]);
    const [filesImage, setFilesImage] = useState([]);
    const [formData, setFormData] = useState({
        "supplier_email": "",
        "customer_note": "",
        "supplier_phone": "",
        "supplier_wechat": "",
        "amount_rmb": "",
        "invoice_file_url": filesImage,
        "other_files": []
    });
    const [invoiceFile, setInvoiceFile] = useState("")
    const [amountFee, setAmountFee] = useState(50000);
    const [kursRmb, setKursRmb] = useState(1)

    const [kursRates, setKursRate] = useState(1)
    const navigate = useNavigate()
    const params = useParams()
    const { loadingShow, loadingClose } = useContext(AuthContext);

    const toast = useToast({
        position: "top",
        align: "center",
    });

    const handleChangeForm = (e) => {
        if (e.target.name) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    }

    const handleSubmitForm = async () => {
        try {
            loadingShow()
            const response = await _axios.post(`api/pay-care/${params.id}`, formData);
            loadingClose()
            if (response.status === true) {
                toast({
                    title: 'importir.com',
                    description: response.message,
                    status: 'success',
                });
                navigate('/escrow')
            }
        } catch (error) {
            alert(error.response.data.error)
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }

    const getEscrowEditData = async () => {
        try {
          const res = await _axios.get(`api/pay-care/${params.id}`);
  
          if (res.status === true) {
            const data = res.data;
            setFormData({
              supplier_email: data.supplier_email,
              supplier_phone: data.supplier_phone,
              supplier_wechat: data.supplier_wechat,
              amount_rmb: data.amount_rmb,
              customer_note: data.customer_note,
              other_files: data.other_files,
              invoice_file_url: data.invoice_file.file_url
            });
            setInvoiceFile(data.invoice_file)
          } else {
            console.error('Error fetching edit data:', res.message);
          }
        } catch (error) {
          console.error('Error fetching edit data:', error);
        }
      };

    const handleFileInputChange = async (event) => {
        const { files: newFiles } = event.target;

        if (newFiles.length === 1) {
            const uploadedFile = await handleFileUpload(newFiles[0]);
            if (uploadedFile) {
                setFormData((prevState) => ({
                    ...prevState,
                    invoice_file_url: uploadedFile,
                }));
                setFiles([{
                    file: URL.createObjectURL(newFiles[0]),
                    fileName: newFiles[0].name,
                    description: newFiles[0].type,
                }]);
            }
        }
    };


    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await _axios.post(
                'api/storage?directory=general',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.log(error, 'Error uploading file');
            toast({
                title: 'Error',
                description: 'Failed to upload file',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return null;
        }
    };



    const handleAnotherFile = async (event, index) => {
        const { files: newFiles } = event.target;

        if (newFiles.length === 1) {
            const uploadedFile = await handleFileUpload(newFiles[0]);
            if (uploadedFile) {
                setFormData((prevState) => {
                    const updatedOtherFiles = [...prevState.other_files];
                    updatedOtherFiles[index] = {
                        _id: prevState.other_files[index]._id,
                        file_url: uploadedFile,
                        description: prevState.other_files[index].description,
                    };
                    return {
                        ...prevState,
                        other_files: updatedOtherFiles,
                    };
                });
            }
        }
    };
      

    const productPrice = () => {
        if (!kursRmb) {
            return 0
        }

        const amount = formData.amount_rmb * kursRmb
        return amount
    }

    const totalInvoice = () => {
        return productPrice() + amountFee
    }

    const getDataRmb = async () => {
        try {
            const res = await axios.get('https://new-admin.importir.com/api/rates')
            if (res.status === 200) {
                setKursRmb(res.data.data.rmb)
                setKursRate(res.data.data)
            }
        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    }


    const addAnotherFiles = () => {
        setFormData(prevState => {
            const otherFilesArray = Array.isArray(prevState.other_files)
                ? prevState.other_files
                : [];
    
            return {
                ...prevState,
                other_files: [
                    ...otherFilesArray,
                    {
                        file_url: '',
                        description: ''
                    }
                ]
            };
        });
    };

    const deleteAnotherFiles = (index) => {
        Swal.fire({
          title: "Are you sure to delete this file?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                setFormData((prevData) => {
                    const otherFilesArray = prevData.other_files.filter(
                        (_, i) => i !== index
                    );
                    return {
                        ...prevData,
                        other_files: otherFilesArray,
                    };
                });
            }
        });
    };
    
    const handleFormSubmit = async () => {
        const uploadedOtherFiles = [];

        try {
            for (const file of formData.other_files) {
                const uploadedFile = await handleFileUpload(file);
                if (uploadedFile) {
                    uploadedOtherFiles.push(uploadedFile);
                }
            }
        } catch (error) {
            console.log(error, 'Error uploading other files');
            toast({
                title: 'Error',
                description: 'Failed to upload other files',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setFormData((prevState) => ({
            ...prevState,
            other_files: uploadedOtherFiles,
        }));

        handleSubmitForm();
    };

    useEffect(() => {
        getDataRmb()
        getEscrowEditData()
    }, [])
  return (
    <Stack px={5} py={6}>
        <SimpleGrid columns={2} gap={5}>
            <Stack gap={4}>
                <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                    <Heading size={'lg'} py={5} px={4}>
                        Supplier Data
                    </Heading>
                    <SimpleGrid columns={2} gap={4} spacing={10} py={5} px={4}>
                        <Box>
                            <Text mb='8px'>Supplier Email*</Text>
                            <Input
                                defaultValue={formData.supplier_email}
                                type='email'
                                placeholder='Here is a sample placeholder'
                                size='lg'
                                name='supplier_email'
                                px={3}
                                py={4}
                                borderRadius={'md'}
                                onChange={handleChangeForm}
                            />
                        </Box>

                        <Box>
                            <Text mb='8px'>Supplier Phonne*</Text>
                            <Input
                                type='number'
                                defaultValue={formData.supplier_phone}
                                placeholder='Here is a sample placeholder'
                                size='lg'
                                name='supplier_phone'
                                px={3}
                                py={4}
                                borderRadius={'md'}
                                onChange={handleChangeForm}
                            />
                        </Box>

                        <Box>
                            <Text mb='8px'>Supplier ID Wechat*</Text>
                            <Input
                                defaultValue={formData.supplier_wechat}
                                placeholder='Here is a sample placeholder'
                                size='lg'
                                name='supplier_wechat'
                                px={3}
                                py={4}
                                borderRadius={'md'}
                                onChange={handleChangeForm}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>

                <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                    <Heading size={'lg'} py={5} px={4}>
                        Invoice File
                    </Heading>
                    <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4}>
                        <HStack spacing={2} alignItems='center' >
                            <Stack>
                                <Text mb='8px'>Commercial File</Text>
                                <Input
                                    type="file"
                                    defaultValue={formData.invoice_file_url}
                                    name='invoice_file_url'
                                    display="none"
                                    id="fileInput"
                                    multiple
                                    onChange={handleFileInputChange}
                                />
                                <label htmlFor="fileInput">
                                    <HStack cursor={'pointer'}>
                                        <Stack>
                                            <MdOutlinePermMedia />
                                        </Stack>
                                        <Text fontSize={'sm'}>
                                            Add Image / Video
                                        </Text>
                                    </HStack>
                                </label>
                            </Stack>
                        </HStack>
                        <SimpleGrid columns={[1, 2, 3]} gap={3}>
                            {files.length > 0 ? 
                                files.map((x, index) => (
                                <Stack key={index}>
                                    {x.description.includes("image/") ? (
                                    <Image src={x.file} borderRadius='xl' alt={x.name} shadow='sm' />
                                    ) : (
                                    <Stack>
                                        <FcFile size={50} />
                                        <Text fontSize={'xs'} noOfLines={2}>{x.description}</Text>
                                    </Stack>
                                    )}
                                </Stack>
                                )) : (
                                invoiceFile && invoiceFile.description && invoiceFile.description.includes("image/") ? (
                                    <Image src={invoiceFile.file_url} borderRadius='xl' alt={'image not found'} shadow='sm'/>
                                ) : (
                                    invoiceFile && (
                                    <Stack>
                                        <a href={invoiceFile.file_url} target='_blank'>
                                            <FcFile size={50} />
                                        </a>
                                        <Text fontSize={'xs'} noOfLines={2}>{invoiceFile.description}</Text>
                                    </Stack>
                                    )
                                )
                                )
                            }
                        </SimpleGrid>
                    </SimpleGrid>
                </Box>

                <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                    <Heading size={'lg'} py={5} px={4}>
                        Amount
                    </Heading>
                    <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4}>
                        <Box>
                            <Text mb='8px'>Amount (RMB) *</Text>
                            <Input
                                defaultValue={formData.amount_rmb}
                                placeholder='Here is a sample placeholder'
                                size='lg'
                                name='amount_rmb'
                                px={3}
                                py={4}
                                borderRadius={'md'}
                                onChange={handleChangeForm}
                            />
                        </Box>
                    </SimpleGrid>
                </Box>

                <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                    <Heading size={'lg'} py={5} px={4}>
                        Notes
                    </Heading>
                    <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4}>
                        <Box>
                            <Text mb='8px'>Notes for admin*</Text>
                            <Textarea
                                defaultValue={formData.customer_note}
                                placeholder='Here is a sample placeholder'
                                size='lg'
                                name='customer_note'
                                px={3}
                                py={4}
                                borderRadius={'md'}
                                onChange={handleChangeForm}
                            ></Textarea>
                        </Box>
                    </SimpleGrid>
                </Box>

                <Box spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5}>
                    <Heading size={'lg'} py={5} px={4}>
                        Other File
                    </Heading>
                    {
                        formData.other_files && formData.other_files.map((item, index) => {
                            return (
                                <SimpleGrid columns={1} gap={4} spacing={10} py={5} px={4} key={item._id}>
                                    <Box key={item._id}>
                                        <input type="text" name='_id' defaultValue={item._id} hidden/>
                                        <Text mb='8px'>Other File</Text>
                                        <HStack spacing={2} alignItems='center'>
                                            <Stack>
                                                <Input
                                                    type="file"
                                                    display="none"
                                                    name='file_url'
                                                    id={`inputFile-${index}`}
                                                    multiple
                                                    onChange={(event) => handleAnotherFile(event, index)}
                                                />
                                                <label htmlFor={`inputFile-${index}`}>
                                                    <HStack cursor='pointer'>
                                                        <Text fontSize='sm'>
                                                            Add Image / Video
                                                        </Text>
                                                    </HStack>
                                                </label>
                                            </Stack>
                                        </HStack>

                                        <SimpleGrid columns={[1, 2, 3]} gap={3}>
                                            {formData.other_files[index].file_url && (
                                                <a href={formData.other_files[index].file_url} target='_blank'>
                                                    <FcFile size={50} />
                                                </a>
                                            )}

                                        </SimpleGrid>
                                    </Box>
                                    <Box>
                                        <Text mb='8px'>Description</Text>
                                        <Input
                                            placeholder='Here is a sample placeholder'
                                            defaultValue={item.description}
                                            size='lg'
                                            name='description'
                                            px={3}
                                            py={4}
                                            borderRadius={'md'}
                                            onChange={(event) => {
                                                const newDescription = event.target.value;
                                                setFormData((prevState) => {
                                                    const updatedOtherFiles = [...prevState.other_files];
                                                    updatedOtherFiles[index] = {
                                                        ...updatedOtherFiles[index],
                                                        description: newDescription,
                                                    };
                                                    return {
                                                        ...prevState,
                                                        other_files: updatedOtherFiles,
                                                    };
                                                });
                                            }}
                                        />
                                    </Box>
                                    {
                                        formData.other_files.length-1 === index ?  
                                        <Stack w={'16'}>
                                            <Button colorScheme='red' onClick={() => deleteAnotherFiles(index)}>Delete</Button>
                                        </Stack>
                                        :
                                        ""
                                    }
                                </SimpleGrid>
                            )
                        })
                    }
                    <Button colorScheme='green' onClick={addAnotherFiles}>Add</Button>
                </Box>
            </Stack>

            <Stack spacing={3} bgColor='white' borderRadius={'xl'} shadow='sm' p={5} h={'2xl'}>
                <Heading size={'md'} textAlign={'center'} px={5} py={6}>
                    Summary
                </Heading>
                <Stack>
                    <HStack borderBottom={'1px'} borderColor={'gray.300'}>
                        <Text>
                            Amount
                        </Text>
                        <Spacer />
                        <Text>
                            Rp. {formatFrice(productPrice())}
                        </Text>
                    </HStack>
                    <HStack borderBottom={'1px'} borderColor={'gray.300'}>
                        <Text>
                            Handling Fee
                        </Text>
                        <Spacer />
                        <Text>
                            Rp. {formatFrice(amountFee)}
                        </Text>
                    </HStack>
                    <HStack borderBottom={'1px'} borderColor={'gray.300'}>
                        <Text >
                            Total
                        </Text>
                        <Spacer />
                        <Text>
                            Rp. {formatFrice(totalInvoice())}
                        </Text>
                    </HStack>

                    <Button colorScheme='green' onClick={() => handleFormSubmit()}>Submit</Button>
                </Stack>
            </Stack>
        </SimpleGrid>
    </Stack>
  )
}

export default EscrowEditPage