/* eslint-disable react-hooks/exhaustive-deps */
import { AspectRatio, Box, Grid, Heading, Image, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';
import store from "store";
// import _axiosEdutech from '../Api/AxiosEdutamaBarrier';
import _axios from '../Api/AxiosBarrier'
import { errorSlack } from '../Api/SlackApi';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getCollectionFirebase } from '../Api/firebaseApi';
 
function CoursePage() {
    const {t} = useTranslation();
    const [courseActive, setCourseActive] = useState("");
    const [academyDetail, setAcademyDetail] = useState('')
    const [courses, setCourses] = useState([]);
    const edutamaToken = store.get("edutama_token");
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const toast = useToast({
        position: "top",
        align: "center",
    });


    const path = window.location.href ?? '#'


    const getDataCourse = async () => {
        try {
            let conditions = [ 
                { field: "external_id", operator: "==", value: Number(params?.id) },
            ]

            const resAcademy = await getCollectionFirebase('academy', conditions)
            setAcademyDetail(resAcademy[0])

            let conditionsDetail = [ 
                { field: "academy_id", operator: "==", value: Number(params?.id) },
            ]

            const res = await getCollectionFirebase('academy_details', conditionsDetail)
            const sortedCourses = res?.sort((a, b) => {
                const dateA = new Date(a?.updated_at?.seconds * 1000);
                const dateB = new Date(b?.updated_at?.seconds * 1000);
                return dateB - dateA;
            });

            setCourses(sortedCourses);
            const subId = queryParams.get("id");
            if (subId !== null) {
                const findCourses = sortedCourses.find(
                    (item) => parseInt(item.id) === parseInt(subId)
                );
                if (findCourses !== undefined){
                    setCourseActive(findCourses);
                }
            } 
        } catch (error) {
            console.log(error.message, 'xxxo');
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
    };

    useEffect(() => {
        getDataCourse();
        return () => { };
    }, []);

    const extractVideoUrl = (description) => {
        const regex = /src="(.*?)"/;
        const match = description.match(regex);
        if (match) {
            return match[1];
        }
        return '';
    };

    const handleActive = (x) => {
        setCourseActive(x);
        navigate(`/courses/${params.id}?id=${x.id}`);
    };



    return (
        <Box w="100%" h="100%" p={[1, 2, 5]} bgColor="gray.100">
            <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={4}>
                <Stack spacing={3}>
                    <Box transition={'all 0.3s ease-in-out'}>
                        {courseActive && (
                            <AspectRatio ratio={16 / 9} transition={'all 0.3s ease-in-out'}>
                                <Box
                                    as="iframe"
                                    title="YouTube video player"
                                    src={extractVideoUrl(courseActive.description)}
                                    allowFullScreen
                                    frameBorder="0"
                                ></Box>
                            </AspectRatio>
                        )}
                    </Box>
                    <Stack spacing={3} bgColor="white" p={5} borderRadius="md" shadow="lg">
                        <Heading size="md">
                            {academyDetail?.title}
                        </Heading>
                        <Heading size="xs">
                            {courseActive ? courseActive.title : ''}
                        </Heading>
                        <Stack>
                            <div style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: academyDetail?.description }} />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack spacing={1} bgColor="white" p={2} borderRadius="md" shadow="lg" h={'800px'} overflowY={'scroll'}>
                    <Text fontWeight="bold" fontSize="xl" color="gray.600">
                        {t('other_courses')}
                    </Text>
                    <Stack spacing={1}>
                        {courses?.length > 0 ? (
                            courses?.map((course) => (
                                <Stack
                                    key={course.id}
                                    _hover={{ transform: "scale(1.05)" }}
                                    transition="0.2s ease-in-out"
                                    onClick={() => handleActive(course)}
                                    cursor="pointer"
                                    borderWidth={1}
                                    py={2}
                                    px={3}
                                    borderRadius="xl"
                                    borderColor={courseActive.title === course.title ? "blue.300" : "transparent"}
                                >
                                    <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={3} alignItems="center" justifyContent="center">
                                        <Box w="100%">
                                            <Image
                                                shadow="lg"
                                                src={course?.image.includes ('https://importir-new') ? course.image : `https://d2jnbxtr5v4vqu.cloudfront.net/images/${course.image}`}
                                                alt={course?.title}
                                                objectFit="cover"
                                                borderRadius="md"
                                            />
                                        </Box>
                                        <Box>
                                            <Text fontSize="sm" fontWeight="bold">
                                                {course.title}

                                            </Text>
                                            <Text fontSize="xs">{moment(course?.updated_at?.seconds * 1000).format('LLL')}</Text>
                                        </Box>
                                    </Grid>
                                </Stack>
                            ))
                        ) : (
                            <Text>
                                {t('video_not_found')}
                            </Text>
                        )}
                    </Stack>
                </Stack>
            </Grid>
        </Box>
    );
}
export default CoursePage;