import { Box, Button, Flex, Grid,  Image, SimpleGrid, Stack, Text, Divider, Input, Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton, 
  useDisclosure, Card, CardBody, useToast, Container, ModalHeader, ModalFooter, Checkbox} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice';
import _axios from '../../Api/AxiosBarrier';
import store from 'store'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function FavoritePage() {
  const [carts, setCarts] = useState([])
  const [user, setUser] = useState({})
  const { userSymbolCurrency, loadingShow, loadingClose } = useContext(AuthContext)
  const navigate = useNavigate()
  const { convertPriceFix } = ConvertPrice()
  const height = window.innerHeight
  const users = store.get('user_api')
  const [quantity, setQuantity] = useState({});
  const [inquiries, setInquiries] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isOpenModal1, setIsOpenModal1] = useState(false)
  const [isOpenModal2, setIsOpenModal2] = useState(false)
  const [isOpenModal3, setIsOpenModal3] = useState(false)
  const openModal1 = () => setIsOpenModal1(true);
  const closeModal1 = () => setIsOpenModal1(false);
  const openModal2 = () => setIsOpenModal2(true);
  const closeModal2 = () => setIsOpenModal2(false);
  const openModal3 = () => setIsOpenModal3(true);
  const closeModal3 = () => setIsOpenModal3(false);
  const [isChecked, setIsChecked] = useState(false);
  const [popupImage, setPopupImage] = useState("")
  const toast = useToast({
    position: 'top',
    align: 'center',
  });

  const getCarts = async () => {
    console.log('getcarts mitra')
    const res = await _axios.get("api/mitra/cart/index")
      if (res.mitra_order_details !== undefined) {
          setCarts(res.mitra_order_details)
      }
    }

  const getUserInquiries = async () => {
    const res = await _axios.get('api/mitra/inquiry')
    setInquiries(res)
    res.map((x, i) => {
      if (x?.default_inquiry !== null) {
        setUser(x)
      }
    })

  }

  const handleChangeinquiry = async (id) => {
    try {
      loadingShow()
      const res = await _axios.get(`api/mitra/inquiry/${id}/change-inquiry`)
      loadingClose()
      if (res.status === true) {
        toast({
          title: 'importir.com',
          description: res.message,
          status: 'success',
        });
        window.location.reload()
      }
    } catch (error) {
      toast({
        title: 'importir.com',
        description: error,
        status: 'error',
      });
    }
  }

  const checkout = async () => {
    try {
      const params = carts.map((x) => ({
        mitra_order_detail_id: x?._id,
        quantity_lot_order: x?.quantity_lot_order,
      }));
      loadingShow()
      const response = await _axios.post('api/mitra/cart/checkout', params);
      loadingClose()
      if (!response.status) {
          Swal.fire({
              title: 'Error',
              text: response.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Yes'
          })
      } else {
          Swal.fire({
          title: 'Success',
              text: response.message,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Yes'
          }).then(async(result) => {
              if (result.isConfirmed) {
                  navigate('/mitra/order/' + response.data._id+'/detail')
              }
          })
      }
    } catch (error) {
      toast({
        title: 'importir.com',
        description: error,
        status: 'error',
      });
    }
  }  

    const incrementQuantity = (id, index) => {
      const cartLocal = carts
      cartLocal[index].quantity_lot_order = cartLocal[index].quantity_lot_order + 1
      setCarts(cartLocal)
      setQuantity((prevQuantity) => ({
        ...prevQuantity,
        [id]: (prevQuantity[id] || 0) + 1,
      }));

    };
  
    const decrementQuantity = (id, index) => { 
      const cartLocal = carts
      if (cartLocal[index].quantity_lot_order > 1) {
        cartLocal[index].quantity_lot_order = cartLocal[index].quantity_lot_order - 1
        setCarts(cartLocal)
        setQuantity((prevQuantity) => ({
          ...prevQuantity,
          [id]: prevQuantity[id] - 1,
        }));

      }
    }
  
    const changeQuantity = (index, qty) => { 
      if (qty > 0) {
        const cartLocal = carts
        cartLocal[index].quantity_lot_order = parseInt(qty)
        setCarts(cartLocal)
      }
    }

    const totalPrice = () => {
      let total = 0;
    
      carts.forEach((x) => {
        total += x?.quantity_lot_order * x?.price_per_pcs * x?.per_lot
      });
    
      return total;
    }
    
    const checkNpwp = () => {
      const user = store.get('user_api')
      if (user?.detail?.npwp_image === "" && user?.detail?.npwp_number === null) {
        setIsOpenModal2(true)
      }
    }

    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };

    const handlePopupImage = (image) => {
      setPopupImage(image)
      setIsOpenModal3(true)
    }

    useEffect(() => {
      getCarts()
      checkNpwp()
      getUserInquiries()
    }, [])


  return (
    <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxW={'container.xl'} position={'relative'} zIndex={'1'} top={'-3rem'}>
      <Stack p={[1, 1, 5]} minH={height}>
        <SimpleGrid columns={2} spacing={2}>
          <Box colSpan={1}>
            {carts.length > 0 ?
              carts.map((cart, index) => (
                  <Stack bgColor={'white'} key={index} spacing={1} borderRadius="5" mt={3} p={2} pb={8} shadow={'2xl'}>
                    <Grid templateColumns={{ base: "1fr", md: "1fr 5fr 3fr 2fr 1fr" }} gap={2} p={3} alignItems="center" justifyContent={'space-around'}>

                        <Stack shadow={5}>
                            <Image src={cart?.image[0]} alt={cart?.title} borderRadius={'xl'} cursor={'pointer'} onClick={() => handlePopupImage(cart?.image[0])} w="150px" />
                        </Stack>

                        <Stack spacing={1}>
                            <Text numberOfLines={2} fontWeight="bold" fontSize="sm">
                            {cart?.title}
                            </Text>
                            <Text color={'gray.700'} fontSize="sm">
                            {userSymbolCurrency} {formatFrice(convertPriceFix(cart?.price_per_pcs))} / pcs
                            </Text>
                            <Text color={'gray.700'} fontSize="sm">
                            Quantity : {cart?.quantity_lot_order}
                            </Text>
                            <Text color={'gray.700'} fontSize="sm">
                            Per lot : {cart?.per_lot} pcs
                            </Text>
                        </Stack>

                        <Stack spacing={1}>
                            <Stack spacing={0}>
                            <Text color={'gray.700'} fontSize="sm">
                                Total
                            </Text>
                            <Text color={'green.700'} fontWeight="bold">
                              {userSymbolCurrency} {formatFrice(convertPriceFix((cart?.product_price_total)))}
                            </Text>
                            </Stack>
                        </Stack>

                        <Box textAlign="center" mt={8}>
                          <SimpleGrid columns={3} spacing={10}>
                            <Stack>
                              <Button onClick={() => decrementQuantity(cart?._id, index)} borderRadius={'full'}>
                                -
                              </Button>

                            </Stack>
                            <Stack>
                              <Input
                                type="number"
                                value={cart.quantity_lot_order}
                                onChange={(e) => changeQuantity(index, e.target.value)}
                                w="50px"
                              />
                            </Stack>
                            <Stack>
                              <Button onClick={() => incrementQuantity(cart?._id, index)} ml={'3'} borderRadius={'full'}>
                                +
                              </Button>
                            </Stack>
                          </SimpleGrid>
                        </Box>
                    </Grid>
                  </Stack>
                ))    
              : 
                <Stack h={height / 2} alignItems="center" justifyContent="center" mt={'10'}>
                    <Image src="https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Ronaldo/empty-cart-2023_06_23_11_04_12.png" w={'sm'}></Image>
                    <Text textColor={'gray'} fontWeight={'bold'} fontSize={'2xl'}>Whoa, your shopping cart is empty</Text>
                    <Text textColor={'gray'}>Let's fill it with your dream items!</Text>
                </Stack>  
            }
          </Box>
          <Box colSpan={2} mt={5} mr={4} maxH={'500px'} position={'sticky'} top={'4'} width={"100%"}>
          <Box bgColor={'white'} p={3} borderRadius={5} shadow={'2xl'}>
              <Text>Account Detail</Text>
              <Divider p={2}/>
              <Flex gap={3} p={1}>
                <Text>Status : </Text>
                <Text color={'green.300'} fontWeight={'bold'}>{user?.status_inquiry}</Text>
              </Flex>
              <Flex gap={3} p={1}>
                <Text>Account Name : </Text>
                <Text>{user?.name}</Text>
              </Flex>
              <Flex gap={3} p={1}>
                <Text>Account Name At The Bank : </Text>
                <Text>{user?.name_by_server}</Text>
              </Flex>
              <Flex gap={3} p={1}>
                <Text>Account Number : </Text>
                <Text>{user?.account_no}</Text>
              </Flex>
              <Flex gap={3} p={1}>
                <Text>NPWP Number : </Text>
                <Text>{users?.detail !== null ? users?.detail?.npwp_number : "-"}</Text>
              </Flex>
              <Divider p={3}/>
              <SimpleGrid columns={[1, 2, 3]} gap={2} p={4}>
                <Button colorScheme='blue' onClick={() => navigate('/profile?account_number=true')} size={'sm'}>New Account</Button>
                <Button colorScheme='green' onClick={() => openModal1()} size={'sm'}>Choose Account</Button>
                <Button colorScheme='orange' onClick={() => openModal2()} size={'sm'}>Update NPWP</Button>
                {/* <Button colorScheme='orange' onClick={() => navigate('/profile?edit_npwp=true')} size={'sm'}>Edit NPWP</Button> */}
              </SimpleGrid>
            </Box>
            <Box bgColor={'white'} p={3} borderRadius={5} shadow={'2xl'} mt={'5'}>
              <Flex>
                <Text fontWeight={'bold'} fontSize={'xl'}>Total Harga : </Text>
                <Text ml={'2'} textAlign={'end'} fontWeight={'bold'} fontSize={'xl'}>Rp. {formatFrice(totalPrice())}</Text>
                <Stack ml={'auto'}>
                  <Button bgColor={'blue.400'} color={"white"} onClick={() => checkout()}>Checkout</Button>
                </Stack>
              </Flex>
            </Box>
          </Box>
        </SimpleGrid>
        {/* modal select account bill */}
        <Modal isOpen={isOpenModal1} onClose={closeModal1} size={'xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody p={4}>
            {
              inquiries?.length > 0 &&
              inquiries?.map((x, i) => (
                x?.status_inquiry === "SUCCESS" ? (
                  <Card key={i} mt={4}>
                    <CardBody>
                      <Flex>
                        <Text>Account Name : </Text>
                        <Text ml={'2'}>{x?.name}</Text>
                      </Flex>
                      <Flex>
                        <Text>Account number : </Text>
                        <Text ml={'2'}>{x?.account_no}</Text>
                      </Flex>
                      <Flex>
                        <Text>Bank Name : </Text>
                        <Text ml={'2'}>{x?.bank_code}</Text>
                      </Flex>
                      <Flex>
                        <Text>Status : </Text>
                        <Text ml={'2'} color={'green.300'}>{x?.status_inquiry}</Text>
                      </Flex>
                    </CardBody>
                    <Stack>
                      {
                        x?.default_inquiry === null ? (
                          <Stack p={3}>
                            <Button colorScheme='blue' onClick={() => handleChangeinquiry(x.id)}>Change</Button>
                          </Stack>
                        ) : (
                          <Text textAlign={'end'} color={'green.300'} p={3}>Applied</Text>
                        )
                      }
                    </Stack>
                  </Card>
                ) : null
              ))
            }
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpenModal2} onClose={closeModal2} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'} size={'xl'}>Terms & Conditions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack p={5} fontSize={'lg'}>
              <Text>for marketing services subject to withholding income tax article 21</Text>
              <Text>If you attach an NPWP, the income tax article 21 deduction for marketing services is 2.5%</Text>
              <Text>If <b>you do not</b> attach NPWP, deduction of income tax article 21 for marketing services at 4%</Text>
            </Stack>
            <Flex justifyContent={'center'}>
              <Checkbox defaultChecked={isChecked} onChange={handleCheckboxChange}>
                I Agree
              </Checkbox>
            </Flex>
          </ModalBody>

          <ModalFooter
            style={{
              backgroundImage: "url('https://d2jnbxtr5v4vqu.cloudfront.net/filemanager//vector-2-2021_05_31_13_20_20.png')",
              backgroundSize: "cover",
              backgroundPosition: "center", 
              backgroundPositionY: '80px'
            }}
            justifyContent={'center'}
          >
            <Button
              colorScheme={isChecked ? 'green' : 'green'}
              mr={3}
              onClick={() => onClose}
              isDisabled={!isChecked}
            >
              Continue Checkout
            </Button>
            <Button colorScheme='orange' onClick={() => navigate('/profile?edit_npwp=true')}>Input NPWP</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenModal3} onClose={closeModal3}>
        <ModalOverlay />
        <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Image src={popupImage}></Image>
            </ModalBody>
        </ModalContent>
      </Modal>
      </Stack>
    </Container>
  )
}

export default FavoritePage