/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Heading, HStack, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Skeleton, Spacer, Stack, Tag, Text, useToast } from '@chakra-ui/react'
import { arrayRemove, doc, increment, setDoc, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { db } from '../Config/firebase';
import AuthContext from '../Routes/hooks/AuthContext';
import { formatFrice } from '../Utils/Helper'
import { useNavigate } from 'react-router-dom';
import ConvertPrice from '../Utils/ConvertPrice';
import { errorSlack } from '../Api/SlackApi';
import _axios from '../Api/AxiosBarrier';
import ProductCardWishlist from '../Components/Card/ProductCardWishlist';
import { useTranslation } from 'react-i18next';
function FavoritePage() {

  const {t} = useTranslation();
  const { currentUser, kursRmb, productListWishlist, userSymbolCurrency, loadingShow, loadingClose } = useContext(AuthContext)

  const [sortedProductList, setSortedProductList] = useState([]);



  const { convertPriceFix } = ConvertPrice()


  const [imageView, setImageView] = useState('')
  const [detailModal, setDetailModal] = useState(false)

  const [count, setCount] = useState(1)


  const [historyWishlist, setHistoryWishlist] = useState([])
  const [historyWishlistModal, setHistoryWishlistModal] = useState(false)

  const path = window.location.href ??  '#'

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const navigate = useNavigate()
  const height = window.innerHeight


  const getDataWishlistSort = () => {
    if (productListWishlist?.data?.length > 0) {
      const sortedData = [...productListWishlist.data].sort((a, b) => {
        const createdAtA = a?.createdAt_wishlist?.seconds;
        const createdAtB = b?.createdAt_wishlist?.seconds;
        return createdAtB - createdAtA;
      });
      setSortedProductList(sortedData);
    }
  }

  const getDataWishlistCurrent = async () => {
    let wishListArr = []
    try {
      const res = await _axios.get(`api/wishlist?page=${count}`)
      if (res.status === true) {
        const dataArr = res.data.data
        wishListArr.push(...dataArr)
      }
      if (count > 1) {
        setHistoryWishlist([...historyWishlist, ...wishListArr])
      } else {
        setHistoryWishlist(wishListArr)
      }
      loadingClose()
    } catch (error) {
      console.log(error, 'ini error')
    }

  }

  useEffect(() => {
    getDataWishlistCurrent()

    return () => {

    }
  }, [])

  const handlePagination = async () => {
    setCount(count + 1)
    getDataWishlistCurrent(count)
  }


  useEffect(() => {
    getDataWishlistSort()

    return () => {
    }
  }, [productListWishlist?.data])




  const handleDelete = async (item) => {
    loadingShow()
    let firebaseData = {}
    firebaseData = { ...item }

    try {

      const ref = doc(db, "wishlist", currentUser.uid);
      await setDoc(ref, {
        uid: currentUser.uid,
        data: arrayRemove(firebaseData),
        createdAt: new Date()
      }, { merge: true });

      firebaseData = {}

      const docRef = doc(db, "products", (item.id).toString());
      try {
        await updateDoc(docRef, {
          wishlist_count: increment(-1),
        });
        console.log('Success Increment');

        const updatedFavorites = sortedProductList.filter((x) => x.id !== item.id);
        setSortedProductList(updatedFavorites);
        loadingClose()
      } catch (error) {
        console.log(error);

        const resSlack = await errorSlack(error, 'importir.com', path)
        console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        loadingClose()
      }

      toast({
        title: 'importir.com',
        description: 'Success delete product.',
        status: 'success'
      })
      loadingClose()

    } catch (error) {
      toast({
        title: 'importir.com',
        description: error.message,
        status: 'error'
      })

      const resSlack = await errorSlack(error, 'importir.com', path)
      console.log(resSlack, 'CONNECTED ERROR IN SLACK')
      loadingClose()

    }
    loadingClose()



  }




  const handleImage = (data) => {
    setImageView(data)
    setDetailModal(true)
  }

  const handleNavigateProduct = (idProduct, platformType) => {
    // if (userDb) {
    const navigateRouteProduct = `/product/${platformType}/${idProduct}`
    navigate(navigateRouteProduct)
    // } else {
    //     navigate('/signup')
    // }

  }



  return (
    <Stack bgColor={'gray.100'} p={[1, 1, 5]} minH={height}>
      <HStack py={3}>
        <Heading size={'md'} textTransform='capitalize'>
          {t("wishlist")}
        </Heading>
        <Spacer />
        <Stack>
          <Button bgColor={'#ffd600'} size='sm' onClick={() => setHistoryWishlistModal(true)}>
            <Text color={'black'}>{`${t("history")} ${t("wishlist")}`}</Text>
          </Button>
        </Stack>
      </HStack>

      {sortedProductList?.length > 0 && (
        <SimpleGrid columns={[1, 1, 4]} gap={5} mx={[1, 3, 5]}>
          {sortedProductList?.map((product, index) => (
            <ProductCardWishlist
              key={index}
              product={product}
              kursRmb={kursRmb}
              handleImage={handleImage}
              handleDelete={handleDelete}
              handleNavigateProduct={handleNavigateProduct}
              userSymbolCurrency={userSymbolCurrency}
            />
          ))}
        </SimpleGrid>
      )}

      {imageView !== "" && (
        <Modal isOpen={detailModal} onClose={() => setDetailModal(false)} >
          <ModalOverlay />
          <ModalContent bgColor={'white'} >
            <ModalHeader>
              <HStack spacing={2} alignItems='center' >
                {/* <FontAwesome5 name="file-invoice" size={22} color="black" /> */}
                <Text fontSize={'lg'} fontWeight='bold'>Detail</Text>
                <Spacer />
                <HStack alignItems={'center'} justifyContent='flex-end'>
                  <Stack>
                    <Tag>CN 🇨🇳</Tag>
                  </Stack>


                </HStack>
              </HStack>
            </ModalHeader>

            <ModalBody >
              <Stack borderRadius={'xl'} spacing={2} bgColor='white' >
                <Stack alignItems={'center'} cursor='pointer'>
                  {imageView.image ? (
                    <Image w={'full'} src={imageView.image} alt='img' borderRadius={'md'} />
                  ) : (
                    <Skeleton w={'200px'} h='300px' borderRadius={'md'} />
                  )}

                </Stack>
                <Stack px={3}>

                  <Text textTransform={'capitalize'} fontWeight='bold' fontSize={'sm'} noOfLines={2}> {imageView["title-en"]?.en}</Text>
                </Stack>

                <Spacer />

                <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                  <Stack spacing={0} alignItems='flex-start' justifyContent={'flex-start'} >

                    <Text textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>{t("price")}</Text>
                    {/* <Text textTransform={'capitalize'} fontWeight='extrabold' color={'black'} fontSize={'md'}>{userSymbolCurrency} {formatFrice(x.price)}</Text> */}
                    <Text textTransform={'capitalize'} fontWeight='bold' color={'black'} fontSize={'sm'}>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(imageView.price)))}</Text>
                  </Stack>

                  <Stack >

                    <Stack spacing={0} alignItems='flex-end' justifyContent={'flex-end'}>
                      <Text fontsize textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>{t("sold")}</Text>
                      <Text textTransform={'capitalize'} color={'black'} fontWeight='bold' fontSize={'sm'}>{imageView.sold}</Text>
                    </Stack>
                  </Stack>


                </SimpleGrid>

              </Stack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={5}>
                <Button bgColor='green.400' onClick={() => handleNavigateProduct(imageView.id, '1688')}>
                  <Text color={'white'}>🛒 {t("order_now")}</Text>
                </Button>
                <Button colorScheme='red' mr={3} onClick={() => setDetailModal(false)}>
                  Close
                </Button>
              </HStack>
            </ModalFooter>


          </ModalContent>
        </Modal>
      )}

      <Modal isOpen={historyWishlistModal} onClose={() => setHistoryWishlistModal(false)} >
        <ModalOverlay />
        <ModalContent bgColor={'white'} >
          <ModalHeader>
            <HStack spacing={2} alignItems='center' >
              {/* <FontAwesome5 name="file-invoice" size={22} color="black" /> */}
              <Text fontSize={'lg'} fontWeight='bold'>{`${t("history")} ${t('wishlist')}`}</Text>
            </HStack>
          </ModalHeader>

          <ModalBody >
            <Stack py={5} borderRadius={'xl'} spacing={2} bgColor='white' h={height / 1.5} overflowY='scroll'>
              {historyWishlist.length > 0 ? (
                <Stack>
                  <SimpleGrid columns={[1, null, 2]} gap={4}>

                    {historyWishlist.map((x, index) => {
                      return (
                        <Stack shadow='md' key={index} borderRadius={'xl'} spacing={2} bgColor='white' _hover={{ transform: "scale(1.05)" }} transition={"0.2s ease-in-out"} >
                          <Stack alignItems={'center'} cursor='pointer' onClick={() => handleImage(x)}>
                            {x?.product?.image ? (
                              <Image src={x?.product?.image} alt='img' borderRadius={'md'} />
                            ) : (
                              <Skeleton w={'200px'} h='300px' borderRadius={'md'} />
                            )}

                          </Stack>
                          <Stack px={3}>
                            <HStack alignItems={'center'} justifyContent='flex-end'>
                              <Stack>
                                <Tag>CN 🇨🇳</Tag>
                              </Stack>

                            </HStack>
                            <Text textTransform={'capitalize'} fontWeight='bold' fontSize={'sm'} noOfLines={2}> {x?.name}</Text>
                          </Stack>

                          <Spacer />

                          <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                            <Stack spacing={0} alignItems='flex-start' justifyContent={'flex-start'} >

                              <Text textTransform={'capitalize'} color={'gray.600'} fontSize={'sm'}>{t('price')}</Text>
                              {/* <Text textTransform={'capitalize'} fontWeight='extrabold' color={'black'} fontSize={'md'}>{userSymbolCurrency} {formatFrice(x.price)}</Text> */}
                              <Text textTransform={'capitalize'} fontWeight='bold' color={'black'} fontSize={'sm'}>{userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(x.product.prices)))}</Text>
                            </Stack>


                          </SimpleGrid>

                          <Stack p={3} >
                            <Button size={'sm'} bgColor='green.400' onClick={() => navigate(`/product/${x.platform_type}/${x.product_id}`)}>
                              <Text color={'white'}>🛒 {t("order_now")}</Text>
                            </Button>
                          </Stack>
                        </Stack>
                      )
                    })}
                  </SimpleGrid>

                  <Stack>
                    <Button size={'sm'} onClick={() => handlePagination()} bgColor='#ffd600'>
                      <Text color={'black'}>{t("load_more")}..</Text>
                    </Button>
                  </Stack>
                </Stack>
              ) : (
                <Stack h={'400px'} w='100%' alignItems={'center'} justifyContent='center'>
                  <Text color={'gray.600'} fontSize='sm'>{t("dont_have_history_wishlist")}</Text>
                </Stack>
              )}




            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={5}>

              <Button colorScheme='red' mr={3} onClick={() => setHistoryWishlistModal(false)}>
                {t('close')}
              </Button>
            </HStack>
          </ModalFooter>


        </ModalContent>
      </Modal>

    </Stack>
  )
}

export default FavoritePage