import { Stack, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { HiOutlineBellAlert } from 'react-icons/hi2'
import { useParams } from 'react-router-dom'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi'
import AuthContext from '../../Routes/hooks/AuthContext'

function BusinessDetailPage() {

    const height = window.innerHeight

    const { dataList } = useContext(AuthContext)

    const [dataListing, setDataListing] = useState("")

    const param = useParams()

    const getData = async () => {
           const dataFind = dataList.find((x) => x.id === param.id)
           setDataListing(dataFind) 
    }

    useEffect(() => {
        getData()

        return () => {
        }
    }, [])




    return (
        <Stack alignItems={'center'} justifyContent='center' h={height / 1.5}>
            <HiOutlineBellAlert size={70} color='gray' />
            <Text fontSize={'xl'} textAlign='center' fontWeight='bold' color={'blackAlpha.600'} >This Business importir product will be launch soon.</Text>
            <Text fontSize={'sm'} textAlign='center' color={'blackAlpha.600'} >IMPORTIR.COM</Text>
        </Stack>
    )
}

export default BusinessDetailPage