import { Badge, Text } from '@chakra-ui/react'
import React from 'react'

export const getCountry =  (data) => {

  if(data==='1688' ){
    return <Badge bgColor='red.300' size={'md'}>CN 🇨🇳</Badge>
  }
  if(data==="taobao"){
    return <Badge bgColor='yellow.300' size={'md'}>
      <Text textTransform={'capitalize'}>Taobao</Text>
    </Badge>
  }
  if(data==='amazonus' ){
    return <Badge bgColor='red.300' size={'md'}>USA 🇺🇸</Badge>
  }
  if(data==='coupang' ){
    return <Badge bgColor='red.300' size={'md'}>KOREA 🇰🇷</Badge>
  }
}
