import { HStack, Image,  SimpleGrid, Skeleton, Spacer, Stack, Text, Container, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, useDisclosure} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatFrice } from '../../Utils/Helper'
import ConvertPrice from '../../Utils/ConvertPrice';
import _axios from '../../Api/AxiosBarrier';

function FavoritePage() {
  const [wishlists, setWishlists] = useState([])
  const { kursRmb, userSymbolCurrency } = useContext(AuthContext)
  const { convertPriceFix } = ConvertPrice()
  const height = window.innerHeight
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [popupImage, setPopupImage] = useState("")

  const getDataWishlist = async () => {
    const res = await _axios.get("api/mitra/wishlist/index")
    setWishlists(res)
  }

  const handlePopupImage = (image) => {
    setPopupImage(image)
    onOpen()
  }

  useEffect(() => {
    getDataWishlist()
  }, [])

  const handleDelete = async (product) => {
    await _axios.delete("api/mitra/wishlist/" + product?._id + "/delete")
    window.location.reload()
  }

  return (
    <Container bg={'white'} borderRadius={'md'} shadow={'md'} maxW="container.xl" position={'relative'} zIndex={'1'} top={'-3rem'}>
        <Stack p={[1, 1, 5]} minH={height}>
        <SimpleGrid columns={[2, null, 4]} gap={5} mx={[1, 3, 5]}>
            {wishlists?.map((product, index) => (
            <Stack shadow='md' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.2s ease-in-out'>
                <Stack alignItems='center' cursor='pointer'>
                    {product?.mitra_product?.image_mitra_products?.product_images[0] ? (
                        <Image src={product?.mitra_product?.image_mitra_products?.product_images[0]?.link} alt='img' borderRadius='md' onClick={() => handlePopupImage(product?.mitra_product?.image_mitra_products?.product_images[0]?.link)} />
                    ) : (
                        <Skeleton w='200px' h='300px' borderRadius='md' />
                    )}
                </Stack>
                <Stack px={3}>
                    <HStack alignItems='flex-end' justifyContent='flex-end'>
                        <Stack cursor='pointer' onClick={() => handleDelete(product)}>
                            <AiOutlineDelete style={{ fontSize: 20, color: 'red' }} />
                        </Stack>
                    </HStack>
                    <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={2}>
                        {product?.mitra_product?.title}
                    </Text>
                </Stack>
                <Spacer />
                <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                    <Stack spacing={0} alignItems='flex-start' justifyContent='flex-start'>
                        <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                            Price
                        </Text>
                        <Text textTransform='capitalize' fontWeight='bold' color='black' fontSize='sm'>
                            {userSymbolCurrency} {formatFrice(convertPriceFix(kursRmb * Number(product?.mitra_product?.price)))}
                        </Text>
                    </Stack>
                    <Stack>
                        <Stack spacing={0} alignItems='flex-end' justifyContent='flex-end'>
                            <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                                Roi
                            </Text>
                            <Text textTransform='capitalize' color='black' fontWeight='bold' fontSize='sm'>
                                {product?.mitra_product?.roi} %
                            </Text>
                        </Stack>
                    </Stack>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2, 2]} spacing={0} px={3}>
                    <Stack spacing={0} alignItems='flex-start' justifyContent='flex-start'>
                        <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                            Lot
                        </Text>
                        <Text textTransform='capitalize' fontWeight='bold' color='black' fontSize='sm'>
                            {product?.mitra_product?.lot}
                        </Text>
                    </Stack>
                    <Stack>
                        <Stack spacing={0} alignItems='flex-end' justifyContent='flex-end'>
                            <Text textTransform='capitalize' color='gray.600' fontSize='sm'>
                                Per Lot
                            </Text>
                            <Text textTransform='capitalize' color='black' fontWeight='bold' fontSize='sm'>
                                {product?.mitra_product?.per_lot} %
                            </Text>
                        </Stack>
                    </Stack>
                </SimpleGrid>
                <Stack p={3}>
                    {/* <Button size='sm' bgColor='green.400' onClick={() => handleOrderNow()}>
                        <Text color='white'>🛒 Order now</Text>
                    </Button> */}
                </Stack>
            </Stack>
            ))}
        </SimpleGrid>

        </Stack>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                <Image src={popupImage}></Image>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Container>
  )
}

export default FavoritePage