/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, HStack, Image, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../Routes/hooks/AuthContext';
import { formatCategoryName } from '../../Utils/Helper';

function AppCategoryV2({handleFunc}) {
  const [categoryActive, setCategoryActive] = useState('');
  const [nicheCategoryList, setNicheCategoryList] = useState([]);
  const [nicheActive, setNicheActive] = useState('');

  const { categoryList, categoryFrom } = useContext(AuthContext);
  const navigate = useNavigate();

  const getNicheCategory = async (category) => {
    if (category !== categoryActive && categoryList.length > 0) {
      const filterArr = categoryList.find((x) => x.category === category.category);
      setNicheCategoryList(filterArr?.subCategory);
      setCategoryActive(filterArr);
    }
  };

  useEffect(() => {
    
    if (categoryList?.length > 0) {
      const firstCategory = categoryList[0];
      getNicheCategory(firstCategory);
    }
  }, [categoryList]);

  const handleNiche = (y) => {
    setNicheActive(y);
    handleFunc()
    if (y['1688-id']) {
      navigate(`/category?detail=${categoryActive.category}&subCategory=${y.title}&id=${y['1688-id']}`);
    } else if (y["amazon-id"]) {
      navigate(`/category?detail=${categoryActive.category}&subCategory=${y.title}&id=${y["amazon-id"]}`);
    } else if (y["coupang-id"]) {
      navigate(`/category?detail=${categoryActive.category}&subCategory=${y.title}&id=${y['coupang-id']}`);
    }
  };

  return (
    <Stack bgColor="white" spacing={3} w="full" overflow="hidden">
      <Text fontSize={'xs'} color='red.300'>*You are in the {categoryFrom === "usa" ? "USA" : categoryFrom === "korea" ? "Korea" : "China"} products category</Text>
      <Grid templateColumns={{ base: '1fr', md: '1fr 4fr' }} gap={3} fontSize="sm">
        <Stack h="450px" overflowY="scroll" borderRight="1px" borderColor="gray.200">
          {
            categoryList?.length > 0 &&
              categoryList.map((x, index) => (
                <HStack key={index} spacing={2} cursor="pointer" onClick={() => getNicheCategory(x)} bgColor={categoryActive === x ? 'green' : 'white'} p={2}>
                  {x?.subCategory[0]?.image && (
                    <Image src={x?.subCategory[0]?.image} alt="image" width="15px" />
                  )}
                  <Text color={categoryActive === x ? 'white' : 'black'} fontSize="sm">
                    {formatCategoryName(x.category)}
                  </Text>
                </HStack>
              ))}
        </Stack>
        <Stack h="450px" overflowY="scroll">
          {nicheCategoryList.length > 0 ? (
            <SimpleGrid columns={[1, 2, 4]} gap={3}>
              {nicheCategoryList.map((y, index) => (
                <HStack key={index} cursor="pointer" onClick={() => handleNiche(y)}>
                  {y.image && (
                    <Image src={y.image} alt={y.title} width="20px" />
                  )}
                  <Text fontSize="xs" color={nicheActive.title === y.title ? '#ffd600' : 'black'}>
                    {formatCategoryName(y.title)}
                  </Text>
                </HStack>
              ))}
            </SimpleGrid>
          ) : (
            <Stack alignItems="center" justifyContent="center" h="100%" w="100%">
              <Spinner size={'md'} />
            </Stack>
          )}
        </Stack>
      </Grid>
    </Stack>
  );
}

export default AppCategoryV2;
