/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Grid, Heading, HStack, Image, Input, Select, SimpleGrid, Spacer, Stack, Tag, Text, Container, Box, Center, GridItem } from '@chakra-ui/react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { FaShip } from 'react-icons/fa'
import { IoAirplaneSharp, IoCaretBackOutline } from 'react-icons/io5'
import { SlArrowDown } from 'react-icons/sl'
import { useNavigate } from 'react-router-dom'
import _axios from '../Api/AxiosBarrier'
import { errorSlack } from '../Api/SlackApi'
import AuthContext from '../Routes/hooks/AuthContext'
import colors from '../Utils/colors'
import ConvertPrice from '../Utils/ConvertPrice'
import { getCountry } from '../Utils/country'
import { formatFrice } from '../Utils/Helper'
import { useTranslation } from 'react-i18next'
function OrderPage() {
    const {t} = useTranslation();

    const path = window.location.href  ?? '#'

    const [orderList, setOrderList] = useState([])
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState('');
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [startPaid, setStartPaid] = useState('')
    const [endPaid, setEndPaid] = useState('')

    const navigate = useNavigate()


    const height = window.innerHeight

    const { loadingShow, loadingClose, userSymbolCurrency } = useContext(AuthContext)

    const { convertPriceFix } = ConvertPrice()

    const getDataOrder = async () => {
        loadingShow();
        try {
            const res = await _axios.get(`api/orders?page=${count}&order_number=${search}&freight=${status}&title=${title}&&start_paid_at=${startPaid}&end_paid_at=${endPaid}`);
            if (res && res.data && res.data.orders) {
                const dataArr = res.data.orders;
                if (count > 1) {
                    setOrderList(prevDataList => [...prevDataList, ...dataArr]);
                } else {
                    setOrderList(dataArr);
                }
            }
            loadingClose();
        } catch (error) {
            console.log(error);
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            loadingClose();
        }
    };


    const handleDateChange = (e) => {
        const { id, value } = e.target;

        if (id === 'startPaid') {
            setStartPaid(value);
        } else if (id === 'endPaid') {
            setEndPaid(value);
        }
    };



    useEffect(() => {
        getDataOrder();

        return () => {
        };
    }, [count]);

    const handlePagination = () => {
        setCount(prevCount => prevCount + 1);
    };



    const handleModalDetail = async (id) => {
        navigate(`/order/${id}`)
    }



    const handleReset = () => {
        setCount(1);
        setSearch('');
        setStatus('');
        setStartPaid('')
        setEndPaid('')
        setTitle('')
        getDataOrder();
    };


    return (
        <Stack bgColor={'gray.100'} p={[1, 1, 5]}  mt={[10, 10, 0]}>
            <HStack>
                <HStack cursor='pointer' zIndex='50' w='100px' mx={5} mb={2} p={2} alignItems='center' shadow={'base'} justifyContent={'center'} borderRadius='full' bgColor={colors.theme} onClick={() => navigate(-1)}>
                    <IoCaretBackOutline size={15} />
                    <Text fontSize={'xs'} letterSpacing={0.5}>{t('back')}</Text>
                </HStack>
                <Spacer />
            </HStack>


            <Container maxW={'container.2xl'}>
                <Stack bgColor={'#d9edf7'} borderColor={'#bcdff1'} spacing={1} borderRadius='md' p={2} shadow={3} display={'flex'} justifyContent={'center'} width={'full'}>
                    <Box textAlign={'center'} fontSize={{base:'sm', md: 'md',lg:'2xl'}} fontStyle={'italic'}>
                    <Text dangerouslySetInnerHTML={{ __html: t('old_page_redirect', { link: '<a href="/old-order" style="color: blue; font-weight: bold;">' + t('click') + '</a>' }) }} />
                    </Box>
                </Stack>
            </Container>

            <Grid templateColumns={{ base: "1fr", md: "1fr 4fr" }}>
                <Stack p={[1, 1, 5]}>
                    <Heading size={'md'}>
                        {t('order_history_list')}
                    </Heading>
                </Stack>
                <SimpleGrid columns={[1, 3, 6]} gap={1} p={[1, 1, 5]}>
                    <Input
                        id="searchList"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        size='sm'
                        placeholder={t('order_number')}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                getDataOrder();
                            }
                        }}
                    />

                    <Input
                        id="searchList"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        size='sm'
                        placeholder={t('title')}
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                getDataOrder();
                            }
                        }}
                    />

                    <Select fontSize='sm' size={'sm'} bgColor={'white'} placeholder={t('all')} onChange={(e) => setStatus(e.target.value)}>
                        <option value="sea">{t('sea')}</option>
                        <option value="air">{t('air')}</option>
                    </Select>

                    <Input
                        id="startPaid"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        type={'date'}
                        size='sm'
                        placeholder="Start Paid Date (YYYY-MM-DD)"
                        value={startPaid}
                        onChange={handleDateChange}
                    />

                    <Input
                        id="endPaid"
                        bgColor={'white'}
                        color={colors.black}
                        fontWeight="bold"
                        fontSize={'sm'}
                        size='sm'
                        type={'date'}
                        placeholder="End Paid Date (YYYY-MM-DD)"
                        value={endPaid}
                        onChange={handleDateChange}
                    />


                    <HStack alignItems="center" justifyContent="center" space={3} >
                        <Button bgColor={colors.theme} size='sm' onClick={() => getDataOrder()}>
                            <HStack alignItems="center" justifyContent="center">
                                <Text color={colors.black}>{t('search')}</Text>
                            </HStack>
                        </Button>

                        <Button bgColor={colors.theme} size='sm' onClick={() => handleReset()}>
                            <HStack alignItems="center" justifyContent="center">
                                <Text color={colors.black}>{t('reset')}</Text>
                            </HStack>
                        </Button>
                    </HStack>

                </SimpleGrid>
            </Grid>

            {orderList.length > 0 ? (
                <Center>
                    <Stack>
                        <Stack p={[1, 1, 5]}>
                            {orderList.map((x, index) => (
                                <Stack spacing={1} key={index} bgColor={'white'} borderRadius='xl' p={[1, 1, 5]} shadow={'md'} maxW={'container.md'} >
                                    <Stack mx={3}>
                                        <Text color={'gray.600'} fontSize='xs'>{moment(x.created_at).format('LLL')}</Text>
                                    </Stack>
                                    <Grid templateColumns={{ base: '1fr', md: '1fr 2fr 3fr 1.2fr' }} gap={3} justifyContent={'space-between'} m={3}>
                                        <Stack >
                                            <Image
                                                src={x.product_image}
                                                alt={x.product_name}
                                                borderRadius={'xl'}
                                            />
                                        </Stack>

                                        <Stack spacing={3} >
                                            <Text fontSize={'lg'} fontWeight='bold'>{x.order_number}</Text>
                                            <Text color={'gray.600'} fontSize='sm' numberOfLines={2}>{x.product_name}</Text>
                                            <HStack alignItems={'center'} spacing={2} >
                                                <Stack>
                                                    {x?.platform_type && (
                                                        getCountry(x?.platform_type)
                                                    )}
                                                </Stack>

                                                <Stack>
                                                    {x.freight === "sea" ? (
                                                        <Tag size="sm" bgColor={colors.black}>
                                                            <HStack>
                                                                <FaShip size={15} color="white" />
                                                            </HStack>
                                                        </Tag>
                                                    ) : (
                                                        <Tag size="sm" bgColor={colors.black}>
                                                            <HStack>
                                                                <IoAirplaneSharp size={15} color="white" />
                                                            </HStack>
                                                        </Tag>
                                                    )}
                                                </Stack>
                                            </HStack>
                                        </Stack>


                                        <Stack>
                                            <Stack>
                                                <Text fontWeight={'bold'} fontSize='sm'>{t('estimated_total_purchase')}</Text>
                                                <Text color={'red.300'} fontSize='xs'>{t('total_price_including_tax')}</Text>
                                            </Stack>
                                            <HStack spacing={2}>
                                                {x?.last_status !== null && (
                                                    <Button shadow={'md'} size='sm' borderRadius={'lg'} bgColor={'#ffd600'} onClick={() => navigate(`/product/${x.platform_type}/${x?.product?.product_id}`)}>
                                                        <HStack>

                                                            <Text color={'gray.800'} fontWeight='bold' fontSize={'sm'}>{t('buy_again')}</Text>
                                                        </HStack>
                                                    </Button>
                                                )}
                                                <Button shadow={'md'} size='sm' borderRadius={'lg'} bgColor={'green.400'} onClick={() => handleModalDetail(x.order_number)}>
                                                    <HStack>

                                                        <Text color={'white'} fontWeight='bold' fontSize={'sm'}>Detail</Text>
                                                    </HStack>
                                                </Button>
                                            </HStack>

                                        </Stack>
                                    </Grid>
                                    <Divider />
                                    <Grid templateColumns='repeat(2, 1fr)'>
                                        <GridItem alignContent={'center'}>
                                            <Center>
                                                <Text fontSize={'sm'} color='gray.600'>{t('product_id')}</Text>
                                            </Center>
                                        </GridItem>
                                        <GridItem>
                                            <Center>
                                                <Text fontSize={'sm'}>{x?.product_id}</Text>
                                            </Center>
                                        </GridItem>
                                    </Grid>

                                    <Grid templateColumns='repeat(2, 1fr)'>
                                        <GridItem alignContent={'center'}>
                                            <Center>
                                                <Text fontSize={'sm'} color='gray.600'>Status</Text>
                                            </Center>
                                        </GridItem>
                                        <GridItem>
                                            <Center>
                                                {x?.last_status === null ? (
                                                    <HStack spacing={1} alignItems='center' justifyContent={'center'}>
                                                        <Text fontSize={'sm'}>{t('unpaid')}</Text>
                                                        <BsCircleFill size={8} color="red" />
                                                    </HStack>
                                            ) : (
                                                    <HStack spacing={1} alignItems='center' justifyContent={'center'}>
                                                        <Text fontSize={'sm'} textTransform='capitalize'>{x.last_status}</Text>
                                                        <BsCircleFill size={8} color="green" />
                                                    </HStack>
                                                )}
                                            </Center>
                                        </GridItem>
                                    </Grid>

                                    <Grid templateColumns='repeat(2, 1fr)'>
                                        <GridItem alignContent={'center'}>
                                            <Center>
                                                <Text fontSize={'sm'} color='gray.600'>{t('quantity')}</Text>
                                            </Center>
                                        </GridItem>
                                        <GridItem>
                                            <Center>
                                                <Text fontSize={'sm'}>{x?.quantity} Pcs</Text>
                                            </Center>
                                        </GridItem>
                                    </Grid>

                                    <Grid templateColumns='repeat(2, 1fr)'>
                                        <GridItem alignContent={'center'}>
                                            <Center>
                                                <Text fontSize={'sm'} color='gray.600'>{t('price')}</Text>
                                            </Center>
                                        </GridItem>
                                        <GridItem>
                                            <Center>
                                                <Text fontSize={'sm'} fontWeight='bold'>{userSymbolCurrency} {formatFrice(convertPriceFix(x?.total_price[0]?.total))}</Text>
                                            </Center>
                                        </GridItem>
                                    </Grid>
                                </Stack>
                            ))}
                            <Stack pt={5} alignItems='center' justifyContent={'center'}>
                                <Button onClick={() => handlePagination()} bgColor='#ffd600' shadow={'md'}>
                                    <HStack alignite>
                                        <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                                            {t('load_more')}
                                        </Text>
                                        <Stack>
                                            <SlArrowDown size={10} />
                                        </Stack>
                                    </HStack>
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Center>
                ) : (
                <Stack h={height / 2} alignItems={'center'} justifyContent='center' >
                    <Text color={'gray.500'} fontWeight='bold'>{t('order_unavailable')}</Text>
                </Stack>
            )
            }

        </Stack >
    )
}

export default OrderPage