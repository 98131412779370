import { Box, Flex, Grid, Heading, HStack, Image, Stack, Table, Tag, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import _axios from '../../Api/AxiosBarrier'
import { errorSlack } from '../../Api/SlackApi'
import CustomStepperSFY from '../../Components/Stepper/CustomStepperSFY'
import AuthContext from '../../Routes/hooks/AuthContext'
import ConvertPrice from '../../Utils/ConvertPrice'
import { formatFrice } from '../../Utils/Helper'
import SourceForYouChat from './SourceForYouChat'
import { useTranslation } from 'react-i18next'

function SourceForYouDetail() {

    const {t} = useTranslation();
    const [detailData, setDetailData] = useState({})


    const [currentStep, setCurrentStep] = useState('')
    const [messageStep, setMessageStep] = useState('')
    const [steps, setSteps] = useState([])

    const param = useParams()
    const path = window.location.href ?? '#'

    const { convertPriceFix } = ConvertPrice();

    const { userSymbolCurrency, loadingShow, loadingClose } = useContext(AuthContext)

    const getData = async () => {
        loadingShow()
        try {
            const res = await _axios.get(`api/sfy/detail/${param.id}`)
            if (res.status === true) {
                setDetailData(res.data)
                const dataArr = res.data.statuses.reverse()
                setCurrentStep((dataArr[0].title))
                setMessageStep((dataArr[0].message))
                setSteps(dataArr.reverse())
                loadingClose()

            } else {
                console.log(res.message, 'ini error')
                loadingClose()
                const resSlack = await errorSlack(res.message, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
            }
            loadingClose()
        } catch (error) {
            console.log(error, 'ini error')
            loadingClose()
            const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }
        loadingClose()
    }



    useEffect(() => {
        getData()

        return () => {
        }
    }, [])


    return (
        <Stack>
            <Stack p={[1, 1, 5]}>
                <Box overflowX={'scroll'}>
                    <Stack p={[1, 1, 5]} spacing={3}>

                        <Stack alignItems={'flex-start'} justifyContent='flex-start' spacing={0}>
                            <Text>{t('sfy_id')}</Text>
                            <Heading>{param.id}</Heading>
                            <Tag bgColor={'#ffd600'}>{detailData?.current_status}</Tag>
                        </Stack>

                        <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={3} >
                            <Stack spacing={3}>
                                {/* <Text fontSize='md' fontWeight='bold'>Rincian Produk</Text> */}
                                <Stack>
                                    <Stack bgColor={'white'} p={3} borderRadius='md' shadow={'md'}>
                                        <CustomStepperSFY steps={steps} currentStep={currentStep} message={messageStep} />
                                    </Stack>
                                </Stack>

                                <Stack bgColor='white' spacing={1} borderRadius='md' p={3} shadow='md'>
                                    <Table variant='striped' colorScheme='gray'>
                                        <Thead>
                                            <Tr>
                                                <Th fontSize='sm'>Title</Th>
                                                <Th fontSize='sm'>Detail</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {detailData &&
                                                <>
                                                    <Tr >
                                                        <Td fontSize='sm'>{t('product_image')}</Td>
                                                        <Td fontSize='sm'>
                                                            {detailData?.image?.length > 0 && (
                                                                <Image alt='image' width={'300px'} src={detailData?.image[0]} borderRadius='md' shadow={'md'} />

                                                            )}
                                                        </Td>
                                                    </Tr>
                                                    <Tr >
                                                        <Td fontSize='sm'>{t('product_name')}</Td>
                                                        <Td fontSize='sm'>
                                                            <Text>{detailData?.title}</Text>
                                                        </Td>
                                                    </Tr>
                                                    <Tr >
                                                        <Td fontSize='sm'>{t('product_description')}</Td>
                                                        <Td fontSize='sm'>
                                                            <Text>{detailData?.description}</Text>
                                                        </Td>
                                                    </Tr>
                                                    <Tr >
                                                        <Td fontSize='sm'>{t('product_link')}</Td>
                                                        <Td fontSize='sm' wordBreak={"break-word"}>
                                                            <Text>{detailData?.link_market}</Text>
                                                        </Td>
                                                    </Tr>
                                                    <Tr >
                                                        <Td fontSize='sm'>Status</Td>
                                                        <Td fontSize='sm'>
                                                            <Text>{detailData?.current_status}</Text>
                                                        </Td>
                                                    </Tr>
                                                    <Tr >
                                                        <Td fontSize='sm'>{t('price')}</Td>
                                                        <Td fontSize='sm'>
                                                            <Text> {userSymbolCurrency} {formatFrice(convertPriceFix(Number(detailData?.expected_price)))}</Text>
                                                        </Td>
                                                    </Tr>
                                                    <Tr >
                                                        <Td fontSize='sm'>{t('created_at')}</Td>
                                                        <Td fontSize='sm'>
                                                            <Text>{moment(detailData?.created_at).format('LLL')}</Text>
                                                        </Td>
                                                    </Tr>

                                                </>


                                            }
                                        </Tbody>
                                    </Table>
                                </Stack>

                                <Stack>
                                    <Text fontSize='md' fontWeight='bold'>{t('file')}</Text>
                                    <Stack bgColor='white' spacing={1} borderRadius='xl' p={3} shadow='md'>
                                        <Table variant='striped' colorScheme='gray'>
                                            <Thead>
                                                <Tr>
                                                    <Th fontSize='sm' textAlign='center'>Admin</Th>
                                                    <Th fontSize='sm' textAlign='center'>{t('file')}</Th>
                                                    <Th fontSize='sm' textAlign='center'>{t('message')}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {/* {detailOrder?.items?.length > 0 && detailOrder?.items?.map((x, index) => (
                                                    <Tr key={index}>
                                                        <Td fontSize='sm' >
                                                            {x.image === "" ? (
                                                                <Stack alignItems={'center'} justifyContent='center'>
                                                                    <Image src={detailOrder?.product_image} alt={x.name} w='50px' />
                                                                </Stack>
                                                            ) : (
                                                                <Stack alignItems={'center'} justifyContent='center'>
                                                                    <Image src={x.image} alt={x.name} w='50px' />
                                                                </Stack>
                                                            )}

                                                        </Td>
                                                        <Td fontSize='sm' textAlign='center'>{x.name}</Td>
                                                        <Td fontSize='sm' textAlign='center'>{x.quantity}</Td>
                                                    </Tr>
                                                ))} */}
                                            </Tbody>
                                        </Table>
                                    </Stack>
                                </Stack>

                                


                            </Stack>
                            <Stack>
                                <SourceForYouChat id={param.id} />
                            </Stack>
                        </Grid>
                        <Text fontSize='md' fontWeight='bold'>{t('simulator')}</Text>
                        <Stack bgColor='white' spacing={1} borderRadius='xl'>
                            <Box overflowX={'scroll'} p={3} shadow='md'>
                                <Table variant='striped' colorScheme='gray'>
                                    <Thead>
                                        <Tr>
                                            <Th fontSize='sm' textAlign='center'>{t('amount')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('quantity')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('bm')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('ppn')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('pph')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('shipping_fee')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('handling_fee')} (%)</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('handling_fee')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('ppn_handling_fee')}</Th>
                                            <Th fontSize='sm' textAlign='center'>{`${t('total')} ${t('hpp')}`}</Th>
                                            <Th fontSize='sm' textAlign='center'>{t('hpp')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {detailData?.simulator_publish?.length > 0 && detailData?.simulator_publish?.map((x, index) => (
                                            <Tr key={index}>
                                                <Td fontSize='sm' textAlign='center'>{x.quantity}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.total_product)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.bm_fee)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.ppn_fee)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.pph_fee)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.freight_fee)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{x.transaction_fee} %</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.ppn_transaction_fee)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.total_tax_shipping_hanlding)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.total_amount)}</Td>
                                                <Td fontSize='sm' textAlign='center'>{formatFrice(x.hpp)}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        </Stack>

                    </Stack>

                </Box>
            </Stack>
        </Stack>
    )
}

export default SourceForYouDetail