import React, { useContext, useEffect, useState } from 'react'
import { auth, db } from '../Config/firebase';
import { addDoc, arrayUnion, collection, doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { Box, Button, Divider, Heading, HStack, Image, Input, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid, Spacer, Spinner, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import colors from '../Utils/colors'
import { FaHome } from 'react-icons/fa';
import { MdAccountCircle, MdEmail, MdFlag, MdLock, MdOutlinePhoneIphone } from 'react-icons/md';
import AuthContext from '../Routes/hooks/AuthContext';
import { sendEmailVerification, updateProfile } from 'firebase/auth';
import AppHeader from '../Components/AppHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import AppHeaderV2 from '../Components/AppHeaderV2';
import { formatFrice } from '../Utils/Helper';
import { apiPaymentXenditStaging } from '../Api/importirApi';
import ConvertPrice from '../Utils/ConvertPrice';
import { errorSlack } from '../Api/SlackApi';


function PricingPage() {


    const toast = useToast({
		position: "top",
		align: "center",
	});

    const path = window.location.href ?? '#'
    
    const navigate = useNavigate()

    const { loadingShow, loadingClose, currentUser, userSymbolCurrency } = useContext(AuthContext);

    let [searchParams, setSearchParams] = useSearchParams()

    const { convertPriceFix } = ConvertPrice()


    const idCategory = searchParams.get("id")

    const banks = [
		{ name: "BNI", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bni.png" },
		{ name: "BCA", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bca.png" },
		{ name: "MANDIRI", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-mandiri.png" },
		{ name: "PERMATA", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-permatabank.png" },
		{ name: "BRI", uri: "https://www.xendit.co/wp-content/uploads/2019/11/logo-bri.png" }
	]
    const [modalPayment, setModalPayment] = useState(false)

    const [categoryActive, setCategoryActive] = useState()
    const [bankAccount, setBankAccount] = useState('')
    const [bankAccountImage, setBankAccountImage] = useState('')
    const [pricePayment, setPricePayment] = useState(0)

    const getDataCategory = async () => {
        try {
            let q = query(
                collection(db, "category"),
                where("1688-id", "==", Number(idCategory)),
            );
            const querySnapshot = await getDocs(q);
            const arrProducts = [];
            querySnapshot.forEach((doc) => {
                const objData = doc.data();
                objData.id = doc.id;
                arrProducts.push(objData);
            });
            setCategoryActive(...arrProducts);

        } catch (error) {
            console.log(error, 'ini error')
            const resSlack = await errorSlack(error, 'importir.com' , path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        }

    }

    useEffect(() => {
        getDataCategory()

        return () => {
        }
    }, [])




    const priceData = [
        // {
        //     id: 1,
        //     label: 'Free',
        //     description: 'dapatkan akses langsung terupdate kategori trending',
        //     harga: 0
        // },
        {
            id: 2,
            label: 'Monthly',
            description: 'dapatkan akses langsung terupdate kategori trending + course',
            harga: 300000
        },

    ]

    const height = window.innerHeight
    const width = window.innerWidth

    const handleModalPayment = (price) => {
        setModalPayment(true)
        setPricePayment(price)
    }

    const handleBankActive = (name, image) => {
        setBankAccount(name)
        setBankAccountImage(image)
    }

    const handlePayment = async () => {
        loadingShow()

        if (bankAccount !== "") {
            const dataBody = {
                company: "EPD",
                external_id: `BIMP-MEMBER-${moment(new Date()).valueOf()}`,
                bank_code: bankAccount,
                name: currentUser.displayName,
                expected_amount: Number(pricePayment),
                callback_url: "https://stage.importir.com/api/callback-custom-va"
            }



            try {
                const res = await apiPaymentXenditStaging(dataBody)
                if (res.status === true) {
                    console.log(res.data)


                    const ref = doc(db, "payment", currentUser.uid);
                    await setDoc(ref, {
                        uid: currentUser.uid,
                        payment_membership: arrayUnion({
                            product_pay: categoryActive,
                            product_id: idCategory,
                            name: currentUser.displayName,
                            email: currentUser.email,
                            expected_amount: Number(pricePayment),
                            external_id: `BIMP-MEMBER-${moment(new Date()).valueOf()}`,
                            company: 'EPD',
                            bank_code: bankAccount,
                            callback_url: "https://stage.importir.com/api/callback-custom-va",
                            response_payment: res.data,
                            createdAt: new Date(),
                            product_type: 'membership',
                            bank_code_image: bankAccountImage
                        }),
                        createdAt: new Date()
                    }, { merge: true });
                    toast({
                        title: 'importir.com',
                        description: 'Success create new invoice.',
                        status: 'success'
                    })
                    navigate(`/invoice/membership`)
                    setModalPayment(false)
                    loadingClose()
                }
            } catch (error) {
                loadingClose()
                toast({
                    title: 'importir.com',
                    description: error.message,
                    status: 'error'
                })

                const resSlack = await errorSlack(error, 'importir.com', path)
                console.log(resSlack, 'CONNECTED ERROR IN SLACK')
                
            }
        } else {
            toast({
                title: 'importir.com',
                description: 'pilih metode pembayaran terlebih dahulu.',
                status: 'error'
            })
            
        }



        loadingClose()
    }






    return (
        <>
            <Stack bgColor={'gray.100'} minH={height} spacing='-10'>
                <Stack pt={20} spacing={10} minH={height / 1.8} alignItems='center' justifyContent={'center'} bg="url(https://images.unsplash.com/photo-1615457938971-3ab61c1c0d57?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80) center  fixed" >
                    {/* <Stack pt={20} spacing={10} minH={height / 1.8} alignItems='center' justifyContent={'center'} bg="url(https://importir.s3.ap-southeast-1.amazonaws.com/images/sports-2020_09_15_13_12_40.jpg) no-repeat center  fixed" bgSize="cover"> */}
                    <Stack alignItems='center' justifyContent={'center'}>
                        <Heading size={'2xl'} color='blackAlpha.900'>IMPORTIR.COM</Heading>
                        <Text fontSize={'md'} color='blackAlpha.700' >berlangganan kategori {categoryActive?.category}  sekarang !</Text>
                    </Stack>

                    <Stack bgColor={'white'} p={2} borderRadius='xl' borderWidth='medium' borderColor='blue.400'>
                        <Text fontWeight={'bold'} fontSize='sm' color='blue.400'>Quarterly</Text>
                    </Stack>

                </Stack>

                <Stack>
                    {priceData !== null &&
                        <SimpleGrid columns={[1, null, 3]} gap={6} px={6}>
                            {priceData.length > 0 && (
                                priceData.map((x, index) => {
                                    return (

                                        <VStack key={index} bgColor='white' shadow={'base'} borderRadius='lg' spacing={5} p={3}>
                                            <Stack alignItems={'flex-start'} justifyContent='flex-start' w={'100%'} spacing={3}>
                                                <Image w={'30px'} src='https://buildfire.com/wp-content/themes/buildfire/assets/images/plan2@3x.png' alt='https://buildfire.com/wp-content/themes/buildfire/assets/images/plan1@3x.png' />
                                                <Text fontSize={'lg'} fontWeight="bold" color='black'>{x.label}</Text>
                                                <Text fontSize={'sm'} color='black'>{x.description}</Text>
                                                <HStack>
                                                    <Text alignSelf={'flex-start'} color={'black'} fontWeight='bold'>Rp</Text>
                                                    <Heading color={'black'} size='3xl'>{formatFrice(convertPriceFix(x.harga))}</Heading>
                                                    <Text alignSelf={'flex-end'} color={'black'} fontWeight='bold'>/ mo</Text>
                                                </HStack>
                                                <Text fontSize={'sm'} color='black'>Per month billed quarterly.</Text>

                                            </Stack>
                                            <Spacer />
                                            <Stack w={'full'}>
                                                <Button
                                                    fontSize="sm"
                                                    fontWeight="bold"
                                                    size={'sm'}
                                                    color={colors.light}
                                                    // onClick={() => checkOut(productData.price.priceId)}
                                                    // onClick={currentUser !== null ? (() => checkOut(productData.price.priceId, currentUser?.uid)) : (() => handleModal(productData.price.priceId))}
                                                    onClick={currentUser !== null ? (() => handleModalPayment(x.harga, currentUser?.uid, idCategory)) : (() => navigate('/signup'))}
                                                    bgColor={'blue.400'}
                                                >
                                                    <Text color={'white'}>{currentUser !== null ? ('Get Started') : ('Login')}</Text>
                                                </Button>
                                            </Stack>
                                        </VStack>

                                    )

                                }
                                )
                            )}
                        </SimpleGrid>
                    }
                </Stack>
                {currentUser && (
                    <Modal isOpen={modalPayment} onClose={() => setModalPayment(false)} >
                        <ModalOverlay />
                        <ModalContent >
                            <ModalHeader>Pembayaran</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody >
                                <Stack space={3} m={5}>

                                    <HStack>
                                        <Text fontSize={'sm'} color='gray.700'>Name</Text>
                                        <Spacer />
                                        <Text fontSize={'sm'} color='gray.700'>{currentUser.displayName}</Text>
                                    </HStack>

                                    <HStack>
                                        <Text fontSize={'sm'} color='gray.700'>Email</Text>
                                        <Spacer />
                                        <Text fontSize={'sm'} color='gray.700'>{currentUser.email}</Text>
                                    </HStack>

                                    <HStack>
                                        <Text fontSize={'sm'} color='gray.700'>Product</Text>
                                        <Spacer />
                                        <Text fontSize={'sm'} color='gray.700' textTransform={'capitalize'}>membership {categoryActive && categoryActive.category}</Text>
                                    </HStack>

                                    <HStack>
                                        <Text fontSize={'sm'} color='gray.700'>Product Detail</Text>
                                        <Spacer />
                                        <Image w={'50px'} src={categoryActive?.image} alt={categoryActive && categoryActive['title (en)']} />
                                    </HStack>

                                    <Stack>
                                        <Text fontSize={'sm'} color='gray.700'>Metode Pembayaran</Text>
                                        <Spacer />
                                        <Stack>
                                            <SimpleGrid columns={[2, 2, 3]} gap={3} mb={2} >
                                                {banks.map((x, index) => {
                                                    return (
                                                        <HStack spacing={3} p={2}  borderColor='blue.300' borderRadius={'md'} key={index} borderWidth={bankAccount === x.name ? 1 : 0} alignItems='center' justifyContent={'center'} cursor='pointer' onClick={() => handleBankActive(x.name, x.uri)}>
                                                            <Image src={x.uri} alt={x.name} w='60px' />
                                                        </HStack>
                                                    )
                                                })}
                                            </SimpleGrid>
                                        </Stack>
                                    </Stack>

                                    <Divider />

                                    <HStack>
                                        <Text fontSize={'sm'} color='gray.700'>Harga</Text>
                                        <Spacer />
                                        <Text fontSize={'md'} color='gray.700' fontWeight={'bold'}>{userSymbolCurrency} {formatFrice(convertPriceFix(pricePayment))}</Text>
                                    </HStack>




                                </Stack>
                            </ModalBody>

                            <ModalFooter>
                                <HStack bg="white" alignItems="center" safeAreaBottom>
                                    <Stack py="1.5" space={1} mx="auto">
                                        <VStack py="0.5" justifyContent="space-between">

                                        </VStack>
                                        <Button width="100%" onClick={() => handlePayment()} bg="green.600">
                                            <HStack alignItems={'center'} justifyContent='center' space={2}>
                                                {/* <Ionicons name="ios-cart-outline" size={25} color="white" /> */}
                                                <Text color="gray.100" fontWeight='bold'>Pay now</Text>
                                            </HStack>
                                        </Button>
                                    </Stack>
                                </HStack>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                )}

            </Stack>
        </>
    )
}

export default PricingPage