import {
	Box, Heading, Text, SimpleGrid, Modal,
	ModalOverlay,
	ModalContent, Flex,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton, Button, useDisclosure
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { getApifyActor, getApifyRuns, getApifyStorage } from '../../Api/Apify'
import { errorSlack } from '../../Api/SlackApi'

function UpdateDataPage() {

	const [actor, setActor] = useState()
	const [runs, setRuns] = useState()
	const [runsTitle, setRunTile] = useState()

	const path = window.location.href ?? '#'


	const getActor = async () => {
		try {
			const res = await getApifyActor()
			console.log(res)
			setActor(res)
		} catch (error) {
			console.log(error.message)
			const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
		}

	}

	const getRuns = async (data) => {
		setRunTile(data)
		try {
			const res = await getApifyRuns(data)
			console.log(res)
			setRuns(res)
		} catch (error) {
			console.log(error.message)
			const resSlack = await errorSlack(error, 'importir.com', path)
            console.log(resSlack, 'CONNECTED ERROR IN SLACK')
		}
	}

	useEffect(() => {
		getActor()

		return () => {
			setRunTile()
			setActor()
			setRuns()
		}
	}, [])


	return (
		<>
			<Flex>
				<Box p='2'>
					<Heading>Admin</Heading>
					<SimpleGrid gap='2'>
						{actor ? actor.items.map((x, i) =>
							<Box bgColor='gray' onClick={() => getRuns(x.name)}>
								<Text>{x.name}</Text>
								<Text>{x.id}</Text>

							</Box>
						)
							:
							<></>}
					</SimpleGrid>
				</Box>
				<Box p='2'>
					<Heading>Runs {runsTitle}</Heading>
					<SimpleGrid gap='2' columns={4}>
						{runs ? runs.items.map((x, i) =>
							<Box bgColor='gray'>
								<Text>{x.defaultDatasetId}</Text>
								<Text>{x.finishedAt}</Text>
								<Text>{x.status}</Text>
								<Text>{`https://api.apify.com/v2/datasets/${x.defaultDatasetId}/items`}</Text>
								<Button onClick={() => getApifyStorage(x.defaultDatasetId)}> push data</Button>
							</Box>
						)
							:
							<></>}

					</SimpleGrid>
				</Box>
			</Flex>
		</>
	)
}

export default UpdateDataPage