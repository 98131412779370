/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Heading,
  Text,
  useToast,
  Divider,
  Button,
  Tag,
  Grid,
  HStack,
  Spacer,
  Link,
  Box,
  SimpleGrid,
  Input
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import _axios from "../../Api/AxiosBarrier";
import { useParams } from "react-router-dom";
import { formatFrice } from "../../Utils/Helper";
import ConvertPrice from "../../Utils/ConvertPrice";
import AuthContext from "../../Routes/hooks/AuthContext";
import EscrowChat from "../../Components/Chat/EscrowChat";
import { errorSlack } from "../../Api/SlackApi";
import { FcFile } from "react-icons/fc";
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
function EscrowDetailPage() {
  const {t} = useTranslation();
  const [escrowDetail, setEscrowDetail] = useState([]);
  const params = useParams();
  const path = window.location.href ?? "#";
  const { loadingShow, loadingClose } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    other_files: [],
  });
  const [currentFile, setCurrentFile] = useState(0)

  const toast = useToast({
    position: "top",
    align: "center",
  });

  const { userSymbolCurrency } = useContext(AuthContext);

  const { convertPriceFix } = ConvertPrice();

  const getEscrowDetailData = async () => {
    try {
      const res = await _axios.get(`api/pay-care/${params.id}`);
      if (res.status === true) {
        setEscrowDetail(res.data);
        setFormData({
          other_files: res.data.other_files
        })
      } else {
        toast({
          title: "importir.com",
          description: res.message,
          status: "error",
        });
        const resSlack = await errorSlack(res.message, "importir.com", path);
        console.log(resSlack, "CONNECTED ERROR IN SLACK");
      }
    } catch (error) {
      toast({
        title: "importir.com",
        description: error.message,
        status: "error",
      });
      const resSlack = await errorSlack(error, "importir.com", path);
      console.log(resSlack, "CONNECTED ERROR IN SLACK");
    }
  };

  const handlePayment = async (billId) => {
    const host = window.location.origin;
    loadingShow();
    const { status, message, data } = await _axios.post(
      "api/pay-care/make-payment",
      {
        bill_id: billId,
        third_party: "xendit",
        invoiceId: billId,
        payment_method: "INVOICE_TRANSFER_VA",
        redirect_url: host + "/escrow/" + params.id,
      }
    );
    loadingClose();
    if (status) {
      return window.location.replace(data);
    }
    return toast({
      title: "importir.com",
      description: message,
      status: "error",
    });
  };

  const handleSubmitForm = async () => {
    try {
      loadingShow()
      const response = await _axios.post(
        `api/pay-care/upload-other-file/${params.id}`,
        formData
      );
      loadingClose()
      if (response.status === true) {
        toast({
          title: "importir.com",
          description: response.message,
          status: "success",
        });
        window.location.reload();
      }
    } catch (error) {
      alert(error.response.data.error);
      const resSlack = await errorSlack(error, "importir.com", path);
      console.log(resSlack, "CONNECTED ERROR IN SLACK");
    }
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await _axios.post(
        "api/storage?directory=general",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error, "Error uploading file");
      toast({
        title: "Error",
        description: "Failed to upload file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };

  const handleAnotherFile = async (event, index) => {
    const { files: newFiles } = event.target;

    if (newFiles.length === 1) {
      const uploadedFile = await handleFileUpload(newFiles[0]);
      if (uploadedFile) {
        setFormData((prevState) => {
          const updatedOtherFiles = [...prevState.other_files];
          setCurrentFile(index)
          updatedOtherFiles[index] = {
            inc: index,
            file_url: uploadedFile,
            description: prevState.other_files[index].description,
          };
          return {
            ...prevState,
            other_files: updatedOtherFiles,
          };
        });
      }
    }
  };

  const addAnotherFiles = () => {
    setFormData((prevData) => {
      const currentFilesArray = prevData.other_files || [];
  
      const newFile = {
        inc: currentFile,
        file_url: '',
        description: ''
      };
  
      const updatedFilesArray = [...currentFilesArray, newFile];
      return {
        ...prevData,
        other_files: updatedFilesArray,
      };
    });
  };

  const deleteAnotherFiles = (index) => {
    Swal.fire({
      title: "Are you sure to delete this file?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData((prevData) => {
          const otherFilesArray = prevData.other_files.filter(
              (_, i) => i !== index
          );
          return {
              ...prevData,
              other_files: otherFilesArray,
          };
        });
      }
    });
  };

  useEffect(() => {
    getEscrowDetailData();
  }, []);
  return (
    <Stack p={[1, 1, 5]}>
      <Stack
        mt={[10, 10, 0]}
        alignItems={"flex-start"}
        justifyContent="flex-start"
        spacing={0}
        py={1}
      >
        <Text>Order ID</Text>
        <Heading>{params.id}</Heading>
        <Tag bgColor={"#ffd600"}>{t(`escrow_statuses.${escrowDetail?.last_status}`)}</Tag>
      </Stack>
      <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={3}>
        <Stack>
          <Stack bg={"white"} shadow="md" borderRadius={"md"} p={5} spacing={5}>
            <Heading size={"md"}>{t('supplier_data')}</Heading>
            <Stack fontSize={"sm"}>
              <HStack>
                <Text textAlign={"center"}>{t('supplier_email')}</Text>
                <Spacer />
                <Text alignContent={"center"} textAlign={"center"}>
                  {escrowDetail?.supplier_email}
                </Text>
              </HStack>

              <HStack>
                <Text textAlign={"center"}>{t('supplier_phone')}</Text>
                <Spacer />
                <Text alignContent={"center"} textAlign={"center"}>
                  {escrowDetail?.supplier_phone}
                </Text>
              </HStack>

              <HStack>
                <Text textAlign={"center"}>{t('supplier_wechat')}</Text>
                <Spacer />
                <Text alignContent={"center"} textAlign={"center"}>
                  {escrowDetail?.supplier_wechat}
                </Text>
              </HStack>
            </Stack>
          </Stack>

          <Stack bg={"white"} shadow="md" borderRadius={"md"} p={5} spacing={5}>
            <Heading size={"md"}>{t('total')}</Heading>
            <HStack fontSize={"sm"}>
              <Text textAlign={"center"}>{t('total')} (RMB)</Text>
              <Spacer />
              <Text alignContent={"center"} textAlign={"center"}>
                {escrowDetail?.amount_rmb}
              </Text>
            </HStack>
          </Stack>

          <Stack bg={"white"} shadow="md" borderRadius={"md"} p={5} spacing={5}>
            <Heading size={"md"}>{t('invoice_file')}</Heading>
            <HStack fontSize={"sm"}>
              <Text textAlign={"center"}>{t('invoice_file')}</Text>

              <Spacer />
              {escrowDetail?.invoice_file !== undefined ? (
                <Link href={escrowDetail?.invoice_file.file_url} isExternal>
                  <Button bgColor={"#ffd600"} size="sm">
                    <Text>Details</Text>
                  </Button>
                </Link>
              ) : (
                <Text>-</Text>
              )}
            </HStack>
          </Stack>

          <Stack bg={"white"} shadow="md" borderRadius={"md"} p={5} spacing={5}>
            <Heading size={"md"}>{t('supplier_file')}</Heading>
            {escrowDetail &&
            escrowDetail?.supplier_files &&
            escrowDetail?.supplier_files.length > 0 ? (
              escrowDetail?.supplier_files.map((item) => {
                return (
                  <HStack fontSize={"sm"}>
                    <Text>{item.description}</Text>
                    <Spacer />

                    <Link href={item?.file_url} isExternal>
                      <Button bgColor={"#ffd600"} size="sm">
                        <Text>Details</Text>
                      </Button>
                    </Link>
                  </HStack>
                );
              })
            ) : (
              <Text>-</Text>
            )}
          </Stack>

          <Stack bg={"white"} shadow="md" borderRadius={"md"} p={5} spacing={5}>
            <Heading size={"md"}>{t('admin_file')}</Heading>
            {escrowDetail &&
            escrowDetail?.admin_files &&
            escrowDetail?.admin_files.length > 0 ? (
              escrowDetail?.admin_files.map((item) => {
                return (
                  <HStack fontSize={"sm"}>
                    <Text>{item.description ?? "---"}</Text>
                    <Spacer />

                    <Link href={item?.file_url} isExternal>
                      <Button bgColor={"#ffd600"} size="sm">
                        <Text>Details</Text>
                      </Button>
                    </Link>
                  </HStack>
                );
              })
            ) : (
              <Text>-</Text>
            )}
          </Stack>

          <Stack bg={"white"} shadow="md" borderRadius={"md"} p={5} spacing={5}>
            <Heading size={"md"}>{t('supplier_paid_file')}</Heading>
            {escrowDetail &&
            escrowDetail?.supplier_paid_files &&
            escrowDetail?.supplier_paid_files.supplier_payment_files.length >
              0 ? (
              escrowDetail?.supplier_paid_files.supplier_payment_files.map(
                (item) => {
                  return (
                    <HStack fontSize={"sm"}>
                      <Link href={item?.link} isExternal>
                        <Button bgColor={"#ffd600"} size="sm">
                          <Text>Details</Text>
                        </Button>
                      </Link>
                    </HStack>
                  );
                }
              )
            ) : (
              <Text>-</Text>
            )}
          </Stack>

          <Stack>
            <Box
              spacing={3}
              bgColor="white"
              borderRadius={"xl"}
              shadow="sm"
              p={5}
            >
              <Heading size={"lg"} py={5} px={4}>
                {t('other_file')}
              </Heading>
              {formData.other_files &&
                formData.other_files.map((item, index) => {
                  return (
                    <SimpleGrid
                      columns={1}
                      gap={4}
                      spacing={10}
                      py={5}
                      px={4}
                      key={index}
                    >
                      <Box>
                        <Text mb="8px">{t('other_file')}</Text>
                        <HStack spacing={2} alignItems="center">
                          <Stack>
                            <Input
                              type="file"
                              display="none"
                              name="file_url"
                              id={`inputFile-${index}`}
                              multiple
                              onChange={(event) =>
                                handleAnotherFile(event, index)
                              }
                            />
                            <label htmlFor={`inputFile-${index}`}>
                              <HStack cursor="pointer">
                                <Text fontSize="sm">{`${t('add')} ${t('image')} / ${t('video')}`}</Text>
                              </HStack>
                            </label>
                          </Stack>
                        </HStack>

                        <SimpleGrid columns={[1, 2, 3]} gap={3}>
                          {formData.other_files[index].file_url && (
                            <a href={formData.other_files[index].file_url} target='_blank'>
                              <FcFile size={50} />
                            </a>
                          )}
                        </SimpleGrid>
                      </Box>
                      <Box>
                        <Text mb="8px">{t('description')}</Text>
                        <Input
                          placeholder="Here is a sample placeholder"
                          defaultValue={item.description}
                          size="lg"
                          name="description"
                          px={3}
                          py={4}
                          borderRadius={"md"}
                          onChange={(event) => {
                            const newDescription = event.target.value;
                            setFormData((prevState) => {
                              const updatedOtherFiles = [
                                ...prevState.other_files,
                              ];
                              updatedOtherFiles[index] = {
                                ...updatedOtherFiles[index],
                                description: newDescription,
                              };
                              return {
                                ...prevState,
                                other_files: updatedOtherFiles,
                              };
                            });
                          }}
                        />
                      </Box>
                      {
                        formData.other_files.length-1 === index ?  
                        <Stack w={'16'}>
                            <Button colorScheme='red' onClick={() => deleteAnotherFiles(index)}>{t('delete')}</Button>
                        </Stack>
                        :
                        ""
                      }
                    </SimpleGrid>
                  );
                })}
              <Button colorScheme="green" onClick={addAnotherFiles}>
                {t('add')}
              </Button>
              <Button colorScheme="blue" ml={"5"} onClick={handleSubmitForm}>
                {t('submit')}
              </Button>
            </Box>
          </Stack>
        </Stack>

        <Stack>
          <Stack bg={"white"} shadow="md" borderRadius={"md"} p={5} spacing={5}>
            <Heading size={"md"}>{t('bill_detail')}</Heading>
            {escrowDetail?.bills?.length > 0 ? (
              escrowDetail?.bills?.map((item, index) => (
                <Stack key={index} fontSize={"sm"}>
                  {item?.bill_details.length > 0 &&
                    item.bill_details.map((x, index) => (
                      <HStack key={index}>
                        <Text>{x?.title}</Text>
                        <Spacer />
                        <Text>
                          {" "}
                          {userSymbolCurrency}{" "}
                          {formatFrice(convertPriceFix(x?.amount))}
                        </Text>
                      </HStack>
                    ))}

                  <HStack>
                    <Text>{t('total')}</Text>
                    <Spacer />
                    <Text>
                      {" "}
                      {userSymbolCurrency}{" "}
                      {formatFrice(convertPriceFix(item?.amount))}
                    </Text>
                  </HStack>

                  <HStack>
                    <Text>Status</Text>
                    <Spacer />
                    <Text> {item?.paid_at === null ? `${t('unpaid')}` : `${t('paid')}`}</Text>
                  </HStack>

                  <Divider />
                  {escrowDetail?.last_status === "admin confirm" &&
                  item?.paid_at === null ? (
                    <Stack alignItems={"end"}>
                      <Button
                        bgColor={"blue.500"}
                        textColor={"white"}
                        width={"20%"}
                        onClick={() => handlePayment(item.id)}
                      >
                        {t('pay')}
                      </Button>
                    </Stack>
                  ) : (
                    ""
                  )}
                </Stack>
              ))
            ) : (
              <Text>-</Text>
            )}
            {/* </SimpleGrid> */}
          </Stack>

          <EscrowChat id={params.id} />
        </Stack>
      </Grid>
    </Stack>
  );
}

export default EscrowDetailPage;
