/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  HStack,
  Button,
  Text,
  Heading,
  Spacer,
  Flex,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { SlArrowDown } from 'react-icons/sl'
import _axios from '../../Api/AxiosBarrier'
import AuthContext from '../../Routes/hooks/AuthContext'
import { useNavigate } from 'react-router-dom'
import { errorSlack } from '../../Api/SlackApi'
import { useTranslation } from 'react-i18next'

function EscrowPage() {
  const {t} = useTranslation();
  const { loadingShow, loadingClose } = useContext(AuthContext)
    const [count, setCount] = useState(1)
    const [dataList, setDataList] = useState([])
    const navigate = useNavigate()
    const height = window.innerHeight

    const path = window.location.href ?? '#'

  const getDataEscrow = async () => {
    loadingShow()
    let escrowArr = []
    try {
        const res = await _axios.get(`api/pay-care?page=${count}`)
        if (res) {
            const dataArr = res.data.pay_care
            escrowArr.push(...dataArr)
        }
        if (count > 1) {
            setDataList([...dataList, ...escrowArr])
        } else {
            setDataList(escrowArr)
        }
        loadingClose()
    } catch (error) {
        console.log(error)
        const resSlack = await errorSlack(error, 'importir.com', path)
        console.log(resSlack, 'CONNECTED ERROR IN SLACK')
        loadingClose()
    }
    loadingClose()
  }

  const handlePagination = async () => {
    setCount(count + 1)
    getDataEscrow(count)
  }

  useEffect(() => {
    getDataEscrow()

    return () => {
    }
  }, [])

  return (
    <Stack bg={'white'} p={[1, 1, 5]} shadow='md' borderRadius={'md'}  minH={height/1.2} mt={[10, 10, 0]} >
      <HStack alignItems={'center'} justifyContent='center'  m={5}>
        <Heading size={'md'}>
          Escrow
        </Heading>
        <Spacer />
          <Button onClick={() => navigate('/escrow/create')} bgColor='blue' shadow={'md'} zIndex={'99999'}>
              <HStack >
                  <></>
                  <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'} textColor={'white'}>
                      {t('create_order')}
                  </Text>
              </HStack>
          </Button>
      </HStack>
      <TableContainer px={4} py={5}>
        <Table variant='simple'>
          <Thead bg={'gray.300'}>
            <Tr>
              <Th>Order ID</Th>
              <Th>{t('total')} (RMB)</Th>
              <Th>{t('last_status')}</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {dataList.length > 0 ?
              dataList.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td>{item.order_number}</Td>
                    <Td>{item.amount_rmb}</Td>
                    <Td>{item.last_status}</Td>
                    <Td>
                      <Button onClick={() => navigate(`/escrow/${item.order_number}`)} bgColor='#ffd600' shadow={'md'} zIndex={'99999'}>
                        <HStack alignItems='center'>
                          <Text fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                            Detail
                          </Text>
                        </HStack>
                      </Button>
                      {
                        item.last_status === 'customer ready' || item.last_status === 'Waiting Approval Admin' ?
                        <Button onClick={() => navigate(`/escrow-edit/${item.order_number}`)} colorScheme='whatsapp' shadow={'md'} ml={'5'} zIndex={'99999'}>
                          <HStack alignItems='center'>
                            <Text color={'white'} fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                              {t('edit')}
                            </Text>
                          </HStack>
                        </Button>
                        : <Text></Text>
                      }
                    </Td>
                  </Tr>
                )
              })
              :
              <Flex flex={1}  h={'100%'} alignItems='center' justifyContent={'center'}>
              <Text color={'gray.600'}>{t('data_not_found')}</Text>
              </Flex>
            }
          </Tbody>
        </Table>
      </TableContainer>

      <Stack py={5} alignItems='center' justifyContent={'center'}>
          <Button onClick={() => handlePagination()} bgColor='#ffd600' shadow={'md'}>
              <HStack alignite>
                  <Text alignItems='center' fontSize={'sm'} textTransform={'capitalize'} justifyContent={'center'}>
                      {t('load_more')}
                  </Text>
                  <Stack>
                      <SlArrowDown size={10} />
                  </Stack>
              </HStack>
          </Button>
      </Stack>
    </Stack>
  )
}

export default EscrowPage