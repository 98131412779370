import { Box, Button, Center, Input, InputGroup, Select, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, WrapItem } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import _axios from '../../Api/AxiosBarrier';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../Routes/hooks/AuthContext';

function IndexPage() {
  const { loadingShow, loadingClose } = useContext(AuthContext)
  const [hscodes, setHscodes] = useState([])
  const height = window.innerHeight
  const [keyword, setKeyword] = useState("")
  const [type, setType] = useState("hscode")
  const [pageNow, setPageNow] = useState(1)
  const navigate = useNavigate()

  const getData = async (url = null, page = 1) => {
    loadingShow()
    const res = await _axios.get(url === null ? "api/hscode/search" : url)
    loadingClose()
    if (page === 1) {
      setHscodes(res.data)
    } else {
      setHscodes(arr => [...arr, ...res.data])
    }
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitHscode = async (page = 1) => {
    let param = `keyword=${keyword}&page=${page}`;
    const url = `api/hscode/search?${param}`
    await getData(url, page)
  }

  const loadMore = () => {
    setPageNow(pageNow+1)
    submitHscode(pageNow+1)
  }

  return (
    <Stack bgColor={'gray.100'} p={[1, 1, 5]} minH={height}>
      <Center>
        <Text fontWeight={'bold'} fontSize={'3xl'}>List Hscode</Text>
      </Center>
      <Box bgColor={'white'} p={2} shadow={'2xl'} borderRadius={'xl'}>
        <Stack mx={10}>
          <InputGroup>
            <Input type='text' placeholder='Input your hscode or desc' onChange={(e) => setKeyword(e.target.value)} />
            <Button onClick={() => submitHscode()} ml={2} p={5} color={'white'} bgColor={'blue.300'}>Search</Button>
          </InputGroup>
        </Stack>
        <Table variant='striped' colorScheme='teal'>
          <Thead>
            <Tr>
              <Th>Hscode</Th>
              <Th>Product Description</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              hscodes.map((hs, index) => (
                <Tr key={index}>
                  <Td>{hs.hs_format}</Td>
                  <Td overflow={false}>
                    <WrapItem>{hs.desc_text}</WrapItem>
                  </Td>
                  <Td>
                    {
                      hs.hs_format.length === 8 ? 
                        <Button bgColor={'green.200'} onClick={() => navigate('/hscode/'+hs.hs_format)}>Detail</Button>
                      : ""
                    }
                  </Td>
                </Tr>
              ))    
            }
          </Tbody>
        </Table>
        <Center mt={2}>
          <Button onClick={() => loadMore()}>Load More</Button>
        </Center>
      </Box>
    </Stack>
  )
}

export default IndexPage